import { ApiAvatar, ApiEmailConfirmation, ApiInaccurateLocation, ApiIncluded, ApiSubscription, ApiUser, ApiUserProfile } from '@app/constants/ApiTypes/entities'

import { ApiActionBuilderDescriptor } from '@app/store/apiMiddleware/builderDescriptor'
import { createPayloadlessAction } from '@app/store/toolkit'

export const profileRequestActionDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('PROFILE_REQUEST')
  .setShape<{ data: ApiUserProfile; included?: ApiIncluded }>()
export const userRequestActionDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('USER_REQUEST')
  .setShape<{ data: ApiUser; included?: (ApiSubscription | ApiAvatar | ApiInaccurateLocation)[] }, { token: string }>()
export const getUsersByIdsDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('USERS_REQUEST_ACTION')
  .setShape<{ data: ApiUser[]; included?: (ApiSubscription | ApiAvatar | ApiInaccurateLocation)[] }>()
export const sendEmailConfirmationDescriptor = new ApiActionBuilderDescriptor().setLabel('SEND_EMAIL_CONFIRMATION').setShape<{ data: ApiEmailConfirmation }>()
export const resendEmailConfirmationDescriptor = new ApiActionBuilderDescriptor().setLabel('RESEND_EMAIL_CONFIRMATION').setShape<unknown>()
export const hideEmailConfirmationMessage = createPayloadlessAction<'HIDE_EMAIL_CONFIRMATION_MESSAGE'>('HIDE_EMAIL_CONFIRMATION_MESSAGE')
