import React, { FunctionComponent } from 'react'

import { noop } from '@app/utils/noop'

import { ControlledSuggestInput } from '@app/components/SuggestInput/ControlledSuggestInput'
import { SuggestInputTheme } from '@app/components/SuggestInput/SuggestInput'

import {
  defaultLabelExtractor,
  PlaceInputInputValue,
  PlaceInputLabelExtractor,
  PlaceInputOnBlurCallBack,
  PlaceInputOnChangeCallback,
  PlaceInputOnFocusCallback,
} from './shared'
import { useController } from './useController'

export interface PlaceInputProps {
  className?: string
  initiator: string
  value?: PlaceInputInputValue | null
  placeholder?: string
  onChange?: PlaceInputOnChangeCallback
  onFocus?: PlaceInputOnFocusCallback
  onBlur?: PlaceInputOnBlurCallBack
  extractLabel?: PlaceInputLabelExtractor
  disabled?: boolean
  error?: Error | boolean
  detectLocation?: boolean
  theme?: SuggestInputTheme
  map?: boolean
}

export const PlaceInput: FunctionComponent<PlaceInputProps> = ({
  className,
  initiator,
  value = null,
  placeholder = '',
  onChange = noop,
  onFocus = noop,
  onBlur = noop,
  extractLabel = defaultLabelExtractor,
  disabled,
  error,
  detectLocation = false,
  theme,
  map = true,
}) => {
  const { id, buttons, fetchSuggestions, handleSelected, getValue, renderSuggestion } = useController(
    value,
    initiator,
    onChange,
    extractLabel,
    error,
    detectLocation,
    map
  )

  return (
    <ControlledSuggestInput
      buttons={buttons}
      className={className}
      disabled={disabled}
      error={error}
      fetchSuggestions={fetchSuggestions}
      getSuggestionValue={getValue}
      onBlur={onBlur}
      onChange={handleSelected}
      onFocus={onFocus}
      placeholder={placeholder}
      renderSuggestion={renderSuggestion}
      resettable={false}
      selectAllOnFocus={true}
      theme={theme}
      value={id ?? ''}
    />
  )
}
