import React, { FunctionComponent, useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'

import { APP_INFO, EXTERNAL_LINKS_REL } from '@app/constants/Misc'

import { ensureType } from '@app/utils/ensureType'
import { useRouter } from '@app/utils/routing/hooks'

import { profileUserAccountTypeSelector } from '@app/store/selectors/profile'

import classes from './AppStoreIcons.module.scss'

export type AppStoreIconsProps = {
  version?: 'parent' | 'sitter'
}

export const AppStoreIcons: FunctionComponent<AppStoreIconsProps> = ({ version }) => {
  const { formatMessage } = useIntl()
  const router = useRouter()
  const profileAccountType = useSelector(profileUserAccountTypeSelector)
  const account_type = (version ?? router.location.pathname.startsWith('/babysitter')) ? 'sitter' : profileAccountType === 'sitter' ? 'sitter' : 'parent'
  const links = useMemo(
    () => ({
      app_store: APP_INFO[account_type].ios.url,
      google_play: APP_INFO[account_type].android.url,
      galaxy_store: APP_INFO[account_type].galaxy.url,
      apk: `/apps/android/${account_type}/latest.apk`,
    }),
    [account_type]
  )

  return (
    <div className={classes.root}>
      {(['app_store', 'google_play', 'galaxy_store', 'apk'] as const).map(key => {
        return (
          <a className={classes.item} href={links[key]} key={key} rel={key === 'apk' ? undefined : EXTERNAL_LINKS_REL} target="_blank">
            <img
              alt={formatMessage(messages[key])}
              src={images[key][0]}
              srcSet={images[key].map((src, index) => `${src}${index ? ` ${index + 1}x` : ''}`).join(', ')}
            />
          </a>
        )
      })}
    </div>
  )
}

const images = {
  app_store: ensureType<[string, string, string]>([
    require('./assets/app_store.png'),
    require('./assets/app_store@2x.png'),
    require('./assets/app_store@3x.png'),
  ]),
  google_play: ensureType<[string, string, string]>([
    require('./assets/google_play.png'),
    require('./assets/google_play@2x.png'),
    require('./assets/google_play@3x.png'),
  ]),
  galaxy_store: ensureType<[string, string, string]>([
    require('./assets/galaxy_store.png'),
    require('./assets/galaxy_store@2x.png'),
    require('./assets/galaxy_store@3x.png'),
  ]),
  apk: ensureType<[string, string, string]>([require('./assets/apk.png'), require('./assets/apk@2x.png'), require('./assets/apk@3x.png')]),
}

const messages = defineMessages({
  app_store: 'Скачать в App Store',
  google_play: 'Скачать в Google Play',
  galaxy_store: 'Скачать в Galaxy Store',
  apk: 'Скачать APK',
})
