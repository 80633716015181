import classes from './Static.module.scss'

export const Static = {
  section: classes.section,
  menu: classes.menu,
  hashlink: classes.hashlink,
  content: classes.content,
  page: classes.page,
  isActive: classes.is_active,
}
