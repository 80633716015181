import { ServerState } from '@app/constants/StoreTypes'

import { postServer } from '@app/store/actions/server.descriptors'
import { createReducer } from '@app/store/toolkit'

/** available only on server, on client is null */
export default createReducer<ServerState | null>(null, builder => {
  builder.addCase(postServer.shape, (_state, action) => {
    return action.payload
  })
})
