import { ApiDepersonalizedAnnouncement, ApiInaccurateLocation } from '@app/constants/ApiTypes/entities'

import { ApiActionBuilderDescriptor } from '@app/store/apiMiddleware/builderDescriptor'

import { PointsMapPoint } from '@app/components/PointsMap/PointsMapPoint'

export const getDepersonalizedAnnouncementsSearchCountDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('GET_DEPERSONALIZED_ANNOUNCEMENTS_SEARCH_COUNT')
  .setShape<{
    data: ApiDepersonalizedAnnouncement[]
    meta: {
      total: number
      total_pages: number
      current_page: number
      cursor: string
    }
    included?: ApiInaccurateLocation[]
  }>()

export const getDepersonalizedAnnouncementsSearchDescriptor = new ApiActionBuilderDescriptor().setLabel('GET_DEPERSONALIZED_ANNOUNCEMENTS_SEARCH').setShape<{
  data: ApiDepersonalizedAnnouncement[]
  meta: {
    total: number
    total_pages: number
    current_page: number
    cursor: string
  }
  included?: ApiInaccurateLocation[]
}>()

export const getDepersonalizedAnnouncementsByQueryDescriptor = new ApiActionBuilderDescriptor().setLabel('GET_DEPERSONALIZED_ANNOUNCEMENTS_BY_QUERY').setShape<{
  data: ApiDepersonalizedAnnouncement[]
  included?: ApiInaccurateLocation[]
}>()

export const getDepersonalizedAnnouncementsSearchMapDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('GET_DEPERSONALIZED_ANNOUNCEMENTS_SEARCH_MAP')
  .setShape<{ data: PointsMapPoint[]; meta: { initial: boolean; cursor: string } }>()
