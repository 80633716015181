import { LocationDescriptorObject, RouterState } from '@app/utils/routing/types'

import { createAction, createPayloadlessAction } from '@app/store/toolkit'

export const beforeNavigate = createAction<
  'ROUTER_BEFORE_NAVIGATE',
  {
    state: RouterState
    initial: boolean
  }
>('ROUTER_BEFORE_NAVIGATE')

export const navigate = createAction<'ROUTER_NAVIGATE', RouterState>('ROUTER_NAVIGATE')

export const push = createAction<'HISTORY_PUSH', LocationDescriptorObject>('HISTORY_PUSH')
export const replace = createAction<'HISTORY_REPLACE', LocationDescriptorObject>('HISTORY_REPLACE')
export const refresh = createPayloadlessAction('HISTORY_REFRESH')
