import { fetchRequestsDescriptor } from '@app/store/actions/request.descriptors'
import { beforeNavigate } from '@app/store/actions/router.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  history: boolean
  loading: boolean
  next_page: number | null
  page: number | null
  per_page: number
  prev_page: number | null
  total: number | null
  total_pages: number | null
}>(
  {
    history: false,
    loading: false,
    page: null,
    per_page: 20,
    next_page: null,
    prev_page: null,
    total: null,
    total_pages: null,
  },
  builder => {
    builder.addCase(beforeNavigate.shape, (state, action) => {
      if (!action.payload.initial) {
        return { ...state, history: false }
      }
      return state
    })

    builder.addCase(fetchRequestsDescriptor.shapes.fulfilled, (state, action) => {
      const page = action.payload.meta.current_page
      const per_page = action.meta.query.per_page
      const prev_page = action.meta.query.page === 1 ? null : action.meta.query.page - 1
      const next_page = action.meta.query.page >= action.payload.meta.total_pages ? null : action.meta.query.page + 1
      return { ...state, ...action.payload.meta, page, per_page, prev_page, next_page, history: true, loading: false }
    })

    builder.addCase(fetchRequestsDescriptor.shapes.pending, (state, _action) => {
      return { ...state, loading: true }
    })

    builder.addCase(fetchRequestsDescriptor.shapes.rejected, (state, _action) => {
      return { ...state, loading: false }
    })
  }
)
