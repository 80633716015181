import { checkErrorRecursively } from '@app/utils/checkErrorRecursively'

export class FetchError extends Error {}

export class ApiError extends Error {
  status?: number
  description?: string
  response!: any

  constructor(status: number, description: string, response: any) {
    super(`${status} - ${description}`)
    this.status = status
    this.description = description
    this.response = response
  }
}

export const isApiError = (e: unknown): e is ApiError => e instanceof ApiError

export const asApiError = (e: unknown) => (isApiError(e) ? e : null)

export class RequestError extends Error {
  status?: number
  description?: string
  body: string = ''

  static create(status: number, description: string, body: string) {
    let message = `${status}`
    if (description) message += ` - ${description}`
    if (body) message += `: ${body}`
    const err = new RequestError(message)
    err.status = status
    err.description = description
    err.body = body
    return err
  }
}

export const isRequestError = (e: any): e is RequestError => e instanceof RequestError

export const asRequesError = (e: any) => (isRequestError(e) ? e : null)

export const isNetworkError = (e: any): e is ApiError | RequestError => isApiError(e) || isRequestError(e)

export const asNetworkError = (e: any) => (isNetworkError(e) ? e : null)

export const isNetworkFailError = (e: unknown) =>
  checkErrorRecursively(
    e,
    e =>
      (e instanceof FetchError && /network/i.test(e.message)) || (e instanceof Error && (/failed to fetch/i.test(e.message) || /load failed/i.test(e.message)))
  )
