/**
 * Wrapper for app runner, which function is to load polyfills first
 */

import './clientPolyfills'

import { StoreSerializer } from '@app/services/StoreSerializer'

import { loadBrowserIntlPolyfill } from '@app/utils/IntlUtils'
import { history as browserHistory } from '@app/utils/routing/BrowserRouter'
import { loadUnorm } from '@app/utils/unormPolyfill'

async function app() {
  if ((window as any).ERROR) return

  const state = new StoreSerializer().deserialize((window as any).storeState)

  await Promise.all([loadBrowserIntlPolyfill(state.locale), loadUnorm()])

  const { ClientRunner } = await import(/* webpackMode: "eager" */ './run.client')
  const runner = new ClientRunner()
  await runner.load(state)
  runner.run(browserHistory.location)
}

app()
