import { IMPORT_MAP } from '@app/importMap'

import { AsyncRoute } from '@app/utils/routing/types'

const RedirectToRoot = () => IMPORT_MAP.components.Redirect().then(m => m.createRedirect('/'))
const Announcements = () => IMPORT_MAP.routes.Announcements.SitterAnnouncements().then(m => m.default)

// @routes declaration
const routes: AsyncRoute[] = [
  {
    path: '/',
    event_id: 'announcements',
    component: Announcements,
  },
  {
    path: '/announcements',
    event_id: 'announcements',
    component: RedirectToRoot,
  },
  {
    path: '/announcements/:id',
    event_id: 'announcements',
    event_params: {
      announcement_id: 'id',
    },
    component: Announcements,
  },
]

export default routes
