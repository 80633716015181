import { ApiEmailToken } from '@app/constants/ApiTypes/entities'

import { isAbortError } from '@app/errors/AbortError'

import { SerializableError } from '@app/utils/SerializableError'

import { verifyEmailTokenDescriptor } from '@app/store/actions/emailToken.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  verification_state: null | { error: true; data: Error } | { error: false; data: ApiEmailToken }
  verification_loading: boolean
}>(
  {
    verification_state: null,
    verification_loading: false,
  },
  builder => {
    builder.addCase(verifyEmailTokenDescriptor.shapes.pending, (state, _action) => {
      return { ...state, verification_loading: true }
    })
    builder.addCase(verifyEmailTokenDescriptor.shapes.fulfilled, (_state, action) => {
      return { verification_state: { error: false, data: action.payload.data }, verification_loading: false }
    })
    builder.addCase(verifyEmailTokenDescriptor.shapes.rejected, (_state, action) => {
      return {
        verification_state: isAbortError(action.payload) ? null : { error: true, data: SerializableError.create(action.payload) },
        verification_loading: false,
      }
    })
  }
)
