import loggerCreator from './loggerCreator'

const log = loggerCreator('ga', 'cadetblue', undefined, true)
let gaProxy = (...args) => process.env.NODE_ENV !== 'production' && log(...args)

function ga(...args) {
  gaProxy(...args)
}

ga.init = function init(trackingCode) {
  ;(function (i, s, o, g, r, a, m) {
    i['GoogleAnalyticsObject'] = r
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    ;(i[r] =
      i[r] ||
      function () {
        ;(i[r].q = i[r].q || []).push(arguments)
      }),
      (i[r].l = 1 * new Date())
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    ;(a = s.createElement(o)), (m = s.getElementsByTagName(o)[0])
    a.async = 1
    a.src = g
    m.parentNode.insertBefore(a, m)
  })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga')

  window.ga('create', trackingCode, 'auto')
  gaProxy = (...args) => window.ga(...args)
}

export default ga
