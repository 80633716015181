import { processRoutes } from '@app/utils/processRoutes'
import { normalizePath } from '@app/utils/url'

import { setPlaceByRegion } from '@app/store/actions/profile'
import { profilePlaceStateSelector, profileRegionIsChangeableSelector, profileRegionSlugSelector } from '@app/store/selectors/profile'
import { availableRegionsSlugsSelector, defaultRegionSelector } from '@app/store/selectors/regions'
import { createThunk } from '@app/store/thunk'

import { getRegionFromPath, matchRegion, trimRegionalRoute } from './region'
import { StaticRedirect } from './StaticRedirect'
import { LocationDescriptorObject, MatchedRoute, Route } from './types'

export const performRegionCheck = (location: LocationDescriptorObject, matchedRoutes: MatchedRoute[]) => {
  return createThunk(async dispatch => {
    return await processRoutes(matchedRoutes, async ({ route }) => {
      const result = dispatch(checkRegionDifference(route, location))
      if (result?.type === 'promise') return { ...result, stop: true }
      return result ?? undefined
    })
  })
}

const checkRegionDifference = (route: Route, location: LocationDescriptorObject) =>
  createThunk((dispatch, getState): { type: 'promise'; promise: () => Promise<void> } | { type: 'redirect'; redirect: StaticRedirect } | null => {
    if (!route.regional) return null

    const state = getState()
    const placeState = profilePlaceStateSelector(state)
    const manageRegion = profileRegionIsChangeableSelector(state)
    const profileRegionSlug = profileRegionSlugSelector(state)
    const regionsList = availableRegionsSlugsSelector(state)
    const pathRegionSlug = getRegionFromPath(regionsList, location.pathname ?? '')

    if (!manageRegion) {
      if (pathRegionSlug) {
        return {
          type: 'redirect',
          redirect: new StaticRedirect({
            ...location,
            pathname: normalizePath(`/${trimRegionalRoute(regionsList, location.pathname ?? '')}`),
            state: { ...location.state, hard: location.state?.hard ?? false },
          }),
        }
      }
      return null
    }

    if (profileRegionSlug === pathRegionSlug) return null

    if (!pathRegionSlug || placeState.change) {
      return {
        type: 'redirect',
        redirect: new StaticRedirect({
          ...location,
          pathname: normalizePath(`/${profileRegionSlug ?? ''}${trimRegionalRoute(regionsList, location.pathname ?? '')}`),
          state: { ...location.state, hard: location.state?.hard ?? false },
        }),
      }
    }

    return { type: 'promise', promise: () => dispatch(setPlaceByRegionSlug(pathRegionSlug)) }
  })

const setPlaceByRegionSlug = (slug: string) =>
  createThunk(async (dispatch, getState) => {
    const state = getState()
    const regionsList = availableRegionsSlugsSelector(state)
    const defaultRegion = defaultRegionSelector(state)
    const matchedSlug = matchRegion(regionsList, slug)
    const region = !matchedSlug ? defaultRegion : Object.values(getState().regions.models).find(r => r.attributes.slug === matchedSlug)
    if (!region) throw new Error(`Can't find region: ${region}`)

    return dispatch(setPlaceByRegion(region.id))
  })
