import { getNotificationSubscriptionId } from '@app/helpers/notification_subscriptions'

import { excludeAbortError } from '@app/errors/AbortError'

import {
  deleteNotificationSubscriptionsByTopicAndChannelDescriptor,
  postNotificationSubscriptionsDescriptor,
} from '@app/store/actions/notifications.descriptors'
import { createReducer } from '@app/store/toolkit'

/** ApiNotificationSubscritpion Topic and channel joined with _ */
type SubscriptionID = string

export default createReducer<Record<SubscriptionID, { loading: boolean; error: Error | null } | undefined>>({}, builder => {
  builder.addCases(
    [postNotificationSubscriptionsDescriptor.shapes.pending, deleteNotificationSubscriptionsByTopicAndChannelDescriptor.shapes.pending],
    (state, action) => {
      return { ...state, [getNotificationSubscriptionId(action.meta)]: { loading: true, error: null } }
    }
  )

  builder.addCases(
    [postNotificationSubscriptionsDescriptor.shapes.fulfilled, deleteNotificationSubscriptionsByTopicAndChannelDescriptor.shapes.fulfilled],
    (state, action) => {
      return { ...state, [getNotificationSubscriptionId(action.meta)]: { loading: false, error: null } }
    }
  )

  builder.addCases(
    [postNotificationSubscriptionsDescriptor.shapes.rejected, deleteNotificationSubscriptionsByTopicAndChannelDescriptor.shapes.rejected],
    (state, action) => {
      return { ...state, [getNotificationSubscriptionId(action.meta)]: { loading: false, error: excludeAbortError(action.payload) } }
    }
  )
})
