import { getFavoritesDescriptor } from '@app/store/actions/profile.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  fetched: boolean
  loading: boolean
  page: number
  per_page: number
  total: number
  total_pages: number
}>(
  {
    fetched: false,
    loading: false,
    page: 0,
    per_page: 0,
    total: 0,
    total_pages: 0,
  },
  builder => {
    builder.addCase(getFavoritesDescriptor.shapes.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.meta.query,
        ...{
          page: action.payload.meta.current_page,
          total: action.payload.meta.total,
          total_pages: action.payload.meta.total_pages,
        },
        fetched: true,
        loading: false,
      }
    })

    builder.addCase(getFavoritesDescriptor.shapes.pending, (state, _action) => {
      return { ...state, loading: true }
    })

    builder.addCase(getFavoritesDescriptor.shapes.rejected, (state, _action) => {
      return { ...state, loading: false }
    })
  }
)
