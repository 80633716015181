export class ApiActionBuilderDescriptor<L extends string, R extends any, M extends any> {
  label!: L
  shapes!: {
    pending: { type: `${L}_PENDING`; meta: M }
    fulfilled: { type: `${L}_FULFILLED`; payload: R; meta: M }
    rejected: { type: `${L}_REJECTED`; error: true; payload: Error; meta: M }
  }

  setLabel<NewL extends string>(label: NewL) {
    this.label = label as any
    return this as any as ApiActionBuilderDescriptor<NewL, R, M>
  }

  setShape<NewR extends any = undefined, NewM extends any = undefined>() {
    const label = this.label
    if (!label) throw new Error('Must provide label')

    this.shapes = {
      pending: { type: `${label}_PENDING` },
      fulfilled: { type: `${label}_FULFILLED` },
      rejected: { type: `${label}_REJECTED` },
    } as any
    return this as any as ApiActionBuilderDescriptor<L, NewR, NewM>
  }
}
