import { ApiMetaTags } from '@app/constants/ApiTypes/entities'

import { mergeMaps } from '@app/utils/mergeMaps'
import { normalize } from '@app/utils/normalizer'

import {
  getAcademyCourseByIdDescriptor,
  getAcademyCoursesDescriptor,
  getAcademyLessonsByIdDescriptor,
  getAcademyLessonsDescriptor,
} from '@app/store/actions/academy.descriptors'
import { getFavoritesDescriptor } from '@app/store/actions/profile.descriptors'
import { cancelRequestDescriptor, rejectRequestDescriptor } from '@app/store/actions/request.descriptors'
import { getPublicParentReviewsDescriptor } from '@app/store/actions/reviews.descriptors'
import { fetchTrustedSittersDescriptor } from '@app/store/actions/trusted.descriptors'
import { getUsersByIdsDescriptor, profileRequestActionDescriptor, userRequestActionDescriptor } from '@app/store/actions/user.descriptors'
import { createReducer } from '@app/store/toolkit'

import { getSitterSearchDescriptor } from '@app/routes/Search/actions.descriptors'

export default createReducer<{
  [key: string]: ApiMetaTags
}>({}, builder => {
  builder.addCases(
    [
      fetchTrustedSittersDescriptor.shapes.fulfilled,
      getSitterSearchDescriptor.shapes.fulfilled,
      getUsersByIdsDescriptor.shapes.fulfilled,
      userRequestActionDescriptor.shapes.fulfilled,
      getFavoritesDescriptor.shapes.fulfilled,
      cancelRequestDescriptor.shapes.fulfilled,
      rejectRequestDescriptor.shapes.fulfilled,
      profileRequestActionDescriptor.shapes.fulfilled,

      getAcademyLessonsDescriptor.shapes.fulfilled,
      getAcademyLessonsByIdDescriptor.shapes.fulfilled,
      getAcademyCoursesDescriptor.shapes.fulfilled,
      getAcademyCourseByIdDescriptor.shapes.fulfilled,
      getPublicParentReviewsDescriptor.shapes.fulfilled,
    ],
    (state, action) => {
      const { meta_tags, users = {} } = normalize(action.payload)
      if (!meta_tags) return state

      const metaTagsByUserId = Object.fromEntries(
        Object.values(users).flatMap(user => {
          if (user.attributes.account_type !== 'sitter') return []
          const meta_id = 'meta_tags' in user.relationships ? user.relationships.meta_tags.data?.id : null
          if (!meta_id) return []
          const meta = meta_tags[meta_id]
          if (!meta) throw new Error(`Meta for user ${user.id} not found`)
          return [
            [user.id, meta],
            [user.attributes.token, meta],
          ]
        })
      )

      return mergeMaps(state, metaTagsByUserId)
    }
  )
})
