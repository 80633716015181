import { StoreState } from '@app/store/store'

export const bannersStateSelector = (state: StoreState) => state.ui.banners

export const bannersVisibleSelector = (state: StoreState) => !!state.ui.bannersVisible

export const themeColorSelector = (state: StoreState) => state.ui.themeColor

export const classNameSelector = (state: StoreState) => Object.keys(state.ui.className).join(' ')

export const relatedAppsSelector = (state: StoreState) => state.ui.relatedApps

export const sideMenuOpenedSelector = (state: StoreState) => state.ui.menu.opened
