import type { ApiIncluded, ApiRequest } from '@app/constants/ApiTypes/entities'
import type { ApiRequestMetaData } from '@app/constants/ApiTypes/misc'

import { ApiActionBuilderDescriptor } from '@app/store/apiMiddleware/builderDescriptor'

export const fetchRequestsDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('FETCH_REQUESTS')
  .setShape<{ meta: ApiRequestMetaData; data: ApiRequest[]; included?: ApiIncluded }, { query: { page: number; per_page: number } }>()

export const getRequestsWithDebtsDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('GET_REQUESTS_WITH_DEBT')
  .setShape<{ data: ApiRequest[]; included?: ApiIncluded }>()

export const repayRequestsWithDebtsDescriptor = new ApiActionBuilderDescriptor().setLabel('REPAY_REQUESTS_WITH_DEBT').setShape<unknown>()

export const fetchRequestDescriptor = new ApiActionBuilderDescriptor().setLabel('FETCH_REQUEST').setShape<{ data: ApiRequest; included?: ApiIncluded }>()

export const createRequestFromAnnouncementResponseDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('CREATE_REQUEST_FROM_ANNOUNCEMENT_RESPONSE')
  .setShape<{ data: ApiRequest; included?: ApiIncluded }, { announcement_id: string; announcement_response_id: string }>()

export const acceptRequestDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('ACCEPT_REQUEST')
  .setShape<{ data: ApiRequest; included?: ApiIncluded }, { request_id: string }>()

export const declineRequestDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('DECLINE_REQUEST')
  .setShape<{ data: ApiRequest; included?: ApiIncluded }, { request_id: string }>()

export const cancelRequestDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('CANCEL_REQUEST')
  .setShape<{ data: ApiRequest; included?: ApiIncluded }, { request_id: string }>()

export const rejectRequestDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('REJECT_REQUEST')
  .setShape<{ data: ApiRequest; included?: ApiIncluded }, { request_id: string }>()
