import { IMPORT_MAP } from '@app/importMap'

import { AsyncRoute } from '@app/utils/routing/types'

// @routes declaration
const routes: AsyncRoute[] = [
  {
    path: '/quiz',
    event_id: 'quiz',
    component: () => IMPORT_MAP.routes.Quiz.Quiz().then(m => m.Quiz),
  },
  {
    path: '/quiz/watch',
    event_id: 'quiz_watch',
    component: () => IMPORT_MAP.components.Redirect().then(m => m.createRedirect('/quiz', true)),
  },
  {
    path: '/quiz/:slug/watch',
    event_id: 'quiz_watch',
    event_params: {
      slug: 'slug',
    },
    component: () => IMPORT_MAP.routes.Quiz.QuizWatch().then(m => m.QuizWatch),
  },
  {
    path: '/quiz/:slug',
    event_id: 'quiz_game',
    event_params: {
      slug: 'slug',
    },
    component: () => IMPORT_MAP.routes.Quiz.QuizGame().then(m => m.QuizGame),
  },
]

export default routes
