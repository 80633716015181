import { createStructuredSelector as create } from 'reselect'

import { StoreState } from '@app/store/store'

type Selector<R> = (state: StoreState) => R
type SelectorsObject<O> = { [K in keyof O]: Selector<O[K]> }

type StructuredSelector = <O>(selectors: SelectorsObject<O>) => (state: StoreState) => O

/** version of `reselect` `createStructuredSelector` that is bound to app's Store type */
export const createStructuredSelector: StructuredSelector = create
