/**
 * Works like lodash/memoize except it doesnt has cache capacity and stores only last result
 *
 * * Note that type of CacheKeyType must be value type
 *   as reference type equality check will always be false
 */
export function memoizeLast<T extends any[], R, CacheKeyType>(
  fn: (...args: T) => R,
  resolver: (...args: Parameters<typeof fn>) => CacheKeyType = (...args) => args.join(',') as any
): typeof fn {
  let inited = false
  let lastValue: R | null = null
  let lastHash: CacheKeyType | null = null

  const o: typeof fn = (...args) => {
    const hash = resolver(...args)
    if (inited) {
      if (hash === lastHash) return lastValue!
    } else {
      inited = true
    }
    lastHash = hash
    lastValue = fn(...args)
    return lastValue
  }

  return o
}
