import { ApiGiftCard } from '@app/constants/ApiTypes/entities'

import { excludeAbortError } from '@app/errors/AbortError'

import { postGiftCardsDescriptor, restoreGiftcardOrder } from '@app/store/actions/giftcard.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  model: ApiGiftCard | null
  pending: boolean
  loaded: boolean
  error: Error | null
}>(
  {
    model: null,
    pending: false,
    loaded: false,
    error: null,
  },
  builder => {
    builder.addCase(postGiftCardsDescriptor.shapes.pending, (state, _action) => {
      return { ...state, pending: true, loaded: false, error: null }
    })

    builder.addCase(postGiftCardsDescriptor.shapes.fulfilled, (state, action) => {
      return { ...state, model: action.payload.data, pending: false, loaded: true, error: null }
    })

    builder.addCase(postGiftCardsDescriptor.shapes.rejected, (state, action) => {
      return { ...state, pending: false, loaded: false, error: excludeAbortError(action.payload), model: null }
    })

    builder.addCase(restoreGiftcardOrder.shape, (_state, _action, defaultState) => defaultState)
  }
)
