import { ApiAvailableTime } from '@app/constants/ApiTypes/entities'

import { getAvailableTimesDescriptor } from '@app/store/actions/available_times.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<Record<string, ApiAvailableTime[] | undefined>>({}, builder => {
  builder.addCase(getAvailableTimesDescriptor.shapes.fulfilled, (state, action) => {
    const keyLabel = 'user_id' in action.meta ? 'id' : 'token'
    const key = 'user_id' in action.meta ? action.meta.user_id : action.meta.token
    return { ...state, [`${keyLabel}:${key}`]: action.payload.data }
  })
})
