import { getUsersPerksDescriptor, putUsersPerksDescriptor } from '@app/store/actions/perks.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<string[]>([], builder => {
  builder.addCase(getUsersPerksDescriptor.shapes.fulfilled, (state, action) => {
    if (action.meta.currentUser) return action.payload.data.map(p => p.id).sort()
    return state
  })

  builder.addCase(putUsersPerksDescriptor.shapes.pending, (_state, action) => {
    return [...action.meta.newPerks].sort()
  })

  builder.addCase(putUsersPerksDescriptor.shapes.rejected, (_state, action) => {
    return [...action.meta.oldPerks].sort()
  })
})
