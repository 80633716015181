import { ApiRequestReview } from '@app/constants/ApiTypes/entities'

import { mergeV2Entity } from '@app/utils/mergeV2Entity'

import { getReviewsDescriptor } from '@app/store/actions/reviews.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{ [key: string]: ApiRequestReview }>({}, builder => {
  builder.addCase(getReviewsDescriptor.shapes.fulfilled, (state, action) => {
    const patch = action.payload.data.reduce<{ [key: string]: ApiRequestReview }>((c, model) => {
      c[model.id] = mergeV2Entity(state[model.id], model)
      return c
    }, {})
    return { ...state, ...patch }
  })
})
