import { defineMessages } from 'react-intl'

import { IntlService } from '@app/services/IntlService'

import { removeStackInitiator } from '@app/utils/removeStackInitiator'

export type FetchDataErrorMessage = keyof typeof messages

export class FetchDataError extends Error {
  localizedMessage!: string
  status!: number
  expose!: boolean
  /** whether error should be reported */
  handled!: boolean

  static create(message: FetchDataErrorMessage, status: number) {
    const err = new this(`${message} with status ${status}`)
    err.status = status
    err.localizedMessage = IntlService.shared.current.formatMessage(messages[message])
    err.stack = removeStackInitiator(err.stack ?? '')
    err.handled = false
    err.expose = true

    return err
  }
}

const messages = defineMessages({
  'Academy courses fetch failed': 'Возникла ошибка при загрузке типов занятий',
  'Academy lessons fetch failed': 'Возникла ошибка при загрузке занятий',
  'Announcement fetch failed': 'Не удалось загрузить объявление',
  'Announcements fetch failed': 'Не удалось загрузить объявления',
  'App not found': 'Неизвестное приложение',
  'Apps fetch failed': 'Не удалось загрузить данные о приложении',
  'Candidate survey load failed': 'Не удалось загрузить анкету',
  'Card fetch failed': 'Не удалось загрузить данные о карте',
  'Channel template fetch failed': 'Не удалось загрузить канал',
  'Contacts fetch failed': 'Не удалось загрузить список контактов',
  'Course fetch failed': 'Не удалось загрузить тему занятий',
  'Course lessons fetch failed': 'Не удалось загрузить уроки для темы',
  'Email confirmation fetch failed': 'Ошибка при подтверждении почты. Возможно адрес уже подтвержден',
  'Faq fetch failed': 'Не удалось загрузить FAQ',
  'Favorites fetch failed': 'Не удалось загрузить данные о списке избранных',
  'Ignored fetch failed': 'Не удалось загрузить данные о списке',
  'Giftcard fetch failed': 'Возникла ошибка при загрузке подарочной карты',
  'Giftcard types fetch failed': 'Возникла ошибка при загрузке типов подарочных карт',
  'Lesson fetch failed': 'Возникла ошибка при загрузке урока',
  'Lesson not found': 'Урок не найден',
  'Lesson records fetch failed': 'Возникла ошибка при загрузке уроков',
  'Links fetch failed': 'Возникла ошибка при загрузке ссылок',
  'Notification scheme fetch failed': 'Не удалось загруить схему уведомлений',
  'Notification settings fetch failed': 'Не удалось загруить настройки уведомлений',
  'Page fetch failed': 'Не удалось загрузить страницу',
  'Page not found': 'Страница не найдена',
  'Perks fetch failed': 'Возникла ошибка при загрузке преимуществ',
  'Plans fetch failed': 'Возникла ошибка при загрузке цен',
  'Playground event types fetch failed': 'Не удалось загрузить типы детских площадок',
  'Promocode is not applicable to items in cart': 'На выбранные занятия промокод не действует',
  'Promocodes fetch failed': 'Не удалось загрузить данные о промокодах',
  'Promoted sitters fetch failed': 'Не удалось загрузить список ситтеров',
  'Quiz data fetch failed': 'Возникла ошибка при загрузке данных викторины',
  'Quiz not found': 'Викторина не найдена',
  'Referral bonuses fetch failed': 'Не удалось загрузить данные о бонусах',
  'Referral promocode fetch failed': 'Не удалось загрузить реферальный промокод',
  'Referrals list fetch failed': 'Не удалось загрузить список рефералов',
  'Requests fetch failed': 'Не удалось загрузить список заказов',
  'Requests with debt fetch failed': 'Возникла ошибка при загрузке заказов',
  'Reviews fetch failed': 'Не удалось загрузить отзывы',
  'Reviews not found': 'Отзывы не найдены',
  'Reviews summary fetch failed': 'Не удалось загрузить отзывы',
  'Schedule fetch failed': 'Не удалось загрузить расписание',
  'Schedule not found': 'Раcписание не найдено',
  'Schools fetch failed': 'Не удалось загрузить список школ',
  'Sitter profile rules fetch failed': 'Не удалось загрузить правила заполнения анкеты',
  'Sitter rules fetch failed': 'Не удалось загрузить правила присмотра',
  'Sitter search config fetch failed': 'Не удалось загрузить параметры поиска',
  'Sitter search fetch failed': 'Не удалось загрузить список ситтеров',
  'Social identities fetch failed': 'Не удалось загрузить данные о социальных сетях',
  'Statistics fetch failed': 'Не удалось загрузить статистику',
  'Subscription fetch failed': 'Не удалось загрузить данные о подписке',
  'Personnel check types fetch failed': 'Не удалось загрузить список предложений',
  'Telegram code fetch failed': 'Возникла ошибка при загрузке кода Telegram',
  'Timezone by location fetch failed': 'Не удалось определить часовой пояс по местоположению',
  'Timezones fetch failed': 'Возникла ошибка при загрузке часовых поясов',
  'Training request change post failed': 'Возникла ошибка при смене формата',
  'Trusted fetch failed': 'Не удалось загрузить список постоянных пользователей',
  'User access forbidden': 'Доступ к пользователю ограничен',
  'User fetch failed': 'Не удалось загрузить данные о пользователе',
  'User not found': 'Пользователь не найден',
  "Can't fetch income calculator config": 'Не удалось загрузить параметры калькулятора',
  "Can't get payment order": 'Не могу получить заказ',
  "Can't get T-Bank review data": 'Не удалось получить данные о T-Bank',
  "Can't load CloudPayments": 'Не удалось загрузить CloudPayments',
  "Can't load page": 'Не удалось загрузить страницу',
})
