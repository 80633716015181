import { GetSitterSearchConfigRequest } from '@app/constants/ApiTypes/requests'

import { getSitterSearchConfigsDescriptor } from '@app/store/actions/sitter_search_config.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<GetSitterSearchConfigRequest>({ region_slug: 'msk' }, builder => {
  builder.addCase(getSitterSearchConfigsDescriptor.shapes.fulfilled, (_state, action) => {
    return action.meta
  })
})
