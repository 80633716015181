import isEqual from 'lodash/isEqual'

import { ApiParent } from '@app/constants/ApiTypes/entities'

import { fetchTrustingParentsDescriptor } from '@app/store/actions/trusted.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<ApiParent['id'][]>([], builder => {
  builder.addCase(fetchTrustingParentsDescriptor.shapes.fulfilled, (state, action) => {
    const ids = action.payload.data.map(u => u.id)
    return isEqual(state, ids) ? state : ids
  })
})
