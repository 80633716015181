import type { KidsoutConversationTemplate } from '@kidsout-kidsout/chat'

import { getTwilioChannelByUserTemplateDescriptor } from '@app/store/actions/twilio.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{ [key: string]: KidsoutConversationTemplate }>({}, builder => {
  builder.addCase(getTwilioChannelByUserTemplateDescriptor.shapes.fulfilled, (state, action) => {
    return { ...state, [action.meta.userId]: action.payload.data }
  })
})
