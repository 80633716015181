import type { IResult } from 'ua-parser-js'

import packageJson from '../../package.json'

export class ApplicationAgent {
  static shared: ApplicationAgent

  private userAgent: IResult | null
  readonly device: string
  readonly os: string
  readonly sha?: string
  readonly identifier: string
  readonly agent: string

  static init(data?: IResult) {
    if (!this.shared) {
      this.shared = new ApplicationAgent(data)
    }
  }

  constructor(data?: IResult) {
    this.userAgent = data ?? null

    this.device = !this.userAgent ? 'server' : `${this.formatted(this.userAgent?.device?.vendor)}/${this.formatted(this.userAgent?.device?.model)}`

    this.os = !this.userAgent ? this.getOs() : `${this.formatted(this.userAgent?.os?.name)}/${this.formatted(this.userAgent?.os?.version)}`

    this.sha = process.env.GIT_SHA

    this.identifier = 'ru.kidsout.www'

    this.agent = `${this.device} ${this.os} ${this.identifier}/${packageJson.version}`
  }

  private getOs() {
    const os: typeof import('os') = require('os')
    return `${this.formatted(os.platform())}/${this.formatted(os.release())}`
  }

  private formatted(value?: string) {
    return (value || 'unknown').replace(/\s+/g, '_')
  }
}
