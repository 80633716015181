import { routerRaiseError } from '@app/store/actions/misc.descriptors'
import { beforeNavigate } from '@app/store/actions/router.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  code?: number
  message?: string
  localizedMessage?: string
}>({}, builder => {
  builder.addCase(beforeNavigate.shape, (state, action) => {
    // reset errors state so browser navigation (back, forward, push, replace) resets error
    // but if it's initial (server -> client transition) navigation
    // then do nothing
    if (!action.payload.initial) return {}
    return state
  })
  builder.addCase(routerRaiseError.shape, (state, action) => {
    if (state.code) return state

    return { ...state, code: action.payload.errorCode, message: action.payload.message, localizedMessage: action.payload.localizedMessage }
  })
})
