import { AmoWidgetApi } from '@app/utils/AmoWidgetApi'
import { promiseIntoResult } from '@app/utils/intoResult'
import { trimRegionalRoute } from '@app/utils/routing/region'
import { normalizePath } from '@app/utils/url'

import { isAppSelector } from '@app/store/selectors/misc'
import { availableRegionsSlugsSelector } from '@app/store/selectors/regions'
import { Store } from '@app/store/store'

export class AmoWidgetService {
  static shared = new AmoWidgetService()
  instance: AmoWidgetApi | null = null

  private locations = ['/', '/babysitter', '/search']

  store!: Store

  async start() {
    if (this.instance) return
    if (!location.pathname) return

    const state = this.store.getState()
    if (state.config.isPolygon) return

    if (!AmoWidgetApi.available) return

    if (!this.locations.includes(normalizePath(trimRegionalRoute(availableRegionsSlugsSelector(state), location.pathname)))) return
    if (isAppSelector(state)) return

    if ((state.profile.user?.account_type || 'visitor') !== 'visitor') return

    const api = new AmoWidgetApi()
    this.instance = api
    const apiLoadResult = await promiseIntoResult(api.load())
    if (apiLoadResult.error) throw apiLoadResult.value

    const unsub = this.store.subscribe(() => {
      if ((this.store.getState().profile.user?.account_type || 'visitor') === 'visitor') return
      api.destroy()
      unsub()
    })
  }
}
