import { excludeAbortError } from '@app/errors/AbortError'

import { createReducer } from '@app/store/toolkit'

import { getSitterSearchDescriptor } from '@app/routes/Search/actions.descriptors'

export default createReducer<{
  total: number
  total_pages: number
  current_page: number
  loading: boolean
  per_page: number
  inited: boolean
  error: Error | null
}>({ total: 0, total_pages: 0, current_page: 0, loading: false, per_page: 20, inited: false, error: null }, builder => {
  builder.addCase(getSitterSearchDescriptor.shapes.pending, (state, action) => {
    return { ...state, loading: true, per_page: action.meta.current.per_page }
  })

  builder.addCase(getSitterSearchDescriptor.shapes.rejected, (state, action) => {
    return { ...state, loading: false, error: excludeAbortError(action.payload) }
  })

  builder.addCase(getSitterSearchDescriptor.shapes.fulfilled, (state, action) => {
    return { ...state, ...action.payload.meta, loading: false, inited: true, error: null }
  })
})
