import type { ApiPerk } from '@app/constants/ApiTypes/entities'

import { ApiActionBuilderDescriptor } from '@app/store/apiMiddleware/builderDescriptor'

export const getPerksDescriptor = new ApiActionBuilderDescriptor().setLabel('GET_PERKS').setShape<{ data: ApiPerk[] }>()
export const getUsersPerksDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('GET_USERS_PERKS')
  .setShape<{ data: ApiPerk[] }, { currentUser: boolean; id: string }>()
export const putUsersPerksDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('PUT_USERS_PERKS')
  .setShape<unknown, { newPerks: string[]; oldPerks: string[] }>()
