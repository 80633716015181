import { useLayoutEffect as r } from 'react'

export const useLayoutEffect: typeof r = (effect, deps) => {
  if (IS_BROWSER || global.USE_LAYOUT_EFFECT) {
    r(effect, deps)
  }
}

export const useLayoutEffectForce: typeof r = (effect, deps) => {
  if (IS_BROWSER || global.USE_LAYOUT_EFFECT) {
    r(effect, deps)
  } else {
    effect()
  }
}
