import { hideSideMenu, showSideMenu } from '@app/store/actions/menu.descriptors'
import { navigate } from '@app/store/actions/router.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{ opened: boolean }>({ opened: false }, builder => {
  builder.addCase(showSideMenu.shape, state => ({ ...state, opened: true }))
  builder.addCase(hideSideMenu.shape, state => ({ ...state, opened: false }))
  builder.addCase(navigate.shape, (state, action) => {
    if (action.payload.action !== 'REPLACE' || action.payload.location.state?.link) {
      return { ...state, opened: false }
    }
    return state
  })
})
