import { ApiAvailableTime } from '@app/constants/ApiTypes/entities'

import { ApiActionBuilderDescriptor } from '@app/store/apiMiddleware/builderDescriptor'

export const getAvailableTimesDescriptor = new ApiActionBuilderDescriptor().setLabel('GET_AVAILABLE_TIMES').setShape<
  {
    data: ApiAvailableTime[]
  },
  { user_id: string } | { token: string }
>()

export const putAvailableTimesDescriptor = new ApiActionBuilderDescriptor().setLabel('PUT_AVAILABLE_TIMES').setShape<{
  data: ApiAvailableTime[]
}>()
