import { Address } from '@app/utils/address'
import { createCookie } from '@app/utils/cookies'
import { jsonParse, jsonSerialize } from '@app/utils/jsonSerialize'
import moment from '@app/utils/moment'
import { SerializedAddress } from '@app/utils/SerializedAddress'

import { AnnouncementsFilter } from '@app/store/types/announcements'

export const DEFAULT_ANNOUNCEMENTS_FILTER_STATE: AnnouncementsFilter = {
  online: undefined,
  require_attention: undefined,
  order_by: 'start_time',
  order_dir: undefined,
  date: undefined,
  start: undefined,
  end: undefined,
  min_kids: undefined,
  max_kids: undefined,
  youngest_age_months: undefined,
  eldest_age_months: undefined,
  regular: undefined,
  address: undefined,
}

/** Stores state of siiter's announcements filter */
export const filterCookie = createCookie<AnnouncementsFilter>(
  'kidsout__announcements_filter',
  i => {
    try {
      return jsonParse(i, (val, def) => {
        return SerializedAddress.fromData(val) ?? def()
      })
    } catch {
      return null
    }
  },
  i => {
    return jsonSerialize(i, (val, def) => {
      if (val instanceof Address) return SerializedAddress.toJSON(val)
      return def()
    })
  },
  () => ({
    expires: moment().add(1, 'year').toDate(),
  })
)
