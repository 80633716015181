import { getReviewsDescriptor } from '@app/store/actions/reviews.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{ page: number; per_page: number; user_token: string }>(
  {
    page: 0,
    per_page: 10,
    user_token: '',
  },
  builder => {
    builder.addCase(getReviewsDescriptor.shapes.fulfilled, (_state, action) => {
      return action.meta.query
    })
  }
)
