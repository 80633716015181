import React, { FunctionComponent, memo, useCallback } from 'react'

import { IMPORT_MAP } from '@app/importMap'

import { useAppDispatch } from '@app/utils/redux'

import { withProgress } from '@app/store/actions/initial'

import { Button, ButtonProps } from './Button/Button'

type Props = {
  className?: string
} & React.JSX.LibraryManagedAttributes<typeof Button, ButtonProps>

export const LogoutButton: FunctionComponent<Props> = memo<Props>(function LogoutButton({ type, className, eventName, children }) {
  const dispatch = useAppDispatch()
  const handleClick = useCallback(async () => {
    const { destroySession } = await dispatch(withProgress(IMPORT_MAP.actions.session()))
    dispatch(destroySession())
  }, [dispatch])

  return (
    <Button className={className} eventName={eventName} onClick={handleClick} type={type}>
      {children}
    </Button>
  )
})
