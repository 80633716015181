import { ApiYandexMapsReview } from '@app/constants/ApiTypes/entities'

import { normalize } from '@app/utils/normalizer'

import { getYandexMapsReviewsByQueryDescriptor } from '@app/store/actions/yandexMapsReviews.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<Record<string, ApiYandexMapsReview>>({}, builder => {
  builder.addCase(getYandexMapsReviewsByQueryDescriptor.shapes.fulfilled, (state, action) => {
    return action.payload.meta.current_page < 2
      ? (normalize(action.payload).yandex_maps_reviews ?? {})
      : { ...state, ...normalize(action.payload).yandex_maps_reviews }
  })
})
