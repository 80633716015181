import { createIntl, IntlShape } from 'react-intl'

import { translations as availableTranslations } from '@app/constants/Locales'
import { IS_PRODUCTION } from '@app/constants/Misc'

import { Cache } from '@app/utils/cache'
import { INTL_FORMATS } from '@app/utils/IntlUtils'

type IntlServiceContext = {
  fs: {
    readFile: (path: string, encoding?: string) => Promise<string>
    exists: (path: string) => Promise<boolean>
  }
}

export class IntlService {
  static shared: IntlService
  private ctx: IntlServiceContext | null = null

  static async init(ctx: IntlServiceContext | null, locale: string, cache?: Cache<{ [key: string]: string } | null>) {
    this.shared = new IntlService(ctx, cache)
    await this.shared.load(locale)
  }

  static initDefault() {
    this.shared = new IntlService()
    this.shared.loadDefault()
  }

  current!: IntlShape
  private cache?: Cache<{ [key: string]: string } | null>

  constructor(ctx?: IntlServiceContext | null, cache?: Cache<{ [key: string]: string } | null>) {
    this.ctx = ctx ?? null
    this.cache = cache
  }

  async load(locale: string) {
    const hasMessages = !!this.cache?.has(locale)
    let messages = this.cache?.get(locale) ?? undefined
    if (!hasMessages) {
      messages = (await this.fetchTranslations(locale))!
      this.cache?.set(locale, messages)
    }

    this.current = createIntl({
      defaultFormats: INTL_FORMATS,
      defaultLocale: 'ru',
      formats: INTL_FORMATS,
      locale,
      messages,
      onError: (err: Error) => console.error(err.message),
      onWarn: (msg: string) => console.warn(msg),
    })
  }

  loadDefault() {
    this.current = createIntl({
      defaultFormats: INTL_FORMATS,
      defaultLocale: 'ru',
      formats: INTL_FORMATS,
      locale: 'ru',
      messages: undefined,
    })
  }

  private async fetchTranslations(locale: string = 'ru'): Promise<{ [key: string]: string } | null> {
    if (availableTranslations.indexOf(locale as any) === -1) return null

    if (IS_BROWSER) {
      return fetch(`/internal/translations/${locale}`)
        .then(resp => {
          if (resp.status !== 200) return null
          return resp.json()
        })
        .catch(() => null)
    }

    return this.readTranslation(locale)
  }

  private static translationCache: Cache<{ [key: string]: string } | 'none'> = new Cache()

  private async readTranslation(locale: string = 'ru'): Promise<{ [key: string]: string } | null> {
    if (IS_PRODUCTION && IntlService.translationCache.has(locale)) {
      const val = IntlService.translationCache.get(locale)
      if (val === 'none') return null
      return val
    }
    if (!this.ctx) throw new Error('IntlService.ctx is not defined')
    const { readFile, exists } = this.ctx.fs
    const translationPath = `./build/intl/${locale}.json`
    if (!(await exists(translationPath))) {
      IntlService.translationCache.set(locale, 'none')
      return null
    }
    const data = await readFile(translationPath)
    const messages = JSON.parse(data) as { [key: string]: string }
    IntlService.translationCache.set(locale, messages)
    return messages
  }
}
