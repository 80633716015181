import { IMPORT_MAP } from '@app/importMap'

import { AsyncRoute } from '@app/utils/routing/types'

// @routes declaration
const routes: AsyncRoute[] = [
  {
    path: '/playground',
    regional: true,
    event_id: 'playground_landing',
    component: () => IMPORT_MAP.routes.Playground.Playground().then(m => m.Playground),
  },
]

export default routes
