import { ApiQuizGame } from '@app/constants/ApiTypes/entities'

import { getQuizGamesDescriptor } from '@app/store/actions/quiz.descriptors'
import { createReducer } from '@app/store/toolkit'

export type QuizGame = { id: ApiQuizGame['id'] } & ApiQuizGame['attributes']

export default createReducer<{ loaded: boolean; games: QuizGame[] }>({ loaded: false, games: [] }, builder => {
  builder.addCase(getQuizGamesDescriptor.shapes.fulfilled, (_state, action) => {
    return { loaded: true, games: action.payload.data.map(convert) }
  })
})

const convert = (game: ApiQuizGame): QuizGame => {
  return { id: game.id, ...game.attributes }
}
