import { ApiTbankReview } from '@app/constants/ApiTypes/entities'

import { normalize } from '@app/utils/normalizer'

import { getTBankReviewsByQueryDescriptor } from '@app/store/actions/tbankReviews.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<Record<string, ApiTbankReview>>({}, builder => {
  builder.addCase(getTBankReviewsByQueryDescriptor.shapes.fulfilled, (state, action) => {
    return action.payload.meta.current_page < 2 ? (normalize(action.payload).t_bank_reviews ?? {}) : { ...state, ...normalize(action.payload).t_bank_reviews }
  })
})
