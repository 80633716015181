import { useEffect } from 'react'
import { breadcrumbsIntegration, BrowserOptions } from '@sentry/browser'
import { reactRouterV6BrowserTracingIntegration, init as sentryInit } from '@sentry/react'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router'

import config from '@app/config'

import { createEventProcessor } from './eventProcessor'

export function init({ requestId }: { requestId: string }) {
  const ownIntegrations = [
    breadcrumbsIntegration({
      console: false,
    }),
    reactRouterV6BrowserTracingIntegration({
      shouldCreateSpanForRequest:
        process.env.NODE_ENV !== 'production'
          ? undefined
          : url => {
              try {
                return !!['kidsout.ru', 'api.kidsout.ru', 'staging.kidsout.ru', 'api.staging.kidsout.ru', /^\//].find(origin =>
                  typeof origin === 'string' ? url.includes(origin) : origin.test(url)
                )
              } catch {
                return false
              }
            },
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ]

  const options: BrowserOptions = {
    dsn: 'https://744c1f9bf3b6475183d42cb58533b699@sentry.io/144703',
    release: process.env.GIT_SHA,
    enabled: config.sentry,
    allowUrls: process.env.NODE_ENV !== 'production' ? undefined : [/https?:\/\/(cdn\.)?(staging\.)?kidsout\.ru\/assets/],
    environment: config.isStaging ? 'staging' : process.env.NODE_ENV,
    integrations: defaultIntegrations => [
      ...defaultIntegrations.filter(integration => !ownIntegrations.find(i => i.name === integration.name)),
      ...ownIntegrations,
    ],
    replaysOnErrorSampleRate: 0,
    replaysSessionSampleRate: 0,
    tracesSampleRate: process.env.MODE !== 'production' ? 1 : 0.02,
    beforeSend: createEventProcessor({ requestId }),
  }

  sentryInit(options)
}
