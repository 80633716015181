import { ApiSitterSearchConfig } from '@app/constants/ApiTypes/internalEntities'

import { getSitterSearchConfigsDescriptor } from '@app/store/actions/sitter_search_config.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<Record<string, ApiSitterSearchConfig>>({}, builder => {
  builder.addCase(getSitterSearchConfigsDescriptor.shapes.fulfilled, (state, action) => {
    return { ...state, [action.meta.region_slug]: action.payload.data }
  })
})
