import { ApiTimeZone } from '@app/constants/ApiTypes/entities'

import { excludeAbortError } from '@app/errors/AbortError'

import { getTimezonesDescriptor } from '@app/store/actions/initial.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  listMeta: {
    loaded: boolean
    loading: boolean
    error: Error | null
  }
  list: ApiTimeZone[]
}>(
  {
    listMeta: {
      loaded: false,
      loading: false,
      error: null,
    },
    list: [],
  },
  builder => {
    builder.addCase(getTimezonesDescriptor.shapes.pending, (state, _action) => {
      return { ...state, listMeta: { ...state.listMeta, loading: false, error: null } }
    })
    builder.addCase(getTimezonesDescriptor.shapes.fulfilled, (_state, action) => {
      return { list: action.payload.data, listMeta: { loaded: true, loading: false, error: null } }
    })
    builder.addCase(getTimezonesDescriptor.shapes.rejected, (state, action) => {
      return { ...state, listMeta: { ...state.listMeta, loading: false, error: excludeAbortError(action.payload) } }
    })
  }
)
