import { Address } from './address'

export class SerializedAddress {
  static readonly key = 'address:9FCF959D-4A7C-40EC-A6A1-9FA577FBEBD0'
  static toJSON(address: Address) {
    return {
      key: SerializedAddress.key,
      data: address.toJSON(),
    }
  }

  static fromData(data: any) {
    try {
      if (data.key !== SerializedAddress.key) return undefined
      return Address.fromJSON(data.data)
    } catch {
      return undefined
    }
  }
}
