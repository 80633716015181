import * as api from '@app/utils/api'
import { urlEscaped } from '@app/utils/urlEscaped'

import { ApiActionBuilder } from '@app/store/apiMiddleware/builder'
import { ApiError, asNetworkError } from '@app/store/apiMiddleware/errors'
import { ApiActionPromise } from '@app/store/apiMiddleware/types'
import { createThunk } from '@app/store/thunk'
import { StoreUser } from '@app/store/types/users'

import { setSession } from './session'
import { getTrainingRequest } from './trainingRequest'
import {
  getUsersByIdsDescriptor,
  profileRequestActionDescriptor,
  resendEmailConfirmationDescriptor,
  sendEmailConfirmationDescriptor,
  userRequestActionDescriptor,
} from './user.descriptors'

const GET_CURRENT_USER_SYMBOL = Symbol('GET_CURRENT_USER_SYMBOL')

export const profileRequestAction = new ApiActionBuilder(profileRequestActionDescriptor)
  .setInit((force: boolean) => ({
    method: 'GET',
    endpoint: api.path('/api/v2/users/me'),
    headers: api.headers(),
    bailout: ({ session }) => !force && !session.access_token,
  }))
  .build()

export function getCurrentUser({
  force = false,
}: {
  force?: boolean
} = {}) {
  return createThunk(async (dispatch, getState, { promiseManager }) => {
    if (force) promiseManager.discard(GET_CURRENT_USER_SYMBOL)
    return promiseManager.create(
      GET_CURRENT_USER_SYMBOL,
      async () => {
        const profilePromise = dispatch(profileRequestAction(force)).then(resp => {
          if (resp?.error && asNetworkError(resp.payload)?.status === 401) return undefined
          return resp
        })

        const sessionUpdatePromise = profilePromise.then(async response => {
          if (!response || response.error) return
          const state = getState()

          await dispatch(
            setSession({
              access_token: state.session.access_token || '',
            })
          )
        })

        const trainingPromise = profilePromise.then(async () => {
          const user = getState().profile.user
          if (user && user.account_type === 'sitter' && !user.approved && !user.training_completed && user.has_training_request) {
            await dispatch(getTrainingRequest())
          }
        })

        const promise = Promise.all([profilePromise, sessionUpdatePromise, trainingPromise]).then(([profileResp]) => profileResp)

        const result = await promise
        return result
      },
      {
        freshness: force ? -1 : 0,
        discard: res => res.error,
      }
    )
  })
}

const fetchUserPromises: { [key: string]: ApiActionPromise<StoreUser> } = {}

export const userRequestAction = new ApiActionBuilder(userRequestActionDescriptor)
  .setInit((payload: { type: 'token'; value: string } | { type: 'id'; value: string }) => ({
    method: 'GET',
    endpoint: api.path(payload.type === 'id' ? urlEscaped`/api/v2/users/${payload.value}` : urlEscaped`/api/v2/users/by_token/${payload.value}`),
    headers: api.headers(),
    meta: { token: payload.value },
  }))
  .build()

export function fetchUser(
  payload: { type: 'id'; value: string } | { type: 'token'; value: string },
  { force = false, ensureAccountType }: { force?: boolean; ensureAccountType?: 'sitter' | 'parent' } = {}
) {
  return createThunk<ApiActionPromise<StoreUser>>(async (dispatch, getState) => {
    if (payload.value.includes('support'))
      return {
        error: true,
        payload: new ApiError(404, `Attempt to fetch user with id: ${payload.value}`, undefined),
      }

    const state = getState()
    const user = state.users.models[payload.value]

    if (ensureAccountType && user && user.account_type !== ensureAccountType) return { error: true, payload: new Error('Invalid account type') }

    if (!force && user && user.avatarsComplete) return { error: false, payload: user }

    const promiseId = `${payload.type}:${payload.value}`

    let promise = fetchUserPromises[promiseId]

    if (!promise) {
      promise = dispatch(userRequestAction(payload)).then(p => {
        if (promiseId in fetchUserPromises) delete fetchUserPromises[promiseId]

        if (p?.error) return p

        return { error: false, payload: getState().users.models[payload.value] }
      })

      fetchUserPromises[promiseId] = promise
    }

    if (ensureAccountType) {
      return promise.then(resp => {
        if (!resp || resp.error) return resp
        if (resp.payload.account_type !== ensureAccountType)
          return {
            error: true,
            payload: new ApiError(400, 'InvalidAccountError', undefined),
          }
        return resp
      })
    }

    return promise
  })
}

export const getUsersByIds = new ApiActionBuilder(getUsersByIdsDescriptor)
  .setInit((ids: string[], accountType: 'parent' | 'sitter') => ({
    method: 'GET',
    endpoint: api.path('/api/v2/users', {
      'filter[id]': ids,
      'filter[account_type]': accountType,
    }),
    headers: api.headers(),
  }))
  .build()

export const sendEmailConfirmation = new ApiActionBuilder(sendEmailConfirmationDescriptor)
  .setInit((token: string) => ({
    method: 'POST',
    endpoint: api.path('/api/v2/email_confirmation'),
    headers: api.headers(),
    body: JSON.stringify({ token }),
    bailout: ({ emailConfirmation }) => !!(emailConfirmation.data.email || emailConfirmation.data.error),
  }))
  .build()

export const resendEmailConfirmation = new ApiActionBuilder(resendEmailConfirmationDescriptor)
  .setInit(() => ({
    method: 'POST',
    endpoint: api.path('/api/v2/email_confirmation/resend'),
    headers: api.headers(),
  }))
  .build()
