import { uiClearBannersState, uiSetBannersState } from '@app/store/actions/ui.descriptors'
import { createReducer } from '@app/store/toolkit'
import { BannersState } from '@app/store/types/banners'

export default createReducer<BannersState>({}, builder => {
  builder.addCase(uiSetBannersState.shape, (state, action) => {
    return { ...state, ...action.payload }
  })

  builder.addCase(uiClearBannersState.shape, (_state, _action, defaultState) => {
    return defaultState
  })
})
