import React, { Component } from 'react'
import { createBrowserHistory, LocationListener } from 'history'
import { connect } from 'react-redux'
import { NavigationType, Router as ReactRouter } from 'react-router'

import { WithDispatchProp } from '@app/store/dispatch'

import { RouterContext, RouterContextInterface, setRouter } from './Context'
import { matchRoutes } from './functions'
import { getRedirect, Redirect, RedirectLocation } from './Redirect'
import { RouteRenderer } from './RouteRenderer'
import { StaticRedirect } from './StaticRedirect'
import { Action, History, Location, LocationDescriptor, LocationDescriptorObject, LocationState, MatchedRoute, Route } from './types'
import { getRootMatch } from './utils'

export const history: History = createBrowserHistory({ forceRefresh: false })

type RouterProps = {
  getRoutes: () => Route[]
  redirect?: StaticRedirect
  onBeforeLocationChange?: (location: LocationDescriptorObject, action: Action) => Promise<unknown>
  onLocationChange?: (location: LocationDescriptorObject, action: Action, matchedRoutes: MatchedRoute[]) => Promise<void | StaticRedirect>
  afterRender?: (location: LocationDescriptorObject, matchedRoutes: MatchedRoute[], hardNavigation: boolean) => Promise<unknown>
} & WithDispatchProp

interface RouterState {
  location: Location
  action: Action
}

export const RouterFactory = (regions: string[], history: History) => {
  /**
   * The main difference with stock router
   * (https://github.com/ReactTraining/react-router/blob/520a0acd48ae1b066eb0b07d6d4d1790a1d02482/packages/react-router/modules/Router.js#L10)
   * is that it doesn't listen to history changes.
   *
   * In our case we fire rerender manually by `render`
   * function in `@app/render.js:render` which is fired
   * after history update via chain `@app/app.client.js:app -> @app/app.client.js:run`
   *
   * TODO: RouterContext is considered unstable and can be changed any time
   * by maintainers of `react-router`
   */
  class Router extends Component<RouterProps, RouterState> {
    unlisten: () => void
    scroll: LocationState['scroll']
    matchedRoutes: MatchedRoute[] = []
    redirect?: StaticRedirect
    changeAbortController: AbortController = new AbortController()

    constructor(props: RouterProps) {
      super(props)

      this.redirect = this.props.redirect

      this.state = {
        location: history.location,
        action: NavigationType.Push,
      }

      this.matchedRoutes = (() => {
        const routes = this.props.getRoutes()
        return matchRoutes(regions, routes, history.location.pathname)
      })()

      this.unlisten = history.listen(this.handleLocationChange)
    }

    componentDidMount() {
      this.props.afterRender?.(this.state.location, this.matchedRoutes, true)
    }

    componentDidUpdate(_prevProps: RouterProps, prevState: RouterState) {
      if (prevState.location === this.state.location) return

      if (!this.scroll) {
        window.scrollTo(0, 0)
      } else if (Array.isArray(this.scroll)) {
        window.scrollTo(...this.scroll)
      }

      this.props.afterRender?.(this.state.location, this.matchedRoutes, this.state.action === 'POP' ? true : !!this.state.location.state?.hard)
    }

    componentWillUnmount() {
      this.unlisten()
    }

    render() {
      const matchedRoute = this.matchedRoutes.at(-1) ?? null

      const routerContext: RouterContextInterface = {
        history,
        location: this.state.location,
        match: matchedRoute ? matchedRoute.match : getRootMatch(),
        route: matchedRoute?.route ?? null,
      }

      this.props.dispatch(setRouter(routerContext))

      return (
        <RouterContext.Provider value={routerContext}>
          <ReactRouter location={routerContext.location} navigationType={this.state.action} navigator={history}>
            {this.redirect ? <Redirect to={this.redirect.location} /> : <RouteRenderer routes={this.props.getRoutes()} />}
          </ReactRouter>
        </RouterContext.Provider>
      )
    }

    private handleLocationChange: LocationListener<LocationState | undefined> = async (location, action) => {
      if (location === this.state.location) return
      this.changeAbortController.abort()
      const changeAbortController = new AbortController()
      this.changeAbortController = changeAbortController
      this.redirect = undefined

      const performFetch = action === 'POP' ? true : (location.state?.hard ?? true)
      const navigationType = action === 'POP' ? NavigationType.Pop : action === 'REPLACE' ? NavigationType.Replace : NavigationType.Push

      if (performFetch) {
        try {
          await this.props.onBeforeLocationChange?.(location, navigationType)
        } catch {
          return
        }
      }

      if (changeAbortController.signal.aborted) return

      this.scroll = action === 'POP' ? 'save' : location.state && location.state.scroll

      if (performFetch) {
        const routes = this.props.getRoutes()
        this.matchedRoutes = matchRoutes(regions, routes, history.location.pathname)

        const match = this.matchedRoutes.at(-1) ?? null

        if (match) {
          try {
            const redirectLocation =
              getLocation(location, getRedirect(match.route.component)?.to || null) ||
              (await this.props.onLocationChange?.(location, navigationType, this.matchedRoutes))?.location

            if (changeAbortController.signal.aborted) return

            if (redirectLocation) {
              history.replace(redirectLocation)
              return
            }
          } catch (e) {
            console.error(e)
          }
        }
      }

      if (changeAbortController.signal.aborted) return

      this.setState({ location, action: navigationType })
    }
  }

  return connect()(Router)
}

const getLocation = (currentLocation: LocationDescriptorObject, location: null | RedirectLocation): LocationDescriptor | null => {
  if (typeof location === 'function') return location(currentLocation)
  return location
}
