import { ApiTbankReview, ApiTbankReviewsSummary } from '@app/constants/ApiTypes/entities'

import { ApiActionBuilderDescriptor } from '@app/store/apiMiddleware/builderDescriptor'

export const getTBankReviewsByQueryDescriptor = new ApiActionBuilderDescriptor().setLabel('GET_TBANK_REVIEWS').setShape<{
  data: ApiTbankReview[]
  meta: {
    total: number
    total_pages: number
    current_page: number
  }
}>()
export const getTBankReviewsSummaryDescriptor = new ApiActionBuilderDescriptor().setLabel('GET_TBANK_REVIEWS_SUMMARY').setShape<{
  data: ApiTbankReviewsSummary
}>()
