import { ApiYandexMapsReview, ApiYandexMapsReviewsSummary } from '@app/constants/ApiTypes/entities'

import { ApiActionBuilderDescriptor } from '@app/store/apiMiddleware/builderDescriptor'

export const getYandexMapsReviewsByQueryDescriptor = new ApiActionBuilderDescriptor().setLabel('GET_YANDEX_MAPS_REVIEWS').setShape<{
  data: ApiYandexMapsReview[]
  meta: {
    total: number
    total_pages: number
    current_page: number
  }
}>()
export const getYandexMapsReviewsSummaryDescriptor = new ApiActionBuilderDescriptor().setLabel('GET_YANDEX_MAPS_REVIEWS_SUMMARY').setShape<{
  data: ApiYandexMapsReviewsSummary
}>()
