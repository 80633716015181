import queryString from 'query-string'

import { IMPORT_MAP } from '@app/importMap'

import { StaticRedirect } from './routing/StaticRedirect'
import { LocationDescriptorObject } from './routing/types'

export const getShouldLoginRedirect = (location: LocationDescriptorObject) => {
  if (location.pathname !== '/') return null
  if (!location.search) return null
  const query = queryString.parse(location.search)
  if (query.login === 'true' && typeof query.next === 'string') {
    return new StaticRedirect(query.next)
  }
  return null
}

export function makeLoginRedirect(next?: string) {
  return IMPORT_MAP.components.Redirect().then(m =>
    m.createRedirect(
      queryString.stringifyUrl({
        url: '/',
        query: {
          login: 'true',
          next,
        },
      })
    )
  )
}

export function makeStaticLoginRedirect(next?: string) {
  return new StaticRedirect(
    queryString.stringifyUrl({
      url: '/',
      query: {
        login: 'true',
        next,
      },
    })
  )
}
