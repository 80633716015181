import { History } from '@app/utils/routing/types'

import { push, refresh, replace } from '@app/store/actions/router.descriptors'

import { ReduxMiddleware } from './types'

/**
 * Middleware for intercepting actions with types AC.HISTORY_PUSH, AC.HISTORY_REPLACE, AC.HISTORY_REFRESH
 * and initiating a transition.
 */
export default (history?: History): ReduxMiddleware<typeof push.shape | typeof replace.shape | typeof refresh.shape> =>
  () =>
  next =>
  action => {
    if (!history) return next(action)

    switch (action.type) {
      case push.shape.type:
        history.push(action.payload)
        break
      case replace.shape.type:
        history.replace(action.payload)
        break
      case refresh.shape.type:
        history.replace(window.location.pathname + window.location.search + window.location.hash)
        break
    }
    return next(action)
  }
