import { ISO8601 } from '@app/constants/Misc'

import { excludeAbortError } from '@app/errors/AbortError'

import moment from '@app/utils/moment'

import {
  deleteTelegramBotConnectionsDescriptor,
  getTelegramBotConnectionsDescriptor,
  postTelegramBotConnectionsDescriptor,
} from '@app/store/actions/telegram.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  loaded: boolean
  loaded_at: ISO8601 | null
  loading: boolean
  error: Error | null
  postLoading: boolean
  postError: Error | null
  deleteLoading: boolean
  deleteError: Error | null
}>(
  {
    loaded: false,
    loaded_at: null,
    loading: false,
    error: null,
    postLoading: false,
    postError: null,
    deleteLoading: false,
    deleteError: null,
  },
  builder => {
    builder.addCase(getTelegramBotConnectionsDescriptor.shapes.pending, (state, _action) => {
      return { ...state, loading: true, error: null }
    })

    builder.addCase(getTelegramBotConnectionsDescriptor.shapes.fulfilled, (state, _action) => {
      return { ...state, loaded: true, loaded_at: moment().format(), loading: false, error: null }
    })

    builder.addCase(getTelegramBotConnectionsDescriptor.shapes.rejected, (state, action) => {
      return { ...state, loading: false, error: excludeAbortError(action.payload) }
    })

    builder.addCase(postTelegramBotConnectionsDescriptor.shapes.pending, (state, _action) => {
      return { ...state, postLoading: true, postError: null }
    })

    builder.addCase(postTelegramBotConnectionsDescriptor.shapes.fulfilled, (state, _action) => {
      return { ...state, postLoading: false, postError: null }
    })

    builder.addCase(postTelegramBotConnectionsDescriptor.shapes.rejected, (state, action) => {
      return { ...state, postLoading: false, postError: excludeAbortError(action.payload) }
    })

    builder.addCase(deleteTelegramBotConnectionsDescriptor.shapes.pending, (state, _action) => {
      return { ...state, deleteLoading: true, deleteError: null }
    })

    builder.addCase(deleteTelegramBotConnectionsDescriptor.shapes.fulfilled, (state, _action) => {
      return { ...state, deleteLoading: false, deleteError: null }
    })

    builder.addCase(deleteTelegramBotConnectionsDescriptor.shapes.rejected, (state, action) => {
      return { ...state, deleteLoading: false, deleteError: excludeAbortError(action.payload) }
    })
  }
)
