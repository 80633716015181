import { omit } from '@app/utils/omit'

import { addClassName, removeClassName } from '@app/store/actions/ui.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{ [key: string]: number }>({}, builder => {
  builder.addCase(addClassName.shape, (state, action) => {
    const key = action.payload
    return { ...state, [key]: (state[key] ?? 0) + 1 }
  })

  builder.addCase(removeClassName.shape, (state, action) => {
    const key = action.payload
    if (state[key] <= 1) return omit(state, key)
    if (!state[key]) return state
    return { ...state, [key]: state[key] - 1 }
  })
})
