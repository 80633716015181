import React, { ComponentProps, CSSProperties, FunctionComponent, useEffect, useMemo, useRef, useState } from 'react'

type Props = {
  /** css size string */
  presumedHeight?: string
  /** value in ms */
  animationTime?: number
} & ComponentProps<'div'>

export const SlideUp: FunctionComponent<Props> = ({ presumedHeight = '1000px', animationTime = 300, style: pStyle, children, ...props }) => {
  const opened = !!children
  const rootRef = useRef<HTMLDivElement>(null)
  const hideStyle = useMemo<CSSProperties>(() => (opened ? {} : { marginTop: 0, marginBottom: 0, paddingTop: 0, paddingBottom: 0, maxHeight: 0 }), [opened])

  const [height, setHeight] = useState(rootRef.current?.scrollHeight)
  const [reallyOpened, setReallyOpened] = useState(opened)

  const [childrenId, setChildrenId] = useState(0)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const effectiveChildren = useMemo(() => children, [reallyOpened, childrenId])

  const style = useMemo<CSSProperties>(
    () => ({
      ...pStyle,
      transition: `padding ${animationTime}ms, margin ${animationTime}ms, max-height ${animationTime}ms`,
      maxHeight: height ? `${height}px` : presumedHeight,
      overflowY: 'hidden',
      ...hideStyle,
    }),
    [animationTime, height, hideStyle, pStyle, presumedHeight]
  )

  useEffect(() => {
    try {
      const observer = new window.ResizeObserver(() => {
        const height = rootRef.current?.scrollHeight
        if (height) setHeight(height)
      })

      observer.observe(rootRef.current!)

      return () => observer.disconnect()
    } catch {}
  }, [])

  useEffect(() => {
    if (opened) {
      setReallyOpened(opened)
    } else {
      const timeout = setTimeout(() => {
        setReallyOpened(opened)
      }, animationTime + 10)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [opened, animationTime])

  useEffect(() => {
    if (children) {
      setChildrenId(v => (v + 1) & 0xff)
    }
  }, [children])

  return (
    <div style={style} {...props} ref={rootRef}>
      {effectiveChildren}
    </div>
  )
}
