import { IMPORT_MAP } from '@app/importMap'

import loggerCreator from '@app/utils/loggerCreator'

export class FirebaseMessagingManager {
  private promiseChain: Promise<void> = Promise.resolve()
  private disconnectRef: (() => void) | null = null

  static async subscribe() {
    await this.updateServiceWorker()
    const m = await IMPORT_MAP.firebase.messaging()
    const token = await m.getToken(m.getMessaging())
    this.log('Subscribed. token:', token)
    return token
  }

  static async unsubscribe() {
    const m = await IMPORT_MAP.firebase.messaging()
    const result = await m.deleteToken(m.getMessaging())
    this.log('Unsubscribed. Success:', result)
    return result
  }

  static async updateServiceWorker() {
    const regs = await navigator.serviceWorker.getRegistrations()
    try {
      this.log('updateServiceWorker. Found registrations: ', regs)
      const reg = regs.find(r => r.scope.endsWith('/firebase-cloud-messaging-push-scope'))
      if (reg) {
        this.log('updateServiceWorker. Found registration: ', reg)
        await reg?.update()
        this.log('updateServiceWorker. Updated service worker')
      } else {
        this.log('updateServiceWorker. Registration not found')
      }
    } catch (e) {
      console.error('updateServiceWorker. Failed to update service worker: ', e)
      throw e
    }
  }

  messaging() {
    return IMPORT_MAP.firebase.messaging()
  }

  async connect() {
    const messaging = await IMPORT_MAP.firebase.messaging()
    let onMessageUnsubscribe: (() => unknown) | null = null

    if (await messaging.isSupported()) {
      onMessageUnsubscribe = messaging.onMessage(messaging.getMessaging(), payload => {
        this.log('Message', payload)
      })

      this.log('Connected')
    }

    this.disconnectRef = () => {
      onMessageUnsubscribe?.()
      this.log('Disconnected')
    }
  }

  async disconnect() {
    this.promiseChain = this.promiseChain.then(async () => {
      this.disconnectRef?.()
    })

    return this.promiseChain
  }

  private log = loggerCreator('FirebaseMessaging', 'blue')
  private static log = loggerCreator('FirebaseMessaging', 'blue')
}
