import { ApiInaccurateLocation } from '@app/constants/ApiTypes/entities'

import { normalize } from '@app/utils/normalizer'

import {
  getDepersonalizedAnnouncementsByQueryDescriptor,
  getDepersonalizedAnnouncementsSearchDescriptor,
} from '@app/store/actions/depersonalized_announcements.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{ [key: string]: ApiInaccurateLocation }>({}, builder => {
  builder.addCases(
    [getDepersonalizedAnnouncementsSearchDescriptor.shapes.fulfilled, getDepersonalizedAnnouncementsByQueryDescriptor.shapes.fulfilled],
    (state, action) => {
      const { inaccurate_locations } = normalize(action.payload)
      return { ...state, ...inaccurate_locations }
    }
  )
})
