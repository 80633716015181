import mapValues from 'lodash/mapValues'
import uniqBy from 'lodash/uniqBy'
import { shallowEqual } from 'react-redux'

import {
  ApiAcademyCart as AcademyCartType,
  ApiAcademyCourse,
  ApiAcademyLesson,
  ApiAcademyLessonPicture,
  ApiAcademyLessonRecord,
  ApiAcademyPromocode,
  ApiAvatar,
  ApiIncluded,
  ApiSitter,
} from '@app/constants/ApiTypes/entities'

import { excludeAbortError } from '@app/errors/AbortError'

import { createMap } from '@app/utils/createMap'
import { isTruthy } from '@app/utils/isTruthy'
import { normalize } from '@app/utils/normalizer'
import { omit } from '@app/utils/omit'

import {
  getAcademyCourseByIdDescriptor,
  getAcademyCoursesDescriptor,
  getAcademyLessonsByIdDescriptor,
  getAcademyLessonsDescriptor,
  getAcademyProfileLessonRecordsDescriptor,
  resetAcademyLessonRecords,
  setAcademyCart,
  setAcademyCartMeta,
  setAcademyFilter,
  setAcademyInvoice,
  setAcademyInvoiceMeta,
  setAcademyLoginFormEmail,
  setAcademyProfile,
  setAcademyPromocode,
} from '@app/store/actions/academy.descriptors'
import { postEmailTokenDescriptor } from '@app/store/actions/emailToken.descriptors'
import { createReducer } from '@app/store/toolkit'

export type AcademyCourse = { id: ApiAcademyCourse['id']; color: string } & ApiAcademyCourse['attributes']
export type AcademyLesson = {
  id: ApiAcademyLesson['id']
  pictures: ({ id: string } & ApiAcademyLessonPicture['attributes'])[]
  course: AcademyCourse
  sitter: ({ id: string; avatars: ({ id: string } & ApiAvatar['attributes'])[] } & ApiSitter['attributes']) | null
} & ApiAcademyLesson['attributes']

export type AcademyLessonRecord = {
  id: string
  lesson: AcademyLesson & { zoom_link: string | null; zoom_password: string | null }
} & ApiAcademyLessonRecord['attributes']

export interface AcademyState {
  models: { [key: string]: AcademyLesson }
  /** list if AcademyLesson.id */
  list: string[]
  lessonsLoaded: boolean
  courses: AcademyCourse[]
  coursesLoaded: boolean
  profile: { email: string; token: string } | null

  // /academy/course/:id page data
  course: AcademyCourse | null
  courseLessonsList: string[]

  cart: {
    items: (AcademyLesson & { not_available_reason: string | null })[]
    kidName: string
    kidAge: number
    name: string
    phone: string
    email: string
    promocode: string
  }
  cartMeta: {
    restored: boolean
    loading: boolean
  }
  cartInvoice: null | { error: false; data: AcademyCartType } | { error: true; data: Error }
  promocode: null | { error: false; data: ApiAcademyPromocode } | { error: true; data: Error }
  cartInvoiceMeta: {
    loading: boolean
    /** active when user fills form on mobile */
    prePurchasing: boolean
    purchasing: boolean
    purchaseError: Error | null
  }
  filter: {
    age?: number
    /** list of course ids */
    courses: string[]
    start?: number
    end?: number
  }
  loginForm: {
    email: string
  }
  lessonRecords: {
    meta: {
      fetched: boolean
      upcoming?: boolean
      page: number
      totalPages: number
      loading: boolean
      error: Error | null
    }
    models: AcademyLessonRecord[]
  }
}

export default createReducer<AcademyState>(
  {
    models: {},
    list: [],
    lessonsLoaded: false,
    courses: [],
    coursesLoaded: false,
    profile: null,
    course: null,
    courseLessonsList: [],
    cart: {
      items: [],
      kidName: '',
      kidAge: 0,
      name: '',
      phone: '',
      email: '',
      promocode: '',
    },
    cartMeta: {
      restored: false,
      loading: false,
    },
    cartInvoice: null,
    promocode: null,
    cartInvoiceMeta: {
      loading: false,
      prePurchasing: false,
      purchasing: false,
      purchaseError: null,
    },
    filter: {
      age: 0,
      courses: [],
    },
    loginForm: {
      email: '',
    },
    lessonRecords: {
      meta: {
        fetched: false,
        page: 0,
        totalPages: 0,
        loading: false,
        error: null,
      },
      models: [],
    },
  },
  builder => {
    builder.addCase(getAcademyLessonsDescriptor.shapes.fulfilled, (state, action) => {
      const data = action.payload
      const modelsList = convertLessonsData(data)
      const modelsObject = createMap(modelsList, x => x.id)
      const newState = { ...state }
      if (action.meta.course_id) {
        newState.courseLessonsList = modelsList.map(m => m.id)
      } else {
        newState.list = modelsList.map(m => m.id)
      }
      newState.models = { ...newState.models, ...modelsObject }
      if (!action.meta.course_id) {
        newState.lessonsLoaded = true
      }
      return newState
    })
    builder.addCase(getAcademyLessonsByIdDescriptor.shapes.fulfilled, (state, action) => {
      const newState = { ...state, models: { ...state.models } }
      const lesson = convertLessonData(action.payload)
      newState.models[lesson.id] = lesson
      return newState
    })
    builder.addCase(getAcademyCoursesDescriptor.shapes.fulfilled, (state, action) => {
      const data = action.payload
      const newState = { ...state }
      newState.courses = data.data.map(convertCourse)
      newState.coursesLoaded = true
      return newState
    })
    builder.addCase(getAcademyCourseByIdDescriptor.shapes.fulfilled, (state, action) => {
      const data = action.payload
      const newState = { ...state }
      newState.course = convertCourse(data.data)
      return newState
    })
    builder.addCase(setAcademyCart.shape, (state, action) => {
      let models = state.models
      if (action.payload.items) {
        const modelsMap = createMap(
          action.payload.items.map(m => omit(m, 'not_available_reason')),
          x => x.id
        )
        models = { ...models, ...modelsMap }
      }

      return { ...state, models, cart: { ...state.cart, ...action.payload } }
    })
    builder.addCase(setAcademyCartMeta.shape, (state, action) => {
      const newMeta = { ...state.cartMeta, ...action.payload }
      if (shallowEqual(newMeta, state.cartMeta)) return state
      return { ...state, cartMeta: { ...state.cartMeta, ...action.payload } }
    })
    builder.addCase(setAcademyInvoice.shape, (state, action) => ({ ...state, cartInvoice: action.payload }))
    builder.addCase(setAcademyPromocode.shape, (state, action) => ({ ...state, promocode: action.payload }))
    builder.addCase(setAcademyInvoiceMeta.shape, (state, action) => {
      const newMeta = { ...state.cartInvoiceMeta, ...action.payload }
      if (shallowEqual(newMeta, state.cartInvoiceMeta)) return state
      return { ...state, cartInvoiceMeta: { ...state.cartInvoiceMeta, ...action.payload } }
    })
    builder.addCase(setAcademyFilter.shape, (state, action) => ({ ...state, filter: action.payload }))
    builder.addCase(setAcademyProfile.shape, (state, action) => ({ ...state, profile: action.payload }))
    builder.addCase(getAcademyProfileLessonRecordsDescriptor.shapes.pending, (state, _action) => ({
      ...state,
      lessonRecords: { ...state.lessonRecords, meta: { ...state.lessonRecords.meta, loading: true, error: null } },
    }))
    builder.addCase(getAcademyProfileLessonRecordsDescriptor.shapes.fulfilled, (state, action) => {
      const meta = action.payload.meta
      const newRecords = convertRecordsData(action.payload)
      const models = meta.current_page === 1 ? newRecords : uniqBy([...state.lessonRecords.models, ...newRecords], r => r.id)
      return {
        ...state,
        lessonRecords: {
          ...state.lessonRecords,
          models,
          meta: {
            ...state.lessonRecords.meta,
            fetched: true,
            page: meta.current_page,
            totalPages: meta.total_pages,
            loading: false,
            error: null,
            upcoming: action.meta.upcoming,
          },
        },
      }
    })
    builder.addCase(getAcademyProfileLessonRecordsDescriptor.shapes.rejected, (state, action) => ({
      ...state,
      lessonRecords: { ...state.lessonRecords, meta: { ...state.lessonRecords.meta, loading: false, error: excludeAbortError(action.payload) } },
    }))
    builder.addCase(resetAcademyLessonRecords.shape, (state, _action, defaultState) => ({
      ...state,
      lessonRecords: defaultState.lessonRecords,
    }))
    builder.addCase(setAcademyLoginFormEmail.shape, (state, action) => ({
      ...state,
      loginForm: { ...state.loginForm, email: action.payload, error: null, loading: false, sent: false },
    }))
    builder.addCase(postEmailTokenDescriptor.shapes.pending, (state, _action) => ({
      ...state,
      loginForm: { ...state.loginForm, error: null, loading: true, sent: false },
    }))
    builder.addCase(postEmailTokenDescriptor.shapes.fulfilled, (state, _action) => ({
      ...state,
      loginForm: { ...state.loginForm, error: null, loading: false, sent: true },
    }))
    builder.addCase(postEmailTokenDescriptor.shapes.rejected, (state, action) => ({
      ...state,
      loginForm: { ...state.loginForm, error: excludeAbortError(action.payload), loading: false, sent: false },
    }))
  }
)

const convertCourse = (course: ApiAcademyCourse): AcademyCourse => {
  return {
    id: course.id,
    ...course.attributes,
    brand: course.attributes.brand ? course.attributes.brand : course.id === '27' ? { name: 'meeting_with_santa' } : course.attributes.brand,
  }
}
const convertPicture = (picture: ApiAcademyLessonPicture): { id: string } & ApiAcademyLessonPicture['attributes'] => {
  return { id: picture.id, ...picture.attributes }
}

const convertLessonsData = (data: { data: ApiAcademyLesson[]; included: ApiIncluded }): AcademyLesson[] => {
  const { academy_courses = {}, users = {}, avatars = {}, academy_lesson_pictures = {} } = normalize(data)
  const storeAvatars = avatars ? mapValues(avatars, a => ({ id: a.id, ...a.attributes })) : {}
  const storeUsers = users
    ? mapValues(users, user => {
        const sitter = user as ApiSitter
        const avatars = sitter.relationships.avatars?.data ? sitter.relationships.avatars.data.map(a => storeAvatars[a.id]) : []
        return { id: sitter.id, ...sitter.attributes, avatars }
      })
    : {}

  const courses = mapValues(academy_courses, convertCourse)
  const lessons = data.data.map(l => ({
    id: l.id,
    ...l.attributes,
    pictures: (l.relationships.pictures?.data ?? [])
      .map(p => {
        if (!academy_lesson_pictures) return
        const picture = academy_lesson_pictures[p.id]
        if (!picture) return
        return convertPicture(picture)
      })
      .filter(isTruthy),
    course: courses[l.relationships.course.data!.id],
    sitter: l.relationships.sitter.data ? storeUsers[l.relationships.sitter.data.id] : null,
  }))
  return lessons
}

const convertLessonData = (data: { data: ApiAcademyLesson; included: ApiIncluded }): AcademyLesson => {
  const { academy_courses = {}, users = {}, avatars = {}, academy_lesson_pictures = {} } = normalize(data)
  const storeAvatars = avatars ? mapValues(avatars, a => ({ id: a.id, ...a.attributes })) : {}
  const storeUsers = users
    ? mapValues(users, user => {
        const sitter = user as ApiSitter
        const avatars = sitter.relationships.avatars?.data ? sitter.relationships.avatars.data.map(a => storeAvatars[a.id]) : []
        return { id: sitter.id, ...sitter.attributes, avatars }
      })
    : {}

  const courses = mapValues(academy_courses, convertCourse)
  const lesson = {
    id: data.data.id,
    ...data.data.attributes,
    pictures: (data.data.relationships.pictures?.data ?? [])
      .map(p => {
        if (!academy_lesson_pictures) return
        const picture = academy_lesson_pictures[p.id]
        if (!picture) return
        return convertPicture(picture)
      })
      .filter(isTruthy),
    course: courses[data.data.relationships.course.data!.id],
    sitter: data.data.relationships.sitter.data ? storeUsers[data.data.relationships.sitter.data.id] : null,
  }
  return lesson
}

const convertRecordsData = (data: { data: ApiAcademyLessonRecord[]; included: ApiIncluded }): AcademyLessonRecord[] => {
  const { academy_courses = {}, users = {}, avatars = {}, extended_academy_lessons = {}, academy_lesson_pictures = {} } = normalize(data)
  const storeAvatars = avatars ? mapValues(avatars, a => ({ id: a.id, ...a.attributes })) : {}
  const storeUsers = users
    ? mapValues(users, user => {
        const sitter = user as ApiSitter
        const avatars = sitter.relationships.avatars?.data ? sitter.relationships.avatars.data.map(a => storeAvatars[a.id]) : []
        return { id: sitter.id, ...sitter.attributes, avatars }
      })
    : {}

  const courses = mapValues(academy_courses, convertCourse)

  const lessons = mapValues(extended_academy_lessons, l => {
    const sitterId = l.relationships.sitter.data?.id
    const sitter = sitterId ? storeUsers[sitterId] : null
    const course = courses[l.relationships.course.data!.id]
    const pictures = (l.relationships.pictures?.data ?? [])
      .map(p => {
        if (!academy_lesson_pictures) return
        const picture = academy_lesson_pictures[p.id]
        if (!picture) return
        return convertPicture(picture)
      })
      .filter(isTruthy)

    return { id: l.id, ...l.attributes, sitter, course, pictures }
  })

  const records = data.data.map(r => {
    const lesson = lessons[r.relationships.lesson.data!.id]
    return { id: r.id, ...r.attributes, lesson }
  })

  return records
}
