import React, { ComponentProps, memo } from 'react'

import { useRouter } from '@app/utils/routing/hooks'
import { Link } from '@app/utils/routing/Link'

import { Icon } from '@app/components/Icon/Icon'

import classes from './Logo.module.scss'

type Props = Omit<ComponentProps<'a'>, 'ref' | 'defaultValue' | 'aria-relevant'>

export const Logo = memo<Props>(function Logo({ className, ...aprops }) {
  const router = useRouter()

  if (!router)
    return (
      <a className={cn(classes.logo, className)} href="/" {...aprops}>
        Kidsout
        <Icon className={classes.svg} icon="logo" />
      </a>
    )

  return (
    <Link
      className={cn(classes.logo, className)}
      eventName="logo.logo_link"
      region={true}
      rel={router.location.pathname === '/' ? 'nofollow' : undefined}
      to="/"
      {...aprops}
    >
      Kidsout
      <Icon className={classes.svg} icon="logo" />
    </Link>
  )
})
