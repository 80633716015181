export const tryOr = <T extends any>(fn: () => T, fallback: T): T => {
  try {
    return fn()
  } catch {
    return fallback
  }
}

export const tryOrElse = <T extends any>(fn: () => T, fallback: (error: unknown) => T): T => {
  try {
    return fn()
  } catch (e) {
    return fallback(e)
  }
}
