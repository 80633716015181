export const identify: typeof import('./errorReport.server').identify = IS_BROWSER
  ? require('./errorReport.client').identify
  : require('./errorReport.server').identify
export const captureException: typeof import('./errorReport.server').captureException = IS_BROWSER
  ? require('./errorReport.client').captureException
  : require('./errorReport.server').captureException
export const captureMessage: typeof import('./errorReport.server').captureMessage = IS_BROWSER
  ? require('./errorReport.client').captureMessage
  : require('./errorReport.server').captureMessage
export const addBreadcrumb: typeof import('./errorReport.server').addBreadcrumb = IS_BROWSER
  ? require('./errorReport.client').addBreadcrumb
  : require('./errorReport.server').addBreadcrumb
