import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { StoreState } from '@app/store/store'

export const useCreateSelector = <T extends (state: StoreState) => any>(createSelector: () => T, deps: any[]) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selector = useMemo(createSelector, deps)
  return useSelector(selector) as ReturnType<T>
}
