import { ApiCurrency } from '@app/constants/ApiTypes/entities'

import { normalize } from '@app/utils/normalizer'

import { getCurrenciesDescriptor } from '@app/store/actions/currencies.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<Record<string, ApiCurrency>>({}, builder => {
  builder.addCase(getCurrenciesDescriptor.shapes.fulfilled, (state, action) => {
    const { currencies } = normalize(action.payload)
    if (!currencies) return state
    return { ...state, ...currencies }
  })
})
