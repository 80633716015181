import { IMPORT_MAP } from '@app/importMap'

import { AsyncRoute } from '@app/utils/routing/types'

const RedirectToLanding = () => IMPORT_MAP.components.Redirect().then(m => m.createRedirect('/', true))
const Landing = () => IMPORT_MAP.routes.Static.LandingParent().then(m => m.LandingParent)

const routes = (): AsyncRoute[] => [
  {
    event_id: 'main_page',
    path: '/',
    component: Landing,
    regional: true,
  },
  {
    event_id: 'online',
    path: '/online',
    component: Landing,
  },
  {
    event_id: 'urgent-nanny',
    path: '/urgent-nanny',
    component: Landing,
    regional: true,
  },
  {
    event_id: 'comfort',
    path: '/comfort',
    component: RedirectToLanding,
    regional: true,
  },
  {
    event_id: 'part-time-nanny',
    path: '/part-time-nanny',
    component: Landing,
    regional: true,
  },
  {
    event_id: 'safety',
    path: '/safety',
    component: Landing,
    regional: true,
  },
  {
    event_id: 'nanny',
    path: '/nanny',
    component: Landing,
    regional: true,
  },
  {
    event_id: 'camp',
    path: '/camp',
    component: Landing,
    regional: true,
  },
]

export default routes
