import * as valita from '@badrap/valita'

export function maybeType<T extends valita.Type<any>>(t: T, val: any): valita.Infer<T> | null {
  const res = t.try(val, { mode: 'passthrough' })
  return res.ok ? res.value : null
}

export function maybeTypeU<T extends valita.Type<any>>(t: T, val: any): valita.Infer<T> | undefined {
  const res = t.try(val, { mode: 'passthrough' })
  return res.ok ? res.value : undefined
}

export function maybeOrDefault<T extends valita.Type<any>, D>(t: T, val: any, def: D): valita.Infer<T> | D {
  const res = t.try(val, { mode: 'passthrough' })
  return res.ok ? res.value : def
}
