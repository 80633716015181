import { classNameSelector, themeColorSelector } from '@app/store/selectors/ui'
import { Store, storeMonitoring } from '@app/store/store'

export class DOMService {
  private store: Store
  deinitActions: (() => void)[] = []

  constructor(store: Store) {
    this.store = store
  }

  start() {
    const themeSubscription = storeMonitoring(this.store, [themeColorSelector], ([themeColor]) => {
      const el = document.head.querySelector<HTMLMetaElement>("[name='theme-color']")
      if (!el) return
      el.content = themeColor
    })

    this.deinitActions.push(themeSubscription)

    const classNameSubscription = storeMonitoring(this.store, [classNameSelector], ([className]) => {
      document.documentElement.className = className
    })

    this.deinitActions.push(classNameSubscription)
  }

  stop() {
    this.deinitActions.forEach(c => c())
    this.deinitActions = []
  }
}
