import React, { FunctionComponent, ReactNode, useCallback, useEffect, useRef, useState } from 'react'

import { debounce } from '@app/utils/debounce'

export type ScrollableItemsGetScrollButtonCallback = (type: 'start' | 'end', scrollable: boolean, onClick: (e: unknown) => unknown) => ReactNode

export interface ScrollableItemsProps {
  initialOffset?: number
  getScrollButton: ScrollableItemsGetScrollButtonCallback
  onScroll?: (el: HTMLDivElement) => unknown

  className?: string
  containerClassName?: string

  children?: React.ReactNode
}

export const ScrollableItems: FunctionComponent<ScrollableItemsProps> = ({
  initialOffset,
  getScrollButton,
  onScroll,
  className,
  containerClassName,
  children,
}) => {
  const rootRef = useRef<HTMLDivElement>(null)
  const [scrollable, setScrollable] = useState(false)
  const [hasStart, setHasStart] = useState(true)
  const [hasEnd, setHasEnd] = useState(true)

  const goToStart = useCallback((e: unknown) => {
    if (isEvent(e)) e.preventDefault?.()
    const el = rootRef.current!
    // setting maxscroll to container width
    const maxScroll = el.offsetWidth
    // setting scroll length to half of container width
    let scrollLength = maxScroll * 0.5
    // calculating remaining space on left side after scroll
    const delta = el.scrollLeft - scrollLength
    // if delta <= 0 then we hide start button
    let hasStart = delta > 0
    // if delta is small we increase scrollLength so scroll will go to start,
    // rather than ends with small amount of space of left side which is bad ux
    if (delta < Math.min(100, maxScroll * 0.25)) {
      scrollLength += delta
      hasStart = false
    }
    if (!scrollLength) return
    import('seamless-scroll-polyfill')
      .then(({ polyfill }) => {
        polyfill()
      })
      .finally(() => {
        el.scrollBy({ left: -scrollLength, behavior: 'smooth' })
        setHasStart(hasStart)
      })
  }, [])

  const goToEnd = useCallback((e: unknown) => {
    if (isEvent(e)) e.preventDefault?.()
    const el = rootRef.current!
    const maxScroll = el.offsetWidth
    let scrollLength = maxScroll * 0.5
    const delta = el.scrollWidth - el.offsetWidth - (el.scrollLeft + scrollLength)
    let hasEnd = delta > 0
    if (delta < Math.min(100, maxScroll * 0.25)) {
      scrollLength += delta
      hasEnd = false
    }
    if (!scrollLength) return
    import('seamless-scroll-polyfill')
      .then(({ polyfill }) => {
        polyfill()
      })
      .finally(() => {
        el.scrollBy({ left: scrollLength, behavior: 'smooth' })
        setHasEnd(hasEnd)
      })
  }, [])

  useEffect(() => {
    const el = rootRef.current!

    const set = () => {
      onScroll?.(el)
    }

    el.addEventListener('scroll', set)

    return () => {
      el.removeEventListener('scroll', set)
    }
  }, [onScroll])

  useEffect(() => {
    const el = rootRef.current!

    el.scrollLeft = initialOffset ?? 0

    let lastposition = el.scrollLeft
    const set = debounce(() => {
      setScrollable(el.scrollWidth > el.offsetWidth)
      const toLeft = lastposition > el.scrollLeft
      const toRight = lastposition < el.scrollLeft
      lastposition = el.scrollLeft
      const hasStart = el.scrollLeft > 0
      const hasEnd = el.scrollLeft < el.scrollWidth - el.offsetWidth
      setHasStart(v => (!v && toLeft ? v : hasStart))
      setHasEnd(v => (!v && toRight ? v : hasEnd))
    }, 50)

    const Observer = window.ResizeObserver
    const observer = Observer ? new Observer(set) : null

    if (observer) {
      observer.observe(el)
    } else {
      set()
      window.addEventListener('resize', set)
    }

    el.addEventListener('scroll', set)

    return () => {
      el.removeEventListener('scroll', set)
      if (observer) {
        observer.disconnect()
      } else {
        window.removeEventListener('resize', set)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={className}>
      <div className={containerClassName} ref={rootRef}>
        {children}
      </div>
      {scrollable && getScrollButton('start', hasStart, goToStart)}
      {scrollable && getScrollButton('end', hasEnd, goToEnd)}
    </div>
  )
}

const isEvent = (e: unknown): e is { preventDefault?: () => unknown } => {
  return typeof e === 'object' && e !== null && 'preventDefault' in e
}
