import React, { lazy, useMemo } from 'react'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'

import { IMPORT_MAP } from '@app/importMap'

import { pathIsSubPathOf } from '@app/utils/url'

import { ControlClasses } from '@app/components/ControlClasses/ControlClasses'
import { Icon18 } from '@app/components/Icon/Icon'
import { intlFormatters, NavItemVisibility, NavMenuItem } from '@app/components/LayoutMilk/navItemsUtils'
import { Suspense } from '@app/components/Suspense/Suspense'

import { AcademyEventName, URLS } from './config'

export const useHeaderMenu = (event: AcademyEventName, type: 'header' | 'side'): NavMenuItem => {
  const { formatMessage } = useIntl()

  return useMemo<NavMenuItem>(
    () => ({
      type: 'item',
      id: event,
      icon: headerIcons[event] || headerIcons.academy,
      url: URLS.main(event),
      matches: path => path !== URLS.cart(event) && pathIsSubPathOf(URLS.main(event), path),
      title: <FormattedMessage {...(messages[`label__${event}`] || messages.label__academy)} values={intlFormatters} />,
      visibility: event === 'academy' ? NavItemVisibility.All : NavItemVisibility.None,
      children: [
        {
          type: 'item',
          id: `${event}_main`,
          url: URLS.main(event),
          title: formatMessage(messages.label_main),
        },
        {
          type: 'item',
          id: `${event}_memo`,
          url: '/memo',
          title: formatMessage(messages.label_memo),
        },
        {
          type: 'item',
          id: `${event}_purchased`,
          url: URLS.purchased(event),
          title: formatMessage(messages.label_purchased),
        },
        {
          type: 'item',
          id: `${event}_cart`,
          url: URLS.cart(event),
          wrapInLink: false,
          title: (
            <div className={type === 'header' ? ControlClasses.hide({ mobile: true, tablet: true }) : undefined} data-cart-header-pointer>
              <Suspense fallback={null}>
                <HeaderCartButton type={type} />
              </Suspense>
            </div>
          ),
        },
      ],
    }),
    [event, formatMessage, type]
  )
}

const HeaderCartButton = lazy(() => IMPORT_MAP.routes.Academy.HeaderCartButton().then(m => ({ default: m.HeaderCartButton })))

const messages = defineMessages({
  label__academy: 'Онлайн-{zspace}занятия',
  label__new_year_party: 'Утренники',
  label_main: 'Расписание',
  label_memo: 'Памятка онлайн-занятий',
  label_purchased: 'Мои занятия',
})

const headerIcons: { [K in AcademyEventName]?: Icon18 } = {
  academy: 'smile',
  new_year_party: 'tree2',
}
