import { deleteUploadAvatar, setUploadAvatar } from '@app/store/actions/profile.descriptors'
import { createReducer } from '@app/store/toolkit'

export type AvatarUploadItem = { id: string; preview: string; percent: number; rejected?: boolean }

export default createReducer<AvatarUploadItem[]>([], builder => {
  builder.addCase(setUploadAvatar.shape, (state, action) => {
    const index = state.findIndex(i => i.id === action.payload.id)
    if (index === -1) return [...state, action.payload]
    return [...state.slice(0, index), action.payload, ...state.slice(index + 1)]
  })

  builder.addCase(deleteUploadAvatar.shape, (state, action) => {
    const index = state.findIndex(i => i.id === action.payload.id)
    if (index === -1) return state
    const newState = [...state]
    newState.splice(index, 1)
    return newState
  })
})
