import { ApiPerk } from '@app/constants/ApiTypes/entities'

import { getUsersPerksDescriptor } from '@app/store/actions/perks.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{ [key: string]: ApiPerk[] }>({}, builder => {
  builder.addCase(getUsersPerksDescriptor.shapes.fulfilled, (state, action) => {
    return { ...state, [action.meta.id]: action.payload.data }
  })
})
