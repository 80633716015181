import type Cookies from 'universal-cookie'

import { tryJSONparse } from '@app/utils/tryJSONparse'

import { COOKIE_NAME } from './shared'

export function read(cookies: Cookies): Record<string, string | object> | null {
  const data = cookies.get(COOKIE_NAME, { doNotParse: true })
  if (data) {
    return tryJSONparse<any>(decodeURIComponent(data)).unwrapOr(null)
  }
  return null
}
