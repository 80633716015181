import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'
import { useSelector } from 'react-redux'

import { HTMLMeta } from '@app/constants/StoreTypes'

import { useAppDispatch } from '@app/utils/redux'

import { setMeta } from '@app/store/actions/misc.descriptors'
import { metaSelector } from '@app/store/selectors/misc'
import { routingIsTransitioningSelector } from '@app/store/selectors/routing'

import { useLayoutEffectForce } from './useLayoutEffect'

/**
 * Hook to manage meta tags (title, keywords, etc...)
 */
export const useSetMeta = (meta: Partial<HTMLMeta>) => {
  const smeta = useSelector(metaSelector, (l, r) => l.title === r.title)
  const isNavigating = useSelector(routingIsTransitioningSelector)
  const dispatch = useAppDispatch()

  const pmeta = pick(smeta, Object.keys(meta))
  const metaEqual = isEqual(meta, pmeta)

  useLayoutEffectForce(() => {
    if (!metaEqual && (IS_BROWSER ? !isNavigating : true)) {
      dispatch(setMeta(meta))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metaEqual, dispatch])
}
