import { IMPORT_MAP } from '@app/importMap'

import { AsyncRoute } from '@app/utils/routing/types'

// @routes declaration
export const routes: AsyncRoute[] = [
  {
    path: '/telegram/code',
    event_id: 'telegram_code',
    component: () => IMPORT_MAP.routes.Telegram.Code().then(m => m.TelegramCode),
  },
]
