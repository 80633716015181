import type { ApiPlaygroundRequest, ApiSchoolRequest } from '@app/constants/ApiTypes/entities'
import type { HTMLMeta } from '@app/constants/StoreTypes'

import { ApiActionBuilderDescriptor } from '@app/store/apiMiddleware/builderDescriptor'
import { createAction, createPayloadlessAction } from '@app/store/toolkit'
import type { ConfigState } from '@app/store/types/config'

export const sendBrowserInit = createPayloadlessAction('BROWSER_INIT')
export const routerRaiseError = createAction<'ROUTER_ERROR_RAISE', { errorCode: number; message?: string; localizedMessage?: string }>('ROUTER_ERROR_RAISE')
export const syncTime = createAction<'SYNC_TIME', { date: number }>('SYNC_TIME')
export const syncResponseTime = createAction<'SYNC_RESPONSE_TIME', { date: number }>('SYNC_RESPONSE_TIME')

export const setConfigAction = createAction<'MISC_SET_CONFIG', Partial<ConfigState>>('MISC_SET_CONFIG')
export const setBuildSha = createAction<
  'MISC_SET_CONFIG_BUILD',
  {
    sha: string
    /**
     * forcefully set reload state,
     * if undefined, then will be set depending on sha difference
     */
    needsReload?: boolean
  }
>('MISC_SET_CONFIG_BUILD')

export const setMeta = createAction<'MISC_SET_META', Partial<HTMLMeta>>('MISC_SET_META')
export const updateMeta = createAction<'MISC_UPDATE_META', Partial<HTMLMeta>>('MISC_UPDATE_META')
export const pastSchoolRequestsDescriptor = new ApiActionBuilderDescriptor().setLabel('POST_SCHOOL_REQUESTS').setShape<{ data: ApiSchoolRequest }>()
export const postPlaygroundRequestsDescriptor = new ApiActionBuilderDescriptor().setLabel('POST_PLAYGROUND_REQUEST').setShape<{ data: ApiPlaygroundRequest }>()
export const postTrackingsDescriptor = new ApiActionBuilderDescriptor().setLabel('POST_TRACKINGS').setShape<unknown>()
