import { TrainingFormatName } from '@app/constants/ApiTypes/misc'
import { TrainingSignUpRequest } from '@app/constants/ApiTypes/requests'

import * as api from '@app/utils/api'
import { urlEscaped } from '@app/utils/urlEscaped'

import { ApiActionBuilder } from '@app/store/apiMiddleware/builder'

import {
  getTrainingRequestDescriptor,
  getTrainingRequestPromocodesByTokenDescriptor,
  postTrainingRequestConfirmationDescriptor,
  postTrainingRequestDescriptor,
  putTrainingRequestByIdDescriptor,
} from './trainingRequest.descriptors'

export const getTrainingRequest = new ApiActionBuilder(getTrainingRequestDescriptor)
  .setInit(() => ({
    method: 'GET',
    endpoint: api.path('/api/v2/training_request/'),
    headers: api.headers(),
  }))
  .build()

export const postTrainingRequest = new ApiActionBuilder(postTrainingRequestDescriptor)
  .setInit((request: TrainingSignUpRequest) => ({
    method: 'POST',
    endpoint: api.path('/api/v2/training_request/'),
    headers: api.headers(),
    body: JSON.stringify(request),
  }))
  .build()

export const putTrainingRequestById = new ApiActionBuilder(putTrainingRequestByIdDescriptor)
  .setInit((id: string, request: { format: TrainingFormatName }) => ({
    method: 'PUT',
    endpoint: api.path(urlEscaped`/api/v2/training_request/${id}`),
    headers: api.headers(),
    body: JSON.stringify(request),
  }))
  .build()

export const postTrainingRequestConfirmation = new ApiActionBuilder(postTrainingRequestConfirmationDescriptor)
  .setInit((token: string) => ({
    method: 'POST',
    endpoint: api.path('/api/v2/training_request/confirmation'),
    headers: api.headers(),
    body: JSON.stringify({ token }),
    meta: { token },
    bailout: ({ training_request_confirmation }) => {
      const confirmation = training_request_confirmation[token]
      return !!confirmation && 'type' in confirmation
    },
  }))
  .build()

export const getTrainingRequestPromocodesByToken = new ApiActionBuilder(getTrainingRequestPromocodesByTokenDescriptor)
  .setInit((token: string, format: TrainingFormatName, region_id?: string) => ({
    method: 'GET',
    endpoint: api.path(urlEscaped`/api/v2/training_request/promocodes/${token}`, { training_format: format, region_id }),
    headers: api.headers(),
    meta: { token, format, region_id },
  }))
  .build()
