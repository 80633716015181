import { unreadCountSelector } from '@kidsout-kidsout/chat/lib/selectors'

import { sleep } from '@app/utils/sleep'

import { Store } from '@app/store/store'

export class TitleService {
  private store: Store
  private title: string = ''
  private unread: number = 0
  private stopHandlers: (() => unknown)[] = []
  private notification = false
  private service: Promise<typeof import('favicon-notification').default> | null = null

  constructor(store: Store) {
    this.store = store
  }

  start() {
    const unsub = this.store.subscribe(this.handleUpdate)
    this.stopHandlers.push(unsub)
  }

  stop() {
    this.stopHandlers.forEach(h => h())
    this.stopHandlers = []
  }

  private handleUpdate = () => {
    const state = this.store.getState()

    const nextTitle = state.meta.title
    const nextUnread: number = unreadCountSelector(state)

    if (nextUnread !== this.unread) {
      this.updateFavicon(nextUnread)
    }
    if (nextUnread !== this.unread || nextTitle !== this.title) {
      this.updateTitle(nextTitle, nextUnread)
    }

    this.unread = nextUnread
    this.title = nextTitle
  }

  private async updateFavicon(unread: number) {
    if (unread > 0 && !this.notification) {
      this.notification = true
      await this.getNotification().then(n => n.add())
    } else if (this.notification) {
      this.notification = false
      await this.getNotification().then(n => n.remove())
    }
  }

  private async getNotification() {
    if (!this.service) {
      this.service = (async () => {
        const m = await import('favicon-notification').then(m => m.default)
        m.init({ color: '#FF0000' })
        await sleep(1000)
        return m
      })()
    }
    return this.service
  }

  private updateTitle(nextTitle: string, nextUnread: number) {
    const unread = nextUnread > 99 ? '99+' : nextUnread.toString(10)

    document.title = nextUnread > 0 ? `(${unread}) ${nextTitle}` : nextTitle
  }
}
