import { ApiPlaygroundEventType } from '@app/constants/ApiTypes/entities'

import { excludeAbortError } from '@app/errors/AbortError'

import { getPlaygroundRequestsEventTypesDescriptor } from '@app/store/actions/initial.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  models: ApiPlaygroundEventType[]
  pending: boolean
  loaded: boolean
  errors: Error | null
}>(
  {
    models: [],
    pending: false,
    loaded: false,
    errors: null,
  },
  builder => {
    builder.addCase(getPlaygroundRequestsEventTypesDescriptor.shapes.pending, (state, _action) => {
      return { ...state, pending: true, loaded: false, errors: null }
    })

    builder.addCase(getPlaygroundRequestsEventTypesDescriptor.shapes.fulfilled, (state, action) => {
      return { ...state, pending: false, loaded: true, errors: null, models: action.payload.data }
    })

    builder.addCase(getPlaygroundRequestsEventTypesDescriptor.shapes.rejected, (state, action) => {
      return { ...state, pending: false, loaded: true, errors: excludeAbortError(action.payload) }
    })
  }
)
