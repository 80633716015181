import React, { ComponentProps, memo } from 'react'

import './Hamburger.scss'

type Props = {
  open?: boolean
  lineClassName?: string
} & ComponentProps<'div'>

export const Hamburger = memo<Props>(function Hamburger({ open, className, lineClassName, ...rprops }) {
  return (
    <div className={cn('hamburger-2rwf', className, { 'is-open': open })} {...rprops}>
      <span className={lineClassName} />
      <span className={lineClassName} />
      <span className={lineClassName} />
    </div>
  )
})
