import React, { FunctionComponent } from 'react'
import { RawIntlProvider } from 'react-intl'
import { Provider as StoreProvider } from 'react-redux'
import type Cookies from 'universal-cookie'

import { IntlService } from '@app/services/IntlService'

import { CookiesProvider } from '@app/utils/cookies'
import { OverflowProvider } from '@app/utils/OverflowContext'

import { Store } from '@app/store/store'

const AppIntlProvider: FunctionComponent<{ children?: React.ReactNode }> = ({ children }) => {
  return <RawIntlProvider value={IntlService.shared.current}>{children}</RawIntlProvider>
}

export const ContextProvider: FunctionComponent<{ store: Store; cookies: Cookies; children?: React.ReactNode }> = ({ store, cookies, children }) => {
  return (
    <StoreProvider store={store}>
      <AppIntlProvider>
        <CookiesProvider cookies={cookies}>
          <OverflowProvider>{children}</OverflowProvider>
        </CookiesProvider>
      </AppIntlProvider>
    </StoreProvider>
  )
}
