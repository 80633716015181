import GeoDistance from 'geo-distance-safe'
import max from 'lodash/max'
import min from 'lodash/min'
import uniq from 'lodash/uniq'

export type Point = [x: number, y: number]
export type Rectangle = [[x: number, y: number], [x: number, y: number]]

export function rectanglesOverlap(r1: Rectangle, r2: Rectangle) {
  if (r1[0][0] === r1[1][0] || r1[0][1] === r1[1][1] || r2[0][0] === r2[1][0] || r2[0][1] === r2[1][1]) return false
  if (r1[0][0] >= r2[1][0] || r2[0][0] >= r1[1][0]) return false
  if (r1[0][1] >= r2[1][1] || r2[0][1] >= r1[1][1]) return false
  return true
}

export function rectangleIsInsideAnother(needle: Rectangle, haystack: Rectangle) {
  if (needle[0][0] === needle[1][0] || needle[0][1] === needle[1][1] || haystack[0][0] === haystack[1][0] || haystack[0][1] === haystack[1][1]) return false
  return needle[0][0] >= haystack[0][0] && needle[0][1] >= haystack[0][1] && needle[1][0] <= haystack[1][0] && needle[1][1] <= haystack[1][1]
}

/**
 * Checks if given rectangle already contained in set of rectangles
 */
export function rectangleCoveredBySet(rectangle: Rectangle, rects: Rectangle[]): boolean {
  if (rects.length === 0) return false
  const xl = uniq(rects.flatMap(b => [b[0][0], b[1][0]])).sort((a, b) => a - b)
  const yl = uniq(rects.flatMap(b => [b[0][1], b[1][1]])).sort((a, b) => a - b)

  for (let yi = 0; yi < yl.length - 1; yi++) {
    xloop: for (let xi = 0; xi < xl.length - 1; xi++) {
      const x1 = xl[xi]
      const y1 = yl[yi]
      const x2 = xl[xi + 1]
      const y2 = yl[yi + 1]
      const rect2: Rectangle = [
        [x1, y1],
        [x2, y2],
      ]
      for (const rect of rects) {
        if (rectanglesOverlap(rect, rect2)) continue xloop
      }
      // at this point rect2 is considered empty
      if (rectanglesOverlap(rectangle, rect2)) return false
    }
  }

  /**
   * If there is no early return from loop it means that
   * resulting figure of merged rectangles set is also rectangle,
   * and we should just check that rectangle is in another combined rectangle (maxRect)
   */
  const maxRect: Rectangle = [
    [xl[0], yl[0]],
    [xl[xl.length - 1], yl[yl.length - 1]],
  ]

  return rectangleIsInsideAnother(rectangle, maxRect)
}

function rectangleIsInsideOneOf(rect: Rectangle, rects: Rectangle[]) {
  for (const rect2 of rects) {
    if (rectangleIsInsideAnother(rect, rect2)) return true
  }
  return false
}

/** Converts set of overlapping rectangles into set of non-overlapping ones */
export function reduceRectangles(rects: Rectangle[]): Rectangle[] {
  if (rects.length === 0) return []
  const xl = uniq(rects.flatMap(b => [b[0][0], b[1][0]])).sort((a, b) => a - b)
  const yl = uniq(rects.flatMap(b => [b[0][1], b[1][1]])).sort((a, b) => a - b)

  const handled = new Set<string>()
  const result: Rectangle[] = []

  for (let yi = 0; yi < yl.length - 1; yi++) {
    xloop: for (let xi = 0; xi < xl.length - 1; xi++) {
      let xsize = 0
      const rhash = [xi, yi].join(':')
      if (handled.has(rhash)) continue xloop

      // first pass to get xsize
      xpass: for (let xj = xi; xj < xl.length - 1; xj++) {
        const x1 = xl[xj]
        const y1 = yl[yi]
        const x2 = xl[xj + 1]
        const y2 = yl[yi + 1]
        const xrect: Rectangle = [
          [x1, y1],
          [x2, y2],
        ]
        const rhash = [xj, yi].join(':')
        if (handled.has(rhash) || !rectangleIsInsideOneOf(xrect, rects)) break xpass
        ++xsize
      }

      if (!xsize) {
        handled.add(rhash)
        continue xloop
      }

      let ysize = 1

      // second pass to get ysize
      ypass: for (let yj = yi + 1; yj < yl.length - 1; yj++) {
        for (let xj = xi; xj < xi + xsize - 1; xj++) {
          const x1 = xl[xj]
          const y1 = yl[yj]
          const x2 = xl[xj + 1]
          const y2 = yl[yj + 1]
          const xrect: Rectangle = [
            [x1, y1],
            [x2, y2],
          ]
          const rhash = [xj, yj].join(':')
          if (handled.has(rhash) || !rectangleIsInsideOneOf(xrect, rects)) break ypass
        }
        ysize++
      }

      // mark resulted as handled
      for (let yw = yi; yw < yi + ysize; yw++) {
        for (let xw = xi; xw < xi + xsize; xw++) {
          const rhash = [xw, yw].join(':')
          handled.add(rhash)
        }
      }

      const xr1 = xl[xi]
      const yr1 = yl[yi]
      const xr2 = xl[xi + xsize]
      const yr2 = yl[yi + ysize]
      const rrect: Rectangle = [
        [xr1, yr1],
        [xr2, yr2],
      ]
      result.push(rrect)
    }
  }

  return result
}

export function getRectangleSetBounds(set: Rectangle[]) {
  if (!set.length) return null
  const xs = set.flatMap(rect => [rect[0][0], rect[1][0]])
  const ys = set.flatMap(rect => [rect[0][1], rect[1][1]])
  return [
    [min(xs), min(ys)],
    [max(xs), max(ys)],
  ]
}

export function pointBelongsToRect(point: Point, rect: Rectangle) {
  return point[0] >= rect[0][0] && point[0] <= rect[1][0] && point[1] >= rect[0][1] && point[1] <= rect[1][1]
}

export function getDistanceInMeters(bounds: [[lat: number, lon: number], [lat: number, lon: number]]) {
  return parseFloat(GeoDistance.between(...bounds).human_readable_in_units('m').distance) ?? 0
}
