import { DEFAULT_CURRENCY, printCurrency } from '@app/utils/printCurrency'

export class PriceInputValue {
  private formatted: string
  readonly currency: string

  constructor(input: string, currency: string) {
    const parts = input
      .trim()
      .replace(/,/g, '.')
      .split('.')
      .filter(v => !!v)
      .map(v => v.replace(/\D+/, ''))
    if (parts.length > 1) parts[0] = `${parts[0]}.`
    if (parts.length === 1 && (input.endsWith('.') || input.endsWith(','))) parts.push('.')
    this.formatted = parts.join('')
    this.currency = currency
  }

  get value() {
    return this.formatted
  }

  get finalValue() {
    return printCurrency(this.formatted.replace(/\.$/g, ''), null).replace(/\s/g, '')
  }

  get floatValue() {
    return parseFloat(this.finalValue) || 0
  }

  setValue(value: string) {
    return new PriceInputValue(value, this.currency)
  }

  setCurrency(currency: string) {
    return new PriceInputValue(this.value, currency)
  }

  toJSON() {
    return { value: this.value, finalValue: this.finalValue, currency: this.currency }
  }

  equals(other: PriceInputValue): boolean {
    return this.finalValue === other.finalValue && this.currency === other.currency
  }

  isEmpty() {
    return !this.floatValue
  }

  normalized() {
    return PriceInputValue.formatted(this.finalValue, this.currency)
  }

  static empty() {
    return new PriceInputValue('', DEFAULT_CURRENCY)
  }

  static formatted(value: string | number, currency: string) {
    if (!value || !parseFloat(String(value))) return new PriceInputValue('', currency)
    return new PriceInputValue(printCurrency(value, null), currency)
  }
}
