import React, { FunctionComponent, PropsWithChildren, ReactNode, useEffect, useMemo, useState } from 'react'

import { Static } from '@app/routes/Static/components/Static/Static'

import { LayoutMilk, LayoutMilkProps } from '@app/components/LayoutMilk/LayoutMilk'

const StaticLayout: FunctionComponent<PropsWithChildren<{ app?: boolean; headerInject?: () => Promise<ReactNode> }>> = ({ app, headerInject, children }) => {
  const [inject, setInject] = useState<ReactNode>(null)

  const header = useMemo<LayoutMilkProps['header']>(() => (inject ? { inject } : undefined), [inject])

  useEffect(() => {
    headerInject?.().then(node => {
      setInject(node)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <LayoutMilk className="static-ml0m" header={header} isApp={app} page="static">
      <section className={cn(LayoutMilk.classes.section(), Static.section)}>
        <div className={LayoutMilk.classes.sectionWrapper}>
          <div className={Static.content}>
            <div className={Static.page}>{children}</div>
          </div>
        </div>
      </section>
    </LayoutMilk>
  )
}

export default StaticLayout
