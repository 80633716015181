import { ApiError, RequestError } from '@app/store/apiMiddleware/errors'

export class SerializableError extends Error {
  static readonly key = 'error:52CFB8B5-151A-40DB-BFB0-958D2CF83862'

  static create(err: Error) {
    const error = new SerializableError(err.message)
    error.name = err.name
    error.stack = err.stack
    error.cause = err
    return error
  }

  toJSON() {
    const originalError = this.cause as Error

    const data: { [key: string]: any } = {
      key: SerializableError.key,
      message: originalError.message,
      name: originalError.name,
    }

    if (originalError instanceof ApiError) {
      data.description = originalError.description
      data.status = originalError.status
      data.response = originalError.response
      data.type = errKeyMap.get(ApiError)
    }

    if (originalError instanceof RequestError) {
      data.description = originalError.description
      data.status = originalError.status
      data.body = originalError.body
      data.type = errKeyMap.get(RequestError)
    }

    return data
  }

  static fromData(data: any) {
    try {
      if (data.key !== SerializableError.key) return undefined
      const err =
        data.type === errKeyMap.get(ApiError)
          ? new ApiError(data.status, data.description, data.response)
          : data.type === errKeyMap.get(RequestError)
            ? RequestError.create(data.status, data.description, data.body)
            : new Error(data.message)
      err.stack = ''

      return err
    } catch {
      return undefined
    }
  }
}

const errKeyMap = new Map()
errKeyMap.set(ApiError, 'apierror:164AEAEE-3BFB-459D-BD7D-6D44828D8EAE')
errKeyMap.set(RequestError, 'requesterror:CD884ED4-CBB6-4C0A-9E88-6CF6DA56912E')
