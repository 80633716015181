import { IMPORT_MAP } from '@app/importMap'

import { AsyncRoute } from '@app/utils/routing/types'

import { ACADEMY_EVENTS_LIST, AcademyEventName, URLS } from './config'

const getRoutesForEvent = (event: AcademyEventName): AsyncRoute[] => [
  {
    path: '/classrooms',
    event_id: 'courses',
    component: () => IMPORT_MAP.components.Redirect().then(m => m.createRedirect(URLS.main(event), true)),
  },
  {
    path: URLS.main(event),
    event_id: event,
    event_data: async location => {
      const { getFilterFromQuery } = await import('./utils')
      return getFilterFromQuery(location.search ?? '')
    },
    component: () => IMPORT_MAP.routes.Academy.Academy().then(m => m.Academy),
    canonical: (_loc, ctx) => ctx.protocol + '://' + ctx.host + URLS.main(event),
  },
  {
    // replacing abcde because we cannot put ":id" straight (i.e. URLS.course(':id', event))
    // as ":id" will become urlEncoded
    path: URLS.course('abcde', event).replace('abcde', ':id'),
    event_id: `${event}_course`,
    event_params: {
      lesson_id: 'id',
    },
    component: () => IMPORT_MAP.routes.Academy.Course().then(m => m.AcademyCourse),
  },
  {
    path: URLS.courses(event),
    event_id: `${event}_types`,
    component: () => IMPORT_MAP.routes.Academy.Courses().then(m => m.AcademyCourses),
  },
  {
    path: URLS.courses_redirect(event),
    event_id: `${event}_types`,
    component: () => IMPORT_MAP.components.Redirect().then(m => m.createRedirect(URLS.courses(event), false)),
  },
  {
    path: URLS.lessonRedirect(event),
    event_id: event,
    component: () => IMPORT_MAP.components.Redirect().then(m => m.createRedirect(URLS.main(event), false)),
  },
  {
    path: URLS.cart(event),
    event_id: `${event}_cart`,
    component: () => IMPORT_MAP.routes.Academy.Cart().then(m => m.AcademyCart),
  },
  {
    path: URLS.lesson('abcde', event).replace('abcde', ':id'),
    event_id: `${event}_lesson`,
    event_params: {
      lesson_id: 'id',
    },
    component: () => IMPORT_MAP.routes.Academy.Lesson().then(m => m.AcademyLesson),
  },
  {
    path: URLS.lessonJoin('abcde', event).replace('abcde', ':id'),
    event_id: `${event}_lesson_join`,
    event_params: {
      lesson_id: 'id',
    },
    component: () => IMPORT_MAP.routes.Academy.LessonJoin().then(m => m.AcademyLessonJoin),
  },
  {
    path: URLS.purchased(event),
    event_id: 'academy_purchased',
    component: () => IMPORT_MAP.routes.Academy.Purchased().then(m => m.AcademyPurchased),
  },
]

// @routes declaration
const routes: AsyncRoute[] = ACADEMY_EVENTS_LIST.flatMap(getRoutesForEvent)

export default routes
