import {
  deleteCardDescriptor,
  getCardDescriptor,
  postCardCloudpayments3dsDescriptor,
  postCardCloudpaymentsDescriptor,
} from '@app/store/actions/payment.descriptors'
import { createReducer } from '@app/store/toolkit'

export type CardState = {
  id: string
  card_type: string
  number: string
  name: string
} | null

export default createReducer<{
  id: string
  card_type: string
  number: string
  name: string
} | null>(null, builder => {
  builder.addCase(postCardCloudpaymentsDescriptor.shapes.fulfilled, (state, action) => {
    if (action.payload.data.type === 'post3ds') return state

    const card = action.payload.data
    return {
      id: card.id,
      card_type: card.attributes.card_type,
      number: card.attributes.number,
      name: '',
    }
  })
  builder.addCases([postCardCloudpayments3dsDescriptor.shapes.fulfilled, getCardDescriptor.shapes.fulfilled], (_state, action) => {
    const card = action.payload.data
    if (!card) return null

    return {
      id: card.id,
      card_type: card.attributes.card_type,
      number: card.attributes.number,
      name: '',
    }
  })

  builder.addCase(deleteCardDescriptor.shapes.fulfilled, () => null)
})
