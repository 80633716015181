export class AbortControllerService {
  private controllers: { [key: string]: AbortController | undefined } = {}
  /** Returns `AbortController` for label */
  getAbortController(forLabel: string) {
    return this.controllers[forLabel] ?? null
  }

  /** Sets controller to giver one and aborts existing */
  setAbortController(forLabel: string, controller: AbortController) {
    const prevCnt = this.controllers[forLabel]
    if (prevCnt) prevCnt.abort()
    this.controllers[forLabel] = controller
  }

  /** Clears and aborts controller if it exists */
  clearAbortController(forLabel: string) {
    const prevCnt = this.controllers[forLabel]
    if (prevCnt) prevCnt.abort()
    delete this.controllers[forLabel]
  }

  /** Creates new abort controller and abort previous */
  createAbortController(forLabel: string) {
    const prevCnt = this.controllers[forLabel]
    if (prevCnt) prevCnt.abort()
    const controller = new AbortController()
    this.controllers[forLabel] = controller
    return controller
  }
}
