import defer from 'lodash/defer'

import { skipAbortError } from '@app/errors/AbortError'

import { sleep } from '@app/utils/sleep'

import './progress.scss'

export class Progress {
  static shared = new Progress()

  private index = 0
  private indexBlocking = 0

  private get ui() {
    const value = new ProgressUI()

    Object.defineProperty(this, 'ui', {
      value,
      writable: false,
      configurable: false,
      enumerable: false,
    })

    return value
  }

  increment(blocking = true) {
    if (blocking) {
      this.indexBlocking += 1
    } else {
      this.index += 1
    }
    const index = blocking ? this.indexBlocking : this.index
    if (index === 1) {
      if (blocking) document.documentElement.classList.add('nprogress-blocking')
      this.ui
        .start()
        .then(() => {})
        .catch(skipAbortError)
    }
  }

  decrement(blocking = true) {
    if (blocking) {
      this.indexBlocking = Math.max(0, this.indexBlocking - 1)
    } else {
      this.index = Math.max(0, this.index - 1)
    }
    const index = blocking ? this.indexBlocking : this.index
    if (index === 0) this.done(blocking)
  }

  start(blocking = true) {
    if (blocking) {
      this.indexBlocking = 0
    } else {
      this.index = 0
    }
    this.increment(blocking)
  }

  async wrap<T>(promise: Promise<T>, blocking = true): Promise<T> {
    this.increment(blocking)
    try {
      return await promise
    } finally {
      // timeout made so subsequent wrapped calls
      // have overlapping progress, not sequential
      // because progress rendering is not triggered on second call
      defer(this.decrement.bind(this, blocking))
    }
  }

  private done(blocking = true) {
    if (blocking) {
      this.indexBlocking = 0
    } else {
      this.index = 0
    }
    defer(this.ndone.bind(this, blocking))
  }

  private ndone(blocking = true) {
    if (this.index + this.indexBlocking === 0) {
      this.ui.done()
    }
    if (blocking) {
      document.documentElement.classList.remove('nprogress-blocking')
    }
  }
}

class ProgressUI {
  private started = false
  private abortController: AbortController | null = null
  private gracePeriod: number

  constructor(gracePeriod = 150) {
    this.gracePeriod = gracePeriod
  }

  async start() {
    if (this.started) return
    this.started = true
    const abortController = new AbortController()
    this.abortController = abortController
    await Promise.all([import('nprogress'), sleep(this.gracePeriod, abortController.signal)]).then(([m]) => {
      if (abortController.signal.aborted) return
      m.default.start()
    })
  }

  done() {
    this.started = false
    this.abortController?.abort()
    import('nprogress').then(m => m.default.done())
  }
}
