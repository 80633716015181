export async function awaitMap<K, V>(map: Map<K, Promise<V>>): Promise<Map<K, V>> {
  const promises: Promise<[K, V]>[] = []
  for (const [key, value] of map.entries()) {
    promises.push(value.then(resolved => [key, resolved]))
  }

  const resolved = await Promise.all(promises)

  return new Map(resolved)
}
