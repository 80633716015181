import identity from 'lodash/identity'
import pickBy from 'lodash/pickBy'

import { HTMLMeta } from '@app/constants/StoreTypes'

import { setMeta, updateMeta } from '@app/store/actions/misc.descriptors'
import { beforeNavigate } from '@app/store/actions/router.descriptors'
import { createReducer } from '@app/store/toolkit'

import sharingUrl from '@app/assets/sharing/kidsout.jpg'

export const DEFAULT_META_STATE: HTMLMeta = {
  title: 'Kidsout. Лучшие бебиситтеры для ваших детей',
  description: 'Отдохните от детей — найдите им проверенного бебиситтера и сходите по своим делам',
  image: sharingUrl,
  keywords: [
    'kidsout',
    'кидзаут',
    'кидсаут',
    'няня на час',
    'бебиситтер',
    'ситтер',
    'няня',
    'сиделка',
    'присмотр за детьми',
    'онлайн-ситтер',
    'онлайн-бебиситтер',
    'онлайн-присмотр за детьми',
    'онлайн-няня',
    'онлайн-сиделка',
  ].join(', '),
  canonical: undefined,
}

export default createReducer<HTMLMeta>(DEFAULT_META_STATE, builder => {
  builder.addCase(setMeta.shape, (state, action, defaultState) => {
    return {
      ...defaultState,
      ...pickBy(action.payload, identity),
      canonical: action.payload.canonical || state.canonical,
    }
  })

  builder.addCase(updateMeta.shape, (state, action) => {
    return {
      ...state,
      ...pickBy(action.payload, identity),
    }
  })

  builder.addCase(beforeNavigate.shape, (state, action, defaultState) => {
    if (action.payload.initial) return state
    return defaultState
  })
})
