import { setProfilePlaceActionDescriptor } from '@app/store/actions/profile.descriptors'
import { navigate } from '@app/store/actions/router.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  loading: boolean
  place_id: null | string
  change: boolean
}>(
  {
    loading: false,
    place_id: null,
    change: false,
  },
  builder => {
    builder.addCase(setProfilePlaceActionDescriptor.shapes.pending, (state, _action) => {
      return { ...state, loading: true }
    })

    builder.addCase(setProfilePlaceActionDescriptor.shapes.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        place_id: action.payload.data.id,
        change: state.change || (action.meta.cause === 'change' && state.place_id !== action.payload.data.id),
      }
    })

    builder.addCase(setProfilePlaceActionDescriptor.shapes.rejected, (state, _action) => {
      return { ...state, loading: false }
    })

    builder.addCase(navigate.shape, (state, _action) => {
      return { ...state, change: false }
    })
  }
)
