import { setStaticContent } from '@app/store/actions/static.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  [key: string]: string
}>({}, builder => {
  builder.addCase(setStaticContent.shape, (state, action) => {
    return { ...state, [action.payload.key]: action.payload.content }
  })
})
