import React, { ComponentProps, ComponentType, forwardRef, HTMLProps, ReactElement, ReactHTML, useEffect, useState } from 'react'

import { skipAbortError } from '@app/errors/AbortError'

import { AnalyticsEvent } from '@app/services/AnalyticsEvent'

import { sleep } from '@app/utils/sleep'

import { useEvent } from '@app/hooks/useEvent'

import { ControlClasses } from '@app/components/ControlClasses/ControlClasses'

import { ButtonType, ButtonTypePrimary } from './ButtonType'

import classes from './Button.module.scss'

export type ButtonAnimation = { className: string; duration: number }

export type ButtonProps<P extends {} = {}> = Omit<HTMLProps<HTMLElement> & P, 'size' | 'type' | 'component'> & {
  /** Event name for analytics. should be in format "component_name.button_name". Nice if it would have _button or a _link ending */
  eventName: string
  eventParams?: object | (() => any)
  type?: ButtonType
  animation?: ButtonAnimation
  /**
   * Replacement for type property of button as type already occupied.
   *
   * Primary usage of this prop is to forbid form submit on button click
   * by setting htmlType: "button"
   * https://stackoverflow.com/questions/2825856/html-button-to-not-submit-form
   */
  htmlType?: ComponentProps<'button'>['type']
  component?: keyof ReactHTML | ComponentType<P>
}

export const Button = forwardRef<HTMLElement, ButtonProps<any>>(function Button(
  {
    eventName,
    eventParams,
    type = new ButtonTypePrimary(),
    animation: panimation,
    className,
    component = 'button',
    htmlType,
    children,
    onClick: pOnClick,
    ...rprops
  },
  ref
) {
  const [animation, setAnimation] = useState(panimation ? panimation : undefined)
  const onClick = useEvent((e: React.MouseEvent<HTMLElement>) => {
    sendEvent(eventName, eventParams)
    pOnClick?.(e)
  })
  const extendedProps = {
    ...rprops,
    ...(htmlType ? { type: htmlType } : undefined),
    onClick,
    className: cn(ControlClasses.m_no_appearance, classes.button, type.getClassName(), animation ? animation.className : null, className),
    ref,
  }

  useEffect(() => {
    setAnimation(undefined)

    if (!panimation) return

    const abortController = new AbortController()
    sleep(animation ? 30 : 0, abortController.signal)
      .then(async () => {
        setAnimation(panimation)
        await sleep(panimation.duration, abortController.signal)
        setAnimation(undefined)
      })
      .catch(skipAbortError)

    return () => {
      abortController.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panimation])

  return React.createElement(component, extendedProps as any, children)
}) as <P extends {} = {}>(props: ButtonProps<P>) => ReactElement<P>

const sendEvent = async (eventName: string, eventParams?: object | (() => any)) => {
  const params = typeof eventParams === 'function' ? await eventParams() : eventParams ? eventParams : {}
  new AnalyticsEvent(`${eventName}.click`, params).sendInhouse()
}
