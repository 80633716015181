import type { ApiCreditCard, ApiCreditCardPostRequest, ApiPromousage, ApiStripeSetupIntent } from '@app/constants/ApiTypes/entities'

import { ApiActionBuilderDescriptor } from '@app/store/apiMiddleware/builderDescriptor'
import { createAction } from '@app/store/toolkit'

export const getCardDescriptor = new ApiActionBuilderDescriptor().setLabel('GET_CARD').setShape<{ data: ApiCreditCard | null }>()
export const deleteCardDescriptor = new ApiActionBuilderDescriptor().setLabel('DELETE_CARD').setShape<unknown>()
export const postCardCloudpaymentsDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('ADD_CARD')
  .setShape<{ data: ApiCreditCardPostRequest | ApiCreditCard }>()
export const postCardCloudpayments3dsDescriptor = new ApiActionBuilderDescriptor().setLabel('POST_CARD_3DS').setShape<{ data: ApiCreditCard }>()
export const postCardStripeSetupIntentDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('GET_STRIPE_SETUP_INTENT')
  .setShape<{ data: ApiStripeSetupIntent }>()
export const getPromoUsagesDescriptor = new ApiActionBuilderDescriptor().setLabel('GET_PROMO_USAGES').setShape<{ data: ApiPromousage[] }>()
export const postPromoUsagesDescriptor = new ApiActionBuilderDescriptor().setLabel('POST_PROMO_USAGES').setShape<{ data: ApiPromousage }>()

export const updatePromocode = createAction<'UPDATE_PROMOCODE', { value: string }>('UPDATE_PROMOCODE')
