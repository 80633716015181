import { excludeAbortError } from '@app/errors/AbortError'

import { mapObject } from '@app/utils/mapOpject'
import { normalize } from '@app/utils/normalizer'

import {
  cancelAnnouncementDescriptor,
  fetchAnnouncementDescriptor,
  getAnnouncementsByQueryDescriptor,
  getAnnouncementsSearchDescriptor,
  getAnnouncementsSearchNextDescriptor,
  getAnnouncementsUpcomingDescriptor,
} from '@app/store/actions/announcement.descriptors'
import {
  cancelAnnouncementResponseDescriptor,
  createAnnouncementResponseDescriptor,
  fetchAnnouncementResponseDescriptor,
} from '@app/store/actions/announcementResponse.descriptors'
import { createReducer } from '@app/store/toolkit'
import { AnnouncementMeta } from '@app/store/types/announcements'

export const defaultMeta: AnnouncementMeta = { updating: false, error: null }

export default createReducer<{
  __default: AnnouncementMeta
  [key: string]: AnnouncementMeta
}>({ __default: defaultMeta }, builder => {
  builder.addCases(
    [
      fetchAnnouncementDescriptor.shapes.fulfilled,
      getAnnouncementsUpcomingDescriptor.shapes.fulfilled,
      getAnnouncementsSearchDescriptor.shapes.fulfilled,
      getAnnouncementsSearchNextDescriptor.shapes.fulfilled,
      cancelAnnouncementDescriptor.shapes.fulfilled,
      fetchAnnouncementResponseDescriptor.shapes.fulfilled,
      getAnnouncementsByQueryDescriptor.shapes.fulfilled,
    ],
    (state, action) => {
      const { announcements } = normalize(action.payload)
      if (!announcements) return state

      return {
        ...state,
        ...mapObject(announcements, (): AnnouncementMeta => ({ updating: false, error: null })),
      }
    }
  )

  builder.addCases([cancelAnnouncementResponseDescriptor.shapes.fulfilled, createAnnouncementResponseDescriptor.shapes.fulfilled], (state, action) => {
    const { announcement_id } = action.meta
    return { ...state, [announcement_id]: { updating: false, error: null } }
  })

  builder.addCases(
    [cancelAnnouncementDescriptor.shapes.pending, createAnnouncementResponseDescriptor.shapes.pending, cancelAnnouncementResponseDescriptor.shapes.pending],
    (state, action) => {
      const { announcement_id } = action.meta

      return { ...state, [announcement_id]: { updating: true, error: null } }
    }
  )

  builder.addCases(
    [cancelAnnouncementDescriptor.shapes.rejected, createAnnouncementResponseDescriptor.shapes.rejected, cancelAnnouncementResponseDescriptor.shapes.rejected],
    (state, action) => {
      const { announcement_id } = action.meta
      return { ...state, [announcement_id]: { updating: false, error: excludeAbortError(action.payload) } }
    }
  )
})
