import { ApiCountry, ApiLocality, ApiPlace, ApiRegion } from '@app/constants/ApiTypes/entities'

import * as api from '@app/utils/api'
import { ensureApiType } from '@app/utils/ensureApiType'
import { isTruthy } from '@app/utils/isTruthy'

import { ApiActionPromise } from '@app/store/apiMiddleware/types'
import { createTypelessApiAction } from '@app/store/apiMiddleware/utils'
import { createThunk } from '@app/store/thunk'

import { fetchRegion } from './initial'

export function getPlaceById(id: string) {
  return createThunk<ApiActionPromise<{ data: ApiPlace; included: (ApiRegion | ApiLocality | ApiCountry)[] }>>(async (dispatch, _getState) => {
    const resp = dispatch(getPlaceResponse(id))
    if (resp) return { error: false, payload: resp }

    return await dispatch(
      createTypelessApiAction<{ data: ApiPlace; included: (ApiRegion | ApiLocality | ApiCountry)[] }>({
        method: 'GET',
        endpoint: api.path(`/api/v2/places/${decodeURIComponent(id)}`, { include: 'region,country,locality' }),
        headers: api.headers(),
      })
    )
  })
}

export function getPlaceByLocation(latitude: number, longitude: number) {
  return createTypelessApiAction<{ data: ApiPlace; included: (ApiRegion | ApiLocality | ApiCountry)[] }>({
    method: 'GET',
    endpoint: api.path('/api/v2/places/by_location', { latitude, longitude, include: 'region,country,locality' }),
    headers: api.headers(),
  })
}

export function getPlaceByRegion(regionId: string) {
  return createThunk<ApiActionPromise<{ data: ApiPlace; included: (ApiRegion | ApiLocality | ApiCountry)[] }>>(async (dispatch, getState) => {
    const region = getState().regions.models[regionId]
    if (region) {
      const id = region.relationships.default_place.data?.id
      if (id) {
        const resp = dispatch(getPlaceResponse(id))
        if (resp) return { error: false, payload: resp }
      }
    }

    const regionResp = await dispatch(fetchRegion(regionId))
    if (regionResp?.error) return regionResp

    const id = getState().regions.models[regionId].relationships.default_place.data!.id

    const result = dispatch(getPlaceResponse(id))!
    return { error: false, payload: result }
  })
}

export function getRegionByLocation(latitude: number, longitude: number) {
  return createThunk(async (dispatch, _getState) => {
    const resp = await dispatch(getPlaceByLocation(latitude, longitude))

    if (!resp || resp.error) return resp
    const region = resp.payload.included.find(en => ensureApiType('regions')(en)) as ApiRegion

    return { error: false as const, payload: { data: region } }
  })
}

export type PlaceResponse = {
  data: ApiPlace
  included: (ApiRegion | ApiCountry | ApiLocality)[]
}

function getPlaceResponse(placeId: string) {
  return createThunk((_dispatch, getState): PlaceResponse | null => {
    const state = getState()
    const place = state.places[placeId]
    if (!place) return null

    const region = state.regions.models[place.relationships.region.data!.id]
    const country = (place.relationships.country?.data?.id && state.countries[place.relationships.country.data.id]) || null
    const locality = (place.relationships.locality?.data?.id && state.localities[place.relationships.locality.data.id]) || null

    return { data: place, included: [region, country, locality].filter(isTruthy) }
  })
}
