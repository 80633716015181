import classes from './Typography.module.scss'

export const Typography = {
  Paragraph: classes.Paragraph,

  LargeHeader: classes.LargeHeader,

  Header1: classes.Header1,
  Header1Mobile: classes.Header1Mobile,

  Header: classes.Header,
  HeaderMobile: classes.HeaderMobile,

  Subheader: classes.Subheader,
  SubheaderMobile: classes.SubheaderMobile,

  MainText: classes.MainText,
  MainTextMobile: classes.MainTextMobile,

  BodyText: classes.BodyText,
  BodyTextMobile: classes.BodyTextMobile,

  DecorateText: classes.DecorateText,
  DecorateTextMobile: classes.DecorateTextMobile,

  DecorateText1: classes.DecorateText1,
  DecorateText1Mobile: classes.DecorateText1Mobile,

  // Landing
  LandingSubHeader: classes.LandingSubHeader,
  LandingMainBoldText: classes.LandingMainBoldText,
  LandingMainText: classes.LandingMainText,
  LandingDecorationText: classes.LandingDecorationText,

  //

  Dimmed: classes.Dimmed,
  XDimmed: classes.XDimmed,

  //
  Centered: classes.Centered,
}
