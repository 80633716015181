import { IMPORT_MAP } from '@app/importMap'

import { makeRedirectRoute } from '@app/utils/makeRedirectRoute'
import { AsyncRoute } from '@app/utils/routing/types'

const EmailConfirmation = () => IMPORT_MAP.routes.Settings.EmailConfirmation().then(m => m.EmailConfirmation)
const EmailAuth = () => IMPORT_MAP.routes.Settings.EmailAuth().then(m => m.EmailAuth)
const EmailToken = () => IMPORT_MAP.routes.Settings.EmailToken().then(m => m.EmailToken)
const TelegramAuth = () => IMPORT_MAP.routes.Settings.TelegramAuth().then(m => m.TelegramAuth)
const TrainingConfirmation = () => IMPORT_MAP.routes.Settings.TrainingConfirmation().then(m => m.TrainingConfirmation)

// @routes declaration
const routes: AsyncRoute[] = [
  {
    path: '/email-confirmation',
    event_id: 'email_confirmation',
    component: EmailConfirmation,
  },
  makeRedirectRoute('/email_confirmation', '/email-confirmation', 'email_confirmation'),
  {
    path: '/email-auth',
    event_id: 'email_auth',
    component: EmailAuth,
  },
  {
    path: '/telegram-auth',
    event_id: 'telegram_auth',
    component: TelegramAuth,
  },
  {
    path: '/telegram-auth/*',

    event_id: 'telegram_auth',
    component: TelegramAuth,
  },
  makeRedirectRoute('/email_auth', '/email-auth', 'email_auth'),
  {
    path: '/email-token',
    event_id: 'email_token',
    component: EmailToken,
  },
  makeRedirectRoute('/email_token', '/email-token', 'email_token'),
  {
    path: '/training-confirmation',
    event_id: 'training_confirmation',
    component: TrainingConfirmation,
  },
  makeRedirectRoute('/training_confirmation', '/training-confirmation', 'training_confirmation'),
]

export default routes
