import type Cookies from 'universal-cookie'

import { createCookie } from './cookies'
import moment from './moment'

/**
 * Defines current test flights structure.
 * Test flight is a feature which is shown only to
 * particular group of users and is stored in cookie.
 * Think of it of a/b test of features
 *
 * Note, that in "flights" it is better to use keys that are valid variable names,
 * so, no dashes "-", or special symbols, only a-z letters, _ underscore and 0-9 numbers.
 * Doing otherwise might break typescript static check, and will fail silently
 */

export type CurrentTestFlight = {
  flights: {
    search_banner: boolean
  }
  version: 2
}

/** Defines union type of all versions of CurrentTestFlightStructure */
type TestFlight = CurrentTestFlight

const testFlightCookie = createCookie<null | TestFlight>(
  'kidsout__test-flight',
  input => {
    if (input === '') return null
    try {
      const flights = JSON.parse(input)
      if (typeof flights !== 'object') return null
      return flights
    } catch {
      return null
    }
  },
  input => JSON.stringify(input),
  () => ({ expires: moment().add(1, 'year').toDate(), httpOnly: false, sameSite: 'lax', path: '/' })
)

const migrate = (data: any | null): CurrentTestFlight => {
  if (!data || !('flights' in data) || !('version' in data)) {
    data = { flights: {}, version: 0 }
  }
  while (data.version < 2) {
    switch (data.version as number) {
      case 0:
        data.flights.search_banner = Math.random() > 0.5
        break
      case 1:
        data.flights.search_new_window = Math.random() > 0.5
        break
    }
    ++data.version
  }
  return data
}

export const get = (cookies: Cookies): CurrentTestFlight => {
  let value = testFlightCookie.get(cookies)
  if (!value || value.version !== 2) {
    value = migrate(typeof value === 'object' ? value : null)
    testFlightCookie.set(cookies, value)
  }

  return value
}

export const set = (cookies: Cookies, value: Partial<CurrentTestFlight['flights']>) => {
  const svalue = get(cookies)

  const newValue = { ...svalue, flights: { ...svalue.flights, ...value } }

  testFlightCookie.set(cookies, newValue)
}
