import { ErrorEvent, Event } from '@sentry/node'

import { isAbortError } from '@app/errors/AbortError'

import { ApiError, isNetworkFailError } from '@app/store/apiMiddleware/errors'

import { errorWhiteList } from './errorWhitelist'

interface EventProcessor {
  <T extends Event | ErrorEvent>(event: T, hint: { originalException?: unknown }): Promise<T | null>
  id?: string
}

export const createEventProcessor =
  ({ requestId }: { requestId: string }): EventProcessor =>
  async (event, hint) => {
    try {
      const debug = window['__debug_sentry_event']
      if (debug && debug(event, hint)) return null

      const frames = event.exception?.values?.at(0)?.stacktrace?.frames ?? []
      if (!frames.length || frames.at(0)?.filename === '<anonymous>') {
        return null
      }

      const err = hint.originalException
      if (err && matchError(err, errorWhiteList)) return null
      if (isNetworkFailError(err)) return null
      if (isAbortError(err)) return null

      if (err instanceof Error) {
        event.extra = event.extra || {}
        event.extra.request_id = requestId
        event.extra.error_data = JSON.stringify(getErrorsExtra(err), null, 2)
      }
    } catch {}

    return event
  }

function getErrorExtra(err: unknown) {
  if (!(err instanceof Error)) return null
  if (err instanceof ApiError) {
    return {
      message: err.message,
      response: err.response,
    }
  }
  return {
    message: err.message,
  }
}

function getErrorsExtra(err: unknown) {
  const root: any = getErrorExtra(err)
  if (err instanceof Error && err.cause) {
    root.cause = getErrorsExtra(err.cause)
  }
  return root
}

function matchError(error: unknown, regex: RegExp[]) {
  if (!error || typeof error !== 'object') return false
  if ('message' in error) {
    const message = error.message as string
    if (regex.find(error => error.test(message))) return true
  }
  if ('cause' in error && error.cause) return matchError(error.cause, regex)
  return false
}
