import { getDepersonalizedAnnouncementsSearchDescriptor } from '@app/store/actions/depersonalized_announcements.descriptors'
import { createReducer } from '@app/store/toolkit'

type State = {
  cursor?: string
  fetched?: boolean
  loading?: boolean
}

export default createReducer<State>({}, builder => {
  builder.addCase(getDepersonalizedAnnouncementsSearchDescriptor.shapes.pending, state => {
    return { ...state, loading: true }
  })
  builder.addCase(getDepersonalizedAnnouncementsSearchDescriptor.shapes.fulfilled, (state, action) => {
    return { ...state, cursor: action.payload.meta.cursor, fetched: action.payload.data.length === 0, loading: false }
  })
  builder.addCase(getDepersonalizedAnnouncementsSearchDescriptor.shapes.rejected, state => {
    return { ...state, loading: false }
  })
})
