import type { ApiNotificationSubscription } from '@app/constants/ApiTypes/entities'
import type { ApiNotificationSubscriptionsLayout } from '@app/constants/ApiTypes/internalEntities'

import { ApiActionBuilderDescriptor } from '@app/store/apiMiddleware/builderDescriptor'

export const getNotificationSubscriptionsLayoutsDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('GET_NOTIFICATION_SUBSCRIPTION_LAYOUTS')
  .setShape<{ data: ApiNotificationSubscriptionsLayout }>()

export const getNotificationSubscriptionsDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('GET_NOTIFICATION_SUBSCRIPTIONS')
  .setShape<{ data: ApiNotificationSubscription[] }>()

export const postNotificationSubscriptionsDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('POST_NOTIFICATION_SUBSCRIPTIONS')
  .setShape<{ data: ApiNotificationSubscription }, { topic: string; channel: string }>()

export const deleteNotificationSubscriptionsByTopicAndChannelDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('DELETE_NOTIFICATION_SUBSCRIPTIONS')
  .setShape<{ data: ApiNotificationSubscription }, { topic: string; channel: string }>()
