import { getPublicParentReviewsDescriptor } from '@app/store/actions/reviews.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  page: number
  per_page: number
  fetching: boolean
  fetched_all: boolean
}>(
  {
    page: 0,
    per_page: 10,
    fetching: false,
    fetched_all: false,
  },
  builder => {
    builder.addCase(getPublicParentReviewsDescriptor.shapes.fulfilled, (_state, action) => {
      return {
        page: action.meta.query.page,
        per_page: action.meta.query.per_page,
        fetching: false,
        fetched_all: action.payload.data.length < action.meta.query.per_page,
      }
    })
    builder.addCase(getPublicParentReviewsDescriptor.shapes.pending, (state, _action) => {
      return { ...state, fetching: true }
    })
    builder.addCase(getPublicParentReviewsDescriptor.shapes.rejected, (state, _action) => {
      return { ...state, fetching: false }
    })
  }
)
