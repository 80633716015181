import { CLIENT_ERROR_CODE } from '@app/constants/Misc'

import { isAbortError } from '@app/errors/AbortError'
import { FetchDataError, FetchDataErrorMessage } from '@app/errors/FetchDataError'

import { asNetworkError, isNetworkFailError } from '@app/store/apiMiddleware/errors'
import { ApiActionResult } from '@app/store/apiMiddleware/types'
import { Store } from '@app/store/store'

import { StaticRedirect } from './routing/StaticRedirect'
import { Action, LocationDescriptorObject, MatchedRoute } from './routing/types'

export type StaticRouterState<M extends object = any> = MatchedRoute<M> & {
  location: LocationDescriptorObject
  action: Action
  /**
   * Equals to `true` on initial server run.
   * Use this to avoid double fetch on server/client transition
   */
  serverInitial: boolean
  /**
   * Equals to `true` on initial client run.
   * Use this to avoid double fetch on server/client transition
   */
  browserInitial: boolean
}

export type FetchDataObject<M extends object = any> = {
  routerState: StaticRouterState<M>
  store: Store
}

export type FetchData<M extends object = any> = (data: FetchDataObject<M>) => Promise<void | StaticRedirect> | void | StaticRedirect

export interface WithFetchData<M extends object = any> {
  fetchData: FetchData<M>
}

/**
 * Accepts an array of matched routes as returned from react-router's
 * `Router.run()` and calls the given static method on each. The methods may
 * return a promise.
 *
 * Returns a promise that resolves after any promises returned by the routes
 * resolve. The practical uptake is that you can wait for your data to be
 * fetched before continuing. Based off react-router's async-data example
 * https://github.com/rackt/react-router/blob/master/examples/async-data/app.js#L121
 * @param mathodName - Method to call
 * @param location - Location to pass via context
 * @param matchedRoutes - List of matched routes for location
 * @param store - Redux store
 *
 * @returns possibly returns instance of StaticRedirect
 */
export async function performFetchData<M extends object = any>(
  location: LocationDescriptorObject,
  action: Action,
  matchedRoutes: MatchedRoute<M>[],
  store: Store
): Promise<null | StaticRedirect> {
  const {
    routing: { isInitial },
  } = store.getState()

  const routerState: StaticRouterState<M> = {
    ...matchedRoutes.at(-1)!,
    location,
    action,
    serverInitial: isInitial && !IS_BROWSER,
    browserInitial: isInitial && IS_BROWSER,
  }

  const promises = await Promise.all(
    matchedRoutes
      .flatMap<FetchData>(({ route }) => {
        const fetchData = extractFetchData(route.component)
        return fetchData ?? []
      })
      .map(method => method({ routerState, store }))
  )

  return promises.find(result => result instanceof StaticRedirect) || null
}

type ApiActionErrorOptions = {
  expose?: boolean
  handled?: boolean
}

export const assertActionResponseIsNotError = <R>(resp: ApiActionResult<R>) => {
  if (resp?.error) {
    if (isAbortError(resp.payload)) return undefined

    throw resp.payload
  }

  return resp?.payload
}

export const assertApiActionResponse =
  (
    errorString: FetchDataErrorMessage | ((error: Error) => FetchDataErrorMessage),
    options?: ApiActionErrorOptions | ((error: Error) => ApiActionErrorOptions)
  ) =>
  <R>(resp: ApiActionResult<R>) => {
    if (resp?.error) {
      if (isAbortError(resp.payload)) return undefined

      throw errorToFetchDataError(resp.payload, typeof errorString === 'function' ? errorString(resp.payload) : errorString, options)
    }
    return resp
  }

export const errorToFetchDataError = (
  error: Error,
  errorString: FetchDataErrorMessage,
  options?: ApiActionErrorOptions | ((error: Error) => ApiActionErrorOptions)
) => {
  const err = FetchDataError.create(errorString, asNetworkError(error)?.status ?? CLIENT_ERROR_CODE)
  err.cause = error
  const opts = (typeof options === 'function' ? options(error) : options) ?? {}
  for (const key of Reflect.ownKeys(opts)) {
    err[key] = opts[key]
  }
  err.handled = opts.handled ?? (error as any).handled ?? isNetworkFailError(error)
  return err
}

function extractFetchData(component: any): FetchData | null {
  if (component['fetchData']) return component['fetchData']
  const wrapped = component?.WrappedComponent
  if (wrapped) return extractFetchData(wrapped)
  return null
}
