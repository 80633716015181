import React, { ComponentProps, memo, useEffect, useState } from 'react'

import { useAppDispatch } from '@app/utils/redux'
import { wrapError } from '@app/utils/wrapError'

import { useOnChange } from '@app/hooks/useOnChange'

import { createThunk } from '@app/store/thunk'

import { SVG } from '@app/components/SVG/SVG'

// eslint-disable-next-line no-restricted-imports
import { iconsMap } from './icons.client'

import classes from './Icon.module.scss'

export type Icon18 = keyof (typeof iconsMap)['18']
export type Icon32 = keyof (typeof iconsMap)['32']
export type Icon36 = keyof (typeof iconsMap)['36']
export type Icon108 = keyof (typeof iconsMap)['108']

export type IconProps = (
  | {
      size?: '18'
      icon: Icon18
    }
  | {
      size: '32'
      icon: Icon32
    }
  | {
      size: '36'
      icon: Icon36
    }
  | {
      size: '108'
      icon: Icon108
    }
) &
  ComponentProps<'span'>

export const Icon = memo<IconProps>(function Icon({ icon, size = '18', className, ...props }) {
  const data = useIcon(size, icon)
  if (!data) return null
  return <SVG {...props} className={className} content={data} label={icon} />
})

export const InlineIcon = memo<IconProps>(function Icon({ icon, size = '18', className, ...props }) {
  const data = useIcon(size, icon)
  if (!data) return null
  return <SVG {...props} className={cn(classes.inline_icon, classes[`inline_icon_size_${size}`], className)} content={data} label={icon} />
})

export const useIcon = (size: string, name: string) => {
  const dispatch = useAppDispatch()
  const [icon, setIcon] = useState(dispatch(getIcon(size, name)))

  useEffect(() => {
    if (icon) return
    dispatch(loadIcon(size, name)).then(setIcon)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [icon])

  useOnChange(() => {
    const cached = dispatch(getIcon(size, name))
    if (cached) {
      setIcon(cached)
    } else {
      dispatch(loadIcon(size, name)).then(setIcon)
    }
  }, [size, name])

  return icon
}

export const getIcon = (size: string, name: string) => {
  return createThunk((_dispatch, _getState, { iconCache }): string | null => {
    return iconCache.get(size, name) ?? null
  })
}

export const loadIcon = (size: string, name: string) => {
  return createThunk(async (_dispatch, _getState, { iconCache }): Promise<string> => {
    const existing = iconCache.get(size, name)
    if (existing) return existing
    try {
      return await iconCache.upsertAsync(size, name, async () => (await iconsMap[size][name]()).default)
    } catch (e) {
      throw wrapError(new Error(`Cannot get icon: ${size}:${name}`), e)
    }
  })
}
