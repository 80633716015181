import { ApiApp } from '@app/constants/ApiTypes/internalEntities'

import { getAppsDescriptor } from '@app/store/actions/internal.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<Record<string, ApiApp>>({}, builder => {
  builder.addCase(getAppsDescriptor.shapes.fulfilled, (state, action) => {
    return { ...state, [action.payload.data.id]: action.payload.data }
  })
})
