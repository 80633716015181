import { removeStackInitiator } from '@app/utils/removeStackInitiator'

export class LocalizedRequestError extends Error {
  localizedMessage!: string
  status!: number
  expose!: boolean
  handled!: boolean

  static create(message: string, status: number, localizedMessage: string, expose?: boolean) {
    const err = new this(message)
    err.status = status
    err.localizedMessage = localizedMessage
    err.stack = removeStackInitiator(err.stack ?? '')
    err.expose = !!expose
    err.handled = false
    return err
  }
}
