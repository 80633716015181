type ResultValue<T> = T extends Result<infer V, never> ? V : T extends Result<never, infer E> ? E : never

export class Result<T, E extends Error = Error> {
  private __error: boolean
  private __value: T | E

  private constructor(value: any, error: boolean) {
    this.__value = value
    this.__error = error
  }

  get value(): ResultValue<this> {
    return this.__value as any
  }

  unwrap(): T {
    if (this.__error) throw this.__value
    return this.__value as T
  }

  unwrapOr(defaultValue: T): T {
    if (this.__error) return defaultValue
    return this.__value as T
  }

  unwrapOrElse(defaultValue: () => T): T {
    if (this.__error) return defaultValue()
    return this.__value as T
  }

  unwrapErr(): E {
    if (!this.__error) throw new Error('Trying to unwrap ok result')
    return this.__value as E
  }

  unwrapErrOr(defaultErr: E): E {
    if (!this.__error) return defaultErr
    return this.__value as E
  }

  unwrapErrOrElse(defaultErr: () => E): E {
    if (!this.__error) return defaultErr()
    return this.__value as E
  }

  isOk(): this is Result<T, never> {
    return !this.__error
  }

  isErr(): this is Result<never, E> {
    return this.__error
  }

  static ok<T>(value: T): Result<T, never> {
    return new Result(value, false)
  }

  static err<E extends Error>(value: E): Result<never, E> {
    return new Result(value, true)
  }
}
