/** sparse version of Promise */
export class Channel<T = unknown> {
  private promise: Promise<T>

  resolve!: (value: T) => void
  reject!: (value: Error) => void

  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve as any
      this.reject = reject
    })
  }

  wait() {
    return this.promise
  }

  static resolved(): Channel
  static resolved<T>(value: T): Channel<T>
  static resolved(value?: any) {
    const ch = new Channel()
    ch.resolve(value)
    return ch
  }
}
