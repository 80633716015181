import { ApiLocation } from '@app/constants/ApiTypes/entities'

import { mergeMaps } from '@app/utils/mergeMaps'
import { normalize } from '@app/utils/normalizer'

import {
  fetchAnnouncementDescriptor,
  getAnnouncementsByQueryDescriptor,
  getAnnouncementsSearchDescriptor,
  getAnnouncementsSearchNextDescriptor,
  getAnnouncementsUpcomingDescriptor,
} from '@app/store/actions/announcement.descriptors'
import { createAnnouncementResponseDescriptor, fetchAnnouncementResponseDescriptor } from '@app/store/actions/announcementResponse.descriptors'
import { getDirectAnnouncementsIdDescriptor } from '@app/store/actions/directAnnouncement.descriptors'
import {
  cancelRequestDescriptor,
  createRequestFromAnnouncementResponseDescriptor,
  fetchRequestDescriptor,
  fetchRequestsDescriptor,
  getRequestsWithDebtsDescriptor,
  rejectRequestDescriptor,
} from '@app/store/actions/request.descriptors'
import { profileRequestActionDescriptor } from '@app/store/actions/user.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  [key: string]: ApiLocation
}>({}, builder => {
  builder.addCases(
    [
      cancelRequestDescriptor.shapes.fulfilled,
      rejectRequestDescriptor.shapes.fulfilled,
      createRequestFromAnnouncementResponseDescriptor.shapes.fulfilled,
      getDirectAnnouncementsIdDescriptor.shapes.fulfilled,
      getRequestsWithDebtsDescriptor.shapes.fulfilled,
      fetchRequestDescriptor.shapes.fulfilled,
      fetchRequestsDescriptor.shapes.fulfilled,
      getAnnouncementsUpcomingDescriptor.shapes.fulfilled,
      getAnnouncementsSearchDescriptor.shapes.fulfilled,
      getAnnouncementsSearchNextDescriptor.shapes.fulfilled,
      fetchAnnouncementDescriptor.shapes.fulfilled,
      fetchAnnouncementResponseDescriptor.shapes.fulfilled,
      createAnnouncementResponseDescriptor.shapes.fulfilled,
      profileRequestActionDescriptor.shapes.fulfilled,
      getAnnouncementsByQueryDescriptor.shapes.fulfilled,
    ],
    (state, action) => {
      const { locations } = normalize(action.payload)
      if (!locations) return state

      return mergeMaps(state, locations)
    }
  )
})
