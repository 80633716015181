import { ApiBonus } from '@app/constants/ApiTypes/entities'

import { getBonusesDescriptor } from '@app/store/actions/referrals.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<ApiBonus[]>([], builder => {
  builder.addCase(getBonusesDescriptor.shapes.fulfilled, (_state, action) => {
    return action.payload.data
  })
})
