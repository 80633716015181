import { excludeAbortError } from '@app/errors/AbortError'

import { sendEmailConfirmationDescriptor } from '@app/store/actions/user.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  email: string
  error: Error | null
}>(
  {
    email: '',
    error: null,
  },
  builder => {
    builder.addCase(sendEmailConfirmationDescriptor.shapes.fulfilled, (_state, action) => {
      return { ...action.payload.data.attributes, error: null }
    })
    builder.addCase(sendEmailConfirmationDescriptor.shapes.rejected, (state, action) => {
      return { ...state, error: excludeAbortError(action.payload) }
    })
  }
)
