import React, { createContext, FunctionComponent, PropsWithChildren, useContext, useMemo, useRef, useState } from 'react'

import { noop } from './noop'

export interface OverflowContextInterface {
  overflowed: boolean
  isOverflowed: () => boolean
  increment: () => void
  decrement: () => void
}

const defaultContextValue: OverflowContextInterface = {
  overflowed: false,
  isOverflowed: () => false,
  increment: noop,
  decrement: noop,
}

const Context = createContext<OverflowContextInterface>(defaultContextValue)

/** test only export */
export const __OverflowContext = Context

export const OverflowProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  if (!IS_BROWSER) {
    return <Context.Provider value={defaultContextValue}>{children}</Context.Provider>
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const countRef = useRef(0)
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [overflowed, setOverflowed] = useState(false)

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const context = useMemo(
    () => ({
      overflowed,
      isOverflowed: () => countRef.current > 0,
      increment: () => {
        countRef.current++

        if (countRef.current > 0) {
          setOverflowed(true)
          document.body.style.overflow = 'hidden'
        }
      },
      decrement: () => {
        countRef.current--

        if (countRef.current <= 0) {
          setOverflowed(false)
          document.body.style.removeProperty('overflow')
        }
      },
    }),
    [overflowed]
  )

  return <Context.Provider value={context}>{children}</Context.Provider>
}

export const useOverflowContext = () => useContext(Context)
