import { WORK_TODAY_ENABLED } from '@app/constants/Misc'

import { elementOrParentMatchesSelector } from '@app/utils/elementOrParentMatchesSelector'

import { StoreProfileUser } from '@app/store/types/profile'

export const shouldShowWorkTodayButton = (user: StoreProfileUser) =>
  WORK_TODAY_ENABLED && !!(user && user.registration_completed && user.account_type === 'sitter')

const preventSideMenuCloseATTR = 'data-prevent-side-menu-close'

export const sideMenuModifiers = {
  preventSideMenuClose: { [preventSideMenuCloseATTR]: true },
}

export const shouldLeaveSideMenu = (el: HTMLElement) => elementOrParentMatchesSelector(el, `[${preventSideMenuCloseATTR}]`)
