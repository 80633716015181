import config from '@app/config'

import { setTestFlight } from '@app/store/actions/initial.descriptors'
import { setBuildSha, setConfigAction } from '@app/store/actions/misc.descriptors'
import { createReducer } from '@app/store/toolkit'
import { ConfigState } from '@app/store/types/config'

export default createReducer<ConfigState>(
  {
    ...config,
    href: '',
    origin: '',
    userAgent: '',
    userAgentData: null,
    country: 'RU',
    isPolygon: false,
    isApp: false,
    appProtocol: 0,
    online: true,
    build: {
      sha: process.env.GIT_SHA || null,
      needsReload: false,
    },
    testFlights: {
      search_banner: false,
    },
  },
  builder => {
    builder.addCase(setConfigAction.shape, (state, action) => ({ ...state, ...action.payload }))
    builder.addCase(setBuildSha.shape, (state, action) => {
      if (!('needsReload' in action.payload) && state.build.sha === action.payload.sha) return state

      const needsReload =
        'needsReload' in action.payload
          ? !!action.payload.needsReload
          : state.build.needsReload || (state.build.sha !== null && state.build.sha !== action.payload.sha)

      return {
        ...state,
        build: {
          ...state.build,
          sha: action.payload.sha,
          needsReload,
        },
      }
    })
    builder.addCase(setTestFlight.shape, (state, action) => ({ ...state, testFlights: { ...state.testFlights, ...action.payload } }))
  }
)
