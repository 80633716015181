import uniq from 'lodash/uniq'

import { fetchRequestsDescriptor } from '@app/store/actions/request.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<string[]>([], builder => {
  builder.addCase(fetchRequestsDescriptor.shapes.fulfilled, (state, action) => {
    const keys = action.payload.data.map(model => model.id)
    return action.payload.meta.current_page === 1 ? keys : uniq(state.concat(keys))
  })
})
