import { IMPORT_MAP } from '@app/importMap'

import { makeRedirectRoute } from '@app/utils/makeRedirectRoute'
import { AsyncRoute } from '@app/utils/routing/types'

// @routes declaration
const routes: AsyncRoute[] = [
  {
    path: '/payment/bind-card',
    event_id: 'bind_card',
    component: () => IMPORT_MAP.routes.Internal.BindCard().then(m => m.BindCard),
  },
  makeRedirectRoute('/payment/bind_card', '/payment/bind-card', 'bind_card'),
  {
    path: '/internal/tos-accept',
    event_id: 'tos_accept',
    component: () => IMPORT_MAP.routes.Internal.TOSAccept().then(m => m.TOSAccept),
  },
  makeRedirectRoute('/internal/tos_accept', '/internal/tos-accept', 'tos_accept'),
]

export default routes
