import type { ApiDirectAnnouncement, ApiIncluded } from '@app/constants/ApiTypes/entities'
import { PutDirectAnnouncementsAcceptRequest } from '@app/constants/ApiTypes/requests'

import { ApiActionBuilderDescriptor } from '@app/store/apiMiddleware/builderDescriptor'

export const postDirectAnnouncementDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('POST_DIRECT_ANNOUNCEMENTS')
  .setShape<{ data: ApiDirectAnnouncement; included: ApiIncluded }>()

export const putDirectAnnouncementsAcceptDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('PUT_DIRECT_ANNOUNCEMENTS_ACCEPT')
  .setShape<unknown, { direct_announcement_id: string } & PutDirectAnnouncementsAcceptRequest>()

export const putDirectAnnouncementsCancelDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('PUT_DIRECT_ANNOUNCEMENTS_CANCEL')
  .setShape<{ data: ApiDirectAnnouncement; included: ApiIncluded }, { direct_announcement_id: string }>()

export const putDirectAnnouncementsDeclineDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('PUT_DIRECT_ANNOUNCEMENTS_DECLINE')
  .setShape<unknown, { direct_announcement_id: string }>()

export const getDirectAnnouncementsIdDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('GET_DIRECT_ANNOUNCEMENTS_ID')
  .setShape<{ data: ApiDirectAnnouncement; included?: ApiIncluded }, { direct_announcement_id: string }>()
