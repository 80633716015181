export function debounce<T extends any[]>(fn: (...args: T) => unknown, wait: number = 1000): (...args: Parameters<typeof fn>) => void {
  let timeout: number | undefined
  return function (this: any, ...args): void {
    const laterCall = (): unknown => fn.apply(this, args)
    window.clearTimeout(timeout)
    timeout = window.setTimeout(laterCall, wait)
  }
}

/**
 * Unlike plain debounce this function returns result of last called function
 */
export function debounceWithResult<R = any>(fn: () => R, wait: number = 1000): () => Promise<R> {
  let queue: ((value: R) => unknown)[] = []
  let timeout: number | undefined
  const laterCall = (): void => {
    const result = fn()
    const queueCopy = queue.slice(0)
    queue = []
    for (const item of queueCopy) {
      try {
        item(result)
      } catch {}
    }
  }
  return function (this: any): Promise<R> {
    window.clearTimeout(timeout)
    timeout = window.setTimeout(laterCall, wait)
    return new Promise(resolve => {
      queue.push(resolve)
    })
  }
}
