import type { ApiCurrency, ApiCurrencyRate } from '@app/constants/ApiTypes/entities'

import { ApiActionBuilderDescriptor } from '@app/store/apiMiddleware/builderDescriptor'
import { createAction } from '@app/store/toolkit'

export const setCurrency = createAction<'SET_CURRENCY', ApiCurrency>('SET_CURRENCY')

export const getCurrenciesDescriptor = new ApiActionBuilderDescriptor().setLabel('GET_CURRENCIES').setShape<{ data: ApiCurrency[] }>()

export const getCurrencyRatesDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('GET_CURRENCY_RATES')
  .setShape<{ data: ApiCurrencyRate[] }, { base: string }>()
