export type RelatedApp = {
  id: string
  platform: string
  url: string
}

export async function getInstalledRelatedApps(): Promise<RelatedApp[]> {
  try {
    if (!IS_BROWSER) return []
    return (await (navigator as any).getInstalledRelatedApps?.()) ?? []
  } catch {
    return []
  }
}
