import { IMPORT_MAP } from '@app/importMap'

import { AsyncRoute } from '@app/utils/routing/types'

const RedirectToMain = () => IMPORT_MAP.components.Redirect().then(m => m.createRedirect('/'))
const MessagesLayout = () => IMPORT_MAP.routes.Messages.MessagesLayout().then(m => m.default)

// @routes declaration
export default (regionsPrefixes: string[], isParent: boolean): AsyncRoute[] => {
  return [
    {
      path: isParent ? '/' : '/m', // Allow different root for Sitter
      event_id: 'conversations',
      component: MessagesLayout,
    },
    {
      path: '/m/:token',
      event_id: 'conversation',
      component: MessagesLayout,
      progress: false,
      event_params: {
        participant_user_id: 'token',
      },
      name: 'messages',
    },
    // add redirect to "/" in case logged in user landed on `/$region` link
    ...regionsPrefixes.map(
      (prefix): AsyncRoute => ({
        path: `/${prefix}`,
        event_id: 'redirect_to_main',
        component: RedirectToMain,
      })
    ),
  ]
}
