import { combineReducers } from 'redux'

import count from './announcements.count'
import create from './announcements.create'
import hidden from './announcements.hidden'
import list from './announcements.list'
import map from './announcements.map'
import meta from './announcements.meta'
import models from './announcements.models'
import models_meta from './announcements.models_meta'

export default combineReducers({ count, create, hidden, list, map, meta, models_meta, models })
