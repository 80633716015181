import { AbortError } from '@app/errors/AbortError'

import { sleep } from './sleep'

/**
 *
 * @param condition condition function to meet
 * @param timeout timeout in ms, if null then function will wait indefinitely
 *
 * @returns boolean value that indicates whether condition was met, or not met in case of timeout
 */
export async function waitFor<T>(
  condition: () => T | Promise<T>,
  {
    timeout,
    interval = 20,
    abortSignal,
  }: {
    timeout?: number
    interval?: number
    abortSignal?: AbortSignal
  } = {}
): Promise<NonNullable<T>> {
  const timestamp = new Date().getTime()
  while (true) {
    const val = await condition()
    if (val) return val
    await sleep(interval)
    if (abortSignal?.aborted) throw new AbortError('waitFor was aborted')
    if (timeout && new Date().getTime() - timestamp >= timeout) throw new Error('waitFor timed out')
  }
}
