import loadscript from 'load-script'

import { wrapError } from './wrapError'

export async function loadScript(path: string) {
  if (!IS_BROWSER) throw new Error('loadScript unavailable in server context')
  return await new Promise<void>((resolve, reject) => {
    loadscript(path, err => {
      if (err) {
        reject(wrapError(new Error(`Failed to load script at ${path}`), err))
      } else {
        resolve()
      }
    })
  })
}
