export function urlEscaped(strings: TemplateStringsArray, ...args: any[]): string {
  const pargs = args.map(arg => (arg instanceof Raw ? arg.value : encodeURIComponent(String(arg))))
  const pstrings = strings.map((string, index) => string + (pargs[index] || ''))
  return pstrings.join('')
}

export class Raw {
  value: string

  constructor(value: string) {
    this.value = value
  }

  toString() {
    return this.value
  }

  static create(value: string) {
    return new Raw(value)
  }
}
