import { getPromoUsagesDescriptor } from '@app/store/actions/payment.descriptors'
import { beforeNavigate } from '@app/store/actions/router.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  fetched: boolean
}>({ fetched: false }, builder => {
  builder.addCase(beforeNavigate.shape, (state, action, defaultState) => {
    if (!action.payload.initial) return defaultState
    return state
  })

  builder.addCase(getPromoUsagesDescriptor.shapes.fulfilled, (state, _action) => {
    return { ...state, fetched: true }
  })
})
