import isEqual from 'lodash/isEqual'
import isNil from 'lodash/isNil'

import { filterObject } from './filterObject'

export function mergeGuarded<A extends { [key: string]: unknown }, K extends keyof A>(a: A | null, b: A, ...guardKeys: K[]): A {
  if (!a) return b
  if (isEqual(a, b)) return a
  const filtered = filterObject(b, (k, v) => {
    if (guardKeys.includes(k as any) && isNil(v)) return false
    return true
  })
  return { ...a, ...filtered }
}
