import { memoizeLast } from '@app/utils/memoizeLast'
import { pick } from '@app/utils/pick'
import { awaitAsyncRoutes, makeRoutePath, matchRoutes } from '@app/utils/routing/functions'
import { AsyncRoute, LocationDescriptorObject, Route } from '@app/utils/routing/types'

import { affiliatesEnabledSelector } from '@app/store/selectors/misc'
import { availableRegionsSlugsSelector, availableSlugsSelector } from '@app/store/selectors/regions'
import { Store } from '@app/store/store'

import Root from '@app/components/Root'

const routesPromises = {
  landing: () => import(/* webpackMode: "eager" */ './routes/Static/landing'),
  academy: () => import(/* webpackMode: "eager" */ './routes/Academy/routes'),
  quiz: () => import(/* webpackMode: "eager" */ './routes/Quiz/routes'),
  affiliate: () => import(/* webpackMode: "eager" */ './routes/Affiliate/routes'),
  giftcard: () => import(/* webpackMode: "eager" */ './routes/GiftCard/routes'),
  playground: () => import(/* webpackMode: "eager" */ './routes/Playground/routes'),
  telegram: () => import(/* webpackMode: "eager" */ './routes/Telegram/routes'),
  notApprovedSitter: () => import(/* webpackMode: "eager" */ './routes/CandidateSitter/routes'),
  notApprovedSitterPlug: () => import(/* webpackMode: "eager" */ './routes/CandidateSitter/routes_plug'),
  ny: () => import(/* webpackMode: "eager" */ './routes/NY2020/routes'),
  messages: () => import(/* webpackMode: "eager" */ './routes/Messages/routes'),
  news: () => import(/* webpackMode: "eager" */ './routes/News/routes'),
  parentSettings: () => import(/* webpackMode: "eager" */ './routes/Settings/routes_parent'),
  parentAnnouncements: () => import(/* webpackMode: "eager" */ './routes/Announcements/routes_parent'),
  sitterSettings: () => import(/* webpackMode: "eager" */ './routes/Settings/routes_sitter'),
  sitterAnnouncements: () => import(/* webpackMode: "eager" */ './routes/Announcements/routes_sitter'),
  registration: () => import(/* webpackMode: "eager" */ './routes/Settings/routes_registration'),
  unauth: () => import(/* webpackMode: "eager" */ './routes/Settings/routes_unauth'),
  publicApps: () => import(/* webpackMode: "eager" */ './routes/Apps/routes'),
  publicSettings: () => import(/* webpackMode: "eager" */ './routes/Settings/routes_public'),
  publicSearch: () => import(/* webpackMode: "eager" */ './routes/Search/routes'),
  publicStatic: () => import(/* webpackMode: "eager" */ './routes/Static'),
  publicProfile: () => import(/* webpackMode: "eager" */ './routes/Profile/routes'),
  internal: () => import(/* webpackMode: "eager" */ './routes/Internal/routes'),
  service_reviews: () => import(/* webpackMode: "eager" */ './routes/ServiceReviews/routes'),
  polygon: () =>
    process.env.MODE !== 'production'
      ? import(/* webpackChunkName: "polygon_routes" */ './routes/Polygon/routes')
      : Promise.resolve<{ default: () => Promise<AsyncRoute[]> }>({ default: async () => [] }),
}

const memoizedRoutes = memoizeLast(
  async ({
    regionsPrefixes,
    user,
    affiliatesEnabled,
  }: {
    regionsPrefixes: string[]
    user:
      | null
      | {
          account_type: 'visitor' | 'parent'
          registration_completed: boolean
        }
      | {
          account_type: 'sitter'
          approved: boolean
          registration_completed: boolean
          training_completed: boolean
        }
    affiliatesEnabled: boolean
  }) => {
    const routes: Promise<AsyncRoute[]>[] = []

    // add experimental landings
    routes.push(routesPromises.landing().then(m => m.default().filter(route => route.path !== '/')))
    routes.push(routesPromises.academy().then(m => m.default))
    routes.push(routesPromises.quiz().then(m => m.default))
    if (affiliatesEnabled) routes.push(routesPromises.affiliate().then(m => m.default))
    routes.push(routesPromises.affiliate().then(m => m.default))
    routes.push(routesPromises.giftcard().then(m => m.default))
    routes.push(routesPromises.playground().then(m => m.default))
    routes.push(routesPromises.service_reviews().then(m => m.default))
    routes.push(routesPromises.ny().then(m => m.default))
    routes.push(routesPromises.internal().then(m => m.default))

    // Sitter that not yet completed training
    if (user?.account_type === 'sitter' && !user.approved && !user.training_completed) {
      routes.push(
        routesPromises.notApprovedSitter().then(m => m.default),
        routesPromises.sitterSettings().then(m => m.default()),
        routesPromises.news().then(m => m.default),
        routesPromises.notApprovedSitterPlug().then(m => m.default),
        routesPromises.registration().then(m => m.default(false, user.account_type))
      )
      // Registered user
    } else if (user?.account_type === 'parent' || (user?.account_type === 'sitter' && (user.approved || user.training_completed))) {
      routes.push(routesPromises.news().then(m => m.default))

      if (user.registration_completed) {
        routes.push(routesPromises.messages().then(m => m.default(regionsPrefixes, user.account_type === 'parent')))
        routes.push(routesPromises.telegram().then(m => m.routes))

        if (user.account_type === 'parent') {
          routes.push(
            routesPromises.parentSettings().then(m => m.default),
            routesPromises.parentAnnouncements().then(m => m.default)
          )
        } else {
          routes.push(
            routesPromises.sitterSettings().then(m => m.default()),
            routesPromises.sitterAnnouncements().then(m => m.default)
          )
        }
        routes.push(routesPromises.registration().then(m => m.default(false, user.account_type)))
      } else {
        // Unregistered user
        routes.push(routesPromises.registration().then(m => m.default(true, user.account_type)))
      }
    } else {
      // Not authorized
      routes.push(
        // add main landing
        routesPromises.landing().then(m => m.default().filter(route => route.path === '/')),
        // other routes for unauthorized user
        routesPromises.unauth().then(m => m.default())
      )
    }

    // Public routes
    routes.push(
      routesPromises.publicSettings().then(m => m.default),
      routesPromises.publicSearch().then(m => m.routes),
      routesPromises.publicStatic().then(m => m.default(user?.account_type || null)),
      routesPromises.publicApps().then(m => m.default)
    )

    routes.push(routesPromises.polygon().then(m => m.default()))

    // catch all /:sitter_id routes for profiles
    routes.push(routesPromises.publicProfile().then(m => m.default))

    const resolvedRoutes = (await Promise.all(routes)).reduce<AsyncRoute[]>((c, x) => {
      c = c.concat(x)
      return c
    }, [])

    // if (config.country !== 'RU' && config.userAgent.indexOf('facebookexternalhit') === -1) {
    //   resolvedRoutes = resolvedRoutes.filter(route => route.path !== 'school')
    // }

    return resolvedRoutes
  },
  obj => JSON.stringify(obj)
)

export default async (location: LocationDescriptorObject, store: Store): Promise<Route[]> => [
  {
    component: Root,
    routes: await (async () => {
      const state = store.getState()
      const {
        profile: { user },
      } = state
      const regions = availableRegionsSlugsSelector(state)
      const regionsPrefixes = availableSlugsSelector(state)
      const affiliatesEnabled = affiliatesEnabledSelector(state)

      const routes = await memoizedRoutes({
        regionsPrefixes,
        user:
          user && user.account_type === 'sitter'
            ? pick(user, 'account_type', 'approved', 'registration_completed', 'training_completed')
            : user
              ? pick(user, 'account_type', 'registration_completed')
              : null,
        affiliatesEnabled,
      })

      if (!location.pathname) return []

      const matched = matchRoutes(regions, routes, location.pathname)

      const path = makeRoutePath(matched)

      if (!path) return []

      return await awaitAsyncRoutes([path], location)
    })(),
  },
]
