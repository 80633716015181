import { ApiPromousage } from '@app/constants/ApiTypes/entities'

import { getPromoUsagesDescriptor } from '@app/store/actions/payment.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<ApiPromousage[]>([], builder => {
  builder.addCase(getPromoUsagesDescriptor.shapes.fulfilled, (_state, action) => {
    return action.payload.data
  })
})
