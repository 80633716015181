import { IMPORT_MAP } from '@app/importMap'

import { makeRedirectRoute } from '@app/utils/makeRedirectRoute'
import { AsyncRoute } from '@app/utils/routing/types'

// @routes declaration
const routes: AsyncRoute[] = [
  {
    path: '/new_year_2021',
    event_id: 'newyear2021',
    component: () => IMPORT_MAP.routes.NY2020.NY2020().then(m => m.NY2020),
  },
  makeRedirectRoute('/new_year_2021', '/new-year-2021', 'newyear2021'),
]

export default routes
