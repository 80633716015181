import { ApiAvatar } from '@app/constants/ApiTypes/entities'

import { mergeMaps } from '@app/utils/mergeMaps'
import { normalize } from '@app/utils/normalizer'

import {
  fetchAnnouncementDescriptor,
  getAnnouncementsByQueryDescriptor,
  getAnnouncementsSearchDescriptor,
  getAnnouncementsSearchNextDescriptor,
  getAnnouncementsUpcomingDescriptor,
} from '@app/store/actions/announcement.descriptors'
import { fetchAnnouncementResponseDescriptor } from '@app/store/actions/announcementResponse.descriptors'
import { getDirectAnnouncementsIdDescriptor } from '@app/store/actions/directAnnouncement.descriptors'
import {
  addToFavoritesDescriptor,
  deleteFromFavoritesDescriptor,
  getFavoritesDescriptor,
  postEmailCodeVerificationDescriptor,
  postEmailTokenVerificationDescriptor,
} from '@app/store/actions/profile.descriptors'
import {
  createRequestFromAnnouncementResponseDescriptor,
  fetchRequestDescriptor,
  fetchRequestsDescriptor,
  getRequestsWithDebtsDescriptor,
} from '@app/store/actions/request.descriptors'
import { getPublicParentReviewsDescriptor, getReviewsDescriptor } from '@app/store/actions/reviews.descriptors'
import { fetchTrustedSittersDescriptor, fetchTrustingParentsDescriptor } from '@app/store/actions/trusted.descriptors'
import { getUsersByIdsDescriptor, profileRequestActionDescriptor, userRequestActionDescriptor } from '@app/store/actions/user.descriptors'
import { createReducer } from '@app/store/toolkit'

import { getSitterSearchDescriptor } from '@app/routes/Search/actions.descriptors'

export default createReducer<{
  [key: string]: ApiAvatar
}>({}, builder => {
  builder.addCases(
    [
      fetchTrustedSittersDescriptor.shapes.fulfilled,
      fetchTrustingParentsDescriptor.shapes.fulfilled,
      userRequestActionDescriptor.shapes.fulfilled,
      getUsersByIdsDescriptor.shapes.fulfilled,
      getReviewsDescriptor.shapes.fulfilled,
      getSitterSearchDescriptor.shapes.fulfilled,
      getFavoritesDescriptor.shapes.fulfilled,
      addToFavoritesDescriptor.shapes.fulfilled,
      deleteFromFavoritesDescriptor.shapes.fulfilled,
      createRequestFromAnnouncementResponseDescriptor.shapes.fulfilled,
      fetchAnnouncementResponseDescriptor.shapes.fulfilled,
      getDirectAnnouncementsIdDescriptor.shapes.fulfilled,
      getRequestsWithDebtsDescriptor.shapes.fulfilled,
      fetchRequestDescriptor.shapes.fulfilled,
      fetchRequestsDescriptor.shapes.fulfilled,
      fetchAnnouncementDescriptor.shapes.fulfilled,
      getAnnouncementsSearchDescriptor.shapes.fulfilled,
      getAnnouncementsSearchNextDescriptor.shapes.fulfilled,
      getAnnouncementsUpcomingDescriptor.shapes.fulfilled,
      profileRequestActionDescriptor.shapes.fulfilled,
      getPublicParentReviewsDescriptor.shapes.fulfilled,
      getAnnouncementsByQueryDescriptor.shapes.fulfilled,
      postEmailCodeVerificationDescriptor.shapes.fulfilled,
      postEmailTokenVerificationDescriptor.shapes.fulfilled,
    ],
    (state, action) => {
      const { avatars } = normalize(action.payload)
      if (!avatars) return state

      return mergeMaps(state, avatars)
    }
  )
})
