import { createContext } from 'react'

import { noop, noopAsync } from '@app/utils/noop'

import { MountContextInterface } from './types'

export const MountContext = createContext<MountContextInterface>({
  push: () => noopAsync,
  pop: noopAsync,
  autoclose: noop,
  isMounted: () => false,
})
