import keyBy from 'lodash/keyBy'

import { mergeMaps } from '@app/utils/mergeMaps'

import { getMapAnnouncementsSearchDescriptor } from '@app/store/actions/announcement.descriptors'
import { createReducer } from '@app/store/toolkit'

import { PointsMapPoint } from '@app/components/PointsMap/PointsMapPoint'

export default createReducer<{ [key: string]: PointsMapPoint }>({}, builder => {
  builder.addCase(getMapAnnouncementsSearchDescriptor.shapes.fulfilled, (state, action) => {
    const locations = keyBy(action.payload.included, l => l.id)
    const obj = keyBy(
      action.payload.data.map(d => {
        const loc = locations[d.relationships.location.data.id]
        return {
          id: d.id,
          coords: [loc.attributes.latitude, loc.attributes.longitude] as [number, number],
        }
      }),
      d => d.id
    )
    if (action.meta.initial) return obj
    return mergeMaps(state, obj)
  })
})
