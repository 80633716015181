import { ApiPublicParentReview } from '@app/constants/ApiTypes/entities'

import { normalize } from '@app/utils/normalizer'

import { getPublicParentReviewsDescriptor } from '@app/store/actions/reviews.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  [key: string]: ApiPublicParentReview
}>({}, builder => {
  builder.addCase(getPublicParentReviewsDescriptor.shapes.fulfilled, (state, action) => {
    const { public_parent_reviews } = normalize(action.payload)
    if (!public_parent_reviews) return state
    return { ...state, ...public_parent_reviews }
  })
})
