import { useSelector } from 'react-redux'

import { bannersStateSelector } from '@app/store/selectors/ui'
import { BannerLabel } from '@app/store/types/banners'

export const useBannerVisible = (
  name: BannerLabel,
  /** unix time */
  now: number
) => {
  const state = useSelector(bannersStateSelector)
  if (!(name in state)) return true
  const item = state[name]!
  if (item.expire && item.expire < now) return true
  return item.value
}
