import uniq from 'lodash/uniq'

import { getReviewsDescriptor } from '@app/store/actions/reviews.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<string[]>([], builder => {
  builder.addCase(getReviewsDescriptor.shapes.fulfilled, (state, action) => {
    const { data } = action.payload
    const { query } = action.meta
    const keys = data.map(model => model.id)

    if (query.page === 1) {
      return keys
    }

    return uniq(state.concat(keys))
  })
})
