import { getNotificationSubscriptionId } from '@app/helpers/notification_subscriptions'

import { isAbortError } from '@app/errors/AbortError'

import {
  deleteNotificationSubscriptionsByTopicAndChannelDescriptor,
  getNotificationSubscriptionsDescriptor,
  postNotificationSubscriptionsDescriptor,
} from '@app/store/actions/notifications.descriptors'
import { createReducer } from '@app/store/toolkit'

/** ApiNotificationSubscritpion Topic and channel joined with _ */
type SubscriptionID = string

export default createReducer<Record<SubscriptionID, boolean>>({}, builder => {
  builder.addCase(getNotificationSubscriptionsDescriptor.shapes.fulfilled, (_state, action) => {
    return action.payload.data.reduce<Record<SubscriptionID, boolean>>((acc, n) => {
      acc[getNotificationSubscriptionId(n.attributes)] = true
      return acc
    }, {})
  })

  builder.addCase(postNotificationSubscriptionsDescriptor.shapes.pending, (state, action) => {
    return { ...state, [getNotificationSubscriptionId(action.meta)]: true }
  })

  builder.addCase(deleteNotificationSubscriptionsByTopicAndChannelDescriptor.shapes.pending, (state, action) => {
    return { ...state, [getNotificationSubscriptionId(action.meta)]: false }
  })

  builder.addCase(postNotificationSubscriptionsDescriptor.shapes.rejected, (state, action) => {
    if (isAbortError(action.payload)) return state
    return { ...state, [getNotificationSubscriptionId(action.meta)]: false }
  })

  builder.addCase(deleteNotificationSubscriptionsByTopicAndChannelDescriptor.shapes.rejected, (state, action) => {
    if (isAbortError(action.payload)) return state
    return { ...state, [getNotificationSubscriptionId(action.meta)]: true }
  })
})
