import { FocusEvent } from 'react'

import { ApiCountry, ApiLocality, ApiPlace, ApiRegion } from '@app/constants/ApiTypes/entities'

import { uppercaseFirstLetter } from '@app/utils/uppercaseFirstLetter'

export type PlaceInputInputValue = {
  place: ApiPlace
  region?: { attributes?: Partial<ApiRegion['attributes']> } | null
  country?: { attributes?: Partial<ApiCountry['attributes']> } | null
  locality?: { attributes?: Partial<ApiLocality['attributes']> } | null
}

export type PlaceInputResultValue = {
  place: ApiPlace
  region: ApiRegion
  country: ApiCountry | null
  locality: ApiLocality | null
}

export type PlaceInputOnChangeCallback = (event: null | PlaceInputResultValue) => unknown
export type PlaceInputOnFocusCallback = (event: FocusEvent<HTMLInputElement>) => unknown
export type PlaceInputOnBlurCallBack = (event: FocusEvent<HTMLInputElement>) => unknown
export type PlaceInputOnLoadingChangeCallBack = (loading: boolean) => unknown
export type PlaceInputOnErrorChangeCallBack = (error: boolean) => unknown
export type PlaceInputLabelExtractor = (value: PlaceInputInputValue) => string

export const shouldRenderSuggestions = () => true
export const getSuggestionValue = (suggestion: { value: string }) => suggestion.value

export const processLabel = (label: string) => uppercaseFirstLetter(label.replace(/-/gi, '\u2011').replace(/^городской округ /i, ''))

export const defaultLabelExtractor: PlaceInputLabelExtractor = ({ place, country, locality }) =>
  processLabel(locality?.attributes?.name ?? country?.attributes?.name ?? place.attributes.address)
