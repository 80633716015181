import loggerCreator from './loggerCreator'

const c = 'yandex_metrika_callbacks'
const a = 'yandex_metrika_accounts'

type MethodName = 'hit' | 'reachGoal' | 'userParams'

function ymProxy(methodName: MethodName, ...args: any) {
  window[a].forEach((id: string) => {
    try {
      window[id][methodName](...args)
    } catch (ex) {
      console?.warn?.(ex)
    }
  })
}

let ymAsyncProxy = function (methodName: MethodName, ...args: any) {
  const ids: string[] = Object.keys(window).filter(k => k.substring(0, 9) === 'yaCounter')
  if (window['ym'] && ids.length > 0) {
    // if window["ym"] then metrica is initialized
    // and window["yaCounterXXXXXX"] should be available
    window[a] = ids

    // setting function to straigthforward usage
    ymAsyncProxy = ymProxy

    ymProxy(methodName, ...args)
  } else {
    // if not we stacking callbacks which metrica will process upon load
    window[c].push(() => ymProxy(methodName, ...args))
  }
}

const log = loggerCreator('ym', 'yellow')

function ym(methodName: MethodName, ...args: any) {
  if (process.env.NODE_ENV === 'development') {
    log(methodName, ...args)
  } else if (IS_BROWSER) {
    ymAsyncProxy(methodName, ...args)
  }
}

if (IS_BROWSER) {
  window[c] = window[c] || []
  window[a] = window[a] || []
}

export default ym
