import { ApiEntity, ApiUser } from '@app/constants/ApiTypes/entities'

import { mergeMaps } from '@app/utils/mergeMaps'

import { getReviewsDescriptor } from '@app/store/actions/reviews.descriptors'
import { createReducer } from '@app/store/toolkit'

type ReviewUser = { id: string } & ApiUser['attributes']

export default createReducer<{ [key: string]: ReviewUser }>({}, builder => {
  builder.addCase(getReviewsDescriptor.shapes.fulfilled, (state, action) => {
    if (!action.payload.included) return state
    const patch = action.payload.included.reduce<{ [key: string]: ReviewUser }>((acc, e) => {
      if (isUser(e)) {
        acc[e.id] = { id: e.id, ...e.attributes }
      }

      return acc
    }, {})

    return mergeMaps(state, patch)
  })
})

const isUser = (u: ApiEntity): u is ApiUser => u.type === 'users'
