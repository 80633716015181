import { createLocation } from 'history'

import { LocationDescriptorObject } from './types'

export class StaticRedirect {
  location: LocationDescriptorObject
  /** 301 is permanent redirect */
  status: 301 | 302

  constructor(location: string | LocationDescriptorObject, status: 301 | 302 = 302) {
    this.location = typeof location === 'string' ? createLocation(location) : location
    this.status = status
  }

  get fullpath() {
    return [this.location.pathname, this.location.search, this.location.hash].map(v => v ?? '').join('')
  }
}
