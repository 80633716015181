export function omit<T extends object, K extends keyof T>(object: T, ...props: K[]): Omit<T, K> {
  return Object.entries(object).reduce<Omit<T, K>>((c, [key, value]) => {
    if (props.indexOf(key as any) === -1) {
      c[key] = value
    }
    return c
  }, {} as any)
}

export function omitLoose<T extends object, K extends keyof T>(object: T, ...props: (K | string)[]): Omit<T, K> {
  return Object.entries(object).reduce<Omit<T, K>>((c, [key, value]) => {
    if (props.indexOf(key as any) === -1) {
      c[key] = value
    }
    return c
  }, {} as any)
}
