import { ApiAppStoreCustomerReview } from '@app/constants/ApiTypes/entities'

import { normalize } from '@app/utils/normalizer'

import { getAppStoreCustomerReviewsByQueryDescriptor } from '@app/store/actions/appStoreCustomerReviews.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<Record<string, ApiAppStoreCustomerReview>>({}, builder => {
  builder.addCase(getAppStoreCustomerReviewsByQueryDescriptor.shapes.fulfilled, (state, action) => {
    return action.payload.meta.current_page < 2
      ? (normalize(action.payload).app_store_customer_reviews ?? {})
      : { ...state, ...normalize(action.payload).app_store_customer_reviews }
  })
})
