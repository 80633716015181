export function moveCursorToEnd(element: HTMLInputElement | HTMLTextAreaElement) {
  window.setTimeout(() => {
    if (typeof element.selectionStart === 'number') {
      element.selectionStart = element.selectionEnd = element.value.length
    } else if (typeof (element as any).createTextRange !== 'undefined') {
      const range = (element as any).createTextRange()
      range.collapse(false)
      range.select()
    }
  }, 1)
  element.scrollLeft = element.scrollWidth
}
