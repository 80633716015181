import type { ApiInaccurateLocation, ApiIncluded, ApiSitter } from '@app/constants/ApiTypes/entities'
import type { ApiRelationship } from '@app/constants/ApiTypes/misc'
import type { SearchParams } from '@app/constants/StoreTypes'

import { ApiActionBuilderDescriptor } from '@app/store/apiMiddleware/builderDescriptor'
import { createAction } from '@app/store/toolkit'

export type GET_SEARCH_PAYLOAD = {
  data: ApiSitter[]
  included?: ApiIncluded
  meta: {
    total: number
    total_pages: number
    current_page: number
  }
}

export type GET_SEARCH_META = {
  prev: {
    params: SearchParams
    page: number
    per_page: number
  }
  current: {
    params: SearchParams
    page: number
    per_page: number
  }
}

export const getSitterSearchDescriptor = new ApiActionBuilderDescriptor().setLabel('GET_SITTER_SEARCH').setShape<GET_SEARCH_PAYLOAD, GET_SEARCH_META>()

export const setMapArea = createAction<'SET_MAP_AREA', { paramsChanged: boolean; bounds: ymaps.Bounds }>('SET_MAP_AREA')
export const reducedSitterSearchRequestDescriptor = new ApiActionBuilderDescriptor().setLabel('REDUCED_SITTER_SEARCH_REQUEST').setShape<
  {
    data: {
      id: string
      type: 'users'
      attributes: {
        token: string
        name: string
      }
      relationships: {
        inaccurate_location: ApiRelationship<ApiInaccurateLocation>
      }
    }[]
    included: ApiInaccurateLocation[]
    meta: {
      total: number
      total_pages: number
      current_page: number
    }
  },
  { paramsChanged: boolean; bounds?: ymaps.Bounds }
>()

export const setSearchParams = createAction<'SET_SEARCH_PARAMS', SearchParams>('SET_SEARCH_PARAMS')
export const setReducedSitterSearchMeta = createAction<'REDUCED_SEARCH_SITTERS_SET_META', { count: number }>('REDUCED_SEARCH_SITTERS_SET_META')
