import React, { FunctionComponent, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { FetchDataError } from '@app/errors/FetchDataError'

import { WithFetchData } from '@app/utils/performFetchData'
import { wrapError } from '@app/utils/wrapError'

import { useImmutableSelector } from '@app/hooks/useImmutableSelector'
import { useSetMeta } from '@app/hooks/useSetMeta'

import { setRenderData } from '@app/store/actions/initial'
import { setStaticContent } from '@app/store/actions/static.descriptors'
import { renderDataSelector } from '@app/store/selectors/misc'
import { StoreState } from '@app/store/store'

import { NavItem } from '@app/routes/Static/staticMenu'

import { HTMLText } from '@app/components/HTMLText'

import StaticLayout from './StaticLayout/StaticLayout'

export function createStaticPageRenderer(item: NavItem) {
  const contentSelector = (state: StoreState) => state.static[item.event_id]

  const Cmp: FunctionComponent & WithFetchData = () => {
    const rawContent = useSelector(contentSelector)
    const injected = useImmutableSelector<Record<string, string> | null>(renderDataSelector)
    const content = useMemo(() => {
      if (!injected) return rawContent
      let content = rawContent
      for (const [key, value] of Object.entries(injected)) {
        content = content.replace(new RegExp(`\\{${key}\\}`, 'g'), value)
      }
      return content
    }, [injected, rawContent])

    useSetMeta(item.meta)

    if (item.side) return <HTMLText html={content} />

    return (
      <StaticLayout app={item.hideLayout} headerInject={item.headerInject}>
        <HTMLText html={content} />
      </StaticLayout>
    )
  }

  Cmp.displayName = item.event_id

  Cmp.fetchData = async fetchDataObject => {
    const { routerState, store } = fetchDataObject
    const [data, inject] = await Promise.all([
      item.data().catch(e => {
        throw wrapError(FetchDataError.create('Page fetch failed', 400), e)
      }),
      item.inject && !routerState.browserInitial ? item.inject(fetchDataObject) : Promise.resolve(null),
    ])
    store.dispatch(setStaticContent({ key: item.event_id, content: data }))
    if (!routerState.browserInitial) {
      store.dispatch(setRenderData(inject))
    }
  }

  return Cmp
}
