import { ApiCountry } from '@app/constants/ApiTypes/entities'

import { normalize } from '@app/utils/normalizer'

import { getRegionDescriptor, getRegionsDescriptor } from '@app/store/actions/initial.descriptors'
import { setProfilePlaceActionDescriptor } from '@app/store/actions/profile.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  [key: string]: ApiCountry
}>({}, builder => {
  builder.addCases(
    [getRegionsDescriptor.shapes.fulfilled, getRegionDescriptor.shapes.fulfilled, setProfilePlaceActionDescriptor.shapes.fulfilled],
    (state, action) => {
      const { countries } = normalize(action.payload)
      if (!countries) return state
      return { ...state, ...countries }
    }
  )
})
