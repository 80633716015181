import React, { FunctionComponent, useEffect, useState } from 'react'

export const RenderOnce: FunctionComponent<{ cond: boolean; children?: React.ReactNode }> = ({ cond, children }) => {
  const [val, setVal] = useState(cond)

  useEffect(() => {
    if (cond) setVal(true)
  }, [cond])

  if (!val) return null
  return <React.Fragment>{children}</React.Fragment>
}
