import { printCurrency } from './printCurrency'

export class CurrencyPrinter {
  private current: string
  private rates: Record<string, number>

  private fractions = {
    UZS: 100,
    KZT: 1,
  }

  constructor(current: string, rates: Record<string, number>) {
    this.current = current
    this.rates = rates
  }

  print(price: number | string, currency: string) {
    const ratio = this.getRate(currency)
    if (ratio === null) return printCurrency(price, currency)
    const converted = this.getConvertedValue(price, currency)
    return printCurrency(converted, this.current)
  }

  asIs(price: number | string, currency: string) {
    return printCurrency(price, currency)
  }

  printValue(price: number | string, currency: string) {
    const ratio = this.getRate(currency)
    if (ratio === null) return printCurrency(price, null)
    const converted = this.getConvertedValue(price, currency)
    return printCurrency(converted, null)
  }

  getConvertedValue(price: number | string, currency: string) {
    const ratio = this.getRate(currency) ?? 1
    return this.ceilTo2((typeof price === 'string' ? parseFloat(price) : price) * ratio)
  }

  convertBaseToCurrency(price: number | string, currency: string) {
    let ratio = this.getRate(currency)
    if (ratio === null) throw CurrencyError.createCurrecyError(this.current, currency)
    ratio = 1 / ratio // invert
    return (typeof price === 'string' ? parseFloat(price) : price) * ratio
  }

  private getRate(currency: string) {
    if (currency === this.current) return 1
    if (currency in this.rates) return this.rates[currency]
    return null
  }

  private ceilTo2(value: number) {
    const unit = 1 / (this.fractions[this.current] ?? 0.01)
    return Math.ceil(value * unit) / unit
  }
}

export class CurrencyError extends Error {
  private base!: string
  private currency!: string
  get message() {
    return process.env.NODE_ENV === 'production' ? 'Unknown currency rate' : `Unknown currency rate: ${this.base}/${this.currency}`
  }

  static createCurrecyError(base: string, currency: string) {
    const error = new CurrencyError()
    error.base = base
    error.currency = currency
    return error
  }
}
