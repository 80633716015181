import { FaqItem } from '@app/constants/ApiTypes/misc'

import { excludeAbortError, isAbortError } from '@app/errors/AbortError'

import { getFaqDescriptor } from '@app/store/actions/initial.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  models: FaqItem[]
  section?: 'parents' | 'sitters' | 'playground'
  pending: boolean
  loaded: boolean
  error: Error | null
}>(
  {
    models: [],
    section: undefined,
    pending: false,
    loaded: false,
    error: null,
  },
  builder => {
    builder.addCase(getFaqDescriptor.shapes.pending, (state, _action) => {
      return { ...state, pending: true, loaded: false, error: null }
    })
    builder.addCase(getFaqDescriptor.shapes.fulfilled, (state, action) => {
      return { ...state, pending: false, loaded: true, error: null, section: action.meta.section, models: action.payload.data.attributes.questions }
    })
    builder.addCase(getFaqDescriptor.shapes.rejected, (state, action) => {
      return { ...state, pending: false, loaded: false, error: excludeAbortError(action.payload), models: isAbortError(action.payload) ? state.models : [] }
    })
  }
)
