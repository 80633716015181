import { MessageDescriptor } from 'react-intl'

import { IntlService } from '@app/services/IntlService'

import { removeStackInitiator } from '@app/utils/removeStackInitiator'

import { LocalizedError } from './LocalizedError'

export class IntlError extends LocalizedError {
  localizedMessage!: string

  static createWithDescriptor(message: string, intlDescriptor: MessageDescriptor) {
    const err = new this(message)
    err.localizedMessage = IntlService.shared.current.formatMessage(intlDescriptor)
    err.stack = removeStackInitiator(err.stack ?? '')
    return err
  }
}
