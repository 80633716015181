import { PlanModifier } from '@app/constants/ApiTypes/misc'

import { setPlan } from '@app/store/actions/plan.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<PlanModifier>('default', builder => {
  builder.addCase(setPlan.shape, (_state, action) => {
    return action.payload
  })
})
