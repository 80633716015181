import isEqual from 'lodash/isEqual'
import uniq from 'lodash/uniq'

import { ApiSitter } from '@app/constants/ApiTypes/entities'

import { addToTrustedDescriptor, fetchTrustedSittersDescriptor, removeFromTrustedDescriptor } from '@app/store/actions/trusted.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<ApiSitter['id'][]>([], builder => {
  builder.addCase(fetchTrustedSittersDescriptor.shapes.fulfilled, (state, action) => {
    const ids = action.payload.data.map(u => u.id)
    return isEqual(state, ids) ? state : ids
  })

  builder.addCases([removeFromTrustedDescriptor.shapes.rejected, addToTrustedDescriptor.shapes.pending], (state, action) => {
    return uniq([action.meta.sitter_id].concat(state))
  })

  builder.addCases([removeFromTrustedDescriptor.shapes.pending, addToTrustedDescriptor.shapes.rejected], (state, action) => {
    const index = state.indexOf(action.meta.sitter_id)
    if (index === -1) return state

    return [...state.slice(0, index), ...state.slice(index + 1)]
  })
})
