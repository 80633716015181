import isEqual from 'lodash/isEqual'

/**
 * Modifies api v2 entitu attributes
 *
 * ### Guarantees:
 * * If object attributes key deeply equal to new value then no assignment will occur
 * * If all keys equal then reference to object will be returned
 *
 * @param obj object
 * @param attrs partial of object attributes
 */
export function modifyV2EntityAttributes<
  A extends object,
  T extends {
    id: string
    type: string
    attributes: A
  },
>(obj: T, attrs: Partial<T['attributes']>): T {
  let output: T = obj
  let allocated = false
  for (const key of Object.keys(attrs)) {
    if (isEqual(output.attributes[key], attrs[key])) continue
    if (!allocated) {
      allocated = true
      output = { ...obj, attributes: { ...obj.attributes } }
    }
    output.attributes[key] = attrs[key]
  }

  return output
}
