import { ApiReferral } from '@app/constants/ApiTypes/entities'

import { getReferralsDescriptor } from '@app/store/actions/referrals.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<ApiReferral[]>([], builder => {
  builder.addCase(getReferralsDescriptor.shapes.fulfilled, (_state, action) => {
    return action.payload.data
  })
})
