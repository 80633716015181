import { getDepersonalizedAnnouncementsSearchDescriptor } from '@app/store/actions/depersonalized_announcements.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<string[]>([], builder => {
  builder.addCase(getDepersonalizedAnnouncementsSearchDescriptor.shapes.fulfilled, (state, action) => {
    const ids = action.payload.data.map(x => x.id)
    if (action.payload.meta.current_page < 2) {
      return ids
    }
    return state.concat(ids)
  })
})
