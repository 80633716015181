export const locales = [
  {
    locale: 'ru',
    country: 'ru',
    default: true,
  },
  {
    locale: 'en',
  },
] as const

export type Locale = (typeof locales)[number]['locale']

export const defaultLocale: Locale = 'ru'

export const localesList = locales.map(l => l.locale)

export const translations = localesList.filter(l => l !== 'ru')
