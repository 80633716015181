import moment, { Moment } from './moment'

export class Cache<T = any, K = string> {
  data: Map<K, { expires?: Moment; data: any }>

  constructor(initial?: Map<K, { expires?: Moment; data: any }>) {
    this.data = initial ?? new Map()
  }

  set(key: K, data: T, { expires = moment().add(5, 'minutes') }: { expires?: Moment } = {}) {
    this.data.set(key, { expires, data })
  }

  get(key: K): T | null {
    const item = this.data.get(key)
    if (!item) return null
    if (item.expires && item.expires.isSameOrBefore(moment())) return null
    return item.data
  }

  upsert(key: K, getter: () => T, options: { expires?: Moment } = {}): T {
    if (!this.has(key)) {
      this.set(key, getter(), options)
    }
    return this.get(key)!
  }

  async upsertAsync(key: K, getter: () => Promise<T>, options: { expires?: Moment } = {}): Promise<T> {
    if (!this.has(key)) {
      this.set(key, await getter(), options)
    }
    return this.get(key)!
  }

  has(key: K): boolean {
    const item = this.data.get(key)
    if (!item) return false
    if (item.expires && item.expires.isSameOrBefore(moment())) return false
    return !!item
  }

  remove(key: K) {
    this.data.delete(key)
  }
}
