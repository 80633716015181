import uniq from 'lodash/uniq'

import {
  getAnnouncementsSearchDescriptor,
  getAnnouncementsSearchNextDescriptor,
  getAnnouncementsUpcomingDescriptor,
} from '@app/store/actions/announcement.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<string[]>([], builder => {
  builder.addCase(getAnnouncementsUpcomingDescriptor.shapes.fulfilled, (_state, action) => {
    return action.payload.data.map(x => x.id)
  })
  builder.addCase(getAnnouncementsSearchDescriptor.shapes.fulfilled, (_state, action) => {
    return action.payload.data.map(x => x.id)
  })
  builder.addCase(getAnnouncementsSearchNextDescriptor.shapes.fulfilled, (state, action) => {
    return uniq([...state, ...action.payload.data.map(x => x.id)])
  })
})
