import type { ApiTrainingPromocode, ApiTrainingRequest } from '@app/constants/ApiTypes/entities'
import { TrainingFormatName } from '@app/constants/ApiTypes/misc'

import { ApiActionBuilderDescriptor } from '@app/store/apiMiddleware/builderDescriptor'

export const getTrainingRequestDescriptor = new ApiActionBuilderDescriptor().setLabel('GET_TRAINING_REQUEST').setShape<{ data: ApiTrainingRequest | null }>()
export const postTrainingRequestDescriptor = new ApiActionBuilderDescriptor().setLabel('POST_TRAINING_REQUEST').setShape<{ data: ApiTrainingRequest }>()
export const putTrainingRequestByIdDescriptor = new ApiActionBuilderDescriptor().setLabel('PUT_TRAINING_REQUEST_BY_ID').setShape<{ data: ApiTrainingRequest }>()
export const postTrainingRequestConfirmationDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('POST_TRAINING_REQUEST_CONFIRMATION')
  .setShape<{ data: ApiTrainingRequest }, { token: string }>()
export const getTrainingRequestPromocodesByTokenDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('GET_TRAINING_REQUEST_PROMOCODES_BY_TOKEN')
  .setShape<{ data: ApiTrainingPromocode }, { token: string; format: TrainingFormatName; region_id?: string }>()
