/* THIS IS AUTOGENERATED FILE. DO NOT EDIT */
/* see scripts/generateIcons.js */
/* eslint-disable */
// prettier-ignore-start

export const iconsMap = {
  '108': {
    'check-outlined': () => import('@app/assets/img/icons/108/check-outlined.svg?force_raw'),
    'check': () => import('@app/assets/img/icons/108/check.svg?force_raw'),
    'warning-outlined': () => import('@app/assets/img/icons/108/warning-outlined.svg?force_raw'),
    'warning': () => import('@app/assets/img/icons/108/warning.svg?force_raw'),
  },
  '18': {
    'arrow-down': () => import('@app/assets/img/icons/18/arrow-down.svg?force_raw'),
    'arrow-left': () => import('@app/assets/img/icons/18/arrow-left.svg?force_raw'),
    'arrow-right': () => import('@app/assets/img/icons/18/arrow-right.svg?force_raw'),
    'baby': () => import('@app/assets/img/icons/18/baby.svg?force_raw'),
    'book': () => import('@app/assets/img/icons/18/book.svg?force_raw'),
    'bulb': () => import('@app/assets/img/icons/18/bulb.svg?force_raw'),
    'calendar': () => import('@app/assets/img/icons/18/calendar.svg?force_raw'),
    'cancel': () => import('@app/assets/img/icons/18/cancel.svg?force_raw'),
    'card': () => import('@app/assets/img/icons/18/card.svg?force_raw'),
    'cart-filled': () => import('@app/assets/img/icons/18/cart-filled.svg?force_raw'),
    'cart': () => import('@app/assets/img/icons/18/cart.svg?force_raw'),
    'chat': () => import('@app/assets/img/icons/18/chat.svg?force_raw'),
    'check-squared': () => import('@app/assets/img/icons/18/check-squared.svg?force_raw'),
    'check': () => import('@app/assets/img/icons/18/check.svg?force_raw'),
    'chevron-down-small': () => import('@app/assets/img/icons/18/chevron-down-small.svg?force_raw'),
    'chevron-down': () => import('@app/assets/img/icons/18/chevron-down.svg?force_raw'),
    'chevron-left': () => import('@app/assets/img/icons/18/chevron-left.svg?force_raw'),
    'chevron-right-small': () => import('@app/assets/img/icons/18/chevron-right-small.svg?force_raw'),
    'chevron-right': () => import('@app/assets/img/icons/18/chevron-right.svg?force_raw'),
    'chevron-up': () => import('@app/assets/img/icons/18/chevron-up.svg?force_raw'),
    'circle-check': () => import('@app/assets/img/icons/18/circle-check.svg?force_raw'),
    'clocks-2': () => import('@app/assets/img/icons/18/clocks-2.svg?force_raw'),
    'clocks-3': () => import('@app/assets/img/icons/18/clocks-3.svg?force_raw'),
    'clocks': () => import('@app/assets/img/icons/18/clocks.svg?force_raw'),
    'close-2': () => import('@app/assets/img/icons/18/close-2.svg?force_raw'),
    'close-3': () => import('@app/assets/img/icons/18/close-3.svg?force_raw'),
    'close': () => import('@app/assets/img/icons/18/close.svg?force_raw'),
    'description': () => import('@app/assets/img/icons/18/description.svg?force_raw'),
    'download': () => import('@app/assets/img/icons/18/download.svg?force_raw'),
    'exclaim': () => import('@app/assets/img/icons/18/exclaim.svg?force_raw'),
    'external': () => import('@app/assets/img/icons/18/external.svg?force_raw'),
    'facebook': () => import('@app/assets/img/icons/18/facebook.svg?force_raw'),
    'flag-outlined': () => import('@app/assets/img/icons/18/flag-outlined.svg?force_raw'),
    'flag': () => import('@app/assets/img/icons/18/flag.svg?force_raw'),
    'gift': () => import('@app/assets/img/icons/18/gift.svg?force_raw'),
    'headphones': () => import('@app/assets/img/icons/18/headphones.svg?force_raw'),
    'heart-outlined': () => import('@app/assets/img/icons/18/heart-outlined.svg?force_raw'),
    'heart-plus': () => import('@app/assets/img/icons/18/heart-plus.svg?force_raw'),
    'heart': () => import('@app/assets/img/icons/18/heart.svg?force_raw'),
    'home': () => import('@app/assets/img/icons/18/home.svg?force_raw'),
    'info-2': () => import('@app/assets/img/icons/18/info-2.svg?force_raw'),
    'info-3': () => import('@app/assets/img/icons/18/info-3.svg?force_raw'),
    'info': () => import('@app/assets/img/icons/18/info.svg?force_raw'),
    'instagram': () => import('@app/assets/img/icons/18/instagram.svg?force_raw'),
    'lightning': () => import('@app/assets/img/icons/18/lightning.svg?force_raw'),
    'link': () => import('@app/assets/img/icons/18/link.svg?force_raw'),
    'location': () => import('@app/assets/img/icons/18/location.svg?force_raw'),
    'logo-outlined': () => import('@app/assets/img/icons/18/logo-outlined.svg?force_raw'),
    'logo': () => import('@app/assets/img/icons/18/logo.svg?force_raw'),
    'magnifier': () => import('@app/assets/img/icons/18/magnifier.svg?force_raw'),
    'mail': () => import('@app/assets/img/icons/18/mail.svg?force_raw'),
    'menudots': () => import('@app/assets/img/icons/18/menudots.svg?force_raw'),
    'miniclocks': () => import('@app/assets/img/icons/18/miniclocks.svg?force_raw'),
    'minus': () => import('@app/assets/img/icons/18/minus.svg?force_raw'),
    'more': () => import('@app/assets/img/icons/18/more.svg?force_raw'),
    'not-available': () => import('@app/assets/img/icons/18/not-available.svg?force_raw'),
    'order': () => import('@app/assets/img/icons/18/order.svg?force_raw'),
    'passport': () => import('@app/assets/img/icons/18/passport.svg?force_raw'),
    'pen': () => import('@app/assets/img/icons/18/pen.svg?force_raw'),
    'percent': () => import('@app/assets/img/icons/18/percent.svg?force_raw'),
    'phone': () => import('@app/assets/img/icons/18/phone.svg?force_raw'),
    'pin': () => import('@app/assets/img/icons/18/pin.svg?force_raw'),
    'play': () => import('@app/assets/img/icons/18/play.svg?force_raw'),
    'playground': () => import('@app/assets/img/icons/18/playground.svg?force_raw'),
    'plus': () => import('@app/assets/img/icons/18/plus.svg?force_raw'),
    'profile-2': () => import('@app/assets/img/icons/18/profile-2.svg?force_raw'),
    'profile-non-scaling-stroke': () => import('@app/assets/img/icons/18/profile-non-scaling-stroke.svg?force_raw'),
    'profile': () => import('@app/assets/img/icons/18/profile.svg?force_raw'),
    'promocode': () => import('@app/assets/img/icons/18/promocode.svg?force_raw'),
    'purse': () => import('@app/assets/img/icons/18/purse.svg?force_raw'),
    'quickly': () => import('@app/assets/img/icons/18/quickly.svg?force_raw'),
    'receipt': () => import('@app/assets/img/icons/18/receipt.svg?force_raw'),
    'refresh': () => import('@app/assets/img/icons/18/refresh.svg?force_raw'),
    'ring': () => import('@app/assets/img/icons/18/ring.svg?force_raw'),
    'sand-clocks': () => import('@app/assets/img/icons/18/sand-clocks.svg?force_raw'),
    'santahat': () => import('@app/assets/img/icons/18/santahat.svg?force_raw'),
    'school-outlined': () => import('@app/assets/img/icons/18/school-outlined.svg?force_raw'),
    'scroll': () => import('@app/assets/img/icons/18/scroll.svg?force_raw'),
    'settings': () => import('@app/assets/img/icons/18/settings.svg?force_raw'),
    'smile-sad': () => import('@app/assets/img/icons/18/smile-sad.svg?force_raw'),
    'smile': () => import('@app/assets/img/icons/18/smile.svg?force_raw'),
    'spinner': () => import('@app/assets/img/icons/18/spinner.svg?force_raw'),
    'star-2': () => import('@app/assets/img/icons/18/star-2.svg?force_raw'),
    'star-4': () => import('@app/assets/img/icons/18/star-4.svg?force_raw'),
    'star-outlined': () => import('@app/assets/img/icons/18/star-outlined.svg?force_raw'),
    'star': () => import('@app/assets/img/icons/18/star.svg?force_raw'),
    'telegram': () => import('@app/assets/img/icons/18/telegram.svg?force_raw'),
    'thermometer': () => import('@app/assets/img/icons/18/thermometer.svg?force_raw'),
    'tick-double': () => import('@app/assets/img/icons/18/tick-double.svg?force_raw'),
    'tick': () => import('@app/assets/img/icons/18/tick.svg?force_raw'),
    'tree': () => import('@app/assets/img/icons/18/tree.svg?force_raw'),
    'tree2': () => import('@app/assets/img/icons/18/tree2.svg?force_raw'),
    'trophy': () => import('@app/assets/img/icons/18/trophy.svg?force_raw'),
    'vacation': () => import('@app/assets/img/icons/18/vacation.svg?force_raw'),
    'vk': () => import('@app/assets/img/icons/18/vk.svg?force_raw'),
    'warning': () => import('@app/assets/img/icons/18/warning.svg?force_raw'),
    'youtube': () => import('@app/assets/img/icons/18/youtube.svg?force_raw'),
  },
  '32': {
    'fb': () => import('@app/assets/img/icons/32/fb.svg?force_raw'),
    'instagram': () => import('@app/assets/img/icons/32/instagram.svg?force_raw'),
    'ok': () => import('@app/assets/img/icons/32/ok.svg?force_raw'),
    'telegram': () => import('@app/assets/img/icons/32/telegram.svg?force_raw'),
    'twitter': () => import('@app/assets/img/icons/32/twitter.svg?force_raw'),
    'vk': () => import('@app/assets/img/icons/32/vk.svg?force_raw'),
    'vkontakte': () => import('@app/assets/img/icons/32/vkontakte.svg?force_raw'),
    'whatsapp': () => import('@app/assets/img/icons/32/whatsapp.svg?force_raw'),
    'youtube': () => import('@app/assets/img/icons/32/youtube.svg?force_raw'),
  },
  '36': {
    'bubble-active': () => import('@app/assets/img/icons/36/bubble-active.svg?force_raw'),
    'bubble': () => import('@app/assets/img/icons/36/bubble.svg?force_raw'),
    'clocks': () => import('@app/assets/img/icons/36/clocks.svg?force_raw'),
    'flag-active': () => import('@app/assets/img/icons/36/flag-active.svg?force_raw'),
    'flag': () => import('@app/assets/img/icons/36/flag.svg?force_raw'),
    'fullscreen-close': () => import('@app/assets/img/icons/36/fullscreen-close.svg?force_raw'),
    'fullscreen': () => import('@app/assets/img/icons/36/fullscreen.svg?force_raw'),
    'gift': () => import('@app/assets/img/icons/36/gift.svg?force_raw'),
    'heart-active': () => import('@app/assets/img/icons/36/heart-active.svg?force_raw'),
    'heart': () => import('@app/assets/img/icons/36/heart.svg?force_raw'),
    'info': () => import('@app/assets/img/icons/36/info.svg?force_raw'),
    'logo-ny': () => import('@app/assets/img/icons/36/logo-ny.svg?force_raw'),
    'order': () => import('@app/assets/img/icons/36/order.svg?force_raw'),
    'orders-active': () => import('@app/assets/img/icons/36/orders-active.svg?force_raw'),
    'orders': () => import('@app/assets/img/icons/36/orders.svg?force_raw'),
    'plus': () => import('@app/assets/img/icons/36/plus.svg?force_raw'),
    'profile': () => import('@app/assets/img/icons/36/profile.svg?force_raw'),
    'promocode': () => import('@app/assets/img/icons/36/promocode.svg?force_raw'),
    'purse': () => import('@app/assets/img/icons/36/purse.svg?force_raw'),
    'ring': () => import('@app/assets/img/icons/36/ring.svg?force_raw'),
    'speaker-active': () => import('@app/assets/img/icons/36/speaker-active.svg?force_raw'),
    'speaker': () => import('@app/assets/img/icons/36/speaker.svg?force_raw'),
    'square-cap': () => import('@app/assets/img/icons/36/square-cap.svg?force_raw'),
    'telegram': () => import('@app/assets/img/icons/36/telegram.svg?force_raw'),
  },
} as const

// prettier-ignore-end
  