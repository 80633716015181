import { matchPath, PathMatch } from 'react-router'

import { awaitMap } from '@app/utils/awaitMap'

import { trimRegionalRoute } from './region'
import { AsyncRoute, LocationDescriptorObject, Route } from './types'

type GenericRoute<T> = { path?: string; routes?: T[]; regional?: boolean | string | string[]; exact?: boolean }

export function matchRoutes<T extends GenericRoute<T>>(regions: string[], routes: T[], pathname: string): { match: PathMatch; route: T }[] {
  const matcher = new RouteMatcher<T>()
  matcher.regions = regions
  matcher.pathname = pathname
  return matcher.matchRoutes(routes)
}

class RouteMatcher<T extends GenericRoute<T>> {
  regions: string[] = []
  pathname: string = ''

  private acc: { match: PathMatch; route: T }[] = []

  matchRoutes(routes: T[] = []): { match: PathMatch; route: T }[] {
    routes.some(route => {
      const match = this.matchRoute(route)

      if (match) {
        this.acc.push({ route, match })

        if ('routes' in route && route.routes) {
          this.matchRoutes(route.routes)
        }
      }

      return match
    })

    return this.acc
  }

  private matchRoute(route: T): PathMatch | null {
    if (typeof route.path === 'string') {
      const match = route.regional
        ? matchPath({ path: route.path, end: !route.routes }, trimRegionalRoute(this.regions, this.pathname))
        : matchPath({ path: route.path, end: !route.routes }, this.pathname)

      return match
    } else if (route.routes) {
      for (const subroute of route.routes) {
        const match = this.matchRoute(subroute)
        if (match) return match
      }
    }

    return null
  }
}

export function makeRoutePath<T extends GenericRoute<T>>(matched: { match: PathMatch; route: T }[]): T | null {
  if (!matched.length) return null
  const root = { ...matched[0].route }
  let lastChild = root

  for (let i = 1; i < matched.length; i++) {
    const r = { ...matched[i].route }
    lastChild.routes = [r]
    lastChild = r
  }

  return root
}

export function convertRoutesToAsyncRoutes(routes: Route[]): AsyncRoute[] {
  return routes.map(r =>
    mapRoutesTree(r, r => {
      return { ...r, component: async () => r.component } as AsyncRoute
    })
  )
}

function mapRoutesTree<T extends { routes?: T[] }, O extends { routes?: O[] }>(route: T, fn: (input: T) => O): O {
  if (!route.routes) return fn(route)
  return { ...fn(route), routes: route.routes.map(r => mapRoutesTree(r, fn)) }
}

function forEachRoutesTree<T extends { routes?: T[] }, O extends { routes?: O[] }>(route: T, fn: (input: T) => void): void {
  fn(route)

  if (route.routes) {
    route.routes.forEach(r => forEachRoutesTree(r, fn))
  }
}

export async function awaitAsyncRoutes(routes: AsyncRoute[], location: LocationDescriptorObject): Promise<Route[]> {
  const promiseMap: Map<AsyncRoute['component'], Promise<Route['component']>> = new Map()

  routes.forEach(r =>
    forEachRoutesTree(r, r => {
      if (promiseMap.has(r.component)) return
      promiseMap.set(r.component, r.component(location))
    })
  )

  const resolved = await awaitMap(promiseMap)

  return routes.map(r =>
    mapRoutesTree(r, r => {
      return { ...r, component: resolved.get(r.component) } as Route
    })
  )
}
