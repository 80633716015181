import { announcementHideDescriptor } from '@app/store/actions/announcement.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{ [key: string]: boolean }>({}, builder => {
  builder.addCase(announcementHideDescriptor.shapes.pending, (state, action) => {
    return { ...state, [action.meta.announcement_id]: true }
  })
  builder.addCase(announcementHideDescriptor.shapes.rejected, (state, action) => {
    return { ...state, [action.meta.announcement_id]: false }
  })
})
