import { IMPORT_MAP } from '@app/importMap'

import { AsyncRoute } from '@app/utils/routing/types'

// @routes declaration
const routes: AsyncRoute[] = [
  {
    path: '/news',
    event_id: 'news',
    progress: false,
    component: () => IMPORT_MAP.routes.News.News().then(m => m.News),
  },
]

export default routes
