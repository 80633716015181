import { composeWithDevTools } from '@redux-devtools/extension'
import isNil from 'lodash/isNil'
import { Store as __Store, applyMiddleware, combineReducers, compose, createStore, Observable } from 'redux'

import config from '@app/config'

import { AbortError } from '@app/errors/AbortError'

import { History } from '@app/utils/routing/types'

import { StoreDispatch } from './dispatch'
import * as reducers from './reducers'
import { StoreMiddlewares } from './reduxMiddleware'
import { StoreContext } from './reduxMiddleware/types'

export const RootReducer = combineReducers(reducers)

/** Application's State */
export type StoreState = ReturnType<typeof RootReducer>

/** Application's Store */
export type Store = Omit<__Store<StoreState>, 'dispatch'> & { dispatch: StoreDispatch; [Symbol.observable](): Observable<StoreState> }

export type ReducerWrapper = (reducer: typeof RootReducer) => typeof RootReducer

export default function createAppStore(context: StoreContext, initialState?: StoreState, history?: History, reducerWrapper?: ReducerWrapper): Store {
  const shouldUseDevTools =
    (process.env.NODE_ENV !== 'production' || config.isStaging) && typeof window === 'object' && (window as any).__REDUX_DEVTOOLS_EXTENSION__
  const composer = shouldUseDevTools ? composeWithDevTools({ maxAge: 100, latency: 1000, shouldHotReload: false }) : compose
  const enhancer = composer(applyMiddleware(...(StoreMiddlewares(context, history) as any)))

  // eslint-disable-next-line deprecation/deprecation
  return createStore(reducerWrapper ? reducerWrapper(RootReducer) : RootReducer, initialState, enhancer)
}

export type Selector<R, Props = undefined> = Props extends undefined ? (state: StoreState) => R : (state: StoreState, props: Props) => R
type SelectorArray<T extends readonly any[]> = { [K in keyof T]: Selector<T[K]> }

type StoreSubscription = ReturnType<Store['subscribe']>

/** Watches for values over store's state */
export const storeMonitoring = <T extends readonly any[] = []>(
  store: Store,
  selectors: SelectorArray<T>,
  callback: (values: T, prevValues: T) => void
): StoreSubscription => {
  const state = store.getState()
  let values: T = selectors.map(selector => selector(state)) as any

  return store.subscribe(() => {
    const prevValues = values
    const state = store.getState()
    const nextValues: any[] = []
    let changed = false

    for (let i = 0; i < selectors.length; i++) {
      const selector = selectors[i]
      nextValues[i] = selector(state)

      if (nextValues[i] !== prevValues[i]) {
        changed = true
      }
    }

    if (!changed) return

    values = nextValues as any as T
    callback(values, prevValues)
  })
}

/** Watches for values over store's state and unsibscribes upon first change */
export const storeMonitoringOnce = <T extends readonly any[] = []>(
  store: Store,
  selectors: SelectorArray<T>,
  callback: (values: T, prevValues: T) => void
): void => {
  const unsubscribe: any = storeMonitoring(store, selectors, (...args) => {
    callback(...args)
    unsubscribe()
  })
}

/**
 * Wait for selector to return truthy value and returns result
 * @throws `AbortError` on abort
 */
export async function waitForValue<T>(store: Store, condition: (state: StoreState) => T | null | undefined, abortSignal?: AbortSignal): Promise<T> {
  const state = store.getState()
  const val = condition(state)
  if (!isNil(val)) {
    return val
  }
  return await new Promise((resolve, reject) => {
    let unsub: (() => void) | null = null
    const abortHandler = () => {
      reject(new AbortError())
      cleanup()
    }

    const cleanup = () => {
      unsub?.()
      abortSignal?.removeEventListener('abort', abortHandler)
    }

    unsub = storeMonitoring(store, [condition], ([value], [prevValue]) => {
      if (value && !prevValue) {
        resolve(value)
        cleanup()
      }
    })

    abortSignal?.addEventListener('abort', abortHandler)
  })
}
