import { sleep } from './sleep'

export async function waitMax<T>(promise: Promise<T>, time: number) {
  const abortController = new AbortController()
  const result = await Promise.race([
    promise.then(y => {
      abortController.abort()
      return y
    }),
    sleep(time, abortController.signal).then(() => {
      throw new WaitMaxError('waitMax timed out')
    }),
  ])

  return result
}

export class WaitMaxError extends Error {}
