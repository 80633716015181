import { syncResponseTime, syncTime } from '@app/store/actions/misc.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  render?: number
  response?: number
}>({}, builder => {
  builder.addCase(syncTime.shape, (state, action) => ({ ...state, render: action.payload.date }))
  builder.addCase(syncResponseTime.shape, (state, action) => ({ ...state, response: action.payload.date }))
})
