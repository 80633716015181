import type { FirebaseAnnouncementResponse, FirebaseDirectAnnouncement, FirebaseRequest } from '@app/constants/ApiTypes/firebaseEntities'

import { createAction } from '@app/store/toolkit'

type FIREBASE_ENTITY<T> = {
  models: { [key: string]: T }
  added: Set<string>
  changed: Set<string>
  removed: Set<string>
}

export const firebaseBatch = createAction<
  'FIREBASE_BATCH',
  {
    requests: FIREBASE_ENTITY<FirebaseRequest>
    direct_announcements: FIREBASE_ENTITY<FirebaseDirectAnnouncement>
    announcement_responses: FIREBASE_ENTITY<FirebaseAnnouncementResponse>
  }
>('FIREBASE_BATCH')
