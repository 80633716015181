import { ApiIncomeCalculatorConfig } from '@app/constants/ApiTypes/internalEntities'

import { getIncomeCalculatorConfigsBySlugDescriptor } from '@app/store/actions/income_calculator_config.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<Record<string, ApiIncomeCalculatorConfig>>({}, builder => {
  builder.addCase(getIncomeCalculatorConfigsBySlugDescriptor.shapes.fulfilled, (state, action) => {
    return { ...state, [action.meta.slug]: action.payload.data }
  })
})
