import queryString from 'query-string'

import { IMPORT_MAP } from '@app/importMap'

import { SearchParams } from '@app/constants/StoreTypes'

import { AsyncRoute } from '@app/utils/routing/types'

// @routes declaration
export const routes: AsyncRoute[] = [
  {
    name: 'search',
    path: '/search',
    event_id: 'search',
    event_data: async location => {
      const ignoredKeys: (keyof SearchParams)[] = ['address']
      const { parseQuery } = await import('./utils')
      const params = parseQuery(queryString.parse(location.search || ''))
      return Object.keys(params).reduce<Record<string, any>>((c, key) => {
        if (!ignoredKeys.includes(key as any)) c[key] = params[key]
        return c
      }, {})
    },
    component: () => IMPORT_MAP.routes.Search.Search().then(m => m.Search),
  },
]
