import { ApiDepersonalizedAnnouncement } from '@app/constants/ApiTypes/entities'

import { normalize } from '@app/utils/normalizer'

import {
  getDepersonalizedAnnouncementsByQueryDescriptor,
  getDepersonalizedAnnouncementsSearchDescriptor,
} from '@app/store/actions/depersonalized_announcements.descriptors'
import { createReducer } from '@app/store/toolkit'

type State = { [key: string]: ApiDepersonalizedAnnouncement }

export default createReducer<State>({}, builder => {
  builder.addCases(
    [getDepersonalizedAnnouncementsSearchDescriptor.shapes.fulfilled, getDepersonalizedAnnouncementsByQueryDescriptor.shapes.fulfilled],
    (state, action) => {
      const { depersonalized_announcements } = normalize(action.payload)
      return { ...state, ...depersonalized_announcements }
    }
  )
})
