import { ApiContact } from '@app/constants/ApiTypes/entities'
import { ISO8601 } from '@app/constants/Misc'

import { excludeAbortError } from '@app/errors/AbortError'

import moment from '@app/utils/moment'
import { normalize } from '@app/utils/normalizer'

import { getContactsDescriptor } from '@app/store/actions/initial.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  state: {
    loaded: boolean
    loading: boolean
    loadedAt: ISO8601 | null
    error: Error | null
  }
  models: { [key: string]: ApiContact }
}>(
  {
    state: {
      loaded: false,
      loading: false,
      loadedAt: null,
      error: null,
    },
    models: {},
  },
  builder => {
    builder.addCase(getContactsDescriptor.shapes.pending, (state, _action) => {
      return { ...state, state: { ...state.state, loading: true } }
    })

    builder.addCase(getContactsDescriptor.shapes.fulfilled, (state, action) => {
      const { contacts } = normalize(action.payload)
      if (!contacts) return state

      return {
        ...state,
        state: { ...state.state, loaded: true, loading: false, loadedAt: state.state.loadedAt || moment().format(), error: null },
        models: { ...state.models, ...contacts },
      }
    })

    builder.addCase(getContactsDescriptor.shapes.rejected, (state, action) => {
      return { ...state, state: { ...state.state, loading: false, error: excludeAbortError(action.payload) } }
    })
  }
)
