import { ApiGiftCardType } from '@app/constants/ApiTypes/entities'

import { excludeAbortError } from '@app/errors/AbortError'

import { normalize } from '@app/utils/normalizer'

import { getGiftCardsByIdDescriptor, getGiftCardsTypesDescriptor } from '@app/store/actions/giftcard.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  models: { [key: string]: ApiGiftCardType }
  loaded: boolean
  pending: boolean
  error: Error | null
}>(
  {
    models: {},
    loaded: false,
    pending: false,
    error: null,
  },
  builder => {
    builder.addCase(getGiftCardsTypesDescriptor.shapes.pending, (state, _action) => {
      return { ...state, pending: true, loaded: false, error: null }
    })

    builder.addCases([getGiftCardsTypesDescriptor.shapes.fulfilled, getGiftCardsByIdDescriptor.shapes.fulfilled], (state, action) => {
      const { gift_card_types } = normalize(action.payload)
      if (!gift_card_types) return state

      return {
        ...state,
        pending: false,
        loaded: action.type === getGiftCardsTypesDescriptor.shapes.fulfilled.type,
        error: null,
        models: {
          ...state.models,
          ...gift_card_types,
        },
      }
    })

    builder.addCase(getGiftCardsTypesDescriptor.shapes.rejected, (state, action) => {
      return { ...state, pending: false, loaded: false, error: excludeAbortError(action.payload), models: {} }
    })
  }
)
