import { ApiTimeZone } from '@app/constants/ApiTypes/entities'

import { getLocationLatLonArrayKey } from '@app/utils/location'

import { getTimezonesByLocationDescriptor } from '@app/store/actions/timezones.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  [key: string]: ApiTimeZone
}>({}, builder => {
  builder.addCases([getTimezonesByLocationDescriptor.shapes.fulfilled], (state, action) => {
    return { ...state, [getLocationLatLonArrayKey(action.meta.location)]: action.payload.data }
  })
})
