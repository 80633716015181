import { ApiPersonnelCheckType } from '@app/constants/ApiTypes/entities'

import { getPersonnelCheckTypesDescriptor } from '@app/store/actions/personnel_check.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<ApiPersonnelCheckType[]>([], builder => {
  builder.addCase(getPersonnelCheckTypesDescriptor.shapes.fulfilled, (_state, action) => {
    return action.payload.data
  })
})
