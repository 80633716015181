import { ValidatedItem } from '@app/types/common'

import { ApiReferralPromocode } from '@app/constants/ApiTypes/entities'

import { isAbortError } from '@app/errors/AbortError'

import { getReferralPromocodeByCodeDescriptor } from '@app/store/actions/referrals.descriptors'
import { isApiError } from '@app/store/apiMiddleware/errors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<Record<string, ValidatedItem<ApiReferralPromocode, Error>>>({}, builder => {
  builder.addCase(getReferralPromocodeByCodeDescriptor.shapes.fulfilled, (state, action) => {
    return { ...state, [action.meta.code]: { type: 'valid', value: action.payload.data } }
  })

  builder.addCase(getReferralPromocodeByCodeDescriptor.shapes.rejected, (state, action) => {
    if (isAbortError(action.payload)) return state
    if (isApiError(action.payload) && action.payload.status === 404) {
      return { ...state, [action.meta.code]: { type: 'invalid' } }
    }
    return { ...state, [action.meta.code]: { type: 'error', error: action.payload } }
  })
})
