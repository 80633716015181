import React, { Component } from 'react'
import { connect } from 'react-redux'
import { v4 as uuid } from 'uuid'

import { LocalizedRequestError } from '@app/errors/LocalizedRequestError'

import { captureException } from '@app/utils/errorReport/errorReport'
import { createStructuredSelector } from '@app/utils/reselect'
import { RouteRenderer } from '@app/utils/routing/RouteRenderer'
import { RouteConfigComponentProps } from '@app/utils/routing/types'

import { sideMenuOpenedSelector } from '@app/store/selectors/ui'
import { StoreState } from '@app/store/store'

import MountContainer from './Mount/Mount'
import { Suspense } from './Suspense/Suspense'

const errorSelector = (state: StoreState) => state.error

const mapStateToProps = createStructuredSelector({
  menu: sideMenuOpenedSelector,
  error: errorSelector,
})

export type Props = ReturnType<typeof mapStateToProps> & RouteConfigComponentProps

interface State {
  /** holds error which occured in componentDidCatch */
  error: any
  errorUUID?: string
  info?: string
}

class Root extends Component<Props, State> {
  state: State = {
    error: null,
    errorUUID: undefined,
    info: '',
  }

  componentDidCatch(error, info: { componentStack: string }) {
    const errID = uuid()
    this.setState({ error, errorUUID: errID, info: info.componentStack })
    console.error(error, info)
    captureException(error, { silent: true, tags: { error_uuid: errID } })
  }

  render() {
    if (this.state.error) {
      return this.renderError(this.state.error, this.state.errorUUID, this.state.info)
    }

    const { code, message, localizedMessage } = this.props.error

    if (code && code >= 400) return this.renderError(LocalizedRequestError.create(message ?? '', code, localizedMessage ?? ''))

    return this.renderApplication()
  }

  private renderError(error?: any, errorID?: string, info?: string) {
    if (!IS_BROWSER) {
      const ErrorPage = require('./ErrorPage/ErrorPage').ErrorPage
      return <ErrorPage error={error} errorID={errorID} info={info} />
    }

    return (
      <Suspense fallback={null}>
        <ErrorPage error={error} errorID={errorID} info={info} />
      </Suspense>
    )
  }

  private renderApplication() {
    if (!this.props.route) return this.renderError()

    return (
      <MountContainer>
        <RouteRenderer routes={this.props.route.routes} />
      </MountContainer>
    )
  }
}

export default connect(mapStateToProps)(Root)

const ErrorPage = React.lazy(() => import('./ErrorPage/ErrorPage').then(m => ({ default: m.ErrorPage })))
