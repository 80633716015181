import { excludeAbortError } from '@app/errors/AbortError'

import {
  getDepersonalizedAnnouncementsSearchCountDescriptor,
  getDepersonalizedAnnouncementsSearchDescriptor,
} from '@app/store/actions/depersonalized_announcements.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  fetched: boolean
  loading: boolean
  value: number
  error: Error | null
}>(
  {
    fetched: false,
    loading: false,
    value: 0,
    error: null,
  },
  builder => {
    builder.addCase(getDepersonalizedAnnouncementsSearchCountDescriptor.shapes.pending, (state, _action) => {
      return { ...state, loading: true, error: null }
    })
    builder.addCase(getDepersonalizedAnnouncementsSearchCountDescriptor.shapes.fulfilled, (_state, action) => {
      return { fetched: true, loading: false, value: action.payload.meta.total, error: null }
    })
    builder.addCase(getDepersonalizedAnnouncementsSearchCountDescriptor.shapes.rejected, (state, action) => {
      return { ...state, loading: false, error: excludeAbortError(action.payload) }
    })
    builder.addCase(getDepersonalizedAnnouncementsSearchDescriptor.shapes.fulfilled, (state, action) => {
      return { ...state, value: action.payload.meta.total, fetched: true }
    })
  }
)
