import config from '@app/config'
import { AnalyticsEventType, AnalyticsUser } from '@app/types/analytics'

import { addBreadcrumb } from '@app/utils/errorReport/errorReport'
import { facebookTrackCustom } from '@app/utils/facebookPixel'
import ga from '@app/utils/ga'
import loggerCreator from '@app/utils/loggerCreator'
import { omit } from '@app/utils/omit'
import ym from '@app/utils/ym'

import { Amplitude } from './Amplitude'

if (IS_BROWSER && typeof config.gaTrackingCode === 'string') {
  ga.init(config.gaTrackingCode)
}

export function analyticsIdentify(user: AnalyticsUser | null) {
  currentUser = user
  Amplitude.shared.identify(user)
  ym('userParams', getUserData())
  ga('set', 'userId', user?.id || null)
}

export class AnalyticsPageviewEvent<T extends Record<string, any>> {
  constructor(
    public id: string,
    public url: string,
    public path: string,
    public data: T,
    public title: string
  ) {}

  sendYandex() {
    ym('hit', this.url, { title: this.title })
    return this
  }

  sendGoogle() {
    ga('send', 'pageview', { title: this.title, page: this.url })
    return this
  }

  sendAmplitude() {
    Amplitude.shared.logEvent(`pageview.${this.id}`, this.data)
    return this
  }

  sendDataLayer() {
    const layer = {
      event: 'custom_pageview',
      event_id: this.id,
      ...this.data,
      ...getUserData(),
    }

    if (IS_BROWSER && (window as any).dataLayer) {
      ;(window as any).dataLayer.push(layer)
    } else {
      dataLayerLog('pageview', layer)
    }

    return this
  }

  sendBreadcrumb() {
    addBreadcrumb({
      category: 'pageview',
      message: this.id,
      level: 'info',
      data: {
        url: this.url,
        path: this.path,
        ...this.data,
      },
    })
    return this
  }
}

export class AnalyticsEvent<T extends Record<string, any>> {
  constructor(
    public id: string,
    public data: T,
    public type: 'custom' | 'debug' = 'custom'
  ) {}

  public static create(event: AnalyticsEventType) {
    return new AnalyticsEvent(event.id, omit(event, 'id'))
  }

  /** Sends to inhouse analytics: Amplitude and sentry */
  sendInhouse() {
    return this.sendAmplitude().sendBreadcrumb()
  }

  /** Sends to inhouse analytics as well as google and yandex */
  sendGAYS() {
    return this.sendGoogle().sendYandex().sendAmplitude().sendBreadcrumb()
  }

  sendAmplitude() {
    Amplitude.shared.logEvent(`${this.type}.${this.id}`, this.data)
    return this
  }

  sendYandex() {
    ym('reachGoal', this.id, { ...getUserData(), ...this.data })
    return this
  }

  sendGoogle() {
    const data = { ...getUserData(), ...this.data }
    const layer = {
      event: 'custom_event',
      event_id: this.id,
      ...data,
    }
    if (IS_BROWSER && (window as any).dataLayer) {
      ;(window as any).dataLayer.push(layer)
    } else {
      dataLayerLog('event', layer)
    }
    return this
  }

  sendBreadcrumb() {
    addBreadcrumb({
      category: 'log',
      message: this.id,
      data: this.data,
      level: 'info',
    })
    return this
  }

  sendFacebook() {
    if (config.facebookPixelId) {
      facebookTrackCustom(config.facebookPixelId, this.id, Object.keys(this.data).length ? this.data : undefined)
    } else if (Object.keys(this.data).length) {
      facebookLog(this.id, this.data)
    } else {
      facebookLog(this.id)
    }
  }
}

const getUserData = () => {
  let data: any = {}
  if (currentUser) {
    data = {
      ...data,
      user_id: 'token' in currentUser ? currentUser.token : currentUser.id,
      city_id: currentUser && 'region_id' in currentUser ? currentUser.region_id : null,
      account_type: currentUser.account_type,
    }
    if (currentUser.account_type !== 'visitor') {
      data = {
        ...data,
        completed_requests_count: currentUser.completed_requests_count,
        confirmed_requests_count: currentUser.confirmed_requests_count,
      }
    }

    if (currentUser.account_type === 'parent') {
      data = {
        ...data,
        subscriptions_count: currentUser.subscriptions_count,
        payments_count: currentUser.payments_count,
        trial: currentUser?.subscription?.trial ? 1 : 0,
        hidden_search: currentUser.hidden_search,
      }
    }
  }

  return data
}

let currentUser: AnalyticsUser | null = null
const dataLayerLog = loggerCreator('dataLayer.push', 'lightpink', undefined, true)
const facebookLog = loggerCreator('facebookTrack', 'lightpink', undefined, true)
