export const errorWhiteList = [
  /^disconnected/,
  /^Can't connect to twilsock/,
  /^transition is invalid while previous transition/,
  /^Twilsock: request timeout/,
  /^Can't add command: Twilsock/,
  /^Loading chunk/,
  /^Request has been terminated/,
  /Firebase: Error \(auth\/timeout\)\./,
  /Firebase: Error \(auth\/network-request-failed\)/,
  /failed to fetch/i,
  /Network Error/i,
  /NetworkError/i,
  /Retrier attempt is already in progress/,
  /MISSING_TRANSLATION/i,
  /__ybHasBeenPasswordField/, // yandex browser on iPhone throws this
  /Write permission denied/,
  /Indexed Database/,
  /The operation is not supported/,
  /request.*timed out/i,
  /Failed to execute '.*?' on 'Node'/,
  /window\.webkit\.messageHandlers/,
  /Http response closed while proxying/i,
  /Malicious Path/i,
  /Cannot inject key into script value/i,
  /Messaging: This browser doesn't support the API's required to use the Firebase SDK/i,
]
