import { ThunkAction } from './thunk'

type ActionCreator<A extends { type: string; payload: any }> = {
  (payload: A['payload']): A
  action: A['type']
  shape: A // virtual field
}

type ActionPayloadlessCreator<A extends { type: string; payload: undefined }> = {
  (): A
  action: A['type']
  shape: A // virtual field
}

export function createShape<A extends { type: string }>(type: A['type']) {
  return { type } as A
}

export function createAction<S extends string, P extends any>(type: S) {
  const fn: ActionCreator<{ type: S; payload: P }> = ((payload: P) => ({
    type,
    payload,
  })) as any

  fn.action = type
  fn.shape = createShape<{ type: S; payload: P }>(type)
  return fn
}

export function createActionStub<A extends ActionCreator<any>>(action: A, stub: () => ThunkAction<any>) {
  const st = stub as any
  st.action = action.action
  st.shape = action.shape
  return st as A
}

export function createPayloadlessAction<S extends string>(type: S) {
  return createAction(type) as ActionPayloadlessCreator<{
    type: S
    payload: undefined
  }>
}

export function createReducer<S extends any>(initialState: S, creator: (builder: Builder<S>) => void) {
  const builder = new Builder(initialState)
  creator(builder)
  return builder.createReducer()
}

class Builder<S extends any> {
  state: S
  debug = false
  handlers: { [key: string]: (state: S, action: any) => S } = {}

  constructor(state: S) {
    this.state = state
  }

  private defaultHandler = (state: S) => state

  enableDebug() {
    this.debug = true
    return this
  }

  addCase<T extends { type: string }>(shape: T, cb: (state: S, action: T, defaultState: S) => S) {
    if (this.handlers[shape.type]) {
      throw new Error(`Handler for ${shape.type} arleady exists`)
    }
    this.handlers[shape.type] = (state, action) => {
      return cb(state, action, this.state)
    }
    return this
  }

  addCases<T extends { type: string }>(shapes: T[], cb: (state: S, action: T, defaultState: S) => S) {
    for (const shape of shapes) {
      this.addCase(shape, cb)
    }
    return this
  }

  createReducer() {
    return (state: S = this.state, action: any): S => {
      const handler = this.handlers[action.type] ?? this.defaultHandler
      if (this.debug && this.handlers[action.type]) {
        console.info('Action', action)
      }
      state = handler(state, action)
      return state
    }
  }
}
