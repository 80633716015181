import { FirebaseAnnouncementResponse, FirebaseDirectAnnouncement, FirebaseRequest } from '@app/constants/ApiTypes/firebaseEntities'

import { filterObject } from '@app/utils/filterObject'
import moment from '@app/utils/moment'

import { firebaseBatch } from '@app/store/actions/firebase.descriptors'
import { createReducer } from '@app/store/toolkit'
import { AnnouncementResponseOffer, DirectAnnouncementOffer, RequestOffer } from '@app/store/types/firebase'

type State = {
  requests: { [key: string]: RequestOffer }
  announcement_responses: { [key: string]: AnnouncementResponseOffer }
  direct_announcements: { [key: string]: DirectAnnouncementOffer }
}

export default createReducer<State>(
  {
    requests: {},
    announcement_responses: {},
    direct_announcements: {},
  },
  builder => {
    builder.addCase(firebaseBatch.shape, (state, action) => {
      /** requests */
      const reqs = Object.values(action.payload.requests.models).reduce<State['requests']>(requestTransformer, {})
      const reqsLen = Object.keys(reqs).length
      const reqRemoved = action.payload.requests.removed

      /** announcement responses */
      const ars = Object.values(action.payload.announcement_responses.models).reduce<State['announcement_responses']>(announcementResponsesTransformer, {})
      const arsLen = Object.keys(ars).length
      const arRemoved = action.payload.announcement_responses.removed

      /** direct announcements */
      const das = Object.values(action.payload.direct_announcements.models).reduce<State['direct_announcements']>(directAnnouncementsTransformer, {})
      const dasLen = Object.keys(das).length
      const daRemoved = action.payload.direct_announcements.removed

      if (!reqRemoved.size && !reqsLen && !arRemoved.size && !arsLen && !daRemoved.size && !dasLen) {
        return state
      }

      const newState: State = {
        requests: !reqsLen ? state.requests : { ...state.requests, ...reqs },
        announcement_responses: !arsLen ? state.announcement_responses : { ...state.announcement_responses, ...ars },
        direct_announcements: !dasLen ? state.direct_announcements : { ...state.direct_announcements, ...das },
      }

      if (reqRemoved.size) {
        newState.requests = filterObject(newState.requests, (_k, v) => !reqRemoved.has(v.id))
      }
      if (arRemoved.size) {
        newState.announcement_responses = filterObject(newState.announcement_responses, (_k, v) => !arRemoved.has(v.id))
      }
      if (daRemoved.size) {
        newState.direct_announcements = filterObject(newState.direct_announcements, (_k, v) => !daRemoved.has(v.id))
      }

      return newState
    })
  }
)

const requestTransformer = (c: State['requests'], e: FirebaseRequest): State['requests'] => {
  c[e.id] = {
    type: e.type,
    id: e.id,
    date: moment(e.attributes.date + ' ' + e.attributes.start_time, 'YYYY-MM-DD HH:mm').toDate(),
    attributes: e.attributes,
  }
  return c
}

const announcementResponsesTransformer = (c: State['announcement_responses'], e: FirebaseAnnouncementResponse): State['announcement_responses'] => {
  c[e.id] = {
    type: e.type,
    id: e.id,
    date: moment(e.attributes.announcement.date + ' ' + e.attributes.announcement.start_time, 'YYYY-MM-DD HH:mm').toDate(),
    attributes: e.attributes,
  }
  return c
}

const directAnnouncementsTransformer = (c: State['direct_announcements'], e: FirebaseDirectAnnouncement): State['direct_announcements'] => {
  c[e.id] = {
    type: e.type,
    id: e.id,
    date: moment(e.attributes.announcement.date + ' ' + e.attributes.announcement.start_time, 'YYYY-MM-DD HH:mm').toDate(),
    attributes: e.attributes,
  }
  return c
}
