import { excludeAbortError } from '@app/errors/AbortError'

import { createAnnouncementDescriptor, resetAnnouncementErrors } from '@app/store/actions/announcement.descriptors'
import { navigate } from '@app/store/actions/router.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  complete?: boolean
  error?: null | Error
  loading?: boolean
  announcement_id?: string
}>({}, builder => {
  builder.addCase(navigate.shape, (state, _action) => {
    if (state['complete']) return {}
    return state
  })
  builder.addCase(resetAnnouncementErrors.shape, (state, _action) => {
    return { ...state, error: null }
  })
  builder.addCase(createAnnouncementDescriptor.shapes.pending, (state, _action) => {
    return { ...state, loading: true, error: null }
  })
  builder.addCase(createAnnouncementDescriptor.shapes.fulfilled, (state, action) => {
    return { ...state, loading: false, complete: true, announcement_id: action.payload.data.id }
  })
  builder.addCase(createAnnouncementDescriptor.shapes.rejected, (state, action) => {
    return { ...state, errors: excludeAbortError(action.payload), loading: false }
  })
})
