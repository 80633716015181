import { getReviewsDescriptor } from '@app/store/actions/reviews.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{ loading: boolean; current_page: number; total: number; total_pages: number; per_page: number }>(
  {
    loading: false,
    current_page: 0,
    total: 0,
    total_pages: 0,
    per_page: 10,
  },
  builder => {
    builder.addCase(getReviewsDescriptor.shapes.pending, (state, _action) => {
      return { ...state, loading: true }
    })

    builder.addCase(getReviewsDescriptor.shapes.rejected, (state, _action) => {
      return { ...state, loading: false }
    })

    builder.addCase(getReviewsDescriptor.shapes.fulfilled, (state, action) => {
      return { ...state, ...action.payload.meta, per_page: action.meta.query.per_page, loading: false }
    })
  }
)
