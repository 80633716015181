import { ApiCurrencyRate } from '@app/constants/ApiTypes/entities'

import { normalize } from '@app/utils/normalizer'

import { getCurrencyRatesDescriptor } from '@app/store/actions/currencies.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<Record<string, ApiCurrencyRate>>({}, builder => {
  builder.addCase(getCurrencyRatesDescriptor.shapes.fulfilled, (state, action) => {
    const { currency_rates } = normalize(action.payload)
    if (!currency_rates) return state
    return { ...state, ...currency_rates }
  })
})
