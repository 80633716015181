import { captureException } from '@app/utils/errorReport/errorReport'

export class ErrorReportController {
  report(e: any) {
    const err = this.errorForCapture(e)
    if (err) captureException(err)
  }

  errorForCapture(e: any) {
    if (!e) return null
    if (typeof e !== 'object') return e
    if ('status' in e && (e.status === 404 || e.status === 429)) return null
    if (e instanceof Error && 'handled' in e && e.handled) return null
    if ('name' in e && e.name === 'BadRequestError') return null
    if (e instanceof Error) {
      return e
    } else if ('message' in e) {
      const err = new Error(e.message)
      err.cause = e
      return err
    }

    return e
  }
}
