import { ApiStatistics } from '@app/constants/ApiTypes/entities'

import { getStatisticsDescriptor } from '@app/store/actions/initial.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<ApiStatistics['attributes'] & { loaded: boolean }>(
  {
    academy_pupils: 0,
    active_sitters: 0,
    free_hours: '0',
    fulfilled_requests: 0,
    good_reviews: 0,
    good_reviews_percent: 0,
    subscribed_parents: 0,
    loaded: false,
    graduates: 0,
    schools: 0,
  },
  builder => {
    builder.addCase(getStatisticsDescriptor.shapes.fulfilled, (state, action) => {
      return { ...state, ...action.payload.data.attributes, loaded: true }
    })
  }
)
