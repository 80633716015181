import {
  setPushNotificationsPending,
  setPushNotificationsState,
  setPushNotificationsSubscribed,
  updatePushNotificationsBlocked,
} from '@app/store/actions/pushNotifications.descriptors'
import { createReducer } from '@app/store/toolkit'
import { PushNotificationsState } from '@app/store/types/push_notifications'

export default createReducer<PushNotificationsState>(
  {
    restored: false,
    subscribed: false,
    blocked: false,
    pending: false,
  },
  builder => {
    builder.addCase(setPushNotificationsState.shape, (state, action) => {
      return { ...state, ...action.payload }
    })

    builder.addCase(setPushNotificationsPending.shape, (state, action) => {
      return { ...state, pending: action.payload }
    })

    builder.addCase(setPushNotificationsSubscribed.shape, (state, action) => {
      return { ...state, pending: false, blocked: action.payload ? false : state.blocked, subscribed: !!action.payload }
    })

    builder.addCase(updatePushNotificationsBlocked.shape, (state, action) => {
      return { ...state, pending: false, blocked: !!action.payload }
    })
  }
)
