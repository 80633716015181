import { createSelector } from 'reselect'

import { IMPORT_MAP } from '@app/importMap'

import loggerCreator from '@app/utils/loggerCreator'
import moment from '@app/utils/moment'
import { waitBetween } from '@app/utils/waitBetween'
import { wrapError } from '@app/utils/wrapError'

import { getCurrencies, restoreCurrency } from '@app/store/actions/currencies'
import { getContacts, getFeatures, getRegions, restoreTestFlights } from '@app/store/actions/initial'
import { setConfig } from '@app/store/actions/misc'
import { getPlans, restorePlanModifier } from '@app/store/actions/plan'
import { fetchBoundIdentities, getIdentities, restorePlace } from '@app/store/actions/profile'
import { restoreBanners, restoreRelatedApps } from '@app/store/actions/ui'
import { getCurrentUser } from '@app/store/actions/user'
import { unwrapApiActionResultError } from '@app/store/apiMiddleware/utils'
import { profileUserSelector } from '@app/store/selectors/profile'
import { Store, storeMonitoring } from '@app/store/store'

import { BuildChecker } from './BuildChecker'

export class DataRefreshService {
  private logger = loggerCreator('DataRefresh', 'lightcoral', undefined, true)
  private started = false
  private stopHandlers: (() => unknown)[] = []
  private store: Store
  private timeBase = 1000 * 60 // 1 minute

  private buildChecker: BuildChecker
  private regionsUpdateInterval = this.timeBase * 60
  private profileUpdateInterval = this.timeBase
  private debtUpdateInterval = this.timeBase * 5
  private cardUpdateInterval = this.timeBase * 5
  private newsUpdateInterval = this.timeBase * 60
  private identitiesUpdateInterval = this.timeBase * 5
  private buildUpdateInterval = this.timeBase

  onPlaceRestore?: () => Promise<void>

  constructor(store: Store) {
    this.store = store
    this.buildChecker = new BuildChecker(this.store)
  }

  async initServer(): Promise<{ error: false } | { error: true; payload: Error }> {
    this.store.dispatch(restoreTestFlights())
    this.store.dispatch(restorePlanModifier())
    const planModifier = this.store.getState().plans.modifier

    const promises: Promise<any>[] = []

    if (!this.isPolygon()) {
      this.store.dispatch(restoreBanners())

      const regionsPromise = unwrapApiActionResultError(this.store.dispatch(getRegions()))
      regionsPromise.catch(() => {})
      const plansPromise = unwrapApiActionResultError(this.store.dispatch(getPlans(planModifier)))
      plansPromise.catch(() => {})
      const currenciesPromise = unwrapApiActionResultError(this.store.dispatch(getCurrencies()))
      currenciesPromise.catch(() => {})

      const profilePromise = unwrapApiActionResultError(this.store.dispatch(getCurrentUser()))
        .then(waitBetween(Promise.all([regionsPromise, plansPromise, currenciesPromise])))
        .then(profile => {
          const accType = profile?.data.attributes.account_type
          if (!accType || accType === 'visitor') return null
          return accType
        })
        .then(accType => this.runWhenProfileChanges(accType))
      profilePromise.catch(() => {})

      const contactsPromise = profilePromise.then(() => unwrapApiActionResultError(this.store.dispatch(getContacts())))
      contactsPromise.catch(() => {})

      promises.push(regionsPromise, plansPromise, currenciesPromise, profilePromise, contactsPromise)
    } else if (this.onPlaceRestore) {
      promises.push(this.onPlaceRestore?.())
    }

    try {
      await Promise.all(promises)
    } catch (e: any) {
      return { error: true, payload: e }
    }

    return { error: false }
  }

  async initBrowser() {
    this.logger('Init')

    if (this.isPolygon()) return

    await Promise.all([this.store.dispatch(restoreRelatedApps())])
  }

  start() {
    if (this.started) return
    this.started = true
    this.logger('Start')

    const isApp = this.store.getState().config.isApp

    if (!isApp) {
      this.buildChecker.start(this.buildUpdateInterval)
      const unsub = this.trackOnlineStatus()
      this.stopHandlers.push(unsub)
    }

    this.check()
    const interval = setInterval(() => {
      this.check()
    }, this.timeBase)
    this.stopHandlers.push(() => {
      clearInterval(interval)
    })

    const unsubProfile = storeMonitoring(this.store, [profileDataSelector], async ([newData], [oldData]) => {
      if (newData && oldData?.id !== newData.id) this.runWhenProfileChanges(newData.account_type)
    })
    this.stopHandlers.push(unsubProfile)
  }

  stop() {
    if (!this.started) return
    this.logger('Stop')
    this.buildChecker.stop()
    this.stopHandlers.forEach(h => h())
    this.stopHandlers = []
    this.started = false
  }

  private isPolygon() {
    return this.store.getState().config.isPolygon
  }

  private check() {
    this.logger('Check')
    this.checkRegions()
    const profilePromise = this.checkProfile()
    profilePromise
      .then(() => {
        this.checkDebts()
        this.checkCard()
        this.checkNews()
        this.checkIdentities()
      })
      .catch(e => {
        throw wrapError(new Error('Profile check failed'), e)
      })
  }

  private async checkRegions() {
    const updatedAt = this.store.getState().regions.state.loadedAt
    if (!this.isExpired(updatedAt, this.regionsUpdateInterval)) return
    this.logger('Check regions')
    await this.store.dispatch(getRegions())
  }

  private async checkProfile() {
    const state = this.store.getState()
    if (!state.profile.user) return
    const updatedAt = state.profile.meta.loadedAt
    if (!this.isExpired(updatedAt, this.profileUpdateInterval)) return
    this.logger('Check profile')
    await this.store.dispatch(getCurrentUser())
  }

  private async checkDebts(force = false) {
    if (!force) {
      const state = this.store.getState()
      if (state.profile.user?.account_type !== 'parent') return
      const fetchedAt = state.request.with_debt.fetchedAt
      if (!this.isExpired(fetchedAt, this.debtUpdateInterval)) return
    }
    this.logger('Check debts')
    const { getRequestsWithDebts } = await IMPORT_MAP.actions.request()
    await this.store.dispatch(getRequestsWithDebts(true))
  }

  private async checkCard() {
    const state = this.store.getState()
    if (state.profile.user?.account_type !== 'parent') return
    const fetchedAt = state.card.meta.loadedAt
    if (!this.isExpired(fetchedAt, this.cardUpdateInterval)) return
    this.logger('Check card')
    const { getCard } = await IMPORT_MAP.actions.payment()
    await this.store.dispatch(getCard(true))
  }

  private async checkNews() {
    const state = this.store.getState()
    if (!state.profile.user || state.profile.user.account_type === 'visitor') return

    const fetchedAt = state.news.fetchedAt
    if (!this.isExpired(fetchedAt, this.newsUpdateInterval)) return
    this.logger('Check news')
    const { fetchInitialNews } = await IMPORT_MAP.actions.news()
    this.store.dispatch(fetchInitialNews(true))
  }

  private async checkIdentities() {
    const state = this.store.getState()
    if (!state.profile.user || state.profile.user.account_type === 'visitor') return

    {
      const fetchedAt = state.profile.socialIdentities.fetched_at
      if (this.isExpired(fetchedAt, this.identitiesUpdateInterval)) {
        this.logger('Check identites')
        this.store.dispatch(getIdentities(true))
      }
    }
    {
      const fetchedAt = state.telegram_bot_connections.meta.loaded_at
      if (this.isExpired(fetchedAt, this.identitiesUpdateInterval)) {
        this.logger('Check telegram_bot_connection')
        const { getTelegramBotConnections } = await IMPORT_MAP.actions.telegram()
        this.store.dispatch(getTelegramBotConnections())
      }
    }
  }

  private isExpired(ts: string | null, interval: number) {
    return !ts || moment().diff(moment(ts), 'milliseconds') >= interval
  }

  private async runWhenProfileChanges(accountType: 'parent' | 'sitter' | null) {
    await this.store.dispatch(restorePlace())

    const promises: Promise<any>[] = []

    promises.push(unwrapApiActionResultError(this.store.dispatch(restoreCurrency())), unwrapApiActionResultError(this.store.dispatch(getFeatures())))

    if (accountType) {
      promises.push(
        IMPORT_MAP.actions.trusted().then(m => unwrapApiActionResultError(this.store.dispatch(m.fetchTrusted()))),
        IMPORT_MAP.actions.news().then(m => unwrapApiActionResultError(this.store.dispatch(m.fetchInitialNews())))
      )

      if (accountType === 'parent') {
        const { getCard } = await IMPORT_MAP.actions.payment()
        const { getRequestsWithDebts } = await IMPORT_MAP.actions.request()
        promises.push(
          unwrapApiActionResultError(this.store.dispatch(getCard(true))),
          unwrapApiActionResultError(this.store.dispatch(fetchBoundIdentities())),
          unwrapApiActionResultError(this.store.dispatch(getRequestsWithDebts()))
        )
      }
    }

    await Promise.all(promises).then(() => this.onPlaceRestore?.())
  }

  /** tracks online/offline status so we can show bar that warns when connection lost */
  private trackOnlineStatus() {
    const handler = () => {
      this.store.dispatch(setConfig({ online: window.navigator.onLine }))
    }
    window.addEventListener('online', handler)
    window.addEventListener('offline', handler)

    return () => {
      window.removeEventListener('online', handler)
      window.removeEventListener('offline', handler)
    }
  }
}

const profileDataSelector = createSelector([profileUserSelector], profile => {
  if (!profile) return null
  if (profile.account_type === 'visitor') return null
  return { id: profile.id, account_type: profile.account_type }
})
