import React, { FunctionComponent } from 'react'
import { defineMessages, useIntl } from 'react-intl'

import { NavLink } from '@app/utils/routing/NavLink'

import type { StoreParentProfile, StoreSitterProfile } from '@app/store/types/profile'

import { ControlClasses } from '@app/components/ControlClasses/ControlClasses'
import { Suspense } from '@app/components/Suspense/Suspense'

import './UserLabel.scss'

export interface UserLabelProps {
  className?: string
  user: StoreParentProfile | StoreSitterProfile
  to?: string
}

export const UserLabel: FunctionComponent<UserLabelProps> = ({ className, user, to }) => {
  const { formatMessage } = useIntl()

  const content = (
    <React.Fragment>
      <Suspense fallback={null}>
        <Avatar className="user-label-od67__avatar" name={user.name} src={user.avatars.at(0)?.image.thumb} />
      </Suspense>
      <span className="user-label-od67__label">{formatMessage(messages.label)}</span>
    </React.Fragment>
  )

  if (to)
    return (
      <NavLink className={cn(ControlClasses.m_no_appearance, 'user-label-od67', className)} eventName="layout.user_label.user_link" to={to}>
        {content}
      </NavLink>
    )

  return <span className={cn('user-label-od67', className)}>{content}</span>
}

const messages = defineMessages({
  label: 'Личный кабинет',
})

const Avatar = React.lazy(() => import('@app/components/Avatar/Avatar').then(m => ({ default: m.Avatar })))
