import type { ApiAnnouncementResponse, ApiIncluded } from '@app/constants/ApiTypes/entities'
import type {
  CreateDirectAnnouncementResponseRequest,
  PostAnnouncementResponsesRequest,
  PutAnnouncementResponsesRequest,
} from '@app/constants/ApiTypes/requests'

import { ApiActionBuilderDescriptor } from '@app/store/apiMiddleware/builderDescriptor'

export const createAnnouncementResponseDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('CREATE_ANNOUNCEMENT_RESPONE')
  .setShape<{ data: ApiAnnouncementResponse; included?: ApiIncluded }, PostAnnouncementResponsesRequest>()

export const createDirectAnnouncementResponseDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('CREATE_DIRECT_ANNOUNCEMENT_RESPONSE')
  .setShape<{ data: ApiAnnouncementResponse; included?: ApiIncluded }, { direct_announcement_id: string } & CreateDirectAnnouncementResponseRequest>()

export const cancelAnnouncementResponseDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('CANCEL_ANNOUNCEMENT_RESPONSE')
  .setShape<{ data: ApiAnnouncementResponse; included?: ApiIncluded }, { announcement_id: string }>()

export const updateAnnouncementResponseDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('UPDATE_ANNOUNCEMENT_RESPONSE')
  .setShape<{ data: ApiAnnouncementResponse }, { response_id: string } & PutAnnouncementResponsesRequest>()

export const declineAnnouncementResponseDescriptor = new ApiActionBuilderDescriptor().setLabel('DECLINE_ANNOUNCEMENT_RESPONSE').setShape<
  { data: ApiAnnouncementResponse },
  {
    announcement_id: string
    response_id: string
  }
>()

export const fetchAnnouncementResponseDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('FETCH_ANNOUNCEMENT_RESPONSE')
  .setShape<{ data: ApiAnnouncementResponse; included?: ApiIncluded }, { response_id: string }>()
