import { ComponentProps } from 'react'

import { createThunk, ThunkAction } from '@app/store/thunk'

import { iconsMap } from './icons'

export type Icon18 = keyof (typeof iconsMap)['18']
export type Icon32 = keyof (typeof iconsMap)['32']
export type Icon36 = keyof (typeof iconsMap)['36']
export type Icon108 = keyof (typeof iconsMap)['108']

export type IconProps = (
  | {
      size?: '18'
      icon: Icon18
    }
  | {
      size: '32'
      icon: Icon32
    }
  | {
      size: '36'
      icon: Icon36
    }
  | {
      size: '108'
      icon: Icon108
    }
) &
  ComponentProps<'span'>

interface IconLoader {
  (size: '18', name: Icon18): ThunkAction<Promise<string>>
  (size: '32', name: Icon32): ThunkAction<Promise<string>>
  (size: '36', name: Icon36): ThunkAction<Promise<string>>
  (size: '108', name: Icon108): ThunkAction<Promise<string>>
}

export const Icon: typeof import('./Icon.client').Icon = IS_BROWSER ? require('./Icon.client').Icon : require('./Icon.server').Icon
export const InlineIcon: typeof import('./Icon.client').InlineIcon = IS_BROWSER ? require('./Icon.client').InlineIcon : require('./Icon.server').InlineIcon
export const loadIcon: IconLoader = IS_BROWSER
  ? require('./Icon.client').loadIcon
  : (name, size) => createThunk(async dispatch => dispatch(require('./Icon.server').loadIcon(name, size)))
