import { excludeAbortError } from '@app/errors/AbortError'

import { userRequestActionDescriptor } from '@app/store/actions/user.descriptors'
import { createReducer } from '@app/store/toolkit'

import { getSitterSearchDescriptor } from '@app/routes/Search/actions.descriptors'

export interface UserMeta {
  loaded: boolean
  loading: boolean
  error: Error | null
}

const defaultMeta: UserMeta = {
  loaded: false,
  loading: false,
  error: null,
}

export default createReducer<{
  __default: UserMeta
  [key: string]: UserMeta
}>({ __default: defaultMeta }, builder => {
  builder.addCase(userRequestActionDescriptor.shapes.fulfilled, (state, action) => {
    const error: Error | null = null
    const meta: UserMeta = {
      ...(state[action.meta.token] || defaultMeta),
      loading: false,
      error,
    }
    return { ...state, [action.meta.token]: meta }
  })

  builder.addCase(userRequestActionDescriptor.shapes.pending, (state, action) => {
    const meta: UserMeta = {
      loaded: true,
      loading: true,
      error: null,
    }
    return { ...state, [action.meta.token]: meta }
  })

  builder.addCase(userRequestActionDescriptor.shapes.rejected, (state, action) => {
    const meta: UserMeta = {
      ...(state[action.meta.token] || defaultMeta),
      loading: false,
      error: excludeAbortError(action.payload),
    }
    return { ...state, [action.meta.token]: meta }
  })

  builder.addCase(getSitterSearchDescriptor.shapes.fulfilled, (state, action) => {
    const users = action.payload.data
    const patch = users.reduce<{ [key: string]: UserMeta }>((acc, u) => {
      acc[u.attributes.token] = { loaded: true, loading: false, error: null }
      return acc
    }, {})
    return { ...state, ...patch }
  })
})
