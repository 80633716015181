export class KeyboardNavigation {
  static shared = new KeyboardNavigation()

  timeout: ReturnType<typeof setTimeout> | undefined

  increment() {
    document.body.classList.add('is-tabbing')
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      document.body.classList.remove('is-tabbing')
    }, 5000)
  }
}
