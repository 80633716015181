export class IconsCache {
  cache: Map<string, string>

  constructor() {
    this.cache = new Map()
  }

  get(size: string, name: string) {
    return this.cache.get(`${size}:${name}`)
  }

  set(size: string, name: string, data: string) {
    this.cache.set(`${size}:${name}`, data)
  }

  upsert(size: string, name: string, data: () => string) {
    const result = this.cache.get(`${size}:${name}`)
    if (result) return result

    {
      const result = data()
      this.set(size, name, result)
      return result
    }
  }

  async upsertAsync(size: string, name: string, data: () => Promise<string>) {
    const result = this.cache.get(`${size}:${name}`)
    if (result) return result

    {
      const result = await data()
      this.set(size, name, result)
      return result
    }
  }

  restore(data: string) {
    this.cache = new Map(Object.entries(JSON.parse(data)))
    return this
  }

  serialize() {
    return JSON.stringify(Object.fromEntries(this.cache))
  }
}
