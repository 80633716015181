type Result<V> = { error: false; value: V } | { error: true; value: any }

export function intoResult<V>(val: () => V): Result<V> {
  try {
    return ok(val())
  } catch (e) {
    return err(e)
  }
}

export async function promiseIntoResult<V>(promise: Promise<V>): Promise<Result<V>> {
  try {
    const value = await promise
    return ok(value)
  } catch (e) {
    return err(e)
  }
}

export function ok<V>(value: V): { error: false; value: V } {
  return { error: false, value }
}

export function err(value: any): { error: true; value: any } {
  return { error: true, value }
}
