import { combineReducers } from 'redux'

import meta from './users.meta'
import meta_tags from './users.meta_tags'
import models from './users.models'
import perks from './users.perks'

export default combineReducers({
  meta,
  meta_tags,
  models,
  perks,
})
