import { PathMatch } from 'react-router'

import { Location, LocationDescriptorObject } from './types'

export function locationDescriptorObjectToLocation(obj: LocationDescriptorObject): Location {
  return {
    hash: obj.hash ?? '',
    pathname: obj.pathname ?? '',
    search: obj.search ?? '',
    state: obj.state,
    key: obj.key,
  }
}

export function getRootMatch(): PathMatch<never> {
  return {
    pattern: { path: '/', caseSensitive: false, end: true },
    pathnameBase: '/',
    params: {},
    pathname: '/',
  }
}
