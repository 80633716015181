import { IMPORT_MAP } from '@app/importMap'

import { AsyncRoute } from '@app/utils/routing/types'

const ParentAnnouncements = () => IMPORT_MAP.routes.Announcements.ParentAnnouncements().then(m => m.ParentAnnouncements)
const Announcement = () => IMPORT_MAP.routes.Announcements.Announcement().then(m => m.Announcement)
const NewAnnouncement = () => IMPORT_MAP.routes.Announcements.NewAnnouncement().then(m => m.default)
const EditAnnouncement = () => IMPORT_MAP.routes.Announcements.EditAnnouncement().then(m => m.EditAnnouncement)

// @routes declaration
const routes: AsyncRoute[] = [
  {
    path: '/announcements',
    event_id: 'parent_announcements',
    component: ParentAnnouncements,
  },
  {
    path: '/announcements/new',
    event_id: 'new_announcement',
    component: NewAnnouncement,
  },
  {
    path: '/announcements/:id/edit',
    event_id: 'edit_announcement',
    event_params: {
      announcement_id: 'id',
    },
    component: EditAnnouncement,
  },
  {
    path: '/announcements/:id',
    event_id: 'details_announcement',
    event_params: {
      announcement_id: 'id',
    },
    component: Announcement,
  },
]

export default routes
