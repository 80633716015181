type FilterFlags<Base, Condition> = {
  [Key in keyof Base]: Base[Key] extends Condition ? Key : never
}

type AllowedNames<Base, Condition> = FilterFlags<Base, Condition>[keyof Base]

/**
 * Creates a map out of array by given key
 *
 * @param array
 * @param key on which key to create a map
 */
export function arrayToObject<T extends object>(array: T[], key: AllowedNames<T, string | number>): { [key: string]: T } {
  return array.reduce((c, x) => {
    c[x[key] as any as string | number | symbol] = x
    return c
  }, {})
}
