import { excludeAbortError } from '@app/errors/AbortError'

import { beforeNavigate } from '@app/store/actions/router.descriptors'
import { getTrainingRequestDescriptor, postTrainingRequestDescriptor, putTrainingRequestByIdDescriptor } from '@app/store/actions/trainingRequest.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  loading: boolean
  loaded: boolean
  error: Error | null
}>(
  {
    loaded: false,
    loading: true,
    error: null,
  },
  builder => {
    builder.addCase(beforeNavigate.shape, (state, _action) => {
      if (state.loading) return state
      return { ...state, error: null }
    })

    builder.addCases(
      [getTrainingRequestDescriptor.shapes.pending, postTrainingRequestDescriptor.shapes.pending, putTrainingRequestByIdDescriptor.shapes.pending],
      (state, _action) => {
        return { ...state, loading: true, error: null }
      }
    )

    builder.addCases(
      [getTrainingRequestDescriptor.shapes.fulfilled, postTrainingRequestDescriptor.shapes.fulfilled, putTrainingRequestByIdDescriptor.shapes.fulfilled],
      (_state, _action) => {
        return { loading: false, loaded: true, error: null }
      }
    )

    builder.addCases(
      [getTrainingRequestDescriptor.shapes.rejected, postTrainingRequestDescriptor.shapes.rejected, putTrainingRequestByIdDescriptor.shapes.rejected],
      (state, action) => {
        return { ...state, loading: false, error: excludeAbortError(action.payload) }
      }
    )
  }
)
