import { ApiRequest } from '@app/constants/ApiTypes/entities'

import { arrayToObject } from '@app/utils/arrayToObject'
import { mergeV2Entity } from '@app/utils/mergeV2Entity'
import { modifyV2EntityAttributes } from '@app/utils/modifyV2EntityAttributes'

import {
  acceptRequestDescriptor,
  cancelRequestDescriptor,
  createRequestFromAnnouncementResponseDescriptor,
  declineRequestDescriptor,
  fetchRequestDescriptor,
  fetchRequestsDescriptor,
  getRequestsWithDebtsDescriptor,
  rejectRequestDescriptor,
} from '@app/store/actions/request.descriptors'
import { postRequestReviewsDescriptor } from '@app/store/actions/reviews.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  [key: string]: ApiRequest
}>({}, builder => {
  builder.addCases([getRequestsWithDebtsDescriptor.shapes.fulfilled, fetchRequestsDescriptor.shapes.fulfilled], (state, action) => {
    return { ...state, ...arrayToObject(action.payload.data, 'id') }
  })

  builder.addCase(fetchRequestDescriptor.shapes.fulfilled, (state, action) => {
    return { ...state, [action.payload.data.id]: action.payload.data }
  })

  builder.addCases(
    [
      createRequestFromAnnouncementResponseDescriptor.shapes.fulfilled,
      acceptRequestDescriptor.shapes.fulfilled,
      declineRequestDescriptor.shapes.fulfilled,
      cancelRequestDescriptor.shapes.fulfilled,
      rejectRequestDescriptor.shapes.fulfilled,
    ],
    (state, action) => {
      const request = action.payload
      const id = request.data.id
      return { ...state, [id]: mergeV2Entity(state[id], action.payload.data) }
    }
  )

  builder.addCase(postRequestReviewsDescriptor.shapes.fulfilled, (state, action) => {
    const { request_id, reviewer } = action.meta
    const request = state[request_id]
    if (!request) return state

    const attrs: Partial<ApiRequest['attributes']> = reviewer === 'parent' ? { has_parent_review: true } : { has_sitter_review: true }
    return { ...state, [request_id]: modifyV2EntityAttributes(request, attrs) }
  })
})
