import { ReactElement } from 'react'
import { defineMessages, IntlShape, MessageDescriptor } from 'react-intl'

import config from '@app/config'

import { SITTER_SCREENING_ENABLED } from '@app/constants/Misc'

import { LocalizedError } from '@app/errors/LocalizedError'

import { assertApiActionResponse, FetchDataObject } from '@app/utils/performFetchData'

import { getContacts } from '@app/store/actions/initial'

import academyImage from '@app/assets/sharing/academy.jpg'

export type NavItem = {
  type: 'item'
  link: string
  aliases?: string[]
  event_id: string
  label: MessageDescriptor
  headerInject?: () => Promise<ReactElement>
  footer?: boolean
  side: boolean
  meta: {
    title: string
    description?: string
    keywords?: string
    image?: string
  }
  data: () => Promise<string>
  /**
   * Inject items for basic strings interpolation.
   * For example if you add `{phone}` to html, and add `phone` key
   * with actual phone as value then `{phone}` placeholder will be replaced
   */
  inject?: (data: FetchDataObject) => Promise<Record<string, string>>
  show?: (accountType: 'parent' | 'sitter' | 'visitor' | null) => boolean
  /** whether to hide website wrapping layout */
  hideLayout?: boolean
}

export type NavLinkItem = {
  type: 'link'
  event_id: string
  link: string
  label: MessageDescriptor
}

export type NavSpacer = {
  type: 'spacer'
  id: string
}

export class NavItemsManager {
  static shared = new NavItemsManager()

  intl!: IntlShape

  get items() {
    const { formatMessage } = this.intl

    const data: (NavItem | NavLinkItem | NavSpacer)[] = [
      {
        type: 'item',
        link: '/privacy',
        event_id: 'privacy',
        label: messages.privacy,
        footer: true,
        side: true,
        meta: {
          title: formatMessage(messages.privacy_title),
          description: formatMessage(messages.privacy_description),
          keywords: formatMessage(messages.privacy_keywords),
        },
        data: () => import(/* webpackChunkName: "static_privacy" */ '@app/content/privacy.html').then(m => m.default),
      },
      {
        type: 'item',
        link: '/privacy-policy',
        aliases: ['/privacy_policy'],
        event_id: 'privacy_policy',
        label: messages.privacy_policy,
        footer: true,
        side: true,
        meta: {
          title: formatMessage(messages.privacy_policy_title),
          description: formatMessage(messages.privacy_policy_description),
          keywords: formatMessage(messages.privacy_policy_keywords),
        },
        data: () => import(/* webpackChunkName: "static_privacy_policy" */ '@app/content/privacy_policy.html').then(m => m.default),
      },
      {
        type: 'item',
        link: '/licence',
        event_id: 'licence',
        label: messages.licence,
        footer: true,
        side: true,
        meta: {
          title: formatMessage(messages.licence_title),
          description: formatMessage(messages.licence_description),
          keywords: formatMessage(messages.licence_keywords),
        },
        data: () => import(/* webpackChunkName: "static_licence" */ '@app/content/licence.html').then(m => m.default),
      },
      {
        type: 'item',
        link: '/licence-parent',
        aliases: ['/licence_parent'],
        event_id: 'licence_parent',
        label: messages.licence,
        footer: false,
        side: false,
        meta: {
          title: formatMessage(messages.licence_parent_title),
          description: formatMessage(messages.licence_parent_description),
          keywords: formatMessage(messages.licence_parent_keywords),
        },
        data: () => import(/* webpackChunkName: "static_licence" */ '@app/content/licence_parent.html').then(m => m.default),
      },
      {
        type: 'item',
        link: '/licence-sitter',
        aliases: ['/licence_sitter'],
        event_id: 'licence_sitter',
        label: messages.licence,
        footer: false,
        side: false,
        meta: {
          title: formatMessage(messages.licence_sitter_title),
          description: formatMessage(messages.licence_sitter_description),
          keywords: formatMessage(messages.licence_sitter_keywords),
        },
        data: () => import(/* webpackChunkName: "static_licence" */ '@app/content/licence_sitter.html').then(m => m.default),
      },
      {
        type: 'item',
        link: '/academy-offer',
        aliases: ['/academy_offer'],
        event_id: 'academy_offer',
        label: messages.academy_offer,
        footer: true,
        side: true,
        meta: {
          title: formatMessage(messages.academy_offer_title),
          description: formatMessage(messages.academy_offer_description),
          keywords: formatMessage(messages.academy_offer_keywords),
        },
        inject: async ({ store }) => {
          const formatPromise = import('@app/utils/phoneTools/format')
          const contactsPromise = store.dispatch(getContacts()).then(assertApiActionResponse('Contacts fetch failed'))
          await Promise.all([formatPromise, contactsPromise])
          const { format } = await formatPromise
          const academy = Object.values(store.getState().contacts.models).find(department => department.attributes.department === 'academy')?.attributes
          if (!academy) throw LocalizedError.create("Can't find academy data", formatMessage(messages.academy_data_not_found))
          return {
            url: `${config.webUrl}/academy`,
            mail: academy.email ?? '',
            phone: `<a href="tel:${academy.phone}">${format(academy.phone!)}</a>`,
          }
        },
        data: () => import(/* webpackChunkName: "static_academy_offer" */ '@app/content/academy_offer.html').then(m => m.default),
      },
      {
        type: 'item',
        link: '/school-offer',
        aliases: ['/school_offer'],
        event_id: 'school_offer',
        label: messages.school_offer,
        footer: true,
        side: true,
        meta: {
          title: formatMessage(messages.school_offer_title),
          description: formatMessage(messages.school_offer_description),
          keywords: formatMessage(messages.school_offer_keywords),
        },
        data: () => import(/* webpackChunkName: "static_school_offer" */ '@app/content/school_offer.html').then(m => m.default),
      },
      ...(SITTER_SCREENING_ENABLED
        ? [
            {
              type: 'link' as const,
              event_id: 'sitter_screening',
              link: '/sitter-screening',
              label: messages.sitter_screening,
            },
          ]
        : []),
      {
        type: 'link',
        event_id: 'service_reviews',
        link: '/service-reviews',
        label: messages.reviews_on_service,
      },
      {
        type: 'item',
        link: '/memo',
        event_id: 'academy_memo',
        label: messages.academy_memo,
        footer: false,
        side: false,
        meta: {
          title: formatMessage(messages.academy_memo_title),
          description: formatMessage(messages.academy_memo_description),
          keywords: formatMessage(messages.academy_memo_keywords),
          image: academyImage,
        },
        data: () => import(/* webpackChunkName: "static_academy_memo" */ '@app/content/academy_memo.html').then(m => m.default),
        inject: async ({ store }) => {
          const formatPromise = import('@app/utils/phoneTools/format')
          const contactsPromise = store.dispatch(getContacts()).then(assertApiActionResponse('Contacts fetch failed'))
          await Promise.all([formatPromise, contactsPromise])
          const { format } = await formatPromise
          await store.dispatch(getContacts()).then(assertApiActionResponse('Contacts fetch failed'))
          const academy = Object.values(store.getState().contacts.models).find(department => department.attributes.department === 'academy')?.attributes
          if (!academy) throw LocalizedError.create("Can't find academy data", formatMessage(messages.academy_data_not_found))
          return {
            url: `${config.webUrl}/academy`,
            mail: `<a href="mailto:${academy.email}">${academy.email}</a>`,
            phone: `<a href="tel:${academy.phone}">${format(academy.phone!)}</a>`,
          }
        },
        headerInject: () =>
          import(/* webpackChunkName: "static_academy_memo" */ '@app/routes/Academy/HeaderCartButton').then(m => m.getInjectableHeaderButton()),
      },

      { type: 'spacer', id: 'spacer-sitter-all' },
      {
        type: 'item',
        link: '/sitter-rules',
        aliases: ['/sitter_rules'],
        event_id: 'sitter_rules',
        label: messages.sitter_public_rules,
        side: true,
        meta: {
          title: formatMessage(messages.sitter_rules_title),
          description: formatMessage(messages.sitter_rules_description),
          keywords: formatMessage(messages.sitter_rules_keywords),
        },
        data: () => import(/* webpackChunkName: "static_sitter_public_rules" */ '@app/content/sitter_public_rules.html').then(m => m.default),
      },

      { type: 'spacer', id: 'spacer-sitter-all-2' },
      {
        type: 'item',
        link: '/sitter-profile',
        aliases: ['/sitter_profile'],
        event_id: 'sitter_profile',
        label: messages.sitter_profile,
        footer: false,
        side: false,
        meta: {
          title: formatMessage(messages.sitter_profile_title),
        },
        data: () => import(/* webpackChunkName: "static_sitter_profile" */ '@app/content/sitter_profile.html').then(m => m.default),
        show: accType => accType === 'sitter',
      },
      {
        type: 'item',
        link: '/rules',
        event_id: 'rules',
        label: messages.sitter_rules,
        footer: false,
        side: false,
        meta: {
          title: formatMessage(messages.rules_title),
        },
        data: () => import(/* webpackChunkName: "static_sitter_rules" */ '@app/content/sitter_rules.html').then(m => m.default),
        show: accType => accType === 'sitter',
      },
    ]

    Object.defineProperty(this, 'items', {
      value: data,
      writable: false,
      configurable: false,
      enumerable: false,
    })

    return data
  }
}

const messages = defineMessages({
  academy_offer: 'Оферта на\u00a0онлайн\u2011занятия',
  academy_memo: 'Памятка для онлайн\u2011занятий Kidsout',
  privacy: 'Согласие на\u00a0обработку персональных данных',
  privacy_policy: 'Политика в\u00a0отношении обработки персональных данных',
  licence: 'Соглашение об\u00a0условиях использования сервиса Кidsout',
  sitter_public_rules: 'Правила присмотра',
  sitter_rules: 'Правила взаимодействия',
  sitter_profile: 'Правила заполнения анкеты Kidsout',
  school_offer: 'Оферта для\u00a0обучающихся в\u00a0Школе Бебиситтеров Kidsout',

  privacy_title: 'Согласие на обработку персональных данных',
  privacy_description: 'На этой странице можно ознакомиться с согласием на обработку персональных данных.',
  privacy_keywords: 'Правила обработки персональных данных Kidsout',

  privacy_policy_title: 'Политика в\u00a0отношении обработки персональных данных',
  privacy_policy_description: 'На этой странице можно ознакомиться с политикой в\u00a0отношении обработки персональных данных.',
  privacy_policy_keywords: 'Обработка персональных данных, персональный данные',

  licence_title: 'Соглашение об условиях использования сервиса Кidsout',
  licence_description: 'На этой странице можно ознакомиться с соглашением об условиях использования сервиса Кidsout.',
  licence_keywords: 'Оферта на использование базы данных Kidsout',

  licence_parent_title: 'Соглашение об условиях использования сервиса Кidsout для родителя',
  licence_parent_description: 'На этой странице можно ознакомиться с соглашением об условиях использования сервиса Кidsout для родителя.',
  licence_parent_keywords: 'Соглашение об условиях использования сервиса Кidsout для родителя',

  licence_sitter_title: 'Соглашение об условиях использования сервиса Кidsout для бебиситтера',
  licence_sitter_description: 'На этой странице можно ознакомиться с соглашением об условиях использования сервиса Кidsout для бебиситтера.',
  licence_sitter_keywords: 'Соглашение об условиях использования сервиса Кidsout для бебиситтера',

  academy_offer_title: 'Оферта на онлайн-занятия Kidsout',
  academy_offer_description: 'На этой странице можно ознакомиться с офертой на онлайн-занятия Kidsout.',
  academy_offer_keywords: 'Оферта на онлайн-занятия Kidsout',

  school_offer_title: 'Оферта для обучающихся в Школе Бебиситтеров Kidsout',
  school_offer_description: 'На этой странице можно ознакомиться с офертой для обучающихся в Школе Бебиситтеров Kidsout',
  school_offer_keywords: 'Школа Kidsout, обучение в школе',

  academy_memo_title: 'Памятка для онлайн-занятий Kidsout',
  academy_memo_description: 'На этой странице можно ознакомиться с памяткой на онлайн-занятия Kidsout.',
  academy_memo_keywords: 'Памятка для онлайн-занятий Kidsout',
  academy_data_not_found: 'Не удалось найти данные академии',

  sitter_rules_title: 'Правила присмотра Kidsout',
  sitter_rules_description: 'На этой странице можно ознакомиться с правилами присмотра Kidsout.',
  sitter_rules_keywords: 'Правила присмотра, правила присмотра Kidsout',

  sitter_profile_title: 'Правила заполнения анкеты Kidsout',

  rules_title: 'Правила взаимодействия',

  sitter_screening: 'Проверка ситтера',
  reviews_on_service: 'Отзывы о сервисе',
})
