import React, { ReactNode, useCallback } from 'react'

import { ScrollableItems, ScrollableItemsGetScrollButtonCallback } from '@app/components/ScrollableItems/ScrollableItems'

export type ScrollableMenuProps<T> = {
  id: symbol
  items: T[]
  renderItem: (item: T) => ReactNode
  className?: string
  containerClassName?: string
  getScrollButton: ScrollableItemsGetScrollButtonCallback
  shouldResetScroll?: boolean
}

export const ScrollableMenu = <T extends any = unknown>({
  id,
  items,
  renderItem,
  className,
  containerClassName,
  getScrollButton,
  shouldResetScroll,
}: ScrollableMenuProps<T>) => {
  const handleScroll = useCallback(
    (el: HTMLDivElement) => {
      state[id] = el.scrollLeft
    },
    [id]
  )

  if (shouldResetScroll) state[id] = 0

  return (
    <ScrollableItems
      className={className}
      containerClassName={containerClassName}
      getScrollButton={getScrollButton}
      initialOffset={state[id] ?? 0}
      onScroll={handleScroll}
    >
      {items.map(renderItem)}
    </ScrollableItems>
  )
}

const state: Record<symbol, number> = {}
