import React, { ComponentProps, memo } from 'react'

import './svg.scss'

export type Props = {
  label?: string
  /** raw svg content */
  content: string
} & ComponentProps<'span'>

/**
 * Component for inserting inline svgs,
 * should be used in conjunction with `require("svgpath.svg?force_raw")`
 */
export const SVG = memo<Props>(function SVG({ label, content, className, ...props }) {
  return <span {...props} className={cn('svg-icon', label ? `svg-icon--${label}` : null, className)} dangerouslySetInnerHTML={{ __html: content }} />
})
