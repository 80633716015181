import { excludeAbortError } from '@app/errors/AbortError'

import { fetchTrustedSittersDescriptor, fetchTrustingParentsDescriptor } from '@app/store/actions/trusted.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  sittersLoaded: boolean
  sittersLoading: boolean
  sittersError: Error | null
  parentsLoaded: boolean
  parentsLoading: boolean
  parentsError: Error | null
}>(
  {
    sittersLoaded: false,
    sittersLoading: false,
    sittersError: null,
    parentsLoaded: false,
    parentsLoading: false,
    parentsError: null,
  },
  builder => {
    builder.addCase(fetchTrustedSittersDescriptor.shapes.pending, (state, _action) => {
      return { ...state, sittersLoading: true, sittersError: null }
    })

    builder.addCase(fetchTrustedSittersDescriptor.shapes.fulfilled, (state, _action) => {
      return { ...state, sittersLoaded: true, sittersLoading: false, sittersError: null }
    })

    builder.addCase(fetchTrustedSittersDescriptor.shapes.rejected, (state, action) => {
      return { ...state, sittersLoading: false, sittersError: excludeAbortError(action.payload) }
    })

    builder.addCase(fetchTrustingParentsDescriptor.shapes.pending, (state, _action) => {
      return { ...state, parentsLoading: true, parentsError: null }
    })

    builder.addCase(fetchTrustingParentsDescriptor.shapes.fulfilled, (state, _action) => {
      return { ...state, parentsLoaded: true, parentsLoading: false, parentsError: null }
    })

    builder.addCase(fetchTrustingParentsDescriptor.shapes.rejected, (state, action) => {
      return { ...state, parentsLoading: false, parentsError: excludeAbortError(action.payload) }
    })
  }
)
