import React, { FunctionComponent, useCallback } from 'react'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'

import { AUDIT_PROVIDER, EXTERNAL_LINKS_REL, FOUNDING_YEAR, NOW } from '@app/constants/Misc'

import { defineFormatters } from '@app/utils/reactIntl'
import { Link } from '@app/utils/routing/Link'
import { NavLink } from '@app/utils/routing/NavLink'

import { NavItemsManager } from '@app/routes/Static/staticMenu'

import { AppStoreIcons } from '@app/components/AppStoreIcons/AppStoreIcons'
import ContactPhone from '@app/components/ContactPhone'
import { ControlClasses } from '@app/components/ControlClasses/ControlClasses'
import { Icon, InlineIcon } from '@app/components/Icon/Icon'
import { SocialButtons } from '@app/components/SocialButtons/SocialButtons'
import { Typography } from '@app/components/Typography/Typography'

import { LayoutMilk } from './LayoutMilk'
import { NavItemVisibility, NavMenuItem } from './navItemsUtils'

import classes from './Footer.module.scss'

export const Footer: FunctionComponent<{ navItems: NavMenuItem[] }> = ({ navItems }) => {
  const { formatMessage } = useIntl()

  const renderAudit = useCallback(
    (className?: string) => (
      <div className={cn(classes.audit, className)}>
        <div className={cn(Typography.DecorateText1, classes.audit_label)}>{formatMessage(messages.audit_by)}</div>
        <div>
          <a href={AUDIT_PROVIDER.url} rel={EXTERNAL_LINKS_REL} target="_blank" title={AUDIT_PROVIDER.name}>
            <img
              alt={AUDIT_PROVIDER.name}
              className={classes.audit_image}
              height={AUDIT_PROVIDER.height}
              src={AUDIT_PROVIDER.image}
              width={AUDIT_PROVIDER.width}
            />
          </a>
        </div>
      </div>
    ),
    [formatMessage]
  )

  return (
    <footer className={classes.root}>
      <div>
        <div className={LayoutMilk.classes.sectionWrapper}>
          <div className={classes.cols}>
            <div className={classes.cols_item}>
              <Link className={classes.logo} eventName="layout.footer.logo_link" region={true} to="/">
                <Icon icon="logo-outlined" />
              </Link>

              <div className={cn(ControlClasses.hide({ tablet: true, desktop: true }), classes.app_icons, classes.app_icons_mobile)}>
                <h3 className={classes.app_icons_title}>{formatMessage(messages.app_download)}</h3>
                <AppStoreIcons />
              </div>

              {renderAudit(ControlClasses.hide({ mobile: true, tablet: true }))}
            </div>

            <div className={cn(classes.cols_item, classes.cols_item_nav)}>
              <nav className={classes.nav}>
                {navItems.map(item => {
                  if (item.type === 'expander' || item.type === 'spacer') return <div className={classes.nav_spacer} key={item.id} />

                  if (item.type !== 'item' || item.subtype) return null
                  if (!((item.visibility ?? NavItemVisibility.All) & NavItemVisibility.Footer)) return null

                  return (
                    <div className={classes.nav_item} key={item.id}>
                      <div data-id={item.id}>
                        <NavLink eventName={`layout.footer.${item.id}_link`} region={item.region} target={item.external ? '_blank' : undefined} to={item.url}>
                          {item.icon && <InlineIcon className={classes.nav_item_icon} icon={item.icon} />}
                          <span className={classes.nav_item_title}>{item.title}</span>
                        </NavLink>
                      </div>
                      {item.children && item.id === 'babysitter' && (
                        <div>
                          {item.children.map(item => {
                            if (item.type !== 'item') return null
                            return (
                              <div className={classes.nav_item_secondary_item} key={item.id}>
                                {item.url && (item.wrapInLink ?? true) ? (
                                  <NavLink eventName={`layout.footer.${item.id}_link`} onClick={item.onClick} region={item.region} to={item.url}>
                                    {item.title}
                                  </NavLink>
                                ) : (
                                  item.title
                                )}
                              </div>
                            )
                          })}
                        </div>
                      )}
                    </div>
                  )
                })}
              </nav>
            </div>

            <div className={cn(classes.cols_item, classes.cols_item_contacts)}>
              <ContactPhone className={classes.contacts} officeHourseClassName={classes.contacts_office_hours} phoneClassName={classes.contacts_phone} />

              <div>
                <SocialButtons className={classes.soc_buttons} />
              </div>
            </div>

            <div className={classes.cols_item}>
              <nav className={classes.second_nav}>
                {NavItemsManager.shared.items.map(item =>
                  (item.type === 'item' && item.footer) || item.type === 'link' ? (
                    <NavLink className={classes.second_nav_item} eventName={`layout.footer.${item.event_id}_link`} key={item.event_id} to={item.link}>
                      <FormattedMessage {...item.label} />
                    </NavLink>
                  ) : null
                )}
              </nav>

              <div className={cn(ControlClasses.hide({ mobile: true }), classes.app_icons)}>
                <h3 className={classes.app_icons_title}>{formatMessage(messages.app_download)}</h3>
                <AppStoreIcons />
              </div>

              {renderAudit(ControlClasses.hide({ desktop: true }))}
            </div>
          </div>

          <div className={classes.foot}>
            <div className={classes.copy}>
              {`©\u00a0${FOUNDING_YEAR}–${NOW.getFullYear()}, Kidsout`}
              <br />
              <FormattedMessage {...messages.credits} values={formatters.credits} />
            </div>
            {buildString && <div className={classes.build_sha}>build: {buildString}</div>}
          </div>
        </div>
      </div>
    </footer>
  )
}

const messages = defineMessages({
  credits: 'Мы используем <twemoji>Twemoji</twemoji> и\u00a0<maxmind>MaxMind</maxmind>',
  audit_by: 'Проверено',
  app_download: 'Скачайте Приложение Kidsout',
})

const formatters = {
  credits: defineFormatters({
    twemoji: msg => (
      <a href="https://twemoji.twitter.com/" rel={EXTERNAL_LINKS_REL} target="_blank">
        {msg}
      </a>
    ),
    maxmind: msg => (
      <a href="https://www.maxmind.com" rel={EXTERNAL_LINKS_REL} target="_blank">
        {msg}
      </a>
    ),
  }),
}

const buildString = (() => {
  let buildString = ''

  if (process.env.GIT_SHA) {
    buildString += ' ' + process.env.GIT_SHA.substring(0, 8)
  }
  if (process.env.GIT_BRANCH) {
    buildString += ' ' + process.env.GIT_BRANCH
  }

  return buildString
})()
