import { IMPORT_MAP } from '@app/importMap'

import { AsyncRoute } from '@app/utils/routing/types'

// @routes declaration
const routes: AsyncRoute[] = [
  {
    path: '/affiliate',
    event_id: 'affiliate',
    component: () => IMPORT_MAP.routes.Affiliate.Affiliate().then(m => m.Affiliate),
  },
]

export default routes
