import type { ApiIncluded, ApiParentProfile, ApiSitterProfile, ApiUserProfile } from '@app/constants/ApiTypes/entities'
import { SessionData } from '@app/constants/StoreTypes'

import { ApiActionBuilderDescriptor } from '@app/store/apiMiddleware/builderDescriptor'
import { createAction, createPayloadlessAction } from '@app/store/toolkit'

export const setSessionAction = createAction<'SET_SESSION', SessionData>('SET_SESSION')
export const resetSessionErrors = createPayloadlessAction('SESSION_ERROR_RESET')

export const sessionDestroyActionDescriptor = new ApiActionBuilderDescriptor().setLabel('SESSION_DESTROY').setShape()

export const socialLoginActionDescriptor = new ApiActionBuilderDescriptor().setLabel('SOCIAL_LOGIN').setShape<{
  data: (ApiParentProfile | ApiSitterProfile) & {
    meta: { access_token: string }
  }
  included?: ApiIncluded
}>()
export const userDestroyActionDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('USER_DESTROY')
  .setShape<{ data: ApiUserProfile; included?: ApiIncluded }>()
export const setShortSession = createAction<'SET_SHORT_SESSION', string>('SET_SHORT_SESSION')
