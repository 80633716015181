import { ApiCandidateSurvey } from '@app/constants/ApiTypes/entities'

import { getCandidateSurveyDescriptor } from '@app/store/actions/candidate_surveys.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  data: ApiCandidateSurvey | null
  loaded: boolean
  loading: boolean
}>({ data: null, loaded: false, loading: false }, builder => {
  builder.addCase(getCandidateSurveyDescriptor.shapes.fulfilled, (state, action) => {
    return { ...state, data: action.payload.data, loaded: true, loading: false }
  })
  builder.addCase(getCandidateSurveyDescriptor.shapes.pending, state => {
    return { ...state, loading: false }
  })
  builder.addCase(getCandidateSurveyDescriptor.shapes.rejected, state => {
    return { ...state, loading: false }
  })
})
