import React, { FunctionComponent } from 'react'
import { defineMessages, FormattedMessage } from 'react-intl'

import { SUPPORT_OFFICE_HOURS } from '@app/constants/Misc'

const messages = defineMessages({
  hours: 'Ежедневно с {start} до {end}',
})

interface Props {
  className?: string
  phoneClassName?: string
  officeHourseClassName?: string
}

const ContactPhone: FunctionComponent<Props> = ({ className = 'contact-phone', phoneClassName, officeHourseClassName }) => {
  return (
    <div className={className}>
      <a className={phoneClassName} href="tel:88007078097">
        8 (800) 707-80-97
      </a>
      <div className={officeHourseClassName}>
        <FormattedMessage {...messages.hours} values={SUPPORT_OFFICE_HOURS} />
      </div>
    </div>
  )
}

export default ContactPhone
