import { ApiRequest } from '@app/constants/ApiTypes/entities'
import { ISO8601 } from '@app/constants/Misc'

import moment from '@app/utils/moment'

import { getRequestsWithDebtsDescriptor } from '@app/store/actions/request.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  fetched: boolean
  fetchedAt: ISO8601 | null
  loading: boolean
  requests: { id: string; amount: ApiRequest['attributes']['amount'] }[]
}>({ fetched: false, fetchedAt: null, loading: false, requests: [] }, builder => {
  builder.addCase(getRequestsWithDebtsDescriptor.shapes.pending, (state, _action) => {
    return { ...state, loading: true }
  })

  builder.addCase(getRequestsWithDebtsDescriptor.shapes.rejected, (state, _action) => {
    return { ...state, loading: false }
  })

  builder.addCase(getRequestsWithDebtsDescriptor.shapes.fulfilled, (_state, action) => {
    return {
      fetched: true,
      fetchedAt: moment().format(),
      loading: false,
      requests: action.payload.data.map(req => ({ id: req.id, amount: req.attributes.amount })),
    }
  })
})
