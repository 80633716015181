import { combineReducers } from 'redux'

import mapResults from './search.map_results'
import mapResultsArea from './search.map_results_area'
import mapResultsMeta from './search.map_results_meta'
import meta from './search.meta'
import params from './search.params'
import results from './search.results'

export default combineReducers({ params, meta, results, mapResults, mapResultsArea, mapResultsMeta })
