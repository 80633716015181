import { IMPORT_MAP } from '@app/importMap'

import { makeRedirectRoute } from '@app/utils/makeRedirectRoute'
import { AsyncRoute } from '@app/utils/routing/types'

const Sitter = () => IMPORT_MAP.routes.Settings.RegistrationComplete().then(m => m.Sitter)
const ProfileSitter = () => IMPORT_MAP.routes.Settings.ProfileSitter().then(m => m.ProfileSitter)
const Calendar = () => IMPORT_MAP.routes.Settings.Calendar().then(m => m.Calendar)
const Favorites = () => IMPORT_MAP.routes.Settings.Favorites().then(m => m.default)
const Ignored = () => IMPORT_MAP.routes.Settings.Ignored().then(m => m.default)
const Trusted = () => IMPORT_MAP.routes.Settings.Trusted().then(m => m.default)
const History = () => IMPORT_MAP.routes.Settings.History().then(m => m.History)
const Referrals = () => IMPORT_MAP.routes.Settings.Referrals().then(m => m.Referrals)
const Courses = () => IMPORT_MAP.routes.Settings.Courses().then(m => m.default)

// @routes declaration
const routes = (): AsyncRoute[] => [
  {
    path: '/complete',
    event_id: 'registration_completed',
    component: Sitter,
  },
  {
    path: '/settings',
    event_id: 'settings',
    registration: false,
    component: ProfileSitter,
  },
  {
    path: '/notification-settings',
    event_id: 'notification_settings',
    component: () => IMPORT_MAP.routes.Settings.Notifications().then(m => m.NotificationSettings),
  },
  makeRedirectRoute('/notification_settings', '/notification-settings', 'notification_settings'),
  {
    path: '/schedule',
    event_id: 'schedule',
    registration: false,
    component: Calendar,
  },
  {
    path: '/favorites',
    event_id: 'favorites',
    component: Favorites,
  },
  {
    path: '/ignored',
    event_id: 'ignored',
    component: Ignored,
  },
  {
    path: '/trusting',
    event_id: 'trusting',
    component: Trusted,
  },
  {
    path: '/history',
    event_id: 'history',
    component: History,
  },
  {
    path: '/referrals',
    event_id: 'referrals',
    component: Referrals,
  },
  {
    path: '/courses',
    event_id: 'courses',
    component: Courses,
  },
]

export default routes
