import { IMPORT_MAP } from '@app/importMap'

import { AsyncRoute } from '@app/utils/routing/types'

// @routes declaration
const routes: AsyncRoute[] = [
  {
    path: '/giftcard',
    regional: true,
    event_id: 'giftcard_order',
    component: () => IMPORT_MAP.routes.GiftCard.GiftCardOrder().then(m => m.default),
  },
  {
    path: '/giftcard/:uuid',
    regional: true,
    event_id: 'giftcard_order',
    event_params: { is_success: 'false' },
    component: () => IMPORT_MAP.routes.GiftCard.GiftCardDetails().then(m => m.default),
  },
  {
    path: '/giftcard/:uuid/:isSuccess',
    regional: true,
    event_id: 'giftcard_order',
    event_params: { is_success: 'isSuccess' },
    component: () => IMPORT_MAP.routes.GiftCard.GiftCardDetails().then(m => m.default),
  },
]

export default routes
