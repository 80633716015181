import { createSelector } from 'reselect'

import { CurrencyPrinter } from '@app/utils/CurrencyPrinter'
import { DEFAULT_CURRENCY } from '@app/utils/printCurrency'

import { StoreState } from '@app/store/store'

export const currenciesSelector = (state: StoreState) => state.currencies
export const currenciesListSelector = createSelector([currenciesSelector], models => Object.values(models))
export const currenciesEnabledSelector = createSelector([currenciesSelector], models => Object.values(models).length > 1)

export const currencyRatesSelector = (state: StoreState) => state.currency_rates

export const stateCurrencySelector = (state: StoreState) => state.ui.currency
export const currentCurrencySelector = createSelector([stateCurrencySelector, currenciesEnabledSelector, currenciesListSelector], (state, enabled, list) => {
  if (enabled && state) return state
  return list.find(c => c.attributes.iso_code === DEFAULT_CURRENCY)!
})

export const currentCurrencyCodeSelector = (state: StoreState) => currentCurrencySelector(state).attributes.iso_code

export const currencyPrinterSelector = createSelector([currentCurrencyCodeSelector, currencyRatesSelector], (currentCode, rates) => {
  const ratesDict = Object.fromEntries(
    Object.values(rates)
      .filter(r => r.attributes.to === currentCode)
      .map(r => [r.attributes.from, r.attributes.rate])
  )
  return new CurrencyPrinter(currentCode, ratesDict)
})
