import { IMPORT_MAP } from '@app/importMap'

import { AsyncRoute } from './routing/types'

export function makeRedirectRoute(path: string, newPath: string, label: string): AsyncRoute {
  return {
    path,
    event_id: `${label}_redirect`,
    component: () =>
      IMPORT_MAP.components.Redirect().then(m =>
        m.createRedirect(
          location => ({
            pathname: newPath,
            search: location.search,
            hash: location.hash,
          }),
          false
        )
      ),
  }
}
