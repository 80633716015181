import { getYandexMapsReviewsByQueryDescriptor, getYandexMapsReviewsSummaryDescriptor } from '@app/store/actions/yandexMapsReviews.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  total?: number
  total_pages?: number
  current_page?: number
  avg_rating?: number
  total_count?: number
  loading?: boolean
}>({}, builder => {
  builder.addCase(getYandexMapsReviewsByQueryDescriptor.shapes.fulfilled, (state, action) => {
    return { ...state, ...action.payload.meta, loading: false }
  })
  builder.addCase(getYandexMapsReviewsByQueryDescriptor.shapes.pending, state => {
    return { ...state, loading: true }
  })
  builder.addCase(getYandexMapsReviewsByQueryDescriptor.shapes.rejected, state => {
    return { ...state, loading: false }
  })
  builder.addCase(getYandexMapsReviewsSummaryDescriptor.shapes.fulfilled, (state, action) => {
    return { ...state, ...action.payload.data.attributes }
  })
})
