import { reduceRectangles } from '@app/utils/geometry'

import { createReducer } from '@app/store/toolkit'

import { setMapArea } from '@app/routes/Search/actions.descriptors'

export default createReducer<ymaps.Bounds[]>([], builder => {
  builder.addCase(setMapArea.shape, (state, action) => {
    const bounds = action.payload.bounds
    if (!bounds) return state
    let newState = action.payload.bounds ? [] : [...state]
    newState.push(bounds)
    newState = reduceRectangles(newState)
    return newState
  })
})
