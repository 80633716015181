import { Raw, urlEscaped } from '@app/utils/urlEscaped'

import { StoreState } from '@app/store/store'

export const AGES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]

export const cartUpdateInterval = 1000 * 60 * 5

export const ACADEMY_EVENTS_LIST = ['academy', 'selfmamaparty', 'new_year_party'] as const

export type AcademyEventName = (typeof ACADEMY_EVENTS_LIST)[number]

const eventRoots = {
  academy: '/academy',
  selfmamaparty: '/selfmamaparty',
  new_year_party: '/new_year_party',
} as const

export const URLS = {
  main: (event: AcademyEventName) => eventRoots[event],
  schedule: (event: AcademyEventName) => `${Raw.create(eventRoots[event])}#schedule`,
  course: (id: string, event: AcademyEventName) => urlEscaped`${Raw.create(eventRoots[event])}/courses/${id}`,
  courses_redirect: (event: AcademyEventName) => `${eventRoots[event]}/courses`,
  courses: (event: AcademyEventName) => `${eventRoots[event]}/types`,
  lessonRedirect: (event: AcademyEventName) => `${eventRoots[event]}/lessons`,
  lesson: (id: string, event: AcademyEventName) => urlEscaped`${Raw.create(eventRoots[event])}/lessons/${id}`,
  lessonJoin: (id: string, event: AcademyEventName) => urlEscaped`${Raw.create(eventRoots[event])}/lessons/${id}/join`,
  cart: (event: AcademyEventName) => `${eventRoots[event]}/cart`,
  purchased: (event: AcademyEventName) => `${eventRoots[event]}/my/lessons`,
} as const

/** use it as tag in routing */
export const OPEN_CART = 'open_cart'

/** use it as tag in routing */
export const GO_TO_SCHEDULE = 'GO_TO_SCHEDULE'

export const EVENT_DATA = {}

export const isOnVacationSelector = (state: StoreState) => {
  if (state.academy.list.length > 0) return false
  return true
}
