import keyBy from 'lodash/keyBy'

import { getDepersonalizedAnnouncementsSearchMapDescriptor } from '@app/store/actions/depersonalized_announcements.descriptors'
import { createReducer } from '@app/store/toolkit'

import { PointsMapPoint } from '@app/components/PointsMap/PointsMapPoint'

export default createReducer<{ [key: string]: PointsMapPoint }>({}, builder => {
  builder.addCase(getDepersonalizedAnnouncementsSearchMapDescriptor.shapes.fulfilled, (state, action) => {
    const data = keyBy(action.payload.data, 'id')
    if (action.payload.meta.initial) return data
    return { ...state, ...data }
  })
})
