export function filterObject<T, U extends T>(object: { [key: string]: T }, fn: (key: string, value: T) => value is U): { [key: string]: U }
export function filterObject<T>(object: { [key: string]: T }, fn: (key: string, value: T) => boolean): { [key: string]: T }
export function filterObject<T>(object: { [key: string]: T }, fn: (key: string, value: T) => unknown): { [key: string]: T } {
  return Object.entries(object).reduce((c, [key, value]) => {
    if (fn(key, value)) {
      c[key] = value
    }
    return c
  }, {})
}
