import { ApiReferralPromocode } from '@app/constants/ApiTypes/entities'

import { getReferralPromocodeDescriptor } from '@app/store/actions/referrals.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<ApiReferralPromocode | null>(null, builder => {
  builder.addCase(getReferralPromocodeDescriptor.shapes.fulfilled, (_state, action) => {
    return action.payload.data
  })
})
