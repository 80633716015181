import { ApiAppStoreCustomerReview, ApiAppStoreCustomerReviewSummary } from '@app/constants/ApiTypes/entities'

import { ApiActionBuilderDescriptor } from '@app/store/apiMiddleware/builderDescriptor'

export const getAppStoreCustomerReviewsByQueryDescriptor = new ApiActionBuilderDescriptor().setLabel('GET_APP_STORE_CUSTOMER_REVIEWS').setShape<{
  data: ApiAppStoreCustomerReview[]
  meta: {
    total: number
    total_pages: number
    current_page: number
  }
}>()
export const getAppStoreCustomerReviewsSummaryDescriptor = new ApiActionBuilderDescriptor().setLabel('GET_APP_STORE_CUSTOMER_REVIEWS_SUMMARY').setShape<{
  data: ApiAppStoreCustomerReviewSummary
}>()
