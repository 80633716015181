import uniq from 'lodash/uniq'

import { postIgnoredUserDescriptor } from '@app/store/actions/ignored.descriptors'
import { addToFavoritesDescriptor, deleteFromFavoritesDescriptor, getFavoritesDescriptor } from '@app/store/actions/profile.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<string[]>([], builder => {
  builder.addCase(getFavoritesDescriptor.shapes.fulfilled, (state, action) => {
    const keys = action.payload.data.map(model => model.id)
    const { query } = action.meta
    if (query.page === 1) {
      return keys
    }
    return uniq(state.concat(keys))
  })

  builder.addCase(addToFavoritesDescriptor.shapes.fulfilled, (state, action) => {
    return [action.payload.data.id, ...state]
  })

  builder.addCase(deleteFromFavoritesDescriptor.shapes.fulfilled, (state, action) => {
    const id = action.payload.data.id
    const index = state.indexOf(id)

    if (index === -1) return state
    return [...state.slice(0, index), ...state.slice(index + 1)]
  })

  builder.addCase(postIgnoredUserDescriptor.shapes.fulfilled, (state, action) => {
    return state.filter(id => id !== action.meta.user_id)
  })
})
