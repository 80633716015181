export interface AccountManagementUser {
  label: string
  token: string
  type: 'parent' | 'sitter'
  id: string
}

/** STAGING USE ONLY */
export const TEST_USERS: AccountManagementUser[] = [
  {
    label: 'Test parent',
    token: '4f4c82ac03cea100da9c6e1a8b6b1d17',
    type: 'parent',
    id: '40133',
  },
  {
    label: 'Test sitter',
    token: '92a44bb68342dd6a61b90fe528a63740',
    type: 'sitter',
    id: '692',
  },
]
