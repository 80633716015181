import { ReactNode } from 'react'
import { defineMessages, IntlShape } from 'react-intl'

import moment, { Moment } from './moment'

export type ReactIntlFormatter = ReactNode | ((msgs: ReactNode[]) => ReactNode)

export const defineFormatters = <K extends string>(formatters: Record<K, ReactIntlFormatter>) => formatters

const messages = defineMessages({
  date_today: 'Сегодня',
  date_yesterday: 'Вчера',
  date_tomorrow: 'Завтра',
  date: `D MMMM{isSameYear, select,
    false {[, ]YYYY}
    other {}
  }`,
  minute: `{value, plural,
    one {минута}
    few {минуты}
    other {минут}}`,
  hour: `{value, plural,
    one {час}
    few {часа}
    other {часов}}`,
})

export const formatRelativeDate = (formatMessage: IntlShape['formatMessage'], date: Moment, now: Moment = moment()) => {
  if (date.isSame(now, 'day')) return formatMessage(messages.date_today)
  if (date.isSame(now.clone().add(1, 'day'), 'day')) return formatMessage(messages.date_tomorrow)
  if (date.isSame(now.clone().add(-1, 'day'), 'day')) return formatMessage(messages.date_yesterday)

  return date.format(
    formatMessage(messages.date, {
      isSameYear: date.isSame(now, 'year'),
    })
  )
}

export const formatDuration = (
  formatMessage: IntlShape['formatMessage'],
  /** duration in minutes */
  duration: number
) => {
  const hh = Math.floor(duration / 60)
  const mm = duration % 60
  const out: string[] = []
  if (hh) {
    out.push(`${hh}\u00a0${formatMessage(messages.hour, { value: hh })}`)
  }
  if (mm) {
    out.push(`${mm}\u00a0${formatMessage(messages.minute, { value: mm })}`)
  }
  return out.join(' ')
}
