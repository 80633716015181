import { IMPORT_MAP } from '@app/importMap'

import { AsyncRoute } from '@app/utils/routing/types'

const Apps = () => IMPORT_MAP.routes.Apps.Apps().then(m => m.Apps)
const ApkLink = () => IMPORT_MAP.routes.Apps.ApkLink().then(m => m.ApkLink)

// @routes declaration
const routes: AsyncRoute[] = [
  {
    path: '/apps/:platform/:flavor',
    event_id: 'apps',
    event_params: {
      platform: 'platform',
      flavor: 'flavor',
    },
    component: Apps,
  },
  {
    path: '/apps/android/:flavor/latest.apk',
    event_id: 'apps',
    event_params: {
      platform: 'platform',
      flavor: 'flavor',
    },
    component: ApkLink,
  },
]

export default routes
