import forOwn from 'lodash/forOwn'
import isNil from 'lodash/isNil'

import config from '@app/config'
import { AnalyticsUser } from '@app/types/analytics'

import loggerCreator from '@app/utils/loggerCreator'

type AmplitudeEventInterceptor = (event: string, eventProperties?: { [key: string]: any }) => boolean

export class Amplitude {
  static shared = new Amplitude()

  private interceptors: AmplitudeEventInterceptor[] = []

  private inited = false
  private app_request_id = ''

  private constructor() {}

  init(key: string | null, app_request_id: string) {
    this.app_request_id = app_request_id
    const amp = this.getAmplitude().getInstance()
    // in case key is empty we only test for presence of amplitude loader
    if (!key) {
      if (!amp.init) throw new Error('failed to load amplitude')
      return Promise.resolve()
    }

    this.inited = true

    return new Promise<void>(resolve => {
      amp.init(key, undefined, { includeUtm: true, includeGclid: true, includeReferrer: true }, _client => {
        resolve()
      })
    })
  }

  identify(userModel: AnalyticsUser | null) {
    let userProperties: { [key: string]: string | number | boolean | null } = {}

    if (userModel && userModel.account_type !== 'visitor') {
      userProperties.id = userModel.token
      userProperties.account_type = userModel.account_type

      if (userModel.account_type === 'parent') {
        userProperties = {
          ...userProperties,
          city_id: userModel.region_id,
          completed_requests_count: userModel.completed_requests_count,
          confirmed_requests_count: userModel.confirmed_requests_count,
          subscriptions_count: userModel.subscriptions_count,
          payments_count: userModel.payments_count,
          trial: userModel?.subscription?.trial ?? false,
          hidden_search: userModel.hidden_search,
        }
      } else if (userModel.account_type === 'sitter') {
        userProperties = {
          ...userProperties,
          city_id: userModel.region_id,
          completed_requests_count: userModel.completed_requests_count,
          confirmed_requests_count: userModel.confirmed_requests_count,
        }
      }
    }

    forOwn(userModel?.test_flights ?? {}, (value, key) => {
      userProperties['tf_' + key] = value
    })

    if (this.inited) {
      const amplitude = this.getAmplitude()
      const instance = amplitude.getInstance()

      const identity = new amplitude.Identify()

      forOwn(userProperties, (value, key) => {
        if (isNil(value)) {
          identity.unset(key)
        } else {
          identity.set(key, value)
        }
      })

      if (userProperties.id) {
        if (typeof userProperties.id !== 'string') throw new Error('ID should be string')
        instance.setUserId(userProperties.id)
      }

      instance.identify(identity)
    } else if (userProperties.id) {
      this.log('identify:', userProperties.id, userProperties)
    } else {
      this.log('identify:', null)
    }
  }

  logout() {
    if (this.inited) {
      const amplitude = this.getAmplitude()
      amplitude.getInstance().setUserId(null)
      amplitude.getInstance().regenerateDeviceId()
    } else {
      this.log('logout')
    }
  }

  /**
   *
   * @param event - eventName
   * @param eventProperties - map of event properties. Note that keys prefixed with `ampt_` are reserved
   */
  logEvent(event: string, eventProperties?: { [key: string]: any }) {
    for (const interceptor of this.interceptors) {
      if (!interceptor(event, eventProperties)) return
    }
    const props = { ...eventProperties, ampt_tab_id: this.app_request_id, ampt_build: process.env.GIT_SHA ?? '' }
    if (this.inited) {
      this.getAmplitude().getInstance().logEvent(event, props)
      if (config.mode !== 'production') {
        this.log('logEvent', event, props)
      }
    } else if (IS_BROWSER && !!process.env.LOG_TO_SERVER) {
      this.log('logEvent', event, props)
      window.navigator.sendBeacon('/internal/amplitude', JSON.stringify({ event, eventProperties: props }))
    } else {
      this.log('logEvent', event, props)
    }
  }

  addInterceptor(interceptor: AmplitudeEventInterceptor) {
    this.interceptors.push(interceptor)
  }

  private getAmplitude(): typeof import('amplitude-js') {
    return require('amplitude-js').default || require('amplitude-js')
  }

  private log = loggerCreator('Amplitude', 'cornflowerblue', undefined, false)
}
