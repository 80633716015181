import { useMemo } from 'react'

import { useAppDispatch } from '@app/utils/redux'

import { getState } from '@app/store/actions/initial'
import type { StoreState } from '@app/store/store'

export const useImmutableSelector = <TValue extends any>(selector: (state: StoreState) => TValue): TValue => {
  const dispatch = useAppDispatch()
  return useMemo(() => {
    const state = dispatch(getState())
    return selector(state)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
