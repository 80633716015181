import React, { FunctionComponent, ReactNode, useEffect, useMemo, useRef, useState } from 'react'

import { AbortError, isAbortError } from '@app/errors/AbortError'

import { ensureType } from '@app/utils/ensureType'
import { useAppDispatch } from '@app/utils/redux'

import { useEvent } from '@app/hooks/useEvent'

import { showError } from '@app/store/actions/ui'

import { Icon18 } from '@app/components/Icon/Icon'

import {
  SuggestInput,
  SuggestInputButton,
  SuggestInputOnBlurCallBack,
  SuggestInputOnChangeCallback,
  SuggestInputOnFocusCallback,
  SuggestInputOnValueChangeCallback,
  SuggestInputSuggestionFetchCallback,
  SuggestInputTheme,
  SuggestionId,
} from './SuggestInput'

export type ControlledSuggestInputFetchSuggestionsCallback = (
  value: string,
  isDirty: boolean,
  abortSignal: AbortSignal
) => SuggestionId[] | Promise<SuggestionId[]>
export type ControlledSuggestInputRenderSuggestionCallback = (value: SuggestionId | null, current: boolean) => ReactNode
export type ControlledSuggestInputOnChangeCallback = (value: SuggestionId | null, stringVal: string) => unknown
export type ControlledSuggestInputGetValueCallback = (value: SuggestionId | null, current: boolean) => string

export type ControlledSuggestInputButton = { icon: Icon18; className?: string; onPress: (change: (val: string) => unknown) => unknown }

export type ControlledSuggestInputProps = {
  className?: string

  value: SuggestionId | null
  onChange: ControlledSuggestInputOnChangeCallback
  getSuggestionValue?: ControlledSuggestInputGetValueCallback
  renderSuggestion?: ControlledSuggestInputRenderSuggestionCallback
  fetchSuggestions: ControlledSuggestInputFetchSuggestionsCallback
  resettable?: boolean

  placeholder?: string
  disabled?: boolean
  error?: Error | boolean
  submitOnEnter?: boolean

  onFocus?: SuggestInputOnFocusCallback
  onBlur?: SuggestInputOnBlurCallBack

  size?: 'small' | 'large'

  buttons?: ControlledSuggestInputButton[]
  theme?: SuggestInputTheme

  selectAllOnFocus?: boolean
}

export const ControlledSuggestInput: FunctionComponent<ControlledSuggestInputProps> = ({
  className,
  value,
  onChange,
  getSuggestionValue = defaultExtractor,
  renderSuggestion = defaultExtractor,
  fetchSuggestions,
  resettable,
  placeholder,
  disabled,
  error,
  submitOnEnter,
  onFocus,
  onBlur,
  size,
  buttons: propsButtons,
  theme,
  selectAllOnFocus,
}) => {
  const dispatch = useAppDispatch()
  const abortControllerRef = useRef<AbortController | null>(null)

  const [suggestionLoadError, setSuggestionLoadError] = useState<Error | null>(null)
  const [stringValue, setStringValue] = useState(getSuggestionValue(value, true))
  const [suggestionLoading, setSuggestionLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [updateCount, setUpdateCount] = useState(0)
  const [isDirty, setIsDirty] = useState(false)

  const [suggestions, setSuggestions] = useState<SuggestionId[]>([])

  const reset = useEvent(() => {
    onChange(null, '')
  })

  const handleBlur = useEvent<SuggestInputOnBlurCallBack>(event => {
    setIsDirty(false)
    onBlur?.(event)
  })

  const handleChange = useEvent<SuggestInputOnChangeCallback>(async (val, stringVal) => {
    abortControllerRef.current?.abort()
    setSuggestionLoadError(null)
    setSuggestionLoading(false)
    setSuggestions([])

    try {
      setLoading(true)
      if (!val) {
        await onChange(null, stringVal)
        setUpdateCount(c => (c + 1) & 0xff)
      } else {
        await onChange(val, stringVal)
        setUpdateCount(c => (c + 1) & 0xff)
      }
    } catch (error: any) {
      if (isAbortError(error)) return
      dispatch(showError({ error }))
    } finally {
      setLoading(false)
    }
  })

  const buttonPressHandler = useEvent((val: string) => {
    handleChange(val, stringValue)
  })

  const handleValueChange = useEvent<SuggestInputOnValueChangeCallback>(async val => {
    setStringValue(val)
    setIsDirty(true)
  })

  const handleFetchSuggestions = useEvent<SuggestInputSuggestionFetchCallback>(async ({ value, reason }) => {
    if (!['input-focused', 'input-changed'].includes(reason)) return

    const abortController = new AbortController()
    abortControllerRef.current?.abort()
    abortControllerRef.current = abortController
    try {
      setSuggestionLoadError(null)
      setSuggestionLoading(true)
      setSuggestions([])
      const items = await fetchSuggestions(value, isDirty, abortController.signal)
      if (abortController.signal.aborted) throw new AbortError('Suggestiong fetch aborted')
      setSuggestions(items)
    } catch (e: any) {
      if (isAbortError(e)) return
      setSuggestionLoadError(e)
    } finally {
      if (!abortController.signal.aborted) setSuggestionLoading(false)
    }
  })

  const buttons = useMemo(
    () =>
      resettable && value
        ? ensureType<SuggestInputButton[]>([
            ...(propsButtons ?? []).map(b => ({ ...b, onPress: () => b.onPress(buttonPressHandler) })),
            {
              icon: 'cancel',
              onPress() {
                reset()
              },
            },
          ])
        : propsButtons?.map(b => ({ ...b, onPress: () => b.onPress(buttonPressHandler) })),
    [value, resettable, propsButtons, buttonPressHandler, reset]
  )

  useEffect(() => {
    return () => {
      abortControllerRef.current?.abort()
    }
  }, [])

  useEffect(() => {
    setStringValue(getSuggestionValue(value, true))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, updateCount])

  return (
    <SuggestInput
      buttons={buttons}
      className={className}
      disabled={disabled}
      error={error}
      fetchSuggestions={handleFetchSuggestions}
      getSuggestionValue={getSuggestionValue}
      loading={loading}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={onFocus}
      onValueChange={handleValueChange}
      placeholder={placeholder}
      renderSuggestion={renderSuggestion}
      selectAllOnFocus={selectAllOnFocus}
      size={size}
      stringValue={stringValue}
      submitOnEnter={submitOnEnter}
      suggestionLoading={suggestionLoading}
      suggestions={suggestions}
      suggestionsLoadError={suggestionLoadError}
      theme={theme}
      value={value ?? undefined}
    />
  )
}

const defaultExtractor = (val: string | null) => val ?? ''
