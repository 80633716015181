import { ApiTrainingRequest } from '@app/constants/ApiTypes/entities'

import { excludeAbortError } from '@app/errors/AbortError'

import { postTrainingRequestConfirmationDescriptor } from '@app/store/actions/trainingRequest.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<Record<string, ApiTrainingRequest | Error | undefined>>({}, builder => {
  builder.addCase(postTrainingRequestConfirmationDescriptor.shapes.fulfilled, (state, action) => {
    return { ...state, [action.meta.token]: action.payload.data }
  })

  builder.addCase(postTrainingRequestConfirmationDescriptor.shapes.rejected, (state, action) => {
    return { ...state, [action.meta.token]: excludeAbortError(action.payload) ?? undefined }
  })
})
