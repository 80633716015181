import { useEffect, useState } from 'react'

import { debounce } from '@app/utils/debounce'

type DeviceType = 'mobile' | 'tablet' | 'desktop'

const matchDeviceType = (width: number): DeviceType => {
  if (width < 768) return 'mobile'
  if (width < 1024) return 'tablet'
  return 'desktop'
}

export const useDeviceType = () => {
  const [type, setType] = useState<DeviceType>(IS_BROWSER ? matchDeviceType(window.innerWidth) : 'desktop')

  if (IS_BROWSER) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      const handler = debounce(() => {
        setType(matchDeviceType(window.innerWidth))
      }, 100)
      window.addEventListener('resize', handler)

      return () => {
        window.removeEventListener('resize', handler)
      }
    }, [])
  }

  return type
}
