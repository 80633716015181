import { RouterState } from '@app/utils/routing/types'

import { beforeNavigate, navigate } from '@app/store/actions/router.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{ isInitial: boolean; state: RouterState | null; isTransitioning: boolean }>(
  { isInitial: true, state: null, isTransitioning: false },
  builder => {
    builder.addCase(beforeNavigate.shape, (state, action) => {
      return { ...state, isInitial: action.payload.initial, state: action.payload.state, isTransitioning: true }
    })

    builder.addCase(navigate.shape, (state, _action) => {
      return { ...state, isTransitioning: false }
    })
  }
)
