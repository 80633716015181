import { removeStackInitiator } from '@app/utils/removeStackInitiator'

export class LocalizedError extends Error {
  localizedMessage!: string
  expose?: boolean
  handled?: boolean

  static create(message: string, localizedMessage: string) {
    const err = new this(message)
    err.localizedMessage = localizedMessage
    err.stack = removeStackInitiator(err.stack ?? '')
    return err
  }
}
