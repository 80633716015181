import { createSelector } from 'reselect'

import { isRegionAvailable, RegionExtensionData, RegionsExtensionData } from '@app/utils/regions'

import { StoreState } from '@app/store/store'

const regionsListSelector = (state: StoreState) => state.regions.list
export const regionsModelsSelector = (state: StoreState) => state.regions.models

export const regionsSelector = createSelector([regionsListSelector, regionsModelsSelector], (list, models) =>
  list.map(id => {
    const extensionData: Partial<RegionExtensionData> = RegionsExtensionData[models[id].attributes.slug]
    return { id, ...models[id].attributes, ...extensionData }
  })
)

export const defaultRegionModelSelector = createSelector([regionsModelsSelector], regions => Object.values(regions).find(c => c.attributes.slug === 'msk')!)
export const defaultRegionSelector = createSelector([regionsSelector], regions => regions.find(c => c.slug === 'msk')!)

export const availableRegionsSelector = createSelector([regionsSelector], regions => regions.filter(city => city.available))

export const availableRegionsForLandingSelector = availableRegionsSelector

export const availableRegionsForTrainingSelector = createSelector([regionsSelector], regions =>
  regions.filter(region => !!(region.school || region.interview || region.online_school || region.online_interview))
)

export const availableRegionsForSchoolSelector = createSelector([regionsSelector], regions =>
  regions.filter(region => !!(region.school || region.online_school))
)

export const availableRegionsForParentSelector = availableRegionsSelector

export const availableRegionsForSitterSelector = availableRegionsSelector

export const availableRegionsForSitterOfflineSearchSelector = createSelector([regionsSelector], regions =>
  regions.filter(region => region.available && region.type === 'city')
)

export const schoolRegionsSelector = createSelector([regionsSelector], regions => regions.filter(region => !!region.school || !!region.online_school))

export const availableRegionsSlugsSelector = createSelector([availableRegionsForLandingSelector], regions =>
  regions.filter(r => isRegionAvailable(r)).map(c => c.slug)
)
export const availableSlugsSelector = createSelector([availableRegionsSlugsSelector], regions => regions.filter(r => r !== 'msk'))
