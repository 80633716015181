import { excludeAbortError } from '@app/errors/AbortError'

import { getBonusesDescriptor, getReferralPromocodeDescriptor, getReferralsDescriptor } from '@app/store/actions/referrals.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  promocodeLoaded: boolean
  promocodeLoading: boolean
  promocodeError: Error | null
  referralsLoaded: boolean
  referralsLoading: boolean
  referralsError: Error | null
  bonusesLoaded: boolean
  bonusesLoading: boolean
  bonusesError: Error | null
}>(
  {
    promocodeLoaded: false,
    promocodeLoading: false,
    promocodeError: null,
    referralsLoaded: false,
    referralsLoading: false,
    referralsError: null,
    bonusesLoaded: false,
    bonusesLoading: false,
    bonusesError: null,
  },
  builder => {
    builder.addCase(getReferralPromocodeDescriptor.shapes.fulfilled, (state, _action) => {
      return { ...state, promocodeLoaded: true, promocodeLoading: false, promocodeError: null }
    })

    builder.addCase(getReferralPromocodeDescriptor.shapes.pending, (state, _action) => {
      return { ...state, promocodeLoading: true, promocodeError: null }
    })

    builder.addCase(getReferralPromocodeDescriptor.shapes.rejected, (state, action) => {
      return { ...state, promocodeLoading: false, promocodeError: excludeAbortError(action.payload) }
    })

    builder.addCase(getReferralsDescriptor.shapes.fulfilled, (state, _action) => {
      return { ...state, referralsLoaded: true, referralsLoading: false, referralsError: null }
    })

    builder.addCase(getReferralsDescriptor.shapes.pending, (state, _action) => {
      return { ...state, referralsLoading: true, referralsError: null }
    })

    builder.addCase(getReferralsDescriptor.shapes.rejected, (state, action) => {
      return { ...state, referralsLoading: false, referralsError: excludeAbortError(action.payload) }
    })

    builder.addCase(getBonusesDescriptor.shapes.fulfilled, (state, _action) => {
      return { ...state, bonusesLoaded: true, bonusesLoading: false, bonusesError: null }
    })

    builder.addCase(getBonusesDescriptor.shapes.pending, (state, _action) => {
      return { ...state, bonusesLoading: true, bonusesError: null }
    })

    builder.addCase(getBonusesDescriptor.shapes.rejected, (state, action) => {
      return { ...state, referralsLoading: false, referralsError: excludeAbortError(action.payload) }
    })
  }
)
