import React, { FunctionComponent, memo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Route as RouteComponent, Routes as RouterRoutes } from 'react-router'

import { normalizePath } from '@app/utils/url'

import { availableRegionsSlugsSelector } from '@app/store/selectors/regions'

import { useRouter } from './hooks'
import { getRegionFromPath } from './region'
import { Route } from './types'

export let RouteRenderer: FunctionComponent<{ routes: Route[] | undefined }> = ({ routes }) => {
  const router = useRouter()

  const regionsList = useSelector(availableRegionsSlugsSelector)

  const location = router.location
  const pathRegion = getRegionFromPath(regionsList, location.pathname)

  const renderRoute = useCallback(
    (route: Route, i: number) => {
      const path = (() => {
        if (!pathRegion || !route.regional || !route.path) return route.path
        if (
          (Array.isArray(route.regional) && route.regional.includes(pathRegion)) ||
          (typeof route.regional === 'string' && route.path === route.regional) ||
          !!route.regional
        )
          return normalizePath(`/${pathRegion}${route.path}`)
        return route.path
      })()
      return (
        <RouteComponent
          {...(route.index ? { index: true as any as false } : { path })}
          element={
            <route.component history={router.history} location={router.location} match={router.match} route={route} staticContext={router.staticContext} />
          }
          key={route.key || i}
        >
          {route.routes?.map(renderRoute)}
        </RouteComponent>
      )
    },
    [pathRegion, router.history, router.location, router.match, router.staticContext]
  )

  if (!routes) return null

  return <Routes>{routes.map(renderRoute)}</Routes>
}

const Routes = IS_BROWSER ? (require('@sentry/react') as typeof import('@sentry/react')).withSentryReactRouterV6Routing(RouterRoutes) : RouterRoutes

RouteRenderer = memo(RouteRenderer)
