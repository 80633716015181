import { ApiSocialIdentity } from '@app/constants/ApiTypes/entities'

import { excludeAbortError } from '@app/errors/AbortError'

import moment from '@app/utils/moment'
import { omit } from '@app/utils/omit'

import { deleteIdentitiesByIdDescriptor, getIdentitiesDescriptor, postIdentitiesDescriptor } from '@app/store/actions/profile.descriptors'
import { createReducer } from '@app/store/toolkit'

type IdentitiesState = Partial<Record<ApiSocialIdentity['attributes']['provider'], { state: 'ok' | 'loading'; id: string }>>

export default createReducer<{
  fetched: boolean
  fetched_at: string | null
  error: Error | null
  identities: IdentitiesState
}>({ fetched: false, fetched_at: null, error: null, identities: {} }, builder => {
  builder.addCase(getIdentitiesDescriptor.shapes.fulfilled, (_state, action) => {
    return {
      fetched: true,
      fetched_at: moment().format(),
      error: null,
      identities: action.payload.data.reduce<IdentitiesState>((c, x) => {
        c[x.attributes.provider] = { state: 'ok', id: x.id }
        return c
      }, {}),
    }
  })

  builder.addCase(postIdentitiesDescriptor.shapes.pending, (state, action) => {
    return { ...state, error: null, identities: { ...state.identities, [action.meta.provider]: { state: 'loading', id: '' } } }
  })

  builder.addCases([postIdentitiesDescriptor.shapes.fulfilled], (state, action) => {
    return { ...state, error: null, identities: { ...state.identities, [action.meta.provider]: { state: 'ok', id: action.payload.data.id } } }
  })

  builder.addCase(postIdentitiesDescriptor.shapes.rejected, (state, action) => {
    return { ...state, error: excludeAbortError(action.payload), identities: omit(state.identities, action.meta.provider) }
  })

  builder.addCase(deleteIdentitiesByIdDescriptor.shapes.pending, (state, action) => {
    const identity = Object.entries(state.identities).find(([_provider, value]) => value && value.id === action.meta.id)
    if (!identity) return state
    const provider = identity[0] as ApiSocialIdentity['attributes']['provider']
    return {
      ...state,
      error: null,
      identities: { ...state.identities, [provider]: { state: 'loading', id: action.meta.id } },
    }
  })

  builder.addCase(deleteIdentitiesByIdDescriptor.shapes.fulfilled, (state, action) => {
    const identity = Object.entries(state.identities).find(([_provider, value]) => value && value.id === action.meta.id)
    if (!identity) return state
    const provider = identity[0] as ApiSocialIdentity['attributes']['provider']
    return {
      ...state,
      error: null,
      identities: omit(state.identities, provider),
    }
  })

  builder.addCase(deleteIdentitiesByIdDescriptor.shapes.rejected, (state, action) => {
    const identity = Object.entries(state.identities).find(([_provider, value]) => value && value.id === action.meta.id)
    if (!identity) return state
    const provider = identity[0] as ApiSocialIdentity['attributes']['provider']
    return {
      ...state,
      error: excludeAbortError(action.payload),
      identities: { ...state.identities, [provider]: { state: 'ok', id: action.meta.id } },
    }
  })
})
