// eslint-disable-next-line no-restricted-imports
import moment from 'moment-timezone'

export default moment

// eslint-disable-next-line no-restricted-imports
export { Moment } from 'moment-timezone'

export function assertTimezoneValid(tz: string) {
  if (!moment.tz.zone(tz)) throw new Error(`Timezone "${tz}" is invalid`)
}
