import type { ApiBonus, ApiReferral, ApiReferralPromocode } from '@app/constants/ApiTypes/entities'

import { ApiActionBuilderDescriptor } from '@app/store/apiMiddleware/builderDescriptor'

export const getReferralPromocodeDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('GET_REFERRAL_PROMOCODE')
  .setShape<{ data: null | ApiReferralPromocode }>()

export const getReferralPromocodeByCodeDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('GET_REFERRAL_PROMOCODE_BY_CODE')
  .setShape<{ data: ApiReferralPromocode }, { code: string }>()

export const getReferralsDescriptor = new ApiActionBuilderDescriptor().setLabel('GET_REFERRALS').setShape<{ data: ApiReferral[] }>()

export const getBonusesDescriptor = new ApiActionBuilderDescriptor().setLabel('GET_BONUSES').setShape<{ data: ApiBonus[] }>()
