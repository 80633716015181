import isPlainObject from 'lodash/isPlainObject'

const PASS = Symbol('PASS')

export type Converter = (value: any, def: (obj?: { value: any }) => any) => any

export function jsonSerialize(data: any, serialize: Converter) {
  const newData = dataTraverse(data, serialize)

  return JSON.stringify(newData)
}

export function jsonParse(
  json: string,
  /**
   * @param pass - return it when you don't want to skip this value, so it will be processed with default JSON.parse
   */
  parse: Converter
) {
  const data = JSON.parse(json)

  return dataTraverse(data, parse)
}

export function composeConverters(...converters: Converter[]): Converter {
  if (!converters.length) throw new Error('No converters provided')
  return (value, def) => {
    for (const [index, converter] of converters.entries()) {
      const result = converter(value, def)
      if (result !== PASS || index === converters.length - 1) return result
    }
  }
}

export const arrayConverter: Converter = (value, def) => {
  if (Array.isArray(value)) {
    return value.map(v => def({ value: v }))
  }
  return def()
}

function dataTraverse(data: any, parse: Converter) {
  const val = parse(data, obj => (obj ? dataTraverse(obj.value, parse) : PASS))
  if (val !== PASS) return val
  if (!isPlainObject(data)) return data

  const newData = Object.fromEntries(
    Object.entries(data).map(([key, value]) => {
      return [key, dataTraverse(value, parse)]
    })
  )

  return newData
}
