interface LocalStoragePointer {
  <T = string>(
    name: string,
    unmarshall: (input: string) => T,
    marshall: (input: T) => string
  ): {
    get: () => T
    set: (value: T) => void
    rm: () => void
    exists: () => boolean
  }
  (name: string): {
    get: () => string
    set: (value: string) => void
    rm: () => void
    exists: () => boolean
  }
}

const existsSymbol = Symbol()

interface LocalStorageInterface {
  getItem(key: string): string | null
  setItem(key: string, value: string): void
  removeItem(key: string): void
  [existsSymbol]?: (key: string) => boolean
}

const localStorageAvailable = (() => {
  if (!IS_BROWSER) return false
  try {
    localStorage.setItem('__localstoragaavailibilitytest', 'test')
    const eq = localStorage.getItem('__localstoragaavailibilitytest') === 'test'
    localStorage.removeItem('__localstoragaavailibilitytest')
    return eq
  } catch {
    return false
  }
})()

const ls: LocalStorageInterface = (() => {
  if (localStorageAvailable) return localStorage
  if (!IS_BROWSER) {
    const ls: LocalStorageInterface = {
      getItem(_key) {
        throw new Error('localStorage not available on server')
      },
      setItem(_key, _value) {
        throw new Error('localStorage not available on server')
      },
      removeItem(_key) {
        throw new Error('localStorage not available on server')
      },
      [existsSymbol](_key) {
        throw new Error('localStorage not available on server')
      },
    }
    return ls
  }
  const cache: { [key: string]: string } = {}
  const ls: LocalStorageInterface = {
    getItem(key) {
      if (Object.prototype.hasOwnProperty.call(cache, key)) return cache[key]
      return null
    },
    setItem(key, value) {
      cache[key] = value
    },
    removeItem(key) {
      if (Object.prototype.hasOwnProperty.call(cache, key)) delete cache[key]
    },
    [existsSymbol](key) {
      return Object.prototype.hasOwnProperty.call(cache, key)
    },
  }
  return ls
})()

export const createLocalStorage = ((name, unmarhall, marshall) => {
  const get = () => {
    let v = ''
    try {
      v = ls.getItem(name) || ''
    } catch {}
    return unmarhall ? unmarhall(v) : v
  }
  const set = value => {
    const v = marshall ? marshall(value) : value
    try {
      ls.setItem(name, v)
    } catch {}
  }
  const rm = () => {
    try {
      ls.removeItem(name)
    } catch {}
  }
  const exists = () => {
    const exists = ls[existsSymbol]
    if (exists) return exists(name)

    return name in ls
  }
  return { get, set, rm, exists }
}) as LocalStoragePointer
