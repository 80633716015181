import React, { CSSProperties, FocusEvent, FunctionComponent, PropsWithChildren, ReactNode, useCallback, useMemo, useRef, useState } from 'react'
import Autosuggest from 'react-autosuggest'

import { moveCursorToEnd } from '@app/utils/domInput'
import { isKeyboardKeyEvent } from '@app/utils/isKeyboardKeyEvent'
import { noop } from '@app/utils/noop'
import { selectAllInInput } from '@app/utils/selectAllInInput'
import { UNICODE_CHAR } from '@app/utils/unicodeChar'

import { useClickHandler } from '@app/hooks/useClickHandler'
import { useEvent } from '@app/hooks/useEvent'

import { ErrorRenderer } from '@app/components/ErrorRenderer/ErrorRenderer'
import { Icon, Icon18 } from '@app/components/Icon/Icon'
import { Menu, WidthExtractor } from '@app/components/Menu/Menu'
import { SpinnerIcon } from '@app/components/SpinnerIcon/SpinnerIcon'

import classes from './SuggestInput.module.scss'

export type SuggestionId = string

export type SuggestInputOnValueChangeCallback = (id: string) => unknown
export type SuggestInputOnChangeCallback = (id: SuggestionId | null, stringValue: string) => unknown
export type SuggestInputOnFocusCallback = (event: FocusEvent<HTMLInputElement>) => unknown
export type SuggestInputOnBlurCallBack = (event: FocusEvent<HTMLInputElement>) => unknown
export type SuggestInputRenderSuggestionCallback = (id: SuggestionId | null, current: boolean) => ReactNode
export type SuggestInputSuggestionValueCallback = (id: SuggestionId, current: boolean) => string
export type SuggestInputSuggestionFetchCallback = (data: { value: string; reason: string }) => unknown

export type SuggestInputButton = { icon: Icon18; className?: string; onPress: () => unknown }

export type SuggestInputTheme = {
  button?: (error: boolean, disabled: boolean) => string
  input?: (error: boolean, disabled: boolean) => string
  error?: string
  root?: string
  loading?: string
  container?: string
}

export type SuggestInputProps = {
  className?: string
  style?: CSSProperties

  value?: SuggestionId
  stringValue: string
  onValueChange: SuggestInputOnValueChangeCallback

  renderSuggestion: SuggestInputRenderSuggestionCallback
  getSuggestionValue: SuggestInputSuggestionValueCallback

  fetchSuggestions: SuggestInputSuggestionFetchCallback

  placeholder?: string
  disabled?: boolean
  loading?: boolean
  submitOnEnter?: boolean

  error?: Error | boolean

  suggestionLoading?: boolean
  suggestions: SuggestionId[]
  suggestionsLoadError?: Error | null

  onChange?: SuggestInputOnChangeCallback

  onFocus?: SuggestInputOnFocusCallback
  onBlur?: SuggestInputOnBlurCallBack

  size?: 'small' | 'large'

  buttons?: SuggestInputButton[]
  theme?: SuggestInputTheme

  selectAllOnFocus?: boolean
}

export const SuggestInput: FunctionComponent<SuggestInputProps> = ({
  className,
  style,

  value = null,
  stringValue,
  onValueChange,

  renderSuggestion,
  getSuggestionValue,

  fetchSuggestions,

  placeholder = '',
  disabled,
  loading,
  submitOnEnter,

  error,

  suggestionLoading = false,
  suggestionsLoadError,
  suggestions,

  onChange = noop,
  onFocus = noop,
  onBlur = noop,

  size = 'small',

  buttons,
  theme,

  selectAllOnFocus,
}) => {
  const [rawfocused, setFocused] = useState(false)
  const focused = rawfocused && !disabled

  const containerRef = useRef<HTMLDivElement>(null)
  const selectedRef = useRef(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const autosuggestTheme = useMemo(
    () => ({
      input: cn(classes.text_input, classes[`text_input_${size}`]),
      suggestionsContainer: classes.suggestions_container,
      suggestionsList: classes.suggestions_list,
      suggestion: classes.suggestion,
      suggestionHighlighted: classes.suggestion_highlighted,
    }),
    [size]
  )

  const renderSuggestionsContainer = useCallback(
    ({ containerProps, children }) => {
      if (!children && !suggestionsLoadError) return null
      return (
        <Menu anchor={containerRef} width={widthExtractor}>
          <div {...containerProps}>
            {!!suggestionsLoadError && <ErrorRenderer error={suggestionsLoadError} />}
            {children}
          </div>
        </Menu>
      )
    },
    [suggestionsLoadError]
  )

  const hangleChange = useEvent((event, { newValue, method }) => {
    if (!['type', 'up', 'down'].includes(method)) return
    event.preventDefault()
    onValueChange(newValue ?? '')
  })

  const handleInnerFocus = useEvent(() => {
    setFocused(true)

    setTimeout(() => {
      const el = inputRef.current
      if (el) {
        el.focus()
        if (selectAllOnFocus && el instanceof HTMLInputElement) {
          selectAllInInput(el)
        } else {
          moveCursorToEnd(el)
        }
      }
    }, 100)
  })

  const handleFocus = useEvent((_onFocus, event: FocusEvent<HTMLInputElement>) => {
    selectedRef.current = false
    setFocused(true)
    onFocus(event)
    onValueChange(stringValue)

    const el = inputRef.current
    if (el) {
      if (selectAllOnFocus && el instanceof HTMLInputElement) {
        selectAllInInput(el)
      } else {
        moveCursorToEnd(el)
      }
    }
  })

  const handleBlur = useEvent((_onBlur, event: FocusEvent<HTMLInputElement>) => {
    onBlur(event)
  })

  const handleClear = useEvent(() => {
    setFocused(false)
    if (!selectedRef.current) onChange(null, stringValue)
  })

  const handleSuggestionSelected = useEvent(async (event, { suggestion }) => {
    event.preventDefault()
    event.stopPropagation()
    selectedRef.current = true
    await onChange(suggestion, stringValue)
    setFocused(false)
  })

  const inputProps = useMemo(
    () => ({
      error,
      disabled,
      placeholder,
      value: stringValue ?? '',
      onChange: hangleChange,
      onFocus: handleFocus,
      onBlur: handleBlur,
      autoComplete: 'off',
      handleInnerFocus,
      richLabel: renderSuggestion(value, true),
      focused,
      loading,
    }),
    [error, loading, disabled, placeholder, stringValue, hangleChange, handleFocus, handleBlur, handleInnerFocus, renderSuggestion, value, focused]
  )

  const renderInputComponent = useCallback(
    ({ loading, error, disabled, className, handleInnerFocus, value, richLabel, focused, onKeyDown, placeholder, ...props }) => {
      if (!loading && focused) {
        return (
          <input
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={true}
            className={cn(className, { [classes.has_error]: !!error, [classes.is_disabled]: !!disabled }, theme?.input?.(!!error, !!disabled))}
            disabled={disabled}
            onKeyDown={e => {
              if (submitOnEnter && isKeyboardKeyEvent('Enter', e)) inputRef.current?.blur()
              onKeyDown(e)
            }}
            value={value}
            {...props}
            ref={inputRef}
          />
        )
      }

      ;(inputRef as { current: any }).current = {
        focus: () => {
          setFocused(true)
        },
      }

      return (
        <div className={classes.pseudo_text_input_container}>
          <div
            className={cn(
              className,
              classes.pseudo_text_input,
              { [classes.has_error]: !!error, [classes.is_disabled]: !!disabled },
              theme?.input?.(!!error, !!disabled)
            )}
            onClick={handleInnerFocus}
            onFocus={handleInnerFocus}
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex={0}
          >
            {loading ? '...' : richLabel ? richLabel : placeholder ? <span className={classes.placeholder}>{placeholder}</span> : UNICODE_CHAR.nbsp}
          </div>
        </div>
      )
    },
    [submitOnEnter, theme]
  )

  const innerRenderSuggestion = useCallback(
    id => {
      return renderSuggestion(id, false)
    },
    [renderSuggestion]
  )

  const innerGetSuggestionValue = useCallback(
    id => {
      return getSuggestionValue(id, false)
    },
    [getSuggestionValue]
  )

  return (
    <div className={cn(classes.root, theme?.root, className)} style={style}>
      <div className={cn(classes.container, theme?.container)} ref={containerRef}>
        <Autosuggest
          alwaysRenderSuggestions={true}
          focusInputOnSuggestionClick={false}
          getSuggestionValue={innerGetSuggestionValue}
          highlightFirstSuggestion={true}
          inputProps={inputProps}
          onSuggestionSelected={handleSuggestionSelected}
          onSuggestionsClearRequested={handleClear}
          onSuggestionsFetchRequested={fetchSuggestions}
          renderInputComponent={renderInputComponent}
          renderSuggestion={innerRenderSuggestion}
          renderSuggestionsContainer={renderSuggestionsContainer}
          suggestions={suggestions}
          theme={autosuggestTheme}
        />
        <div className={classes.buttons}>
          {!!(loading || suggestionLoading) && (
            <div className={cn(classes.loading_icon, theme?.loading)}>
              <SpinnerIcon />
            </div>
          )}
          {buttons?.map(({ icon, className, onPress }) => (
            <PseudoButton
              className={cn(classes.button, { [classes.has_error]: !!error, [classes.is_disabled]: !!disabled }, theme?.button?.(!!error, !!disabled))}
              disabled={!!disabled}
              key={icon}
              onPress={onPress}
            >
              <Icon className={className} icon={icon} />
            </PseudoButton>
          ))}
        </div>
      </div>
      <ErrorRenderer className={cn(classes.error, theme?.error)} error={typeof error === 'boolean' ? null : error} />
    </div>
  )
}

const widthExtractor: WidthExtractor = anchor => Math.max(300, anchor)

const PseudoButton: FunctionComponent<PropsWithChildren<{ className?: string; disabled: boolean; onPress: () => unknown }>> = ({
  className,
  disabled,
  onPress,
  children,
}) => {
  const clickHandler = useClickHandler(() => {
    onPress()
  })

  return (
    <div className={className} {...(disabled ? undefined : clickHandler)}>
      {children}
    </div>
  )
}
