import { IMPORT_MAP } from '@app/importMap'

import { AsyncRoute } from '@app/utils/routing/types'

const Sitter = () => IMPORT_MAP.routes.Profile.Sitter().then(m => m.Sitter)

// @routes declaration
const routes: AsyncRoute[] = [
  {
    path: '/s/:sitter_id',
    event_id: 'sitter_profile',
    event_params: { profile_user_id: 'sitter_id' },
    component: Sitter,
  },
  {
    path: '/s/:sitter_id/:description',
    event_id: 'sitter_profile',
    event_params: { profile_user_id: 'sitter_id' },
    component: Sitter,
  },
  {
    path: '/:sitter_id',
    event_id: 'sitter_profile',
    event_params: { profile_user_id: 'sitter_id' },
    component: Sitter,
  },
  {
    path: '/:sitter_id/:description',
    event_id: 'sitter_profile',
    event_params: { profile_user_id: 'sitter_id' },
    component: Sitter,
  },
]

export default routes
