import { IMPORT_MAP } from '@app/importMap'

import { AsyncRoute } from '@app/utils/routing/types'

// @routes declaration
const routes: AsyncRoute[] = [
  {
    path: '/',
    event_id: 'not_approved_sitter',
    component: () => IMPORT_MAP.routes.CandidateSitter.CandidateSitter().then(m => m.CandidateSitter),
  },
]

export default routes
