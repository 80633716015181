import { RelatedApp } from '@app/utils/getInstalledRelatedApps'

import { setRelatedApps } from '@app/store/actions/ui.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{ loaded: boolean; list: RelatedApp[] }>({ loaded: false, list: [] }, builder => {
  builder.addCase(setRelatedApps.shape, (_state, action) => {
    return { loaded: true, list: action.payload }
  })
})
