import { MAP_AREA_KIND } from '@app/constants/Misc'

import { Address } from '@app/utils/address'

import { updateLocation } from '@app/store/actions/location.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  /** Address that is house */
  house: Address | null
  /** Address that is at least has address */
  address: Address | null
  /** Address that is address or just map area */
  location: Address | null
}>({ house: null, address: null, location: null }, builder => {
  builder.addCase(updateLocation.shape, (state, action) => {
    return {
      house: action.payload.isHouse() ? action.payload : state.house,
      address: action.payload.kind !== MAP_AREA_KIND ? action.payload : state.address,
      location: action.payload,
    }
  })
})
