/** Selects all text in given input or textarea */
export function selectAllInInput(input: HTMLElement) {
  if ('select' in input && typeof input.select === 'function') {
    input.select()
  } else if (typeof window.getSelection !== 'undefined' && typeof document.createRange !== 'undefined') {
    const range = document.createRange()
    const sel = window.getSelection()!

    range.selectNodeContents(input)
    sel.removeAllRanges()
    sel.addRange(range)
  } else if (typeof (document as any).selection !== 'undefined' && typeof (document.body as any).createTextRange !== 'undefined') {
    const textRange = (document.body as any).createTextRange()

    textRange.moveToElementText(input)
    textRange.select()
  }
}
