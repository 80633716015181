import { StaticRedirect } from './routing/StaticRedirect'
import { MatchedRoute } from './routing/types'

type RouteResult = { type: 'promise'; promise: () => Promise<void>; stop?: boolean } | { type: 'redirect'; redirect: StaticRedirect }

export async function processRoutes(routes: MatchedRoute<any>[], action: (route: MatchedRoute) => Promise<RouteResult | void>): Promise<StaticRedirect | void> {
  for (const route of routes) {
    const result = await action(route)
    if (result?.type === 'redirect') return result.redirect

    if (result?.type === 'promise') {
      await result.promise()
      if (result.stop) return
    }
  }
}
