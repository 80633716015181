import { loadScript } from '@app/utils/loadScript'

export interface CloudPaymentsRequest {
  publicId: string
  description: string
  amount: number
  /** Three letter encoding, e.g 'RUB' | 'EUR' | 'USD' | 'GBP' | 'UAH' */
  currency: string
  invoiceId?: string
  accountId?: string
  email?: string
  requireEmail?: boolean
  /** JSON string with any related parameters */
  data?: any
  skin?: 'classic' | 'modern' | 'mini'
}

interface CloudPaymentsWidget {
  pay(
    type: 'auth' | 'charge',
    request: CloudPaymentsRequest,
    data?: {
      onSuccess?: (request: CloudPaymentsRequest) => unknown
      onFail?: (reason: string, request: CloudPaymentsRequest) => unknown
    }
  ): unknown
  hideWindow(): void
}

interface CloudPaymentsConstructor {
  new (): CloudPaymentsWidget
}

export interface CloudPayments {
  CloudPayments: CloudPaymentsConstructor
}

let cloudPaymentsPromise: Promise<CloudPayments> | null = null

export const loadCloudPayments = () => {
  if (!cloudPaymentsPromise) {
    cloudPaymentsPromise = loadScript('https://widget.cloudpayments.ru/bundles/cloudpayments').then(() => {
      const cp = (window as any).cp
      if (!cp) throw new Error("Can't load CloudPayments")
      return cp
    })
  }
  return cloudPaymentsPromise
}

/** CloudPayments Hook */
export const useCloudPayments = (): (() => Promise<CloudPayments>) => {
  if (!IS_BROWSER) {
    return () => Promise.resolve({} as any)
  }
  return loadCloudPayments
}
