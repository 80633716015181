import { createThunk } from '@app/store/thunk'

import { hideSideMenu, showSideMenu } from './menu.descriptors'

export function toggleSideMenu() {
  return createThunk((dispatch, getState) => {
    const opened = getState().ui.menu.opened

    dispatch(opened ? hideSideMenu() : showSideMenu())
  })
}

export function hideSideMenuIfOpened() {
  return createThunk((dispatch, getState) => {
    const opened = getState().ui.menu.opened
    if (opened) dispatch(hideSideMenu())
  })
}
