import { useEffect, useRef } from 'react'

/**
 * Similar to useEffect hook, that just skips first run
 */
export const useOnChange = <T extends any[] | []>(cb: (prevDeps: T) => (() => void) | void, deps: T) => {
  const firstRun = useRef(true)
  const prevDeps = useRef<T>(deps)
  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false
      return
    }

    const cleanup = cb(prevDeps.current)
    prevDeps.current = deps

    return cleanup
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}
