import {
  getAppStoreCustomerReviewsByQueryDescriptor,
  getAppStoreCustomerReviewsSummaryDescriptor,
} from '@app/store/actions/appStoreCustomerReviews.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  total?: number
  total_pages?: number
  current_page?: number
  avg_rating?: number
  total_count?: number
  loading?: boolean
}>({}, builder => {
  builder.addCase(getAppStoreCustomerReviewsByQueryDescriptor.shapes.fulfilled, (state, action) => {
    return { ...state, ...action.payload.meta, loading: false }
  })
  builder.addCase(getAppStoreCustomerReviewsByQueryDescriptor.shapes.pending, state => {
    return { ...state, loading: true }
  })
  builder.addCase(getAppStoreCustomerReviewsByQueryDescriptor.shapes.rejected, state => {
    return { ...state, loading: false }
  })
  builder.addCase(getAppStoreCustomerReviewsSummaryDescriptor.shapes.fulfilled, (state, action) => {
    return { ...state, ...action.payload.data.attributes }
  })
})
