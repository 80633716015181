import React, { FunctionComponent } from 'react'

import { EXTERNAL_LINKS_REL } from '@app/constants/Misc'

import { Link } from '@app/utils/routing/Link'

import { useClickHandler } from '@app/hooks/useClickHandler'

import { Icon, Icon36 } from '@app/components/Icon/Icon'

import classes from './TopNotification.module.scss'

interface Props {
  className?: string
  icon?: Icon36
  type: 'info' | 'success' | 'danger' | 'muted' | 'telegram'
  onClose?: () => unknown
  onClick?: () => unknown
  url?: string
  urlIsExternal?: boolean
  children?: React.ReactNode
}

export const TopNotification: FunctionComponent<Props> = ({ className, icon = 'info', type = 'info', onClose, onClick, url, urlIsExternal, children }) => {
  const closeHandler = useClickHandler(() => {
    onClose!()
  })

  const link =
    url && urlIsExternal ? (
      <a className={classes.link} href={url} onClick={onClick} rel={EXTERNAL_LINKS_REL} target="_blank">
        <div />
      </a>
    ) : url && urlIsExternal ? (
      <Link className={classes.link} eventName="top_notification.overlay_link" onClick={onClick} to={url}></Link>
    ) : null

  return (
    <div className={cn(classes.root, classes[`type${type}`], { [classes.clickable]: !!onClick }, className)} onClick={!url ? onClick : undefined}>
      <Icon className={classes.icon} icon={icon} size="36" />
      <span className={classes.content}>{children}</span>
      {link}
      {onClose && <Icon className={classes.closeIcon} icon="close" {...closeHandler} />}
    </div>
  )
}

export type WithVisibleHook = {
  useVisible: (
    /** unix time */
    now: number
  ) => boolean
}
