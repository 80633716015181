import React, { FunctionComponent, useRef } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'

import { IMPORT_MAP } from '@app/importMap'

import { isTruthy } from '@app/utils/isTruthy'
import { useAppDispatch } from '@app/utils/redux'

import { useEvent } from '@app/hooks/useEvent'

import { withProgress } from '@app/store/actions/initial'
import { profilePlaceInputValueSelector } from '@app/store/selectors/profile'

import { Icon } from '@app/components/Icon/Icon'
import { PlaceInput as RawPlaceInput } from '@app/components/PlaceInput/PlaceInput'
import { PlaceInputOnChangeCallback } from '@app/components/PlaceInput/shared'
import { SuggestInputTheme } from '@app/components/SuggestInput/SuggestInput'

import classes from './PlaceInput.module.scss'

export type PlaceInputProps = {
  className?: string
  initiator: string
  theme?: 'light' | 'dark'
}

export const PlaceInput: FunctionComponent<PlaceInputProps> = ({ className, initiator, theme = 'light' }) => {
  const { formatMessage } = useIntl()
  const dispatch = useAppDispatch()
  const rootRef = useRef<HTMLDivElement>(null)

  const inputValue = useSelector(profilePlaceInputValueSelector)

  const handlePlaceChange = useEvent<PlaceInputOnChangeCallback>(async value => {
    if (value) {
      const { changePlace } = await dispatch(withProgress(IMPORT_MAP.actions.profile()))
      const { restoreCurrency } = await dispatch(withProgress(IMPORT_MAP.actions.currencies()))
      await dispatch(
        changePlace({
          data: value.place,
          included: [value.region, value.country, value.locality].filter(isTruthy),
        })
      )
      await dispatch(restoreCurrency())
    }
  })

  const handleMagnifierClick = useEvent(() => {
    const input = rootRef.current?.querySelector('input') ?? rootRef.current?.querySelector('[tabindex="0"]')
    try {
      input?.focus()
    } catch {}
  })

  return (
    <div className={cn(classes.cnt, className, classes[theme])} ref={rootRef}>
      <RawPlaceInput
        className={classes.input}
        initiator={initiator}
        map={false}
        onChange={handlePlaceChange}
        placeholder={formatMessage(messages.placeholder)}
        theme={inputTheme}
        value={inputValue}
      />
      <Icon className={classes.magnifier} icon="magnifier" onClick={handleMagnifierClick} />
    </div>
  )
}

const inputTheme: SuggestInputTheme = {
  input: (_error, _disabled) => classes.raw_input,
}

const messages = defineMessages({
  placeholder: 'Начните вводить название',
})
