import { ISO8601 } from '@app/constants/Misc'

import { excludeAbortError } from '@app/errors/AbortError'

import moment from '@app/utils/moment'

import {
  deleteCardDescriptor,
  getCardDescriptor,
  postCardCloudpayments3dsDescriptor,
  postCardCloudpaymentsDescriptor,
} from '@app/store/actions/payment.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  loading: boolean
  loaded: boolean
  loadedAt: ISO8601 | null
  error: Error | null
}>({ loading: false, loaded: false, loadedAt: null, error: null }, builder => {
  builder.addCases(
    [
      postCardCloudpayments3dsDescriptor.shapes.pending,
      getCardDescriptor.shapes.pending,
      postCardCloudpaymentsDescriptor.shapes.pending,
      deleteCardDescriptor.shapes.pending,
    ],
    (state, _action) => {
      return { ...state, loading: true, error: null }
    }
  )

  builder.addCase(postCardCloudpaymentsDescriptor.shapes.fulfilled, (state, action) => {
    return {
      loading: false,
      loaded: action.payload.data.type === 'credit_cards' ? true : state.loaded,
      loadedAt: action.payload.data.type === 'credit_cards' ? moment().format() : state.loadedAt,
      error: null,
    }
  })

  builder.addCases([postCardCloudpayments3dsDescriptor.shapes.fulfilled, getCardDescriptor.shapes.fulfilled, deleteCardDescriptor.shapes.fulfilled], () => {
    return {
      loading: false,
      loaded: true,
      loadedAt: moment().format(),
      error: null,
    }
  })

  builder.addCases(
    [
      postCardCloudpayments3dsDescriptor.shapes.rejected,
      getCardDescriptor.shapes.rejected,
      postCardCloudpaymentsDescriptor.shapes.rejected,
      deleteCardDescriptor.shapes.rejected,
    ],
    (state, action) => {
      return { ...state, loading: false, error: excludeAbortError(action.payload) }
    }
  )
})
