import type { ApiGiftCard, ApiGiftCardType, ApiIncluded } from '@app/constants/ApiTypes/entities'

import { ApiActionBuilderDescriptor } from '@app/store/apiMiddleware/builderDescriptor'
import { createPayloadlessAction } from '@app/store/toolkit'

export const restoreGiftcardOrder = createPayloadlessAction('RESTORE_GIFTCARD_ORDER')

export const getGiftCardsTypesDescriptor = new ApiActionBuilderDescriptor().setLabel('GET_GIFT_CARDS_TYPES').setShape<{ data: ApiGiftCardType }>()
export const postGiftCardsDescriptor = new ApiActionBuilderDescriptor().setLabel('POST_GIFT_CARDS').setShape<{ data: ApiGiftCard; included?: ApiIncluded }>()
export const getGiftCardsByIdDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('GET_GIFT_CARDS_BY_ID')
  .setShape<{ data: ApiGiftCard; included?: ApiIncluded }>()
