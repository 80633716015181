import { PlanModifier } from '@app/constants/ApiTypes/misc'

import * as api from '@app/utils/api'
import { createCookie } from '@app/utils/cookies'
import moment from '@app/utils/moment'

import { ApiActionBuilder } from '@app/store/apiMiddleware/builder'
import { createThunk } from '@app/store/thunk'

import { getPlansDescriptor, setPlan } from './plan.descriptors'

const plans: PlanModifier[] = ['default']

const planCookie = createCookie(
  'kidsout__plan',
  (v): PlanModifier => {
    if (plans.indexOf(v as PlanModifier) === -1) return 'default'
    return v as PlanModifier
  },
  v => v || '',
  () => ({
    expires: moment().add(10, 'years').toDate(),
    httpOnly: false,
  })
)

export const getPlans = new ApiActionBuilder(getPlansDescriptor)
  .setInit((modifier: PlanModifier = 'default', force: boolean = false) => ({
    method: 'GET',
    endpoint: api.path('/api/v2/plans', { campaign: modifier }),
    headers: api.headers(),
    meta: { modifier },
    bailout: ({ plans }) => !force && !!plans.models[modifier],
  }))
  .build()

export const setPlanModifier = (value: PlanModifier) => {
  return createThunk((dispatch, getState, { cookies }) => {
    if (!value) return

    const state = getState()
    const currentPlan = state.plans.modifier
    // do not overwrite if some non-default plan already exists
    if (currentPlan !== 'default') return

    planCookie.set(cookies, value)
    dispatch(setPlan(value))
  })
}

export const restorePlanModifier = () => {
  return createThunk((dispatch, _getState, { cookies }) => {
    const plan = planCookie.get(cookies)

    dispatch(setPlan(plan))
  })
}
