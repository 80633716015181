import { ErrorResponse, ErrorResponseItem } from '@app/constants/ApiTypes/misc'

import { LocalizedError } from '@app/errors/LocalizedError'

import { isApiError } from '@app/store/apiMiddleware/errors'

import { SerializableError } from './SerializableError'
import { uppercaseFirstLetter } from './uppercaseFirstLetter'

/** extracts errors from error payload, and returns list of strings */
export function extractErrors(...errors: (Error | null | undefined)[]): string[] {
  return errors.flatMap(extractError).reduce<string[]>((c, e) => {
    if (!e) return c
    if (c.indexOf(e) === -1) c.push(e)
    return c
  }, [])
}

export const extractMetaError = (e: Error | undefined | null, label: string): string | null => {
  if (!e) return null
  if (!isApiError(e)) return null

  const err = e.response?.errors
    ?.map(e => e.meta[label])
    .flatMap(x => (!x ? [] : Array.isArray(x) ? x.filter(x => !!x) : [x]))
    .join(', ')

  return err || null
}

function detailOrTitle(e: ErrorResponseItem): string[] {
  return e.detail || e.title ? [e.detail || e.title] : []
}

/** extracts errors from error payload, and returns list of strings */
function extractError(error?: Error | null): string[] {
  if (!error) return []

  if (error instanceof SerializableError) return extractError((error.cause as any) ?? null)

  if (error instanceof LocalizedError) return [error.localizedMessage]
  const response: ErrorResponse = (error as any)?.response
  if (response) {
    const list = response.errors.flatMap(detailOrTitle).map(uppercaseFirstLetter)
    if (list.length) return list
  }

  return [error.message]
}
