import clipboardCopy from 'clipboard-copy'

import { getApplicationBridge } from '@app/store/actions/initial'
import { Store } from '@app/store/store'

export class ClipboardManager {
  static shared = new ClipboardManager()
  store!: Store

  /** On android webview ignores clipboard copy, so we pass event handled by the app */
  isApp: boolean = false

  copy(text: string) {
    clipboardCopy(text)

    if (this.isApp) {
      const bridge = this.store.dispatch(getApplicationBridge())
      bridge?.sendMessage({
        type: 'copy_to_clipboard',
        text,
      })
    }
  }
}
