import { IMPORT_MAP } from '@app/importMap'

import { AsyncRoute } from '@app/utils/routing/types'

const ProfileSitter = () => IMPORT_MAP.routes.Settings.ProfileSitter().then(m => m.ProfileSitter)
const ProfilePhone = () => IMPORT_MAP.routes.Settings.ProfilePhone().then(m => m.ProfilePhone)
const ProfileParent = () => IMPORT_MAP.routes.Settings.ProfileParent().then(m => m.ProfileParent)
const Calendar = () => IMPORT_MAP.routes.Settings.Calendar().then(m => m.Calendar)

const RedirectToRegistration = () => IMPORT_MAP.components.Redirect().then(m => m.createRedirect('/registration'))

// @routes declaration
const routes = (redirect: boolean, account_type: 'visitor' | 'parent' | 'sitter'): AsyncRoute[] => {
  const routes: AsyncRoute[] = []

  if (redirect) {
    routes.push({
      path: '/',
      event_id: 'redirect_to_registration',
      component: RedirectToRegistration,
    })
  }

  if (account_type === 'visitor') throw new Error('account type is Visitor')

  if (account_type === 'sitter') {
    routes.push(
      {
        path: '/registration',
        event_id: 'sitter_registration',
        component: ProfileSitter,
      },
      {
        path: '/registration/phone',
        event_id: 'phone_registration',
        component: ProfilePhone,
      },
      {
        path: '/registration/schedule',
        event_id: 'schedule_registration',
        component: Calendar,
      }
    )
  } else {
    routes.push(
      {
        path: '/registration',
        event_id: 'parent_registration',
        component: ProfileParent,
      },
      {
        path: '/registration/phone',
        event_id: 'phone_registration',
        component: ProfilePhone,
      }
    )
  }

  return routes
}

export default routes
