import { IMPORT_MAP } from '@app/importMap'

import { makeRedirectRoute } from '@app/utils/makeRedirectRoute'
import { AsyncRoute } from '@app/utils/routing/types'

// @routes declaration
const routes: AsyncRoute[] = [
  {
    path: '/complete',
    event_id: 'registration_completed',
    component: () => IMPORT_MAP.routes.Settings.RegistrationComplete().then(m => m.Parent),
  },
  {
    path: '/settings',
    event_id: 'settings',
    component: () => IMPORT_MAP.routes.Settings.ProfileParent().then(m => m.ProfileParent),
  },
  {
    path: '/notification-settings',
    event_id: 'notification_settings',
    component: () => IMPORT_MAP.routes.Settings.Notifications().then(m => m.NotificationSettings),
  },
  makeRedirectRoute('/notification_settings', '/notification-settings', 'notification-settings'),
  {
    path: '/history',
    event_id: 'history',
    component: () => IMPORT_MAP.routes.Settings.History().then(m => m.History),
  },
  {
    path: '/billing',
    event_id: 'billing',
    component: () => IMPORT_MAP.routes.Settings.Billing().then(m => m.default),
  },
  {
    path: '/billing/:step',
    event_id: 'billing',
    event_params: { step: 'step' },
    component: () => IMPORT_MAP.routes.Settings.Billing().then(m => m.default),
  },
  {
    path: '/favorites',
    event_id: 'favorites',
    component: () => IMPORT_MAP.routes.Settings.Favorites().then(m => m.default),
  },
  {
    path: '/trusted',
    event_id: 'trusted',
    component: () => IMPORT_MAP.routes.Settings.Trusted().then(m => m.default),
  },
  {
    path: '/promocode',
    event_id: 'promocode',
    component: () => IMPORT_MAP.routes.Settings.Promocode().then(m => m.Promocode),
  },
  {
    path: '/referrals',
    event_id: 'referrals',
    component: () => IMPORT_MAP.routes.Settings.Referrals().then(m => m.Referrals),
  },
  {
    path: '/reviews',
    event_id: 'reviews',
    component: () => IMPORT_MAP.routes.Settings.Reviews().then(m => m.Reviews),
  },
]

export default routes
