import isEqual from 'lodash/isEqual'

/**
 * Merges maps
 *
 * ### Guarantees
 * * if given patch values all equal to map's one, then original map ref will be returned
 * * if patch's value equal to one in map, then map's will be stored
 *
 * @param map
 * @param patch
 */
export function mergeMaps<V>(map: { [key: string]: V }, patch: { [key: string]: V }): { [key: string]: V } {
  let changed = false
  const checked = Object.entries(patch).reduce<{ [key: string]: V }>((c, v) => {
    const stored = map[v[0]]
    if (isEqual(stored, v[1])) {
      c[v[0]] = stored
    } else {
      changed = true
      c[v[0]] = v[1]
    }
    return c
  }, {})
  if (!changed) return map
  return { ...map, ...checked }
}
