import { ApiLink } from '@app/constants/ApiTypes/entities'

import { normalize } from '@app/utils/normalizer'

import { getLinksDescriptor } from '@app/store/actions/initial.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{ [key: string]: ApiLink }>({}, builder => {
  builder.addCase(getLinksDescriptor.shapes.fulfilled, (state, action) => {
    return { ...state, ...normalize(action.payload).links }
  })
})
