import React, { FunctionComponent } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'

import { StoreState } from '@app/store/store'

import { TopNotification, WithVisibleHook } from '@app/components/TopNotification/TopNotification'

export const ConnectionStatusBanner: FunctionComponent & WithVisibleHook = () => {
  const { formatMessage } = useIntl()
  return <TopNotification type="muted">{formatMessage(messages.no_connection)}</TopNotification>
}

ConnectionStatusBanner.useVisible = () => {
  return !useSelector(onlineSelector)
}

const onlineSelector = (state: StoreState) => state.config.online

const messages = defineMessages({
  no_connection: 'Нет соединения...',
})
