import { AsyncRoute } from '@app/utils/routing/types'

// @routes declaration
const routes: AsyncRoute[] = [
  {
    path: '/service-reviews',
    event_id: 'service_reviews',
    component: () => import('./ServiceReviews').then(m => m.ServiceReviews),
  },
]

export default routes
