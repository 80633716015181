import type { ReactNode } from 'react'

import { IMPORT_MAP } from '@app/importMap'

import { AbortError } from '@app/errors/AbortError'

import { createCookie } from '@app/utils/cookies'
import { getInstalledRelatedApps } from '@app/utils/getInstalledRelatedApps'
import moment from '@app/utils/moment'

import { createThunk } from '@app/store/thunk'
import { BannerLabel, BannersState } from '@app/store/types/banners'

import type { ActionButtonsType } from '@app/components/ActionButtonsModal/ActionButtonsType'

import { setRelatedApps, uiClearBannersState, uiSetBannersState } from './ui.descriptors'

export function restoreBanners() {
  return createThunk((dispatch, _getState, { cookies }) => {
    try {
      const state = bannersStateCookie.get(cookies)
      dispatch(uiSetBannersState(state))
    } catch {
      dispatch(uiSetBannersState({}))
    }
  })
}

export function setBannersState(state: BannersState) {
  return createThunk((dispatch, _getState, { cookies }) => {
    try {
      const prevState = bannersStateCookie.get(cookies)
      bannersStateCookie.set(cookies, { ...prevState, ...state })
    } catch {}
    dispatch(uiSetBannersState(state))
  })
}

export function clearBannersState() {
  return createThunk((dispatch, _getState, { cookies }) => {
    try {
      bannersStateCookie.rm(cookies)
    } catch {}
    dispatch(uiClearBannersState())
  })
}

export function restoreRelatedApps() {
  return createThunk(async dispatch => {
    const apps = await getInstalledRelatedApps()
    dispatch(setRelatedApps(apps))
  })
}

export function showError(options: { error: Error | Error[]; onClose?: () => unknown }) {
  return createThunk(async (_dispatch, _getState, context) => {
    return new Promise<void>(resolve => {
      context.mount!.push(
        IMPORT_MAP.modals.ErrorModal().then(m => m.ErrorModal),
        { props: options, onClose: resolve }
      )
    })
  })
}

const shortlabels = {
  add_telegram: 'adt' as const,
  app_store: 'aps' as const,
  auto_accept_sitter: 'aas' as const,
  commission_payment: 'cp' as const,
  online_announcement: 'oa' as const,
  push_notifications: 'pn' as const,
  social_network_bind: 'snb' as const,
} satisfies Record<BannerLabel, string>

const bannersStateCookie = createCookie<BannersState>(
  'kidsout__banners-state',
  data =>
    Object.fromEntries(
      data.split('\n').flatMap<[string, { value: boolean; expire?: number }][]>(line => {
        try {
          const [key, value, expire] = line.split(':')
          for (const [longkey, shortkey] of Object.entries(shortlabels)) {
            if (key === shortkey) {
              return [[longkey, { value: value === '1', expire: expire ? parseInt(expire, 10) : undefined }]]
            }
          }
          return []
        } catch {
          return []
        }
      })
    ),
  raw =>
    Object.entries(raw)
      .map(([key, value]) => {
        for (const [longkey, shortkey] of Object.entries(shortlabels)) {
          if (key === longkey) {
            return `${shortkey}:${value.value ? '1' : '0'}:${value.expire || ''}`
          }
        }
        return ''
      })
      .filter(Boolean)
      .join('\n'),
  () => ({ expires: moment().add(1, 'year').toDate() })
)

export function confirm({ name, title, content, closeButtonContent }: { name: string; title?: ReactNode; content: ReactNode; closeButtonContent?: ReactNode }) {
  return createThunk(async (_dispatch, _getState, { mount }) => {
    return new Promise<boolean>(resolve => {
      mount!.push(
        IMPORT_MAP.modals.Confirm().then(m => m.Confirm),
        {
          props: {
            name,
            title,
            content,
            closeButtonContent,
            onClose: resolve,
          },
        }
      )
    })
  })
}

export function ask<T>({
  name,
  title,
  text,
  actions,
}: {
  name: string
  title?: ReactNode
  text?: ReactNode
  actions: {
    name: string
    type?: ActionButtonsType
    text: string
    onClick: () => T
  }[]
}) {
  return createThunk((_dispatch, _getState, { mount }) => {
    return new Promise<T>((resolve, reject) => {
      let resolved = false
      const close = mount?.push(
        import('@app/components/ActionButtonsModal/ActionButtonsModal').then(m => m.ActionButtonsModal),
        {
          props: {
            name,
            title,
            text,
            actions: actions.map(a => {
              return {
                ...a,
                onClick: async () => {
                  const res = await a.onClick()
                  resolved = true
                  await close?.()
                  resolve(res)
                },
              }
            }),
          },
          onClose: () => {
            if (!resolved) {
              reject(new AbortError('Modal closed without action'))
            }
          },
        }
      )
    })
  })
}
