import { createPath, LocationDescriptorObject } from 'history'

import { Location } from './routing/types'

/**
 * Replaces 2+ slashes with single and removes trailing slash
 */
export function normalizePath(path: string): string {
  if (!path) return '/'
  if (ONLY_SLASH_REGEX.test(path)) return '/'
  return path.replace(DOUBLE_SLASH_REGEX, '/').replace(TRAILING_SLASH_REGEX, '')
}

export function addLeadingSlash(path: string) {
  return path.charAt(0) === '/' ? path : '/' + path
}

export function addBasename(basename: string, location: Location) {
  if (!basename) return location

  return {
    ...location,
    pathname: addLeadingSlash(basename) + location.pathname,
  }
}

export function stripBasename(basename: string, location: Location) {
  if (!basename) return location

  const base = addLeadingSlash(basename)

  if (location.pathname.indexOf(base) !== 0) return location

  return {
    ...location,
    pathname: location.pathname.substring(base.length),
  }
}

export function createURLString(location: string | LocationDescriptorObject<any>) {
  return typeof location === 'string' ? location : createPath(location)
}

export const pathIsSubPathOf = (path: string, child: string): boolean => {
  const npath = normalizePath(path)
  const nchild = normalizePath(child)

  const len = npath.split('/').length
  const trnchild = nchild.split('/').slice(0, len).join('/')

  return npath === trnchild
}

const ONLY_SLASH_REGEX = /^\/+$/
const DOUBLE_SLASH_REGEX = /\/{2,}/g
const TRAILING_SLASH_REGEX = /\/+$/g
