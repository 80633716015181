import React, { ComponentProps, forwardRef, PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { createLocation } from 'history'
import { useSelector } from 'react-redux'
import { NavLinkProps, NavLink as ReactLink } from 'react-router-dom'

import { AnalyticsEvent } from '@app/services/AnalyticsEvent'

import { createURLString } from '@app/utils/url'

import { useEvent } from '@app/hooks/useEvent'

import { profileRegionSlugSelector, profileUserAccountTypeSelector } from '@app/store/selectors/profile'

import { useRouter } from './hooks'
import { LocationState } from './types'

type Props = PropsWithChildren &
  Omit<NavLinkProps, 'to' | 'referrerPolicy' | 'state'> &
  Pick<ComponentProps<'a'>, 'referrerPolicy'> & {
    eventName: string
    /** whether link should be prefixed with current region */
    region?: boolean
    to?: NavLinkProps['to']
    state?: LocationState
  }

/**
 * AppLink is a wrapper for `react-router.Link`
 * that supports regional link prefixing
 */
export const NavLink = forwardRef<HTMLAnchorElement, Props>(function NavLink(
  { eventName, to, region = false, className, style, children, onClick, ...rprops },
  ref
) {
  const currentRegion = useSelector(profileRegionSlugSelector)
  const accountType = useSelector(profileUserAccountTypeSelector)
  const router = useRouter()
  if (!router) throw new Error('You should not use <NavLink> outside a <Router>')
  const [browser, setBrowser] = useState(false)

  const location = useMemo(() => {
    if (!to) return null
    if (typeof to === 'string' && to.startsWith('http://')) return to
    if (typeof to === 'string' && to.startsWith('https://')) return to
    const location = createLocation<LocationState | undefined>(to)

    if (region && currentRegion && (!accountType || accountType === 'visitor')) {
      location.pathname = `/${currentRegion}/${location.pathname}`.replace(/\/{2,}/, '/').replace(/\/$/, '')
    }

    location.state = location.state ?? {}
    location.state.link = true

    return location
  }, [to, region, currentRegion, accountType])

  const handleClick = useEvent(e => {
    if (typeof location === 'string') {
      new AnalyticsEvent(`${eventName}.click`, {
        pathname: location,
        search: '',
        hash: '',
      }).sendInhouse()
    } else {
      new AnalyticsEvent(`${eventName}.click`, {
        pathname: location?.pathname ?? null,
        search: location?.search ?? null,
        hash: location?.hash ?? null,
      }).sendInhouse()
    }
    onClick?.(e)
  })

  useEffect(() => {
    setBrowser(true)
  }, [])

  if (!location || typeof location === 'string') {
    const clsname = typeof className === 'function' ? className({ isActive: false, isPending: false, isTransitioning: false }) : className
    const stl = typeof style === 'function' ? style({ isActive: false, isPending: false, isTransitioning: false }) : style
    return (
      <a className={clsname} href={location || undefined} onClick={eventName ? handleClick : onClick} style={stl} {...rprops}>
        {children}
      </a>
    )
  }

  if (!browser) {
    const clsname = typeof className === 'function' ? className({ isActive: false, isPending: false, isTransitioning: false }) : className
    const stl = typeof style === 'function' ? style({ isActive: false, isPending: false, isTransitioning: false }) : style

    return (
      <a className={clsname} href={createURLString(location)} onClick={eventName ? handleClick : onClick} style={stl} {...rprops} ref={ref}>
        {children}
      </a>
    )
  }

  return (
    <ReactLink
      className={className}
      onClick={eventName ? handleClick : onClick}
      replace={location.pathname === router.location.pathname}
      style={style}
      to={location}
      {...rprops}
      ref={ref}
    >
      {children}
    </ReactLink>
  )
})
