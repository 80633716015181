import { loadScript } from '@app/utils/loadScript'

export class FacebookLoader {
  static shared = new FacebookLoader()

  loadPromise: Promise<void> | null = null

  async load() {
    if (!this.loadPromise) {
      this.loadPromise = loadScript('https://connect.facebook.net/ru_RU/sdk.js')
    }
    return this.loadPromise
  }
}
