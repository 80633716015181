import React, { ComponentProps, memo, MouseEvent, useCallback } from 'react'

import { useRouter } from '@app/utils/routing/hooks'

export type Props = {
  html: string
  tag?: 'div' | 'span' | 'p'
} & ComponentProps<'div'>

/**
 * USE WITH CAUTION, DON'T USE WITH USER-GENERATED CONTENT
 */
export const HTMLText = memo<Props>(function HTMLText(props) {
  const router = useRouter()

  const handleClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      const target: HTMLElement = event.target as any
      if (target.nodeName !== 'A') return
      const href = target.getAttribute('href') || ''
      const targetAttr = target.getAttribute('target')

      if (targetAttr && targetAttr !== '_self') return
      if (!(/^\/[^/]/.test(href) || href === '/')) return

      event.preventDefault()
      event.stopPropagation()
      router.history.push(href)
    },
    [router]
  )

  const { html, tag = 'span', ...rprops } = props
  return React.createElement(tag, {
    ...rprops,
    onClick: handleClick,
    dangerouslySetInnerHTML: { __html: html },
  })
})
