import uniqBy from 'lodash/uniqBy'

import { ISO8601 } from '@app/constants/Misc'

import { excludeAbortError } from '@app/errors/AbortError'

import { createSortHandler } from '@app/utils/createSortHandler'
import moment from '@app/utils/moment'
import { normalize } from '@app/utils/normalizer'
import { omit } from '@app/utils/omit'

import { getNewsDescriptor, postNewsReadDescriptor } from '@app/store/actions/news.descriptors'
import { createReducer } from '@app/store/toolkit'

export interface Article {
  id: string
  published_at: string
  read: boolean
  body: string
}

export default createReducer<{
  currentPage: number
  total: number
  totalPages: number
  loading: boolean
  fetchedAt: ISO8601 | null
  error: Error | null
  models: Article[]
}>(
  {
    currentPage: 0,
    total: 0,
    totalPages: 0,
    loading: false,
    fetchedAt: null,
    error: null,
    models: [],
  },
  builder => {
    builder.addCase(getNewsDescriptor.shapes.pending, (state, _action) => {
      return { ...state, loading: true, error: null }
    })

    builder.addCase(getNewsDescriptor.shapes.fulfilled, (state, action) => {
      const { news } = normalize(action.payload)
      if (!news) return state

      const models: Article[] = uniqBy(
        [
          ...Object.values(news).map(
            (model): Article => ({
              id: model.id,
              ...omit(model.attributes, 'id'),
            })
          ),
          ...(action.payload.meta.current_page === 1 ? [] : state.models),
        ],
        a => a.id
      ).sort(createSortHandler(a => [Date.parse(a.published_at)]))

      return {
        ...state,
        currentPage: action.payload.meta.current_page,
        total: action.payload.meta.total,
        totalPages: action.payload.meta.total_pages,
        loading: false,
        fetchedAt: action.payload.meta.current_page === 1 ? moment().format() : state.fetchedAt,
        error: null,
        models,
      }
    })

    builder.addCase(getNewsDescriptor.shapes.rejected, (state, action) => {
      return { ...state, loading: false, error: excludeAbortError(action.payload) }
    })

    builder.addCase(postNewsReadDescriptor.shapes.fulfilled, (state, _action) => {
      let changed = false
      let models = state.models.map(m => {
        if (m.read) return m
        changed = true
        return { ...m, read: true }
      })
      if (!changed) {
        models = state.models
      }
      return { ...state, models }
    })
  }
)
