import once from 'lodash/once'

import { IMPORT_MAP } from '@app/importMap'

import { SITTER_SCREENING_ENABLED } from '@app/constants/Misc'

import { isTruthy } from '@app/utils/isTruthy'
import { makeRedirectRoute } from '@app/utils/makeRedirectRoute'
import { AsyncRoute } from '@app/utils/routing/types'

import { createStaticPageRenderer } from './components/StaticPageRenderer'
import { NavItem, NavItemsManager } from './staticMenu'

const RedirectToSchool = () => IMPORT_MAP.components.Redirect().then(m => m.createRedirect('/babysitter/school', true))

// @routes declaration
const routes: (accountType: 'parent' | 'sitter' | 'visitor' | null) => AsyncRoute[] = accountType => [
  {
    path: '/babysitter',
    regional: true,
    event_id: 'main_page_babysitter',
    component: () => IMPORT_MAP.routes.Static.LandingSitter().then(m => m.default),
  },
  {
    path: '/babysitter/school',
    regional: true,
    event_id: 'school_babysitters',
    component: () => IMPORT_MAP.routes.Static.School().then(m => m.default),
  },
  {
    path: '/school',
    event_id: 'redirect_to_school',
    component: RedirectToSchool,
  },
  {
    path: '/school/babysitters',
    event_id: 'school_babysitters',
    component: RedirectToSchool,
  },
  {
    path: '/school/nanny',
    event_id: 'school_nanny',
    component: () => IMPORT_MAP.routes.Static.SchoolNanny().then(m => m.SchoolNanny),
  },
  {
    path: '/school-v2',
    event_id: 'school_v2',
    component: RedirectToSchool,
  },
  makeRedirectRoute('/school_v2', '/school-v2', 'school_v2'),

  // static routes
  {
    path: '/how-it-works',
    event_id: 'how_it_works',
    component: () => IMPORT_MAP.routes.Static.HowItWorks().then(m => m.HowItWorks),
  },
  ...(SITTER_SCREENING_ENABLED
    ? [
        {
          path: '/sitter-screening',
          event_id: 'sitter_screening',
          component: () => import('./components/LandingSitterCheck/LandingSitterCheck').then(m => m.LandingSitterCheck),
        },
      ]
    : []),
  {
    path: '/account-deletion',
    event_id: 'account_deletion',
    component: () => import('./components/AccountDeletion/AccountDeletion').then(m => m.AccountDeletion),
  },
  {
    path: '/account-deletion/:id',
    event_id: 'account_deletion',
    component: () => import('./components/AccountDeletion/AccountDeletion').then(m => m.AccountDeletion),
  },
  {
    path: '/contacts',
    event_id: 'contacts',
    component: () => IMPORT_MAP.routes.Static.Contacts().then(m => m.default),
  },
  {
    path: '/faq',
    event_id: 'faq',
    component: () => IMPORT_MAP.routes.Static.Faq().then(m => m.default),
  },
  {
    path: '/insurance',
    event_id: 'insurance',
    component: () => IMPORT_MAP.routes.Static.Insurance().then(m => m.default),
  },
  {
    path: '/tos',
    event_id: 'tos',
    component: () => IMPORT_MAP.components.Redirect().then(m => m.createRedirect('/licence')),
  },
  ...NavItemsManager.shared.items
    .filter((item): item is NavItem => item.type === 'item' && !item.side)
    .flatMap(item => [
      {
        path: item.link,
        event_id: item.event_id,
        component: once(() => Promise.resolve(createStaticPageRenderer(item))),
      },
      ...(item.aliases || []).map(alias => makeRedirectRoute(alias, item.link, item.event_id)),
    ]),
  ...(accountType === 'sitter'
    ? [
        {
          path: '/sitter-interactions',
          event_id: 'sitter_interactions',
          component: () => IMPORT_MAP.components.Redirect().then(m => m.createRedirect('/rules')),
        },
        makeRedirectRoute('/sitter_interactions', '/sitter-interactions', 'sitter_interactions'),
      ]
    : []),
  // static routes with menu
  {
    component: () => IMPORT_MAP.routes.Static.StaticLayoutWithMenu().then(m => m.default),
    routes: NavItemsManager.shared.items
      .flatMap(item => {
        if (item.type !== 'item') return []
        if (!item.side) return []
        if (item.show && !item.show(accountType)) return []
        const component = createStaticPageRenderer(item)
        return [
          { path: item.link, event_id: item.event_id, component: () => Promise.resolve(component) },
          ...(item.aliases || []).map(alias => makeRedirectRoute(alias, item.link, item.event_id)),
        ]
      })
      .filter(isTruthy),
  },
]

export default routes
