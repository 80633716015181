import { noop } from './noop'

export class AsyncQueue {
  private promise: Promise<any> = Promise.resolve()

  queued = <T extends any[], R>(fn: (...args: T) => Promise<R>): ((...args: T) => Promise<void>) => {
    return (...args: T) => {
      const newPromise = this.promise
        .then(async () => {
          await fn(...args)
          return
        })
        .catch(noop)
      this.promise = newPromise
      return newPromise
    }
  }
}
