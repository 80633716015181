import { ApiPersonnelCheckOrder, ApiPersonnelCheckType } from '@app/constants/ApiTypes/entities'

import { ApiActionBuilderDescriptor } from '@app/store/apiMiddleware/builderDescriptor'

export const getPersonnelCheckTypesDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('GET_PERSONNEL_CHECK_TYPES')
  .setShape<{ data: ApiPersonnelCheckType[] }>()
export const postPersonnelCheckOrdersDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('POST_PERSONNEL_CHECK_ORDERS')
  .setShape<{ data: ApiPersonnelCheckOrder }>()
