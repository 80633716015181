export class TimeMeasurer {
  private ts: number

  constructor() {
    this.ts = Date.now()
  }

  log(label: string) {
    console.info(`[${label}]`, 'at', this.diff())
  }

  diff() {
    return `${Date.now() - this.ts}ms`
  }
}
