import isEqual from 'lodash/isEqual'

/**
 * Merges entities from v2 api
 *
 * ### Guarantees:
 * * If new object has relationships that has `included: false` meta flag then relationship from old object will be used
 * * If objects attributes are deep equal then old object attributes will be used (so no pointless rerender will happen)
 * * If all relationships in new object are not included then old object relationships object reference will be used
 * * If all relationships in new object are not included and attributes are equal then old object reference will be used
 *
 * ### Should be used when:
 * * You received entities from different api endpoints and endpoint does not include some relationships
 *
 * @param a old object
 * @param b new object
 */
export function mergeV2Entity<
  A extends object,
  R extends object,
  T extends {
    id: string
    type: string
    attributes: A
    relationships: R
  },
>(a: T | undefined | null, b: T): T {
  if (!a) return b
  const output = { ...b, relationships: a.relationships }
  const equal = isEqual(a.attributes, b.attributes)
  if (equal) {
    output.attributes = a.attributes
  }
  let changed = 0
  for (const key of Object.keys(b.relationships)) {
    if (b.relationships[key].data && !isEqual(a.relationships[key], b.relationships[key])) {
      ++changed
      output.relationships[key] = b.relationships[key]
    }
  }
  if (changed === 0) {
    if (equal) return a
    output.relationships = a.relationships
  }
  return output
}
