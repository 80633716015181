import React, { FunctionComponent, useMemo } from 'react'
import * as valita from '@badrap/valita'
import { defineMessages, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'

import { EXTERNAL_LINKS_REL } from '@app/constants/Misc'

import { maybeType } from '@app/utils/maybeType'
import { useAppDispatch } from '@app/utils/redux'

import { useBannerVisible } from '@app/hooks/useBannerVisible'
import { useClickHandler } from '@app/hooks/useClickHandler'

import { setBannersState } from '@app/store/actions/ui'
import { configSelector, metaSelector } from '@app/store/selectors/misc'
import { sessionSelector } from '@app/store/selectors/session'
import { relatedAppsSelector } from '@app/store/selectors/ui'

import { Icon } from '@app/components/Icon/Icon'
import { WithVisibleHook } from '@app/components/TopNotification/TopNotification'

import classes from './AppBanner.module.scss'

export const AppBanner: FunctionComponent & WithVisibleHook = () => {
  const { formatMessage } = useIntl()
  const dispatch = useAppDispatch()
  const meta = useSelector(metaSelector)
  const appData = useMemo(() => {
    return maybeType(RT_AppMeta, meta.data?.['google_app_description'])
  }, [meta.data])

  const handleOpen = useClickHandler(() => {
    dispatch(setBannersState({ app_store: { value: false, expire: new Date().getTime() + EXPIRE_DAYS_OPEN * 1000 * 3600 * 24 } }))
  }, false)

  const handleClose = useClickHandler(() => {
    dispatch(setBannersState({ app_store: { value: false, expire: new Date().getTime() + EXPIRE_DAYS_CLOSE * 1000 * 3600 * 24 } }))
  })

  if (!appData) return null

  return (
    <div className={classes.root}>
      <div className={classes.close} {...handleClose}>
        <Icon icon="close" />
      </div>
      <img alt="" className={classes.image} src={appData.image} />
      <div>
        <div>{appData.label}</div>
        <div className={classes.developer}>{appData.developer}</div>
        <div className={classes.subtitle}>{formatMessage(messages.subtitle)}</div>
      </div>
      <div className={classes.spacer} />
      <div className={classes.view}>{formatMessage(messages.view)}</div>
      <a className={classes.link} href={appData.url} {...handleOpen} rel={EXTERNAL_LINKS_REL} target="_blank">
        <div />
      </a>
    </div>
  )
}

AppBanner.useVisible = now => {
  const config = useSelector(configSelector)
  const isAndroid = config.userAgentData?.os.name === 'Android'
  const relatedApps = useSelector(relatedAppsSelector)
  const meta = useSelector(metaSelector)
  const appDataRaw = meta.custom?.find(c => c.name === 'google-play-app')
  const appDataResult = RT_AppMeta.try(appDataRaw)
  const visible = useBannerVisible('app_store', now)
  const session = useSelector(sessionSelector)

  return isAndroid && appDataResult.ok && !session.supervise && relatedApps.loaded && !relatedApps.list.find(a => a.id === appDataResult.value.id) && visible
}

const RT_AppMeta = valita.object({
  name: valita.string().optional(),
  content: valita.string().optional(),
  id: valita.string(),
  label: valita.string(),
  url: valita.string(),
  image: valita.string(),
  developer: valita.string(),
})

const EXPIRE_DAYS_CLOSE = 30
const EXPIRE_DAYS_OPEN = 90

const messages = defineMessages({
  view: 'Открыть',
  subtitle: 'Бесплатно в Play Store',
})
