import { createCookie } from '@app/utils/cookies'
import moment from '@app/utils/moment'
import { normalizePath } from '@app/utils/url'

/** slug which do not have own routes */
export const SLUGLESS_REGIONS = ['msk', 'world']

/** Matches if given string is in regions list, if not returns default region */
export function matchRegion(regions: string[], region: string): string | null {
  if (SLUGLESS_REGIONS.includes(region)) return null
  if (regions.includes(region)) return region
  return null
}

/** Parses region out of path. Example `/spb/something` -> `spb`. Defaults to DefaultRegion */
export function getRegionFromPath(regions: string[], path: string): string | null {
  const regex = new RegExp(`^\\/(${regions.join('|')})(\\/|\\/.*)?$`, 'i')
  if (!regex.test(path)) return null
  const index = path.substring(1).indexOf('/')
  return path.substring(1, index === -1 ? undefined : index + 1)
}

export const regionCookie = createCookie(
  'kidsout-region',
  i => i,
  i => i,
  () => ({ expires: moment().add(1, 'year').toDate(), path: '/' })
)

export const placeCookie = createCookie(
  'kidsout__place',
  i => i,
  i => i,
  () => ({ expires: moment().add(1, 'year').toDate(), path: '/' })
)

/** trims prefix out of pathname, ie `/spb/babysitter` becomes `/babysitter` */
export function trimRegionalRoute(regions: string[], pathname: string): string {
  const parts = normalizePath(pathname).split('/')
  if (regions.filter(r => !SLUGLESS_REGIONS.includes(r)).indexOf(parts[1] as any) !== -1) return '/' + parts.slice(2).join('/')
  return pathname
}

export function getRegionKeywords(regionSlug: string): string[] {
  if (regionSlug in regionKeywords) {
    return regionKeywords[regionSlug]
  }

  return []
}

/** Map of city to city usual keywords */
const regionKeywords: { [key: string]: string[] } = {
  msk: ['москва', 'мск'],
  spb: ['санкт-петербург', 'петербург', 'спб', 'питер'],
  tmn: ['тюмень'],
  ekb: ['екатеринбург', 'екат'],
  nsk: ['новосибирск'],
}
