export function getCurrentStackPlace(offset = 0): string {
  const stack = new Error().stack ?? ''
  const format = stack.includes('    at ') ? 'full' : 'short'
  const parts = stack.split('\n') ?? []
  if (!parts.length) return '<unknown>'
  if (format === 'full') {
    if (parts.length < 3 + offset) return '<unknown>'
    let out = parts[2 + offset]
    if (out.startsWith('    at ')) out = out.slice(7)
    return out
  }

  if (parts.length < 2 + offset) return '<unknown>'
  return parts[1 + offset]
}
